import {ApoEvents} from "./apoEvents";

export class ApoSelectController {
  static instance: ApoSelectController;
  private controller: AbortController;

  constructor() {
    if (ApoSelectController.instance) {
      return ApoSelectController.instance;
    }
    ApoSelectController.instance = this;

    this.controller = new AbortController();
    this.initSelectFields();
    this.registerGlobalEvents();
  }

  private toggleFloatingLabelClass(wrapper: HTMLElement, target: HTMLInputElement) {
    const label = wrapper.querySelector('label');
    if (label) {
      label.classList.add('floating-label');
      if (target.value) {
        label.classList.add('active');
      } else {
        label.classList.remove('active');
        target.blur();
      }
    }
  }

  private selectItemCallback(element: HTMLElement, select: HTMLSelectElement) {
    const inputText = select?.parentNode?.querySelector<HTMLInputElement>('input[id$="_text"]') ||
      select?.parentNode?.querySelector<HTMLInputElement>('input[name$="_text"]');

    const inputValue = select?.parentNode?.querySelector<HTMLInputElement>('input:not([name$="_text"])');
    Array.from(select.options).forEach((sOption, index) => {
      if (sOption.innerHTML === element.innerHTML && !sOption.classList.contains('disabled')) {
        const selectedArray = element?.parentNode?.parentNode?.querySelectorAll('.same-as-selected');
        const label = select?.parentNode?.querySelector('label');
        selectedArray?.forEach(selected => {
          selected.removeAttribute('class');
        });
        element.setAttribute('class', 'same-as-selected');
        select.selectedIndex = index;
        this.setInputValue(element.innerHTML, inputText as HTMLInputElement);
        element.dataset.value && this.setInputValue(element.dataset.value, inputValue as HTMLInputElement);
        label?.classList.add('active');
        inputText?.click();
      }
    });


  }

  private setInputValue(value: string, input: HTMLInputElement | null) {
    if (input) {
      input.setAttribute('value', value.trim());
      input.dispatchEvent(new Event('change'));
    }
  }

  private openSelectCallback(element: HTMLInputElement) {
    const forbiddenConditions = [
      element.classList.contains('disabled'),
      element.classList.contains('read-only'),
      element.getAttribute('disabled'),
      element.getAttribute('readonly')
    ];
    this.closeAllSelect(element);
    element.focus();

    if (forbiddenConditions.every(condition => !condition || condition === 'null')) {
      element?.nextElementSibling?.classList.toggle('select-hide');
      element.classList.toggle('select-arrow-active');
    }

    if (element.classList.contains('select-arrow-active')) {
      const label = element?.parentNode?.querySelector('label');
      label?.classList.remove('active');
    }
  }

  private closeAllSelect(element: HTMLElement) {
    const arrNo: number[] = [];
    const selectItems = document.querySelectorAll('.select-items');
    const selects = document.querySelectorAll<HTMLInputElement>('.select-selected');
    selects.forEach((select, index) => {
      if (element === select) {
        arrNo.push(index);
      } else {
        select.classList.remove('select-arrow-active');
      }
      select.parentNode && this.toggleFloatingLabelClass(select.parentNode as HTMLElement, select);
    });
    selectItems.forEach((selectItem, index) => {
      if (!arrNo.includes(index)) {
        selectItem.classList.add('select-hide');
      }
    });
  }

  private setSelectItemListeners(selectItem: HTMLDivElement, select: HTMLSelectElement) {
    console.log('setSelectItemListeners :>> ', selectItem, select);
    selectItem.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.selectItemCallback(selectItem, select);
    }, {signal: this.controller.signal});

    selectItem.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' || e.code === 'Space') {
        e.preventDefault();
        e.stopPropagation();
        this.selectItemCallback(selectItem, select);
      }
    }, {signal: this.controller.signal});
  }

  private setOpenSelectListeners(select: HTMLInputElement) {
    console.log('setOpenSelectListeners :>> ', select);
    select.addEventListener('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.openSelectCallback(select);
    }, {signal: this.controller.signal});

    select.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' || e.code === 'Space') {
        e.stopPropagation();
        e.preventDefault();
        this.openSelectCallback(select);
      }
    }, {signal: this.controller.signal});

    select.addEventListener('change', (e) => {
      e.stopPropagation();
      e.preventDefault();

      document.dispatchEvent(new CustomEvent(ApoEvents.SELECTFIELD_CHANGED, {
        detail: {
          target: e.target
        }
      }));

    }, {signal: this.controller.signal});
  }

  private setValidityListener(input: HTMLInputElement, wrapper: HTMLElement) {
    input.addEventListener('change', (e) => {
      const element = e.target as HTMLInputElement;
      if (element.value !== '') {
        wrapper.classList.remove('invalid');
        input?.offsetParent?.classList.remove('invalid');
      } else {
        wrapper.classList.add('invalid');
        input?.offsetParent?.classList.add('invalid');
      }
    }, {signal: this.controller.signal});
  }

  private registerGlobalEvents() {
    document.addEventListener('click', () => this.closeAllSelect);
    document.addEventListener(ApoEvents.SELECTFIELD_OPTIONS_CHANGED, this.refillOptions.bind(this));
    document.addEventListener(ApoEvents.STEPPER_UPDATE, this.onStepperUpdate.bind(this));
  }

  private onStepperUpdate(e: CustomEvent) {
    // this.controller.abort();
    this.initSelectFields(e);
  }

  private refillOptions(e: CustomEvent) {
    const select: HTMLSelectElement = e.detail?.changedEl;
    const wrapper: HTMLElement | null = (select?.parentNode as HTMLElement)?.matches('.select-floating-label') ? select.parentNode as HTMLElement : null;
    if (!wrapper) {
      console.warn('No matching wrapper found to refill options!');
      return;
    }

    const appendTo: HTMLDivElement = wrapper.querySelector('.select-items .select-items-internal')!;
    const inputText: HTMLInputElement = wrapper.querySelector('input:not([name$="_text"])')!;
    const inputValue: HTMLInputElement = wrapper.querySelector('input[name$="_text"]')!;

    appendTo.textContent = '';

    this.appendOptionsToCustomDiv(select, inputText, inputValue, appendTo);
  }

  private appendOptionsToCustomDiv(select: HTMLSelectElement,
                                   inputText: HTMLInputElement,
                                   inputValue: HTMLInputElement,
                                   fillTarget: HTMLElement) {
    Array.from(select.childNodes).forEach((option, index) => {
      if ((option as HTMLInputElement).value) {
        const selectItem = document.createElement('div');
        const optionAttributes = {
          disabled: (option as HTMLInputElement).getAttribute('disabled'),
          selected: (option as HTMLInputElement).getAttribute('selected')
        };
        optionAttributes.disabled !== null && selectItem.classList.add('disabled');
        optionAttributes.selected !== null && option.textContent && this.setInputValue(option.textContent, inputText);
        optionAttributes.selected !== null && this.setInputValue((option as HTMLInputElement).value, inputValue);
        selectItem.innerHTML = (option as HTMLInputElement).innerHTML;
        selectItem.dataset.value = (option as HTMLInputElement).value;
        selectItem.tabIndex = index;
        this.setSelectItemListeners(selectItem, select);
        fillTarget.appendChild(selectItem);
      }
    });
  }

  private initSelectFields(e?: CustomEvent) {
    console.log('initSelectFields');

    this.controller = new AbortController();

    let targetElement : Document | HTMLElement = document;
    if(e && e.type === ApoEvents.STEPPER_UPDATE) {
      targetElement = (e as CustomEvent).detail?.element as HTMLElement;
    }

    const customSelectWrappers = targetElement.querySelectorAll<HTMLElement>('.select-floating-label');
    customSelectWrappers.forEach((selectWrapper: HTMLElement) => {
      // Entferne den vorherigen Inhalt, der neu aufgebaut wird
      const previousSelectItemsWrapper = selectWrapper.querySelector('.select-items');
      const previousInputText = selectWrapper.querySelector('input.select-selected');
      const previousInputValue = selectWrapper.querySelector('input[type="hidden"]');
      const previousDropdownIconSpan = selectWrapper.querySelector('.dropdown-icon');
      const previousSelectStartIcon = selectWrapper.querySelector('.select-icon');

      previousSelectItemsWrapper?.remove();
      previousInputText?.remove();
      previousInputValue?.remove();
      previousDropdownIconSpan?.remove();
      previousSelectStartIcon?.remove();

      const select = selectWrapper.querySelector('select');
      const inputText = document.createElement('input');
      const inputValue = document.createElement('input');
      const selectItemsWrapper = document.createElement('div');
      const internalSelectItemsWrapper = document.createElement('div');
      const classes = ['select-selected'];
      const selectAttributes = {
        disabled: select?.getAttribute('disabled'),
        readOnly: select?.getAttribute('readonly'),
        required: select?.hasAttribute('required') ? 'required' : '',
        id: select?.getAttribute('id'),
        name: select?.getAttribute('name'),
        'data-selector': select?.getAttribute('data-selector'),
        class: classes.join(' '),
        spellcheck: 'false',
        onkeypress: 'return false;'
      };
      Object.entries(selectAttributes).forEach(([key, value]) => {
        if (value !== null) {
          if (key === 'id' || key === 'name' || key === 'data-selector') {
            inputText.setAttribute(key, value + '_text');
            value && inputValue.setAttribute(key, value);
          } else {
            value && inputText.setAttribute(key, value);
          }
          select?.removeAttribute(key);
        }
      });
      inputText.tabIndex = 0;
      inputValue.style.display = 'none';
      selectItemsWrapper.setAttribute('class', 'select-items select-hide');
      internalSelectItemsWrapper.setAttribute('class', 'select-items-internal');

      select && this.appendOptionsToCustomDiv(select, inputText, inputValue, internalSelectItemsWrapper);

      const insertIconClasses = ['select-floating-label', 'select-button', 'select-icon', 'select-large'];
      const dropdownIconSpan = document.createElement('span');
      dropdownIconSpan.setAttribute('class', 'dropdown-icon');

      selectItemsWrapper.appendChild(internalSelectItemsWrapper);

      if (insertIconClasses.every(cssClass => selectWrapper.classList.contains(cssClass))) {
        const selectStartIcon = document.createElement('span');
        selectStartIcon.classList.add('select-icon');
        selectWrapper.prepend(selectStartIcon);
      }
      selectWrapper.prepend(dropdownIconSpan);
      selectWrapper.prepend(selectItemsWrapper);
      selectWrapper.prepend(inputText);
      selectWrapper.prepend(inputValue);
      this.setOpenSelectListeners(inputText);
      this.setValidityListener(inputText, selectWrapper);
      this.toggleFloatingLabelClass(selectWrapper, inputText);
    });
  }
}