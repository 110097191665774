export type CartOverlaySliderItemImageType = {
  image_saving_percent: number;
  image_prescription: boolean;
  image_size_50_50: string;
  image_size_50_50_x2: string;
  image_size_70_70: string;
  image_size_70_70_x2: string;
  image_size_90_90: string;
  image_size_90_90_x2: string;
  image_size_100_100: string;
  image_size_100_100_x2: string;
  image_size_120_120: string;
  image_size_120_120_x2: string;
  image_size_150_150: string;
  image_size_150_150_x2: string;
  image_size_160_160: string;
  image_size_160_160_x2: string;
  image_size_180_180: string;
  image_size_180_180_x2: string;
  image_size_200_200: string;
  image_size_200_200_x2: string;
  image_size_320_320: string;
  image_size_320_320_x2: string;
  image_size_400_400: string;
  image_size_400_400_x2: string;
  image_size_500_500: string;
  image_size_500_500_x2: string;
  image_size_original: string;
  alt_text: string;
}

export type CartOverlaySliderItemType = {
  products_id: number;
  products_model: string;
  rezeptpflichtig: string;
  kuehlartikel: string;
  PRODUCTS_LINK: string;
  PRODUCTS_IMAGE: CartOverlaySliderItemImageType;
  PRODUCTS_NAME: string;
  PRODUCTS_INHALT: string;
  PRODUCTS_GP_PRICE: string;
  PRODUCTS_UVP: string;
  PRODUCTS_UVP_NO_FORMAT: string;
  PRODUCTS_UVP_INTEGER_PART: string;
  PRODUCTS_UVP_DECIMAL_PART: string;
  PRODUCTS_PRICE_CURRENCY_SYMBOL_LEFT: string;
  PRODUCTS_PRICE: string;
  PRODUCTS_PRICE_INTEGER_PART: string;
  PRODUCTS_PRICE_DECIMAL_PART: string;
  PRODUCTS_PRESENTATION: any;
  PRODUCTS_HOMOEOPATHIKUM?: any;
  PRODUCTS_PHYTOTHERAPEUTIKUM?: any;
  PRODUCTS_RATING_AVERAGE: number;
  PRODUCTS_RATING_AVERAGE_ROUNDED: number;
  PRODUCTS_RATING_COUNT: number;
}

export class CartOverlaySlider {
  constructor(
    public cartOverlaySliderItems: CartOverlaySliderItemType[] | []
  ) {
  }

  static fromApiObject(object: CartOverlaySliderItemType[] | []) {
    return new CartOverlaySlider(object)
  }
}