const $copyButtons: HTMLElement[] = Array.from(
  document.querySelectorAll('[data-copy]')
);
const $htmlCodeSections: HTMLElement[] = Array.from(document.querySelectorAll('.language-markup'));

$htmlCodeSections?.forEach(($htmlCodeSection) => {
  $htmlCodeSection.innerHTML = $htmlCodeSection.innerHTML.trim();
});

$copyButtons?.forEach(($copyButton) => {
  const $copyElement: HTMLDivElement | null = document.querySelector(
    $copyButton.dataset.copy as string
  );
  $copyElement &&
    $copyButton.addEventListener('click', () => {
      const copyText = $copyButton.innerText;
      navigator.clipboard.writeText($copyElement.innerHTML);
      $copyButton.innerText = 'Copied!';
      $copyButton.setAttribute('disabled', 'true');
      setTimeout(() => {
        $copyButton.innerText = copyText;
        $copyButton.removeAttribute('disabled');
      }, 3000);
    });
});

export default {};
