import collapseAnimation from '../collapseAnimation';

const $collpaseElement: HTMLElement | null = document.querySelector(
  '[data-collapse="#add-address-box"]'
);

if ($collpaseElement) {
  collapseAnimation($collpaseElement, {
    open: {
      transitionDuration: '400ms',
      transitionDelay: '700ms',
      transitionTimingFunction: 'ease-out',
    },
    close: {
      transitionDuration: '400ms',
      transitionDelay: '200ms',
      transitionTimingFunction: 'ease-out',
    },
  });
}
