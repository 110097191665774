const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export const figureZoom = () => {
  window.addEventListener('DOMContentLoaded', () => {
    const figuresToZoom = document.querySelectorAll(
      'figure[data-zoom-function]'
    );

    figuresToZoom.forEach((fig: any) => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        let latesttap = new Date().getTime();

        fig.addEventListener('touchstart', () => {
          var now = new Date().getTime();
          var timesince = now - latesttap;
          if (timesince < 400 && timesince > 0) {
            if (fig.classList.contains('zoom')) {
              fig.classList.remove('zoom');
              fig.style.backgroundPosition = `${50}% ${50}%`;
            } else {
              fig.classList.add('zoom');
            }
          }
          latesttap = new Date().getTime();
        }, { passive: true });

        let oldX = 0;
        let oldY = 0;

        fig.addEventListener('touchmove', (e: any) => {
          if (!fig.classList.contains('zoom')) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          var zoomer = e.currentTarget;

          const _tmp = window
            .getComputedStyle(zoomer, null)
            .backgroundPosition.trim()
            .split(/\s+/);
          const currentBgPos = {
            left: parseInt(_tmp[0].replace('%', '')),
            top: parseInt(_tmp[1].replace('%', '')),
          };

          let newPosX = currentBgPos.left;
          let newPosY = currentBgPos.top;
          
          if (e.touches[0].screenX < oldX) {
            newPosX = currentBgPos.left + 1;
          } else if (e.touches[0].screenX > oldX) {
            newPosX = currentBgPos.left - 1;
          }
          
          if (e.touches[0].screenY < oldY) {
            newPosY = currentBgPos.top + 1;
          } else if (e.touches[0].screenY > oldY) {
            newPosY = currentBgPos.top - 1;
          }

          oldX = e.touches[0].screenX;
          oldY = e.touches[0].screenY;

          newPosX = clamp(newPosX, 0, 100);
          newPosY = clamp(newPosY, 0, 100);

          zoomer.style.backgroundPosition = `${newPosX}% ${newPosY}%`;
        }, { passive: true });
      } else {
        fig.classList.add('zoom');
        fig.onmousemove = (e: any) => {
          var zoomer = e.currentTarget;
          let offsetX = 0;
          let offsetY = 0;
          e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches[0].pageX);
          e.offsetY ? (offsetY = e.offsetY) : (offsetX = e.touches[0].pageX);
          const x = (offsetX / zoomer.offsetWidth) * 100;
          const y = (offsetY / zoomer.offsetHeight) * 100;
          zoomer.style.backgroundPosition = x + '% ' + y + '%';
        };
      }
    }, { passive: true });
  });
};

figureZoom();
