import { useCurrentRefinements, UseCurrentRefinementsProps } from "react-instantsearch";

/**
 * Active Filter Bubble Counter - displays the number of active filter on a filter option (accordion-toggler)
 *
 * @param props
 * @constructor
 */
export const ActiveFilterBubbleCount = (props: UseCurrentRefinementsProps) => {
  const { items } = useCurrentRefinements(props);
  let refinementCount = <span></span>
  if (items[0]) {
    refinementCount = <span className={"refinement-count btn btn-secondary bg-aco-blue text-aco-white"}>{items[0].refinements.length}</span>
  }

  return (
    refinementCount
  );
}