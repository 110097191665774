import {CartFromApi} from "./cart-types"

export enum CartApiDefaults {
  url = 'ajax/cart.php',
  method = 'GET'
}

export interface CartApiInterface {
  url: string,
  method: string,
}

export class CartApi implements CartApiInterface {
  public url: string
  public method: string

  constructor(config: CartApiInterface = CartApiDefaults) {
    this.url = config.url
    this.method = config.method
  }

  private async request(params = {}): Promise<CartFromApi> {
    let options
    let url = this.url

    if ('GET' === this.method) {
      url = this.url + (Object.entries(params).length > 0 ?  '?' + (new URLSearchParams(params)).toString() : '');
      options = {
        method: this.method
      }
    }
    if ('POST' === this.method) {
      options = {
        method: this.method,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(params)
      }
    }

    return await fetch(url, options).then(response => response.json()).catch(error => console.error(error))
  };

  public getData(): Promise<CartFromApi> {
    return this.request()
  }

  public changeProductQty(id: number, qty: number, prescription_type: number = 2): Promise<CartFromApi> {
    return this.request({products_id: id, products_qty: qty, prescription_type: prescription_type})
  }

  public removeProduct(id: number, qty: number = 0): Promise<CartFromApi> {
    return this.request({products_id: id, products_qty: qty})
  }

  public addProduct(id: number, qty: number, prescription_type: number = 2, algolia_query_id = ''): Promise<CartFromApi> {
    return this.request({products_id: id, products_qty: qty, prescription_type: prescription_type, action: 'add', algolia_query_id: algolia_query_id})
  }

  public applyCouponCode(couponCode: FormDataEntryValue): Promise<CartFromApi> {
    return this.request({apply_coupon_code: couponCode})
  }

  public removeCouponCode(couponCode: FormDataEntryValue): Promise<CartFromApi> {
    return this.request({remove_coupon_code: couponCode})
  }

  public applyBonusPoints(bonusPoints: FormDataEntryValue): Promise<CartFromApi> {
    return this.request({apply_bonus_points: bonusPoints})
  }

  public removeBonusPoints(): Promise<CartFromApi> {
    return this.request({remove_bonus_points: true})
  }
}