import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {Cart, CartApi} from "../../entity/Cart/"
import {CartCounter} from "../CartCounter"
import {triggerCartOverlay, isOverlayActive, reloadATCModal} from "../../state/CartOverlay";
import {CONSTANTS} from "../../../../../../apo_com_responsive/assets/javascript/app/ui/Search/constants";
import {trackCartButtonClickedEvent} from "../../../../../../apo_com_responsive/assets/javascript/app/ui/Search/tracking";

@customElement('cart-button')
export class CartButton extends LitElement {
  private cartApi: CartApi
  private counter: number
  private cartCounter: CartCounter | null

  constructor() {
    super()
    this.cartApi = new CartApi()
    this.counter = 0
    this.cartCounter = document.querySelector('cart-counter')
  }

  // Reset button styles
  static styles = css`
      :host() {
          margin: 0;
          padding: 0;
      }

      button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          text-decoration: none;
          border: none;
          background-color: transparent;
          padding: 0;
          cursor: pointer;
      }

      button.w-100 {
          width: 100%;
      }
  `;

  @property({type: Number})
  product = 0

  @property({type: Object})
  products = {}

  @property({type: Number})
  quantity = 0

  @property({type: Number})
  prescription = 2

  @property({type: Number})
  max: number | undefined

  @property({type: Boolean})
  disabled = false

  @property({type: String})
  additionalClass = ""

  @property({type: String})
  algoliaQueryID = ""

  @property({type: String})
  pzn = ""

  protected render() {
    let className = this.additionalClass ? this.additionalClass : ""

    if (this.products !== {}) {
      this.classList.remove('btn', 'btn-primary', 'btn-lg');
    }

    return html`
      <button
          @click=${this.handleClick}
          ?disabled=${this.disabled}
          part=${this.products !== {} ? "button-slim" : "button"}
          class=${className}
      >
        <slot></slot>
      </button>
    `
  }

  private async handleClick() {
    if (!this.validateProperties()) {
      return;
    }

    this.disabled = true;

    //if the algolia query id was not given, try to locate it in a GET Parameter if possible (necessary for tracking on pdp's)
    if (this.algoliaQueryID === '') {
      try {
        let url: URL = new URL(window.location.href);
        let extracted_algolia_query_id: string | null = url.searchParams.get("queryID");
        if (extracted_algolia_query_id !== null) {
          this.algoliaQueryID = extracted_algolia_query_id;
        }
      } catch (error) {
        console.error(error);
      }
    }

    // recursive loop to add products one after another, when there
    // was a server callback to prevent cart-items' count corruption
    if (Object.entries(this.products).length > 0) {
      await (async (): Promise<boolean> => {
          return new Promise((resolve) => {
            const productsLength: number = Object.entries(this.products).length;
            let index: number = 0;
            const addProduct = (id: number, qty: number, prescription: number) => this.cartApi.addProduct(id, qty, prescription);
            const addProducts = (products: any[] = Object.entries(this.products)) => {
              const productVO = products[index][1];

              index++;

              if (!!productVO.products_status && productVO.products_status !== '0') {
                addProduct(productVO.PRODUCTS_ID, 1, productVO.rezeptpflichtig).then(data => {
                  const cart = Cart.fromApiObject(data);
                  this.counter = +this.counter + +this.quantity;
                  if (this.max !== undefined && this.counter >= this.max) {
                    this.disabled = true;
                  }
                  if (this.cartCounter instanceof HTMLElement) {
                    this.cartCounter.updateCounter(cart.cartItemsAmount);
                  }

                  let productData = cart.cartItems.find(cartItem => cartItem.PRODUCTS_ID === productVO.PRODUCTS_ID);
                  if (typeof (window.dataLayer) !== 'undefined' && productData) {
                    window.dataLayer.push({
                                            'event': 'addToCartEvent',
                                            'ecommerce': {
                                              'addToCartClick': {
                                                'pzn': productData.PRODUCTS_ID,
                                                'name': productData.PRODUCTS_NAME,
                                                'price': productData.PRODUCT_AMOUNT_PRICE,
                                                'amount': productData.PRODUCTS_QTY,
                                                'cartproducts': cart.cartItems,
                                                'cartvalue': cart.orderTotal
                                              }
                                            }
                                          })
                  }

                  if (index >= productsLength) {
                    resolve(true);
                  } else addProducts();
                })
              } else if (index < productsLength) addProducts();
              else resolve(true);
            };
            addProducts();
          })
        }
      )();
      this.disabled = false;
    } else {

      isOverlayActive() && reloadATCModal(true)

      this.cartApi.addProduct(this.product, this.quantity, this.prescription, this.algoliaQueryID)
        .then(data => {
          const cart = Cart.fromApiObject(data)
          triggerCartOverlay(true, cart, this.product, this.quantity)
          this.counter = +this.counter + +this.quantity
          if (this.max !== undefined && this.counter >= this.max) {
            this.disabled = true
          }
          if (this.cartCounter instanceof HTMLElement) {
            this.cartCounter.updateCounter(cart.cartItemsAmount)
          }

          isOverlayActive() && reloadATCModal(false)

          // BEGIN tracking
          // prepare data for tracking
          let productData = cart.cartItems.find(cartItem => cartItem.PRODUCTS_ID === this.product)
          // @ts-ignore
          if (typeof (window.dataLayer) !== 'undefined' && productData) {
            // @ts-ignore
            window.dataLayer.push({
                                    'event': 'addToCartEvent',
                                    'ecommerce': {
                                      'addToCartClick': {
                                        'pzn': this.product,
                                        'name': productData.PRODUCTS_NAME,
                                        'price': productData.PRODUCT_AMOUNT_PRICE,
                                        'amount': productData.PRODUCTS_QTY,
                                        'cartproducts': cart.cartItems,
                                        'cartvalue': cart.orderTotal
                                      }
                                    }
                                  })
          }

          //Algolia Tracking
          if (productData && CONSTANTS.aid !== '' && CONSTANTS.sak !== '' && this.algoliaQueryID !== '' && this.pzn !== '') {
            trackCartButtonClickedEvent(this.algoliaQueryID, this.pzn)
          }
          // END tracking
        }).then(() => this.disabled = false)
    }
  }

  private validateProperties(): boolean {
    const regex = new RegExp('[0-9]{1,3}')
    return (this.products !== {} || this.product <= 0 || this.quantity > 0 || !regex.test(
      String(this.prescription)) || (this.max !== undefined && this.counter >= this.max))
  }
}