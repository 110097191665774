import {
  useSortBy,
  UseSortByProps,
} from 'react-instantsearch';
import React from "react";

interface CustomSortByConnectorParams extends UseSortByProps {
  groupingKey: string
}

/**
 * Custom Sort By - enables the sorting of the given products based on an algolia index
 *
 * @param props
 * @constructor
 */
export const CustomSortBy = (props: CustomSortByConnectorParams) => {
  const { currentRefinement, options, refine } = useSortBy(props);

  /**
   * Synchronizes the sidebar & offcanvas active radio item
   * @param event - the clicked input element
   */
  const syncRadioSelection = (event: React.MouseEvent) => {
    const clickedRadioInput = event.target as HTMLInputElement;
    // get all rating radio items (with .rating-star-radio)
    const customSortByGroupSelector = clickedRadioInput?.classList[1];
    const customSortByGroupElements= document.querySelectorAll("." + customSortByGroupSelector);

    // clear previous active radios
    customSortByGroupElements?.forEach(customSortBy => {
      customSortBy?.removeAttribute("checked");
      customSortBy.parentElement?.classList.remove("input-checked");
    })

    // get radio item that was clicked on + its twin in offcanvas (with .rating-star-radio-x)
    const customSortByClickedSelector = clickedRadioInput?.classList[2];
    const customSortByClickedElements= document.querySelectorAll("." + customSortByClickedSelector);

    // set new active radio
    customSortByClickedElements?.forEach(clickedRadio => {
      clickedRadio?.setAttribute("checked", "true");
      clickedRadio.parentElement?.classList.add("input-checked");
    })
  }

  return (
    <>
      {options.map((option) => (
        <div key={option.value}>
          <div className={"list-group"}>
            <div className="dropdown-menu-item mb-spacing-8 p-spacing-0">
              <div className={"d-flex align-items-center list-group-item list-group-item-selection"}
                   data-selector="list-group-item-selection"
                   data-parent="label-container" >
                <div className={currentRefinement === option.value ? "list-group-item-radio input-checked" : "list-group-item-radio"}>
                  <input type={"radio"}
                         id={option.value}
                         className={`form-check-input custom-sort-by-radio custom-sort-by-radio-${option.value}`}
                         name={`sorting-${props.groupingKey}`}
                         value={option.value}
                         readOnly={true}
                         onClick={(event) => {
                           refine(option.value)
                           syncRadioSelection(event)
                          }
                         }
                         checked={currentRefinement === option.value}
                  />
                </div>
                <div className={"list-group-item-content"}>
                  <label htmlFor={option.value} className={"form-check-label w-100"}>
                    <span className={"filter-name"}>{option.label}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}