import type { Hit as AlgoliaHit } from "instantsearch.js/es/types/results";
import { useTranslation } from "react-i18next";
import { Highlight, useHits, UseHitsProps } from "react-instantsearch";

interface HitProps {
  hit: AlgoliaHit<{
    name: string;
    image_url: string;
    objectID: number;
    product_url: string;
  }>;
}
interface UseHitsPropsCustom extends UseHitsProps {
  hits?: HitProps[];
}

export const ProductHits = (props: UseHitsPropsCustom) => {
  const { t } = useTranslation();
  const { hits, results, sendEvent } = useHits(props);

  return (
    <div className={`${results!.query === "" || results!.nbHits === 0 ? "d-none" : ""}`} role="status" aria-live="assertive">
      <h3 className={"mb-spacing-16"}>{t("search.autosuggest.products.header")}</h3>
      <ul className={"auto-suggest-products z-index-100 list-style-type-none"}>
        {hits.map((hit) => (
          <li key={hit.objectID} className="mb-spacing-24" tabIndex={0} onClick={() => sendEvent("click", hit, "click on product item | Autosuggest")}>
            <a
              className="d-flex justify-content-between align-items-center gap-spacing-8"
              role="button"
              href={(hit.product_url as string) + "?objectID=" + hit.objectID + "&queryID=" + hit.__queryID + "&query=" + results?.query}
              target={"_self"}
            >
              <img src={hit.image_url as string} alt={hit.__queryID} width={64} className="border-rounded-8 me-spacing-12" />
              <div className="d-flex align-items-center justify-content-start w-100">
                <Highlight
                  attribute="name"
                  hit={hit}
                  highlightedTagName="span"
                  nonHighlightedTagName="span"
                  classNames={{
                    root: "autosuggest-highlight",
                    highlighted: "highlighted",
                    nonHighlighted: "non-highlighted",
                  }}
                />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
