import React, {createRef, useEffect} from "react";
import {useKeyPress} from "@apo-app/app/hooks/useKeyPress";

interface Props {
  deCrease: () => void,
  inCrease: () => void,
  value: number,
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void,
  handleKeyPress?: (e: React.KeyboardEvent) => void,
  maxValue?:number
}


export const ProductReminderInputGroup = (props: Props) => {

  const inputBox = createRef<HTMLInputElement>()
  const arrowUpPressed = useKeyPress('ArrowUp', inputBox);
  const arrowDownPressed = useKeyPress('ArrowDown', inputBox);

  useEffect(() => {
    if (arrowUpPressed) {
      props.inCrease()
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      props.deCrease()
    }
  }, [arrowDownPressed]);


  return (
    <>
      <span className="btn btn-minus min button" onClick={props.deCrease}/>
      <input id="qty_per_day" type="text" ref={inputBox}
             maxLength={4}
             style={{width: "80px"}}
             value={props.value}
             onChange={props.handleChange}
             autoFocus
             onKeyDown={props.handleKeyPress}
      />
      <span className="plus button btn btn-plus" onClick={props.inCrease}/>
    </>
  )
}