import { ApoEvents } from "./apoEvents";

export class ApoStepperController {
  static instance: ApoStepperController;
  private stepsList: Map<number, HTMLElement>;
  private currentStepIndex: number;

  constructor() {
    if (ApoStepperController.instance) {
      return ApoStepperController.instance;
    }
    ApoStepperController.instance = this;

    this.stepsList = new Map<number, HTMLElement>();
    this.currentStepIndex = 0; // Initialer Schrittindex
    this.initSteppers();
    this.registerGlobalEvents();
  }

  private registerGlobalEvents(): void {
    document.addEventListener(ApoEvents.DATEPICKER_CHANGED, this.onDatepickerChange);
    document.addEventListener(ApoEvents.SELECTFIELD_CHANGED, this.onSelectfieldChange);
  }

  private initSteppers = (): void => {
    document.addEventListener("DOMContentLoaded", () => {
      const steppers = document.querySelectorAll<HTMLDivElement>("[data-stepper]");

      steppers.forEach((stepper) => {
        const stepperTemplates = document.querySelector(".stepper-templates");
        const steps = stepperTemplates?.querySelectorAll<HTMLTemplateElement>("[data-stepper-step]");

        // Zeige den ersten Schritt an
        if (steps && steps.length > 0) {
          const firstStepTemplate = steps[0];
          const clone = document.importNode(firstStepTemplate.content, true);
          const firstStep = document.createElement("div");
          firstStep.setAttribute("data-stepper-step", "0");
          firstStep.appendChild(clone);
          stepper.innerHTML = "";
          stepper.appendChild(firstStep);

          // Initialize steps list and add the first step
          this.stepsList.set(0, firstStep);

          // Set event handlers for the first step
          this.setEventHandlers(firstStep);

          console.log('initSteppers :>> STEPPER_UPDATE');
          document.dispatchEvent(new CustomEvent(ApoEvents.STEPPER_UPDATE, {
            'detail': {
              element: firstStep,
            }
          }));
        }
      });
    });
  }

  private onDatepickerChange = (e: CustomEvent) => {
    const element = e.detail.datepickerWrap.querySelector('#dob');
    const event = new Event('change', { bubbles: true });
    element.dispatchEvent(event);
  }

  private onSelectfieldChange = (e: CustomEvent) => {
    const element = e.detail.target;
    this.validateInput(element);
  }

  private gotoStep = (
    stepper: HTMLElement,
    targetStep: number,
    selectedStepTemplate?: HTMLTemplateElement
  ) => {
    const direction = targetStep > this.currentStepIndex ? 'next' : 'previous';
    console.log(`gotoStep :>> ${direction}`);

    // Hide the current step
    const currentStep = stepper.querySelector("[data-stepper-step]:not(.d-none)");
    if (currentStep) {
      currentStep.classList.add("d-none");
    }

    // Check if the target step already exists in the stepsList
    let newStep: HTMLElement;
    if (this.stepsList.has(targetStep)) {
      console.log(`Step ${targetStep} already exists. Showing existing step.`);

      newStep = this.stepsList.get(targetStep)!;
      newStep.classList.remove("d-none");

      this.currentStepIndex = targetStep;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    } else if (direction === 'next' && selectedStepTemplate) {
      console.log(`Step ${targetStep} does not exist. Creating new step from template.`);

      // Clone the template content and append it to the stepper
      const clone = document.importNode(selectedStepTemplate.content, true);
      newStep = document.createElement("div");
      newStep.setAttribute("data-stepper-step", targetStep.toString());
      newStep.appendChild(clone);
      stepper.appendChild(newStep);
      this.stepsList.set(targetStep, newStep);

      // Set event handlers for the new step
      this.setEventHandlers(newStep);
    } else {
      console.log(`Step ${targetStep} does not exist and direction is 'previous'. Doing nothing.`);
      return; // Do nothing if the step doesn't exist and direction is 'previous'
    }

    // Aktualisiere den aktuellen Schrittindex
    this.currentStepIndex = targetStep;

    // Scroll to the top of the page after step change
    window.scrollTo({ top: 0, behavior: 'smooth' });

    console.log('gotoStep :>> STEPPER_UPDATE');
    document.dispatchEvent(new CustomEvent(ApoEvents.STEPPER_UPDATE, {
      'detail': {
        element: newStep,
      }
    }));
  }

  private setEventHandlers = (step: HTMLElement) => {
    const buttons = step.querySelectorAll<HTMLButtonElement>("[data-stepper-goto]");

    buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        const targetStep = parseInt(button.dataset.stepperGoto!);
        const validateTrigger = button.hasAttribute('data-stepper-validation');
        const stepperTemplates = document.querySelector(".stepper-templates");
        const selectedStepTemplate = stepperTemplates?.querySelector<HTMLTemplateElement>(
          `[data-stepper-step="${targetStep}"]`
        );

        console.log('selectedStepTemplate :>> ', selectedStepTemplate);

        if (selectedStepTemplate || this.stepsList.has(targetStep)) {
          console.log(`Navigating to step: ${targetStep}`);

          // Nur die Eingabeelemente im aktuellen Schritt validieren
          const currentStep = button.closest("[data-stepper-step]") as HTMLElement;
          const formElementsArray = Array.from(currentStep.querySelectorAll("input"));

          if (validateTrigger) {
            console.log(`Validation triggered for current step: ${this.currentStepIndex}`);

            let isValid: boolean[] = [];
            let firstInvalidElement: HTMLElement | null = null;

            currentStep.classList.add('was-validated');

            const requiredElements = formElementsArray.filter(el => el.required);

            requiredElements.forEach((formElement) => {
              const valid = formElement.checkValidity();
              isValid.push(valid);

              if (!valid) {
                console.log(`Invalid input found: ${formElement.name}`);
                formElement.classList.add('is-invalid');
                formElement.classList.add('form-control');
                formElement?.offsetParent?.classList.add('is-invalid');

                if (!firstInvalidElement) {
                  firstInvalidElement = formElement as HTMLElement;
                }
              } else {
                formElement.classList.remove('is-invalid');
                formElement?.offsetParent?.classList.remove('is-invalid');
              }
            });

            if (!isValid.includes(false)) {
              console.log(`All inputs valid for current step: ${this.currentStepIndex}`);
              if (selectedStepTemplate) {
                this.gotoStep(currentStep.closest("[data-stepper]")!, targetStep, selectedStepTemplate);
              }
            } else {
              console.log(`Validation failed for current step: ${this.currentStepIndex}`);
              event.preventDefault();
              event.stopPropagation();

              document.dispatchEvent(new CustomEvent(ApoEvents.STEPPER_VALIDATION_FAILED, {
                'detail': {
                  element: currentStep,
                }
              }));

              // Scroll to the first invalid element
              if (firstInvalidElement) {
                // @ts-ignore
                firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }
          } else {
            console.log(`No validation required for current step: ${this.currentStepIndex}`);
            if (selectedStepTemplate) {
              this.gotoStep(currentStep.closest("[data-stepper]")!, targetStep, selectedStepTemplate);
            }
          }
        } else {
          console.error(`Step ${targetStep} not found.`);
        }
      });
    });

    // Event-Listener für allgemeine Eingabefelder hinzufügen
    const inputs = step.querySelectorAll<HTMLInputElement>(".form-control");
    inputs.forEach((input) => {
      input.addEventListener("blur", () => this.validateInput(input));
      input.addEventListener("change", () => this.validateInput(input));
      input.addEventListener("input", () => this.validateInput(input));
    });

    // Event-Listener für spezifische Selektoren hinzufügen
    const dobInput = step.querySelector<HTMLInputElement>('#dob');
    dobInput?.addEventListener("change", () => {
      this.validateInput(dobInput)
    });

    // Funktion zum Hinzufügen von Event-Listenern zu neuen Elementen
    const addEventListenersToNewElements = (element: HTMLElement) => {
      if (element.matches('.select-floating-label')) {
        element.querySelector(".select-selected")?.addEventListener("change", (e) => {
          const input = e.target as HTMLInputElement;
          this.validateInput(input);
        });
      }
    };

    // MutationObserver zur Überwachung von DOM-Änderungen
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            addEventListenersToNewElements(node);
          }
        });
      });
    });

    // Observer-Konfiguration und Start
    observer.observe(step, {
      childList: true,
      subtree: true,
    });
  }

  // Funktion zur Validierung eines Eingabefelds
  private validateInput = (input: HTMLInputElement) => {
    if (input.validity.valid) {
      input.classList.remove('is-invalid');
    } else {
      input.classList.add('is-invalid');
    }
  };
}