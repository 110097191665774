export class Postnummer {
  /**
   * Überprüft, ob die gegebene Postnummer gültig ist.
   * @param {string|number} postnummer - Die zu überprüfende Postnummer.
   * @returns {boolean} - True, wenn die Postnummer gültig ist, andernfalls false.
   */
  static isValid(postnummer: any): boolean {
    if (isNaN(postnummer)) {
      return false;
    }

    postnummer = parseInt(postnummer).toString(); // Normalisiere den Eingabewert
    if (postnummer.match(/^\d{6,10}$/) === null) {
      return false;
    }
    postnummer = postnummer.padStart(10, '0');

    return postnummer.substring(postnummer.length - 1) === Postnummer.getCheckDigitFromPostnummer(postnummer).toString();
  }

  /**
   * Berechnet die Prüfziffer für eine gegebene Postnummer.
   * @param {string} postnummer - Die Postnummer, für die die Prüfziffer berechnet werden soll.
   * @returns {number} - Die berechnete Prüfziffer.
   */
  static getCheckDigitFromPostnummer(postnummer: string): number {
    let sum = [...postnummer.substring(0, postnummer.length - 1)].reduce((sum, value, index) => {
      return sum + (parseInt(value) * ((index + 1) % 2 ? 4 : 9));
    }, 0);

    return (10 - (sum % 10)) % 10;
  }
}