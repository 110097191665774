import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-http-backend";

// js translations
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'de',
    backend: {
      loadPath: './dist/resource/locales/{{lng}}/translation.json'
    }
  }).catch(err => {
  console.error(err)
});

export default i18n;