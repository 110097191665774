import {useState, useEffect} from "react";
import {useRange} from "react-instantsearch";
import ReactSlider from "react-slider";

interface ApoPriceSliderProps {
  indexAttribute: string
}

/**
 * RangeSlider - turns a given index property into a 2-handled slider bar
 *
 * @param indexAttribute - the property from the algolia feed
 * @constructor
 */
export const RangeSlider = ({ indexAttribute }: ApoPriceSliderProps) => {
  const { start, range, refine } = useRange({attribute: indexAttribute});

  const incomingMinAlgolia = Number(range.min);
  const incomingMaxAlgolia = Number(range.max);
  const initialMin = Number(start[0]);
  const initialMax = Number(start[1]);

  const [ currentRange, setCurrentRange ] = useState([incomingMinAlgolia, incomingMaxAlgolia]);

  const from = Math.max(incomingMinAlgolia, Number.isFinite(initialMin) ? initialMin : incomingMinAlgolia);
  const to = Math.min(incomingMaxAlgolia, Number.isFinite(initialMax) ? initialMax : incomingMaxAlgolia);

  useEffect(() => {
    setCurrentRange([from, to]);
  }, [from, to]);

  const filterHintList = () => {
    refine([currentRange[0], currentRange[1]]);
  }

  return (
    <>
      <ReactSlider className={"react-slider slider mb-spacing-16"}
                   value={currentRange}
                   min={incomingMinAlgolia}
                   max={incomingMaxAlgolia}
                   onChange={setCurrentRange}
                   onAfterChange={filterHintList}
      />
      <div className={"d-flex justify-content-between"}>
        <div className={"react-slider-input"}>{currentRange[0]}</div>
        <div className={"react-slider-input"}>{currentRange[1]}</div>
      </div>
    </>
  );
}