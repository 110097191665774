import React, {FC} from 'react';
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import CardHeader from "react-bootstrap/CardHeader";
import {Rating} from "../Rating";
import {useTranslation} from "react-i18next";

export type ProductCardProps = {
  id: number
  src: string
  title: string
  text: string
  price: string
  link: string
  rating?: number
  rating_totals?: number
}

export type ProductCardSmallProps = {
  productsId: number;
  productsLink: string;
  productsImageSrc: string;
  productsImageSrcSet?: string;
  productsImageAlt: string;
  productsName: string;
  productsInhalt: string;
  productsBasePrice: string;
  productsUvp: string;
  productsPrice: string;
  productsPrescription: string;
  productsRefrigerated?: string;
  productsPresentation?: string;
  productsHomoeopathic?: string;
  productsPhysiotherapeutic?: string;
  productsRatingAverage: number;
  productsRatingCount: number;
}

export const ProductCard:FC<ProductCardProps> = ({src, id, title, text, price, link, rating, rating_totals}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className={'position-relative'}>
        <CardImg src={src} alt="Product Image"/>
        <wishlist-button class={'position-absolute top-0 end-0'} product={id}></wishlist-button>
      </div>
      <CardHeader>{title}</CardHeader>
      <div className="card-body product-detail">
        {(rating !== undefined && rating_totals !== undefined) &&
          <Rating average={rating} total={rating_totals} size={'sm'}/>
        }
        <p>{text}</p>
        <div className="product-price">
          {price}€
        </div>
        <Button href={link} as="a" variant="primary">
          {t('productCard.gotoProduct')}
        </Button>
      </div>
    </Card>
  );
}

/**
 * @todo This element is still in progress!!
 *
 * @param productsId
 * @param productsLink
 * @param productsImageSrc
 * @param productsImageSrcSet
 * @param productsImageAlt
 * @param productsName
 * @param productsInhalt
 * @param productsBasePrice
 * @param productsUvp
 * @param productsPrice
 * @param productsPrescription
 * @param productsRefrigerated
 * @param productsPresentation
 * @param productsHomoeopathic
 * @param productsPhysiotherapeutic
 * @param productsRatingAverage
 * @param productsRatingCount
 * @constructor
 */
export const ProductCardSmall: FC<ProductCardSmallProps> = (
  {
    productsId,
    productsLink,
    productsImageSrc,
    productsImageSrcSet,
    productsImageAlt,
    productsName,
    productsInhalt,
    productsBasePrice,
    productsUvp,
    productsPrice,
    productsPrescription,
    productsRefrigerated,
    productsPresentation,
    productsHomoeopathic,
    productsPhysiotherapeutic,
    productsRatingAverage,
    productsRatingCount,
  }) => {
  return (
    <Card className={'product-card'}>
      <Card.Header className={'product-card-header'}>
        <Card.Link href={productsLink} className={'product-card-link'}>
          <Card.Img
            className={'product-card-img'}
            variant={'top'}
            src={productsImageSrc}
            srcSet={productsImageSrcSet}
            alt={productsImageAlt}
          />
        </Card.Link>
        <wishlist-button product={productsId}></wishlist-button>
      </Card.Header>
      <Card.Body className={'product-card-body'}>
        <Card.Title className={'product-card-title'}>{productsName}</Card.Title>
        <Card.Text>
          {productsPrescription !== '1' &&
            <Rating average={productsRatingAverage} total={productsRatingCount} size={'sm'}/>
          }
          <div className={'product-card-attributes'}>

          </div>
          <div className={'product-card-dosage'}>
            {productsInhalt} | {productsPresentation}
          </div>
          <div className={'product-card-base-price'}>
            <small>{productsBasePrice}</small>
          </div>
          <div className={'product-card-price'}>

          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
