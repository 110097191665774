import React from "react";
import {NoResultsByFilter} from "./NoResultsByFilter";
import {NoResultsBySearch} from "./NoResultsBySearch";

interface Props {
  searchTerm?: string,
  isRefined?: object | undefined
}

export const NoResults = (props: Props) => {

  return props.isRefined
    ? <NoResultsByFilter/>
    : <NoResultsBySearch searchTerm={props.searchTerm}/>;
}