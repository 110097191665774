import {RatingStars} from '@apo-app/app/web-components/RatingStars/rating-stars'
import {Modal} from "bootstrap";
import i18next from "i18next";

interface Review {
  CUSTOMERS_NAME: string,
  REVIEWS_ID: string,
  DATE: string,
  LINK_VOTE: string,
  LINK_VOTE_NO: string,
  LINK_VOTE_YES: string,
  PRODUCTS_ID: string,
  PRODUCTS_NAME: string,
  RATING_READ: string,
  RATING_READ_POSITIV: string,
  RATING_STARS_COUNT: string,
  RATING_STARS_FROM_110: number,
  TEXT: string,
  TEXT_PLAIN: string,
  TITLE_PLAIN: string,
}

export const productReviews = () => {

  document.addEventListener("DOMContentLoaded", () => {
    const moreRatingsWrapper = document.querySelector('[data-selector="all_reviews_wrapper"]')
    const loadingSpinner = document.querySelector('[data-selector="rating_spinner"]')
    const reviewsWrapper = document.querySelector('[data-selector="reviews_wrapper"]')
    const firstReviewsWrapper = document.querySelector('[data-selector="first_reviews_wrapper"]')
    const reviewSorting = <HTMLInputElement>document.querySelector('[data-selector="review_sorting"]')
    const reviewSortingWrapper = <HTMLInputElement>document.querySelector('[data-selector="review_sorting_wrapper"]')
    const scrollToCreateReview = document.querySelectorAll<HTMLElement>('[data-selector="goto_create_review"]')
    const createReviewContainer = document.querySelector('[data-selector="create_review"]')
    const createRatingStars = document.querySelectorAll<HTMLElement>('[data-selector="rating_stars"] span span')
    const createRatingStarsValue = <HTMLInputElement>document.querySelector('[data-selector="rating_stars_value"]')
    const ratingStars: RatingStars | null = document.querySelector('[data-selector="rating_stars"]')

    createRatingStars.forEach(el => el.addEventListener('click', (evt) => getRatingValue(el)))

    const ratingForm = <HTMLFormElement>document.querySelector('[data-selector="rating_form"]')
    const sendRatingForm = document.querySelectorAll<HTMLInputElement>('[data-selector="send_rating_form"]')
    sendRatingForm?.forEach((el) => el.addEventListener('click', (evt) => submitRating(evt)))
    let errorBox = document.querySelector('[data-selector="error-box"]')
    const openReviewModal = document.querySelector('[data-bs-target="#reviewsModal"]')

    const modalWrapper = document.querySelector('#reviewsModal')
    // const reviewModal = modalWrapper && new Modal(modalWrapper,{})
    const reviewModal = modalWrapper && new Modal(modalWrapper, {})

    openReviewModal?.addEventListener('click', () => reviewModal && reviewModal.show())


    const getRatingValue = (el: HTMLElement) => {
      const value = Number(el.getAttribute('data-value'))
      const ratingPzn = el.parentElement?.parentElement?.getAttribute('data-rating-id')

      if (ratingPzn) {
        const ratingValueToBeUpdated = <HTMLInputElement>document.querySelector(`input[data-rating-id_input="${ratingPzn}"]`)
        const ratingStarWithId = <RatingStars>document.querySelector(`[data-selector="rating_stars"][data-rating-id="${ratingPzn}"]`)
        ratingStarWithId?.updateStars(value)
        ratingValueToBeUpdated!.value = String(value)
        ratingValueToBeUpdated!.checked = true;
      } else {
        ratingStars?.updateStars(value)
        createRatingStarsValue.value = String(value)
        createRatingStarsValue.checked = true;
      }

    }

    //scroll to create rating
    scrollToCreateReview?.forEach(el => el.addEventListener('click', (e) => {
      e.preventDefault();
      createReviewContainer && createReviewContainer.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }))


    if (reviewSorting) {
      reviewSorting.addEventListener('change', () => sortReviews())

      // sort reviews and show first five reviews
      const sortReviews = () => {
        const formData = new FormData()
        const productId = reviewSortingWrapper.getAttribute('data-product-id')
        formData.append('products_id', productId as string)
        formData.append('start_value', '1')
        formData.append('review_sort', reviewSorting.value)
        formData.append('number_of_entries_to_load', '5')

        loadingSpinner?.classList.contains('d-none') && loadingSpinner?.classList.remove('d-none')

        fetch('ajax/get_more_product_reviews.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => renderSortedReviews(response.reviews))
          .catch((error) => console.log(error))
      }
    }

    if (moreRatingsWrapper) {
      const showMoreReviewsButton = <HTMLElement>document.querySelector('[data-action="show_more_reviews"]');
      showMoreReviewsButton && showMoreReviewsButton.addEventListener('click', (evt) => showMoreReviews(evt, showMoreReviewsButton))

      // load 20 more reviews
      const showMoreReviews = (evt: MouseEvent, el: HTMLElement) => {
        const productId = el.getAttribute('data-product-id')
        const formData = new FormData()
        const reviewCount = document.querySelectorAll<HTMLDivElement>('.product-review').length.toString()

        evt.preventDefault()
        formData.append('products_id', productId as string);
        formData.append('start_value', reviewCount);
        formData.append('review_sort', reviewSorting.value);

        loadingSpinner?.classList.contains('d-none') && loadingSpinner?.classList.remove('d-none')

        fetch('ajax/get_more_product_reviews.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => renderReviews(response.reviews))
          .then(() => evaluateReviewsEventListener())
          .catch((error) => console.log(error))
      }
    }

    const renderSortedReviews = (reviews: Review[]) => {
      reviews &&
      !loadingSpinner?.classList.contains('d-none') && loadingSpinner?.classList.add('d-none')

      if (moreRatingsWrapper) {
        // clear extended review list with extra loaded reviews
        moreRatingsWrapper.replaceChildren()
      }

      if (firstReviewsWrapper) {
        // clear review list
        firstReviewsWrapper.replaceChildren()

        reviews.forEach((el: Review) => {
          const contentWrapper = document.createElement('div')
          // create review element
          let content = renderSingleReview(el)

          contentWrapper.innerHTML += content
          firstReviewsWrapper.append(contentWrapper)
        })
      }
      evaluateReviewsEventListener()
    }

    // render single review
    const renderSingleReview = (el: Review) => {
      const isLoggedIn = reviewsWrapper && reviewsWrapper.classList.contains('not_logged_in')
      let review = `
        <div class="product-review">
          <div class="w-100 pe-spacing-16">
            <h2>${el.CUSTOMERS_NAME ? el.CUSTOMERS_NAME : i18next.t('productReview.verifiedCustomer')}</h2>
            <div class="pb-spacing-8">
              <rating-stars average="${el.RATING_STARS_COUNT}" size="sm">
                <span class="rating-star-size-sm"></span>
              </rating-stars>
            </div>
            <div class="d-flex flex-column micro text-aco-gray-01 pb-spacing-12">
                <span> ${i18next.t('productReview.fieldReportFrom', {date: el.DATE})}</span>
                <span class="fw-bold">
                    ${i18next.t('productReview.customersFoundHelpful', {
        number: el.RATING_READ_POSITIV,
        customerRead: el.RATING_READ
      })}
               </span>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center flex-column">
          <p>${el.TEXT}</p>
          ${isLoggedIn ? `<p class="fw-bold text-aco-gray-01">${i18next.t('productReview.wasHelpful')}?</p>
          <form method="post" action="${el.LINK_VOTE}" class="d-flex align-items-center w-100 justify-content-start" data-selector="evaluate_review" data-reviews-id="${el.REVIEWS_ID}">
            <button type="submit" name="action" value="positive" class="btn btn-secondary w-100">Ja</button>&nbsp;&nbsp;
            <button type="submit" name="action" value="negative" class="btn btn-secondary w-100">Nein</button>
          </form>` : ''}
        </div>
        </div>`

      return review
    }

    // render additional reviews
    const renderReviews = (reviews: Review[]) => {
      reviews &&
      !loadingSpinner?.classList.contains('d-none') && loadingSpinner?.classList.add('d-none')

      reviews.forEach((el: Review) => {
        const contentWrapper = document.createElement('div')

        // create review element
        let content = renderSingleReview(el)

        if (moreRatingsWrapper) {
          contentWrapper.innerHTML += content
          moreRatingsWrapper.append(contentWrapper)
        }
      })
    }

    const evaluateReviewsEventListener = () => {
      const formEvaluateReview = document.querySelectorAll<HTMLFormElement>('[data-selector="evaluate_review"]')
      // Remove event listeners from Element
      formEvaluateReview.forEach(el => el.cloneNode(true));
      formEvaluateReview.forEach((el) => el.addEventListener('click', (evt) => evaluateReview(evt, el), {once: true}))
    }

    const evaluateReview = (evt: MouseEvent, el: HTMLFormElement) => {
      const formData = new FormData()

      const element = evt.target as HTMLInputElement
      const result = element.value
      const reviewsId = el.getAttribute('data-reviews-id') as string
      evt.preventDefault()
      formData.append('action', result)
      fetch('product_reviews.php?reviews_id=' + reviewsId, {
        method: 'POST',
        body: formData,
      })
        .then(() => feedbackEvaluate(element))
        .catch(error => console.log(error))

    }

    const feedbackEvaluate = (element: HTMLInputElement) => {
      const feedback = document.createTextNode(i18next.t('productReview.ratingIsAdded'))
      element && element.parentElement?.classList.add('d-none')
      element && element.parentElement?.parentElement?.appendChild(feedback)
    }

    evaluateReviewsEventListener()

    // submit rating and show feedback
    const submitRating = (evt: MouseEvent) => {
      const target = evt.target as HTMLInputElement
      const ratingFormTarget = target.form
      const ratingFormId = ratingFormTarget!.getAttribute('data-rating-id')
      const errorBox = ratingFormTarget!.querySelector<HTMLElement>('[data-selector="error_box"]')

      errorBox!.innerHTML = ""

      const ratingFormWrapper = ratingFormId
        ? document.querySelector(`[data-rating-id="${ratingFormId}"]`)
        : null

      evt.preventDefault();

      const formData = ratingFormTarget ? new FormData(ratingFormTarget) : new FormData(ratingForm)

      fetch('ajax/save_product_rating.php', {
        method: 'POST',
        body: formData,
      })
        .then((result) => {
          return result.ok ? result.json() : Promise.reject(result.json());
        })
        .then((result) => {
          errorBox && ratingFormId ? (
            ratingFormWrapper!.innerHTML = showPositiveRatingMessage(),
              errorBox.classList.add('hidden')
          ) : hideModal(result.message)
        })
        .catch((errorResult) => {
          errorResult.then((errorMessage: { message: string }) => {
            errorBox!.innerHTML = errorMessage.message
            errorBox!.classList.remove('d-none')
          })
        })
    }

    const showPositiveRatingMessage = () => `<div>${i18next.t('productReview.ratingIsAdded')}</div>`

    const hideModal = (result: string, isError = false) => {
      const alertsWrapper = document.querySelector('.alerts-wrapper')
      alertsWrapper?.classList.add('mx-auto')
      alertsWrapper?.setAttribute('data_success_header', '')
      alertsWrapper?.addEventListener('click', () => alertsWrapper?.remove())

      const message = `
        <div class="divider-aco-blue"></div>
        <div class="alert aco-blue ${isError ? `is-danger` : ""} border-rounded-top-0 w-100 " role="alert">
            <div class="content container d-flex justify-content-between">
                <div class="alert-icon"></div>
                <p class="mt-spacing-4 w-100"><b>${result}</b></p>
                <i class="fa fa-close text-aco-white" data-selector="close_rating_success_message" role="button"></i>
            </div>
        </div>`
      if (alertsWrapper) {
        alertsWrapper.innerHTML = message
      }

      // hide all after rating is done
      scrollToCreateReview?.forEach(el => el.classList.add('d-none', 'invisible'))
      createReviewContainer?.classList.add('d-none', 'invisible');
      ratingForm?.classList.add('d-none', 'invisible');
      reviewModal?.hide();
    }

  })

}
productReviews()