interface ICollapseAnimationConfig {
  open: ICollapseTransitionConfig;
  close: ICollapseTransitionConfig;
}

interface ICollapseTransitionConfig {
  transitionDuration: string;
  transitionDelay?: string;
  transitionTimingFunction: string;
}

const DEFAULT_CONFIG: ICollapseAnimationConfig = {
  open: {
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease-out',
  },
  close: {
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease-out',
  },
};

const setTransitionProperties = (
  target: HTMLElement,
  config: ICollapseTransitionConfig
) => {
  target.style.transitionDuration = config.transitionDuration;
  target.style.transitionTimingFunction = config.transitionTimingFunction;
  target.style.transitionDelay = config.transitionDelay ?? '0ms';
};

const resetTransitionProperties = (target: HTMLElement) => {
  setTransitionProperties(target, {
    transitionDuration: '0ms',
    transitionTimingFunction: 'ease-out',
    transitionDelay: '0ms',
  });
};

const openCollapse = (
  collapse: HTMLElement,
  config: ICollapseAnimationConfig['open'],
  callback?: CallableFunction
) => {
  const content: HTMLDivElement | null = collapse.querySelector(
    `${collapse.dataset.collapse}`
  );
  if (content) {
    setTransitionProperties(collapse, config);
    collapse.style.height = `${content.getBoundingClientRect().height}px`;
    if (collapse.dataset.visible !== 'true') collapse.dataset.visible = 'true';
    callback?.();
  }
};

const closeCollapse = (
  collapse: HTMLElement,
  config: ICollapseAnimationConfig['close'],
  callback?: CallableFunction
) => {
  setTransitionProperties(collapse, config);
  collapse.style.height = '0';
  if (collapse.dataset.visible !== 'false') collapse.dataset.visible = 'false';
  callback?.();
};

const isCollapseOpen = (collapse: HTMLElement) =>
  collapse.style.height !== '0px';

const collapseAnimation = (
  target: HTMLElement | HTMLElement[],
  config = DEFAULT_CONFIG
) => {
  if (Array.isArray(target)) {
    target.forEach((collapse) => setupCollapse(collapse, config));
  } else {
    setupCollapse(target, config);
  }
};

const createObserver = (
  target: HTMLElement,
  callback: MutationCallback,
  config?: MutationObserverInit
): MutationObserver => {
  const observer = new MutationObserver(callback);
  observer.observe(target, config);
  return observer;
};

const setupOnResize = (target: HTMLElement): ResizeObserver => {
  const ro = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const collapse = entry.target as HTMLElement;
      const content: HTMLDivElement | null = collapse.querySelector(
        `${collapse.dataset.collapse}`
      );

      if (content && isCollapseOpen(collapse)) {
        resetTransitionProperties(collapse);
        collapse.style.height = `${content.getBoundingClientRect().height}px`;
      }
    });
  });
  ro.observe(target);
  return ro;
};

const setupCollapse = (
  collapse: HTMLElement,
  config: ICollapseAnimationConfig
) => {
  collapse.dataset?.visible === 'true'
    ? openCollapse(collapse, config.open)
    : closeCollapse(collapse, config.close);

  const resizeObserver = setupOnResize(collapse);

  const mutationCallback: MutationCallback = (mutationList, observer) => {
    mutationList.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        if (
          (mutation.target as HTMLElement).parentElement?.id ===
            collapse.dataset.collapse?.substring(1) &&
          (mutation.target as HTMLElement).style.display === 'none'
        ) {
          (mutation.target as HTMLElement).style.display = 'block';
          closeCollapse(collapse, config.close, () => {
            observer.disconnect();
            resizeObserver.disconnect();
          });
        } else if (
          mutation.target === collapse &&
          mutation.attributeName === 'data-visible'
        ) {
          (mutation.target as HTMLElement).dataset.visible === 'true'
            ? openCollapse(collapse, config.open)
            : closeCollapse(collapse, config.close);
        }
      }
    });
  };

  createObserver(collapse, mutationCallback, {
    attributes: true,
    childList: true,
    subtree: true,
  });
};

export default collapseAnimation;
