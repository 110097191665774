const buttons = document.querySelectorAll('.offcanvas.filter .accordion-button')
let savedItem

const isSameAccordion = (next) => {
  return savedItem.querySelector('.accordion-button').dataset.bsTarget === next.querySelector('.accordion-button').dataset.bsTarget
}

if (buttons) {
  buttons.forEach(btn => {
    btn.addEventListener('click', () => {
      const item = btn.closest('.accordion-item')

      if (savedItem && savedItem.classList.contains('border-top-0')) {
        savedItem.classList.remove('border-top-0')
      }

      if (item) {
        const nextItem = item.nextElementSibling

        if (savedItem && nextItem && isSameAccordion(nextItem)) {
          nextItem.classList.remove('border-top-0')
        } else {
          nextItem && nextItem.classList.toggle('border-top-0')
          savedItem = nextItem
        }
      }
    })
  })
}
