import { useStats } from "react-instantsearch";

/**
 * HitCount - Displays the total hits for a current search
 *
 * @param children - the text that is displayed on the button
 * @constructor
 */
export const HitCount = ({ children }: any) => {
  const { nbHits } = useStats();

  return (
    <>
      {nbHits} {children}
    </>
  );
}