import React, {useCallback, useEffect, useRef, useState} from "react";
import {Buffer} from "buffer";
import DOMPurify from "dompurify";

declare const ShoppingCart: ShoppingCart

interface ShoppingCart {
  addCartItem: (id: number, amount: number, prescription_type: number) => void;
}

function useSanitizedHtml(htmlString: string) {
  return DOMPurify.sanitize(htmlString);
}

function HtmlContent(htmlString: string) {
  const sanitizedHtml = useSanitizedHtml(htmlString);
  return <div dangerouslySetInnerHTML={{__html: sanitizedHtml}}/>;
}

const fetchSliderData = () => {
  const [sliderData, setSliderData] = useState(null as JSX.Element | null);

  const fetchData = async () => {
    let global_product_datasets: string[] = []
    let data = new URLSearchParams();
    data.append('predictive_site', 'search_site');
    data.append('predictive_site_products', Buffer.from(JSON.stringify(global_product_datasets)).toString('base64'));

    const response = await fetch('ajax/predictive_intent.php', {
      method: 'post',
      body: data
    });

    const answer = await response.text();
    const parsedContent = HtmlContent(answer)
    setSliderData(parsedContent)
  };

  useEffect(() => {
    fetchData().then(() => {
    })
  }, []);

  return sliderData
}


export const PredictiveIntentSlider = () => {

  const data = fetchSliderData()
  const [sliderData, setSliderData] = useState(null as JSX.Element | null);

  let timeout: number = -1;
  let abortController : AbortController;
  const observer = useRef<ResizeObserver>(new ResizeObserver((entries) => {

    timeout = setTimeout(() => {
      if(abortController) abortController.abort();

      abortController = new AbortController();
      const signal : AbortSignal = abortController.signal
      const atcButtons = entries[0].target.querySelectorAll('input[type="submit"]')

      atcButtons.length !== 0 && atcButtons.forEach(el => {
        const atcButtonWrapper = el.closest<HTMLElement>('.hotw_lower_part');

        if (atcButtonWrapper) {
          const inputValue = atcButtonWrapper.querySelector<HTMLInputElement>('[name="products_id"]')!.value

          el.addEventListener('click', () => {
            inputValue && ShoppingCart.addCartItem(Number(inputValue),1,2)
          },
          {
            signal
          })
        }
      })

      if (entries[0].borderBoxSize[0].blockSize) {
        const slideButton = entries[0].target.querySelector('.none_swipe_next') as HTMLDivElement
        slideButton.style.display="block"
      }
    }, 200);
  }));


  const wrapperRef = useCallback((instance: any) => {
    if (instance) return observer.current.observe(instance);
    observer.current.disconnect();
  }, []);


  useEffect(() => {
    setSliderData(data)
  }, [data]);

  return (
    <div className="wrapper-slider" ref={wrapperRef}>
      {sliderData}
    </div>
  )
}
