/**
 * listen for change inside select and show textarea if "others" is selected
 */
const showOthersInputTextarea = () => {
  const selectOptions = document.querySelectorAll(".select-items-internal div");
  const textareaDiv = document.querySelector('[data-selector="custom_reaseon_textarea"]');
  const textareaEl = document.querySelector("#buy_campaign_unsubscribe_survey_answer_custom");
  const submitBtn = document.querySelector('#buy_campaign_unsubscribe_survey_button_send');

  textareaDiv && textareaEl && submitBtn && selectOptions && selectOptions.forEach((el) => {
    el.addEventListener("click", () => {
      // show custom input are if "others" is selected
      if (el.dataset.value === "others") {
        textareaDiv.classList.remove("d-none");

        // prevent empty input when sending custom reason
        submitBtn.disabled = "true";
        textareaDiv.addEventListener("keyup", () => {
          textareaEl.value !== ""
            ? submitBtn.removeAttribute("disabled")
            : submitBtn.disabled = "true";
        });
      } else {
        textareaDiv.classList.add("d-none");
        submitBtn.removeAttribute("disabled");
      }
    });
  });
}

/**
 * non display input parts after submit
 */
const hideInputsPostSubmit = () => {
  // listen for submit
  const submitBtn = document.querySelector("#buy_campaign_unsubscribe_survey_button_send");
  const inputArea = document.querySelector('[data-selector="custom_reaseon_textarea"]');
  const hideArea = document.querySelector('[data-selector="hide-area"]');
  const successArea = document.querySelector('[data-selector="success-area"]');

  hideArea && inputArea && submitBtn && submitBtn.addEventListener("click", () => {
    hideArea.classList.add("fade-out");
    setTimeout(() => {
      successArea.classList.remove("d-none");
      hideArea.classList.add("d-none");
    }, 650);
  });
}

const init = () => {
  showOthersInputTextarea();
  hideInputsPostSubmit();
};

document.addEventListener("DOMContentLoaded", init);
