import Lottie from 'lottie-web';

/**
 * Initializes and plays a Lottie animation on the homepage.
 *
 * The function waits for the DOM content to be fully loaded, then:
 * 1. Searches for the first HTML element with a `data-animation-src` attribute.
 * 2. Fetches the animation JSON data from the URL specified in the `data-animation-src` attribute.
 * 3. Loads and plays the Lottie animation inside the found element using the Lottie Web library.
 *
 * The animation will use SVG rendering, loop continuously, and autoplay.
 *
 * @returns {void} This function does not return any value. It performs actions directly on the DOM.
 */
function initLottieAnimationHomepage() {
  document.addEventListener('DOMContentLoaded', () => {
    const eRecipeAnimationContainer = document.querySelector('[data-animation-src]');

    if (eRecipeAnimationContainer) {
      const eRecipeAnimationURL = eRecipeAnimationContainer.getAttribute('data-animation-src');

      if (eRecipeAnimationURL) {
        fetch(eRecipeAnimationURL)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            const animation = Lottie.loadAnimation({
                                                     container: eRecipeAnimationContainer,
                                                     renderer: 'svg',
                                                     loop: true,
                                                     autoplay: true,
                                                     animationData
                                                   });

            animation.addEventListener('DOMLoaded', function () {
              const svgElement = eRecipeAnimationContainer.querySelector('svg');
              if (svgElement) {
                svgElement.setAttribute('preserveAspectRatio', 'xMidYMax meet');
              }
            });
          })
          .catch(error => {
            console.error('Error fetching the animation data:', error);
          });
      } else {
        console.warn('Animation URL not found.');
      }
    } else {
      console.warn('Mood animation container not found.');
    }
  });
}

export { initLottieAnimationHomepage };