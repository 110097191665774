/**
 * The `ApoModalFormController` class is used to manage forms within modal dialogs.
 * It provides functionality to reset forms when the modal is hidden.
 */

export class ApoModalFormController {

  // Instance variables
  private _modal: HTMLElement | null;

  /**
   * Constructor
   * @param {HTMLElement | string} modal - An HTMLElement or a string representing a CSS selector.
   */
  constructor(modal: HTMLElement | string) {
    this._modal = typeof modal === 'string' ? document.querySelector(modal) : modal;
  }

  private _restoreRadioStates() {
    const radioFormControls: NodeListOf<HTMLInputElement> | undefined = this._modal?.querySelectorAll('input[type="radio"]');

    if (radioFormControls) {
      radioFormControls.forEach((radio: HTMLInputElement) => {
        if (radio.checked || radio.hasAttribute('checked')) {
          const fireChange = new Event('change');

          radio.dispatchEvent(fireChange);
        }
      });
    }
  }

  // Public methods

  /**
   * resetFormOnHide()
   * Registers an event listener on the modal element to reset the form inside the modal when it is hidden.
   */
  public resetFormOnHide = () => {
    this._modal?.addEventListener('hide.bs.modal', () => {

      // Reset form controls to initial state
      this._modal?.querySelector('form')?.reset();

      // Restore initial view state if any elements are hidden through radio buttons
      this._restoreRadioStates();
    });
  }
}