import {ProductReminderList} from "./product-reminder-types";

export enum ProductReminderApiDefaults {
  url = 'ajax/account_reminder_data.php',
  method = 'GET'
}

export interface ProductReminderApiInterface {
  url: string,
}

export class ProductReminderApi implements ProductReminderApiInterface {
  public url: string

  constructor(config: ProductReminderApiInterface = ProductReminderApiDefaults) {
    this.url = config.url
  }

  private async request(params = {}, method: string = "GET"): Promise<ProductReminderList> {
    let options
    let url = this.url

    if ('GET' === method) {
      url = this.url + (Object.entries(params).length > 0 ? '?' + (new URLSearchParams(params)).toString() : '');
      options = {
        method: "GET"
      }
    }
    return await fetch(url, options).then(response => response.json()).catch(error => console.error(error))
  };

  public show(): Promise<ProductReminderList | false> {
    return this.request({action: 'show'})
  }

  public delete(id: string): Promise<ProductReminderList | false> {
    return this.request({action: 'delete', reminder_id: id})
  }

  public confirm(id: number, amount: number, date_start: string, days: number, qty_per_day: number, qty_per_dosage: number): Promise<ProductReminderList> {
    return this.request({
      action: 'confirm',
      id: id,
      amount: amount,
      date_start: date_start,
      days: days,
      qty_per_day: qty_per_day,
      qty_per_dosage: qty_per_dosage
    })
  }

  public new(id: string): Promise<ProductReminderList> {
    return this.request({action: 'new', id: id})
  }

  public save(title: string, days_remind: number): Promise<ProductReminderList> {
    return this.request({action: 'save', title: title, days_remind: days_remind})
  }

  public default(): Promise<ProductReminderList> {
    return this.request({action: 'default'})
  }
}
