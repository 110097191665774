import { useTranslation } from "react-i18next";
import { ClearRefinements, RefinementList } from "react-instantsearch";
import { CONSTANTS } from "../constants";
import { RangeSlider } from "./CustomFilterOptions/rangeSlider";
import { CustomSortBy } from "./CustomFilterOptions/sortBy";
import { AccordionItem } from "./ShellComponents/accordionitem";
import { RatingMenu } from "./ratingmenu";
import { translateToDisplayName } from "./refinementtranslations";
import { classicLabelMap, genericLabelMap, herbalLabelMap, homeopaticLabelMap, prescriptionTypeLabelMap, singleIngredientLabelMap } from "./translationmaps";

interface SearchFilterProps {
  isDark?: boolean;
  classNames?: string;
  groupingSrc: string;
}

/**
 * Searchfilter - enables the user to filter a product list by attributes like manufacturer, ratings, price....
 *
 * Hint:
 * - attributes that should be searchable must be configured as such in the algolia dashboard: configure > index > filtering and faceting > facets
 * - furthermore, they must be set for display: configure > index > filtering and faceting > facet display
 *
 * @link - https://dashboard.algolia.com/apps/24PW8M0AWS/explorer/configuration
 * @constructor
 */
export const SearchFilter = ({ classNames, isDark, groupingSrc }: SearchFilterProps) => {
  const { t } = useTranslation();
  return (
    <div className={(classNames && classNames) + " accordion " + (isDark && "dark")} id="accordionFilter">
      {/*Sort*/}
      <AccordionItem title={t("search.filter.sort.label")}>
        <CustomSortBy
          groupingKey={groupingSrc}
          items={[
            { label: t("search.filter.sort.standard"), value: CONSTANTS.productIndexStorage },
            { label: t("search.filter.sort.nameAsc"), value: CONSTANTS.nameIndexAsc },
            { label: t("search.filter.sort.nameDesc"), value: CONSTANTS.nameIndexDesc },
            { label: t("search.filter.sort.priceAsc"), value: CONSTANTS.priceIndexAsc },
            { label: t("search.filter.sort.priceDesc"), value: CONSTANTS.priceIndexDesc },
            { label: t("search.filter.sort.savingsAsc"), value: CONSTANTS.savingsIndexAsc },
            { label: t("search.filter.sort.savingsDesc"), value: CONSTANTS.savingsIndexDesc },
          ]}
        />
      </AccordionItem>

      {/*Packungsgröße*/}
      <AccordionItem title={t("search.filter.packageSize.label")} indexAttribute={"amount_unit"}>
        <RefinementList
          attribute="amount_unit"
          sortBy={["isRefined", "count"]}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["amount_unit"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Marke*/}
      <AccordionItem title={t("search.filter.brand.label")} indexAttribute={"brand"}>
        <RefinementList
          attribute="brand"
          searchable={true}
          searchablePlaceholder={t("search.filter.brand.searchablePlaceholder") as string}
          sortBy={["isRefined", "count"]}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["brand"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Hersteller*/}
      <AccordionItem title={t("search.filter.manufacturer.label")} indexAttribute={"manufacturer"}>
        <RefinementList
          attribute="manufacturer"
          searchable={true}
          searchablePlaceholder={t("search.filter.manufacturer.searchablePlaceholder") as string}
          sortBy={["isRefined", "count"]}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["manufacturer"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Darreichung*/}
      <AccordionItem title={t("search.filter.presentation.label")} indexAttribute={"presentation_form"}>
        <RefinementList
          attribute="presentation_form"
          sortBy={["isRefined", "count"]}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["presentation_form"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Produkttyp*/}
      <AccordionItem title={t("search.filter.productType.label")} indexAttribute={"products_type"}>
        <RefinementList
          attribute={"classic"}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, classicLabelMap, true);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <RefinementList
          attribute={"herbal"}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, herbalLabelMap, true);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <RefinementList
          attribute={"generic"}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, genericLabelMap, true);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <RefinementList
          attribute={"homeopatic"}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, homeopaticLabelMap, true);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <RefinementList
          attribute={"single_ingredient"}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, singleIngredientLabelMap, true);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["products_type", "herbal", "homeopatic", "single_ingredient", "classic", "generic"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Bewertungen*/}
      <AccordionItem title={t("search.filter.rating.label")} indexAttribute={"rating"}>
        <RatingMenu attribute={"rating"} groupingKey={groupingSrc} />
        <ClearRefinements
          includedAttributes={["rating"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Verordnung*/}
      <AccordionItem title={t("search.filter.regulation.label")} indexAttribute={"prescription_required"}>
        <RefinementList
          attribute="prescription_required"
          sortBy={["isRefined", "count"]}
          transformItems={(items, metadata) => {
            return translateToDisplayName(items, metadata, prescriptionTypeLabelMap, false);
          }}
          classNames={{
            root: "algolia-root",
            searchBox: "algolia-searchbox",
            count: "algolia-count",
            list: "algolia-list",
            labelText: "algolia-label",
            checkbox: "form-check-input",
            item: "algolia-item mb-spacing-20",
            label: "algolia-item-label",
          }}
        />
        <ClearRefinements
          includedAttributes={["prescription_required"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>

      {/*Spar-Abo*/}
      {/*<AccordionItem title={"Spar-Abo"} indexAttribute={"subscription_status"}>
        <div className={"form-check form-switch"}>
            <RefinementList
              attribute="subscription_status"
              sortBy={['isRefined', 'count']}
              transformItems={( items, metadata ) => {
                  return translateToDisplayName(items, metadata, subscriptionLabelMap, true);
              }}
              classNames={{
                root: "algolia-root",
                searchBox: "algolia-searchbox",
                count: "algolia-count",
                list: "algolia-list",
                labelText: "algolia-label pt-spacing-2",
                checkbox: "form-check-input",
                item: "algolia-item mb-spacing-20",
                label: 'algolia-item-label'
              }}
            />
        </div>
        <ClearRefinements
            includedAttributes={["subscription_status"]}
            classNames={{
                button: "clear-single-refinement"
            }}
            translations={{
                resetButtonText: `Zurücksetzen`,
            }
        }/>
      </AccordionItem>*/}

      <AccordionItem title={t("search.filter.price.label")} indexAttribute={"price_no_format"}>
        <RangeSlider indexAttribute={"price_no_format"} />
        <ClearRefinements
          includedAttributes={["price_no_format"]}
          classNames={{
            button: "clear-single-refinement",
          }}
          translations={{
            resetButtonText: t("search.filter.button.resetShort") as string,
          }}
        />
      </AccordionItem>
    </div>
  );
};
