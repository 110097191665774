import {Modal} from "bootstrap";
import {ApoEvents} from "../apoEvents";

export const loginOverlay = () => {

  const overlayIsActive= document.querySelector('[data-login-overlay-active="true"]')
  const loginPopUpTrigger = document.querySelector('[data-bs-target="#login-modal"]')

  const modalWrapper = document.querySelector('#login-modal')
  const loginModal = modalWrapper && new Modal(modalWrapper, {})
  const isLoginPage = window.location.toString().includes("login.php");

  isLoginPage && loginPopUpTrigger?.removeAttribute('data-bs-target')
  overlayIsActive && loginModal?.show()

  document.dispatchEvent(new CustomEvent(ApoEvents.LOGIN_MODAL_INIT));
}

loginOverlay()
