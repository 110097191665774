import {Modal} from 'bootstrap'
import {Html5Qrcode} from "html5-qrcode";
import i18next from "i18next";
import ScanbotSDK from "scanbot-web-sdk";
import {BarcodeResult, BarcodeScannerConfiguration, IBarcodeScannerHandle, LicenseInfo} from "scanbot-web-sdk/@types";
import * as pdfjsLib from 'pdfjs-dist';
import {PageViewport} from "pdfjs-dist";
// @ts-ignore
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.mjs');


interface ErxScanResult {
  id: number,
  request_id: string,
  prescription_token: string,
  name: string,
  price_formatted: string,
  price: string,
  final_price: string,
  final_price_formatted: string,
  quantity: number,
  pzn: number,
  status: number,
  status_formatted: string,
  additional_payment: boolean,
  prescription_type: string,
  image: string,
  notVisible?: boolean,
  type: string
}


interface Canvas {
  canvasContext: CanvasRenderingContext2D,
  viewport: PageViewport
}

declare global {
  interface Window {
    dataLayer: {}[]
  }
}

export class ErxWorkflow {
  private readonly reader
  private readonly erxModal
  private readonly erxHiddenContent
  private readonly scanResult!: Element | null;
  data: ErxScanResult[] | undefined
  newData: ErxScanResult[] | undefined
  private readonly scanResultFooterFixed
  private introText: NodeListOf<Element> | undefined;
  addToCartErx: Element | null | undefined;
  private feedbackError: Element | null | undefined;
  private readonly errorModal: Element | null | undefined;
  erxModalContainer: HTMLElement | null | undefined;
  private cancelErxButtons!: NodeListOf<HTMLElement>;
  addedToCartModal: Element | null | undefined;
  loadingModal: HTMLElement | null | undefined;
  prescriptionNote: Element | null | undefined;
  hintCamera: Element | null | undefined;
  typeResult: Array<any> | undefined
  private html5QrCode: Html5Qrcode | undefined;
  private licenseKey: string | '';
  private scanbotSDK: ScanbotSDK;
  private scanbotCheckInterval = -1;
  private scanner: IBarcodeScannerHandle;
  private licenseInfo: LicenseInfo

  constructor(reader: HTMLElement) {
    if (reader) {
      this.reader = <HTMLElement>reader;
      this.erxModalContainer = document.getElementById('erx-workflow');
      this.erxModal = this.erxModalContainer && new Modal(this.erxModalContainer, {backdrop: 'static'});
      this.erxHiddenContent = document.querySelector('#erx-workflow .modal-content');
      this.scanResult = document.querySelector('[data-selector="scan_result"]');
      this.scanResultFooterFixed = document.querySelector('[data-selector="scan_result_footer_fixed"]');
      this.introText = document.querySelectorAll('[data-selector="intro_text"]');
      this.addToCartErx = document.querySelector('[data-selector="add_to_cart_erx"]');
      this.feedbackError = this.scanResult?.querySelector('[data-selector="feedback_error"]');
      this.errorModal = document.querySelector('[data-selector="error_modal"]');
      this.loadingModal = document.getElementById('loading-indicator');
      this.cancelErxButtons = document.querySelectorAll('[data-selector="cancel_erx"]');
      this.addedToCartModal = document.querySelector('[data-selector="added_to_cart_modal"]');
      this.prescriptionNote = document.querySelector('[data-selector="prescriptionNote"]');
      this.html5QrCode = new Html5Qrcode("reader");

      this.licenseKey =   "onDqixmVXPYqkDB3XZMrGbiEAnrV+b" +
        "Vn4eymmsPS8BUpoxkU5L0sutHNiTZk" +
        "EAcNOvQ003Rvdm09NhtZ63DX98giyo" +
        "NnQQZqwMgZT5rl7xlQo936K4RlaiuK" +
        "G8CwYt7LZBkPbpFcpn3JY1ddIogmzT" +
        "BjS/rCk6RjVi1QcoGgUa2KcvqK2V6m" +
        "vID46Mgnc6mOLrK381kAD0ffKBjfz9" +
        "7OnhfcApsZDh1L51DSgf6GF1m0GkS7" +
        "N62S+tq52b8xGAcPHwoL0Bv6vheoWx" +
        "DCzU9yntjplDJautiOLo+0E32E9YS2" +
        "jqdpK7SAHbpQMDp5NR+NZSEEBePVW+" +
        "SpTK9e/12fSQ==\nU2NhbmJvdFNESw" +
        "oxMjcuMC4wLjEqfGxvY2FsaG9zdHwq" +
        "YXBvLmNvbXxhcG8uY29tKnwqYXBvdG" +
        "hla2UuYXR8KmFwb2Rpc2NvdW50ZXIu" +
        "cGx8KmFwb2x1eC5kZXwqanV2YWxpcy" +
        "5kZXwqZGV1dHNjaGVpbnRlcm5ldGFw" +
        "b3RoZWtlLmRlfCp2ZXJzYW5kYXBvLm" +
        "RlfCphcG90aGVrZS5kZXwqYXBvZGlz" +
        "Y291bnRlci5kZXxhcG9kaXNjb3VudG" +
        "VyLmRlKgoxNzQ5NzcyNzk5CjUxMgo4" +
        "\n";

      this.initScanBot()
      this.hintCamera = document.querySelector('[data-selector="hint_camera"]')

      this.data = [];
      this.newData = [];
      this.handleErxWorkflow();
    }
  }

  private dataLayerPush = (scanAction: string) => {

    if (typeof (window.dataLayer) !== 'undefined') {
      window.dataLayer.push({
        'event': 'qrCodeDetection',
        'eRX': {
          'action': scanAction
        }
      });
    }
  }

  private initScanBot = async () => {
    this.licenseKey = location.host.search('ngrok') !== -1 ? '' : this.licenseKey;
    this.scanbotSDK = await ScanbotSDK.initialize({
      licenseKey: this.licenseKey,
    });
    this.licenseInfo = await this.scanbotSDK.getLicenseInfo();
  }

  private handleErxWorkflow() {
    this.init()
  }

  private pdfToImg = async (reader: FileReader) => {

    const pdfData = reader.result && typeof reader.result !== 'string' && new Uint8Array(reader.result);

    //set Worker
    pdfjsLib.GlobalWorkerOptions.workerSrc = await pdfjsWorker;

    const pdf = pdfData && await pdfjsLib.getDocument(pdfData).promise;
    const page = pdf && await pdf.getPage(1);
    const viewport = page && page.getViewport({scale: 0.5});
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (viewport) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;
    }

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    page && await page?.render(<Canvas>renderContext).promise;

    return canvas.toDataURL();
  }


  private init = () => {
    const erxModalWrapper = this.erxModalContainer
    const closeModalButton = erxModalWrapper?.querySelector('[data-action="close-modal"]')
    const openModalButton = document.querySelector('[data-open-modal]');
    const uploadCodeButton = document.querySelector('[data-selector="button_upload_qr"]');

    this.addToCartErx && this.addToCartErx.addEventListener('click', () => this.addERXProductsToCart())
    this.cancelErxButtons?.forEach(el => el.addEventListener('click', () => closeModal()))

    closeModalButton && closeModalButton.addEventListener('click', () => closeModal())
    openModalButton && openModalButton.addEventListener('click', () => {

      this.erxModal && this.erxModal.show();

      startScanbotProcess()
        .then(() => {
          this.dataLayerPush('Scan Start')
          this.handleLoadingSpinner('', false)
          this.erxHiddenContent?.classList.contains('invisible') && this.erxHiddenContent.classList.remove('invisible')
          this.erxModalContainer?.querySelector('.modal-content')?.classList.remove('modal-white')
          this.erxModalContainer?.querySelector('.modal-title')?.classList.add('d-none')
          this.erxModalContainer?.querySelector('.modal-header button')?.classList.replace('btn-close-aco-white','btn-close-white')
        })
        .catch(error => this.handleError(error, 'camera'))
    })

    uploadCodeButton && uploadCodeButton.addEventListener('cancel', () => {
      this.dataLayerPush('No Image Uploaded')
    });

    uploadCodeButton && uploadCodeButton.addEventListener('change', async (e) => {
      const input = e.target as HTMLInputElement;
      this.dataLayerPush('Open Image Upload')

      if (!input?.files?.length) {
        this.dataLayerPush('No Image Uploaded')
        return;
      }

      const uploadedFile = input?.files[0];
      const isValid = this.licenseInfo.isValid();
      const extension = input?.files[0].type

      const isPdf = extension === 'application/pdf'

      if (isValid) {
        try {
          let reader = new FileReader();

          isPdf
            ? reader.readAsArrayBuffer(uploadedFile)
            : reader.readAsDataURL(uploadedFile)

          reader.onload = async (e) => {
            const result = isPdf
              ? await this.scanbotSDK.detectBarcodes(await this.pdfToImg(reader))
              : typeof reader.result === "string" && await this.scanbotSDK.detectBarcodes(reader.result)

            if (result && result.barcodes && result.barcodes.length > 0) {
              this.dataLayerPush('Uploaded Image Successful')
              const text = result.barcodes[0].text // The text value of the barcode
              qrCodeSuccessCallback(text)
              this.erxModal && this.erxModal.show()
            } else {
              this.dataLayerPush('Upload Image - No Qr Code Found')
              this.erxModal && this.erxModal.show()
              this.handleError('Ein Fehler ist aufgetreten. Der QR-Code konnte nicht gelesen werden. Bitte versuchen Sie es erneut.')
            }
          };
        } catch (error) {
          this.dataLayerPush('Upload Error')
          this.erxModal && this.erxModal.show()
          this.handleError('Upload Fehler')
        }
      } else {
        this.dataLayerPush('Scanbot License Error')
        this.erxModal && this.erxModal.show()
        this.handleError('Scanfehler')
      }
    })

    const startScanbotProcess = async () => {

      if (!this.scanbotSDK || !this.scanbotSDK.initialized) {
        setTimeout(startScanbotProcess, 200);
        return;
      }

      const isValid = this.licenseInfo.isValid();

      const configuration: BarcodeScannerConfiguration = {
        //  The `id` of the containing HTML element where the Barcode Scanner will be initialized.
        containerId: 'reader',
        barcodeFormats: ['DATA_MATRIX'],
        style: {
          hint: ''
        },
        onBarcodesDetected: (result: BarcodeResult) => {
          // If you're happy with the result, dispose the scanner right away
          this.scanner.dispose();
          // Otherwise the scanner will continue scanning and delivering results
          const text = result.barcodes[0].text // The text value of the barcode

          qrCodeSuccessCallback(text)

        },
        onError: (error: Error) => {
          this.handleError(error.message)
          this.dataLayerPush("Scanbot Barcode Scan Failed")
        }
      }

      if (isValid) {
        try {
          this.scanner = await this.scanbotSDK.createBarcodeScanner(configuration);
          this.hintCamera?.classList.remove('d-none')
        } catch (error: any) {
          this.hintCamera?.classList.add('d-none')
          // Handle cases where scanner could not be opened
          this.handleError(error.name, 'camera') // Console log may be  UnsupportedMediaDevicesError, MediaPermissionError, ...
          this.dataLayerPush("Scanbot Init Failed")
          this.dataLayerPush('Camera Forbidden');
        }
      } else {
        this.handleError(this.licenseInfo.description)
        this.dataLayerPush("Scanbot Licence Error")
      }
    }


    const qrCodeSuccessCallback = (decodedText: string) => {

      if (decodedText !== '') {
        // this.html5QrCode?.stop();
        this.handleLoadingSpinner(i18next.t('erxWorkflow.recipeIsCaptured'));
        this.erxHiddenContent?.classList.add('invisible');

        fetch('ajax/rx_resolve.php', {
          method: 'POST', headers: {
            'Accept': 'application/json', 'Content-Type': 'application/json'
          }, body: decodedText,
        })
          .then(res => {
            return res.status === 200 ? res.json() : Promise.reject(res)
          })
          .then(res => {
            if (res.result.success) {
              if (res.result.products.length !== 0) {
                res.result.products && (this.showCodeScanResult(res.result.products), this.data = res.result.products)
              } else {
                this.handleError(i18next.t('erxWorkflow.noProductFound'))
                this.dataLayerPush("Scan Success - No Product Found")
              }
            } else {
              this.handleError('')
            }
          }).then(() => {
          this.erxHiddenContent?.classList.contains('invisible') && this.erxHiddenContent.classList.remove('invisible')
          this.handleLoadingSpinner(i18next.t('erxWorkflow.isLoading'), false);
        })
          .catch(error => {
            console.log('upload error - ', error);
            this.dataLayerPush("Upload Image - Detection Error")
            this.handleLoadingSpinner(i18next.t('erxWorkflow.isLoading'), false);
            this.erxHiddenContent?.classList.contains('invisible') && this.erxHiddenContent.classList.remove('invisible');
            let errorText = error.statusText ? error.statusText : 'Ein Fehler beim Verarbeiten des Bildes ist aufgetreten.'
            this.handleError(errorText)
          });
      }
      /* handle success */
    };
    const qrCodeErrorCallback = (errorMessage: string) => {
      // this.handleError(errorMessage);
    };

    const config = {
      fps: 10, qrbox: {width: 220, height: 220}
    };
    // loading indicator if modal opens
    erxModalWrapper?.addEventListener('show.bs.modal', () =>
      this.handleLoadingSpinner(i18next.t('erxWorkflow.isLoading'))
    )

    // stop scan if erx modal is closed
    erxModalWrapper?.addEventListener('hide.bs.modal', () => {
      // if scan is active stop it
      this.html5QrCode?.getState() === 2 && this.html5QrCode.stop().catch(error => console.log(error))

      //preparation for next launch
      this.erxHiddenContent?.classList.add('invisible')
      this.clearScanResult()
      this.newData = []
      this.scanner && this.scanner.dispose()
    })


    const closeModal = () => {
      this.erxModal?.hide()
      this.introText?.forEach(el => el.classList.contains('d-none') && el.classList.remove('d-none'))
      this.reader?.classList.remove('d-none')
      this.dataLayerPush('Modal Closed')
    }
  }

  // clear for repaint
  private clearScanResult = () => {
    const scanResultList = this.scanResult?.querySelector('[data-selector="scan_result_list"]');
    const scanResultFooter = this.scanResult?.querySelector('[data-selector="scan_result_footer"]');
    const scanResultFeedback = this.scanResult?.querySelector('[data-selector="feedback_success"]');
    this.reader?.classList.add('d-none')
    if (scanResultList) scanResultList.innerHTML = '';
    scanResultFeedback?.classList.add('d-none');
    if (scanResultFooter) scanResultFooter.innerHTML = '';
    this.scanResultFooterFixed && this.scanResultFooterFixed.classList.add('d-none');
    this.introText?.forEach(el => el.classList.add('d-none'));
    this.errorModal?.classList.add('d-none');
    this.erxModalContainer?.classList.contains('small') && this.erxModalContainer?.classList.remove('small');
    this.addedToCartModal?.classList.add('d-none');
  }

  // loading spinner component
  private handleLoadingSpinner = (content: string, showLoadingSpinner: boolean = true) => {
    const loadingSpinner = <HTMLElement>document.querySelector('[data-selector="loading_screen"]');
    const loadingSpinnerContent = loadingSpinner.querySelector('[data-selector="spinner_content"]');
    // set the given content
    loadingSpinnerContent ? loadingSpinnerContent.innerHTML = content : ''


    if (loadingSpinner && showLoadingSpinner) {
      this.loadingModal?.classList.add('d-block')
      this.loadingModal?.classList.add('show')
      this.loadingModal?.classList.remove('d-none')
      loadingSpinner?.classList.contains('invisible') && loadingSpinner.classList.remove('invisible')
    } else {
      loadingSpinner?.classList.add('invisible')
      this.loadingModal?.classList.remove('d-block')
      this.loadingModal?.classList.remove('show')
      this.loadingModal?.classList.add('d-none')
    }
  }

  // single product card
  private productCard = (product: ErxScanResult, canBeDeleted: boolean = true) => {

    return `
    <div data-selector="erx_scan_product_card" class="d-flex rounded mb-spacing-6 p-spacing-16">
      <img src="${product.image}" width="79px" class="me-spacing-12 mt-spacing-4" alt="${product.name}"/>
      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between gap-spacing-4">
          <div class="d-flex flex-column">
            <b class="text-aco-black mb-spacing-4 mt-nspacing-2">${product.name}</b>
            <p class="text-aco-gray-01 mb-spacing-2">PZN: ${product.type !== 'freitext' && product.type !== 'rezeptur' && product.type !== 'wirkstoff' ? product.pzn : i18next.t(
      'erxWorkflow.noPznGiven')}</p>
            <p class="text-aco-gray-01 mb-spacing-4">Menge: ${product.quantity}</p>
          </div>
           ${canBeDeleted && product.id ? `<a href="#" data-selector="delete_product" data-id="${product.id}" class="mb-spacing-4"><i class="far fa-trash-can fa-sm"></i></a> ` : ''}
      </div>
      <div><p class="text-aco-gray-01 text-end mb-spacing-0" data-selector="additional_payment_sum">${i18next.t(
      'erxWorkflow.addPayment')}: <b class="text-aco-black">${product.final_price_formatted}</b></p></div>
      </div>
    </div>`
  }

  private showCodeScanResult = (data: ErxScanResult[]) => {
    const scanResultList = this.scanResult?.querySelector('[data-selector="scan_result_list"]');
    const scanResultFooter = this.scanResult?.querySelector('[data-selector="scan_result_footer"]');
    const scanResultSuccess = this.scanResult?.querySelector('[data-selector="feedback_success"]');

    let indexNotFound = false
    let notVisibleProducts = 0
    let numberOfResults = 0
    let numberAvailableResults = 0
    let sum = 0


    this.clearScanResult()

    data.forEach((result) => {
        let productWrapper = document.createElement('div');
        productWrapper.classList.add('product_wrapper', 'mb-spacing-6', 'order-5');
        numberOfResults++
        sum += Number(result.final_price);
        numberAvailableResults++;
        !indexNotFound && productWrapper.setAttribute('data-id', String(result.id));
        productWrapper.innerHTML = this.productCard(result);
        scanResultList?.append(productWrapper);
      }
    )
    // disable add to cart button if no product is active
    numberAvailableResults === 0
      ? this.addToCartErx?.classList.add('disabled')
      : this.addToCartErx?.classList.contains('disabled') && this.addToCartErx?.classList.remove('disabled')

    // show number of results
    const countResults = document.createElement('div');
    countResults.classList.add('w-100', 'text-center', 'fs-body', 'mb-spacing-24')
    countResults.innerHTML = `${numberOfResults} ${numberOfResults > 1 ? i18next.t('erxWorkflow.productsFound', {count: 2}) : i18next.t(
      'erxWorkflow.productsFound', {count: 1})}`;

    // special typed erx product available
    const specialTypedProductAvailable = data.filter(el => el.type === 'freitext' || el.type === 'wirkstoff' || el.type === 'rezeptur').length

    const freitextProductAvailable = data.find(el => el.type === 'freitext') && 'Freitextverordnung' || ''
    const wirkstoffProductAvailable = data.find(el => el.type === 'wirkstoff') && 'Wirkstoffverordnung' || ''
    const rezepturProductAvailable = data.find(el => el.type === 'rezeptur') && 'Rezepturverordnung' || ''


    if (specialTypedProductAvailable !== 0) {
      // create array of special products
      const specialTypedProductCollection = []

      freitextProductAvailable !== '' && specialTypedProductCollection.push(freitextProductAvailable)
      wirkstoffProductAvailable !== '' && specialTypedProductCollection.push(wirkstoffProductAvailable)
      rezepturProductAvailable !== '' && specialTypedProductCollection.push(rezepturProductAvailable)

      const textContent = document.createElement('div');
      textContent.classList.add('w-100', 'text-center', 'fs-body', 'mb-spacing-32')
      textContent.innerHTML = i18next.t(
        'erxWorkflow.freeProductInfo', {specialTypedProduct: specialTypedProductCollection.length !== 0 && specialTypedProductCollection.join('und eine')})
      scanResultList?.prepend(textContent)
    }

    // if there is something to put into cart
    if (numberAvailableResults || notVisibleProducts > 0) {
      // show amount
      const currentSum = document.createElement('p')
      currentSum.classList.add('w-100', 'text-end', 'fs-body', 'mt-spacing-16', 'mb-spacing-24', 'text-bold')
      currentSum.innerHTML = i18next.t('erxWorkflow.sum', {sum: sum.toFixed(2)})

      const addToCartText = document.createElement('p');
      addToCartText.setAttribute('data-selector', 'addtocart')
      addToCartText.classList.add('w-100', 'text-center', 'mb-spacing-24', 'fs-body');
      addToCartText.innerHTML = i18next.t('erxWorkflow.freeShipping', {count: numberAvailableResults})

      numberAvailableResults > 0 && scanResultFooter?.append(currentSum)
      numberAvailableResults > 0 && scanResultFooter?.append(addToCartText)
      this.scanResultFooterFixed?.classList.contains('d-none') && this.scanResultFooterFixed.classList.remove('d-none')
    }

    // add it to results
    scanResultList?.prepend(countResults)
    this.hintCamera?.classList.add('d-none')

    this.scanResult?.classList.contains('d-none') && this.scanResult.classList.remove('d-none')
    scanResultSuccess?.classList.contains('d-none') && scanResultSuccess.classList.remove('d-none')

    const deleteButton = document.querySelectorAll<HTMLElement>('[data-selector="delete_product"]')

    deleteButton.forEach(el => el.addEventListener('click', () => {
      this.deleteProduct(el)
    }))
  }

  // add scan results to cart
  private addERXProductsToCart = () => {
    this.erxHiddenContent?.classList.add('invisible')
    this.handleLoadingSpinner(i18next.t('erxWorkflow.dataSend'))

    fetch('ajax/rx_shopping_cart.php?action=add_prescription_products')
      .then(res => res.json())
      .then(() => {
        location.assign('/login.php?return_page=shopping_cart.php');
      })
  }

  private deleteProduct = (productButton: HTMLElement) => {
    const productId = productButton.getAttribute('data-id');

    // hide it @ new paint
    this.newData = this.newData?.length === 0
      ? this.newData = this.data?.filter(el => el.id !== Number(productId))
      : this.newData?.filter(el => el.id !== Number(productId))

    fetch('ajax/rx_shopping_cart.php?action=remove_prescription_product&id=' + productId)
      .then(res => res.json())
      .then(res => {
        if (res.result.success === true) {
          this.clearScanResult();
          this.newData && this.showCodeScanResult(this.newData)
        } else {
          throw new Error('Something went wrong at deleting Product');
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  private handleError = (error: string, origin: string = '') => {
    this.handleLoadingSpinner('', false);
    this.clearScanResult()

    console.log('error = ', error);

    this.erxHiddenContent?.classList.contains('invisible') && this.erxHiddenContent?.classList.remove('invisible')
    this.feedbackError?.classList.contains('d-none') && this.feedbackError?.classList.remove('d-none')
    this.errorModal?.classList.contains('d-none') && this.errorModal.classList.remove('d-none')
    const errorMessage = document.querySelector('[data-selector="error_message_content"]')
    this.erxModalContainer?.classList.add('small');
    this.hintCamera?.classList.add('d-none')
    this.erxModalContainer?.querySelector('.modal-content')?.classList.add('modal-white')
    this.erxModalContainer?.querySelector('.modal-title')?.classList.add('d-none')
    this.erxModalContainer?.querySelector('.modal-header button')?.classList.replace('btn-close-white','btn-close-aco-white')

    if (errorMessage) {

      if (error === "UnsupportedMediaDevicesError") {
        errorMessage.innerHTML = i18next.t('erxWorkflow.errorCamera');
      } else if (error === 'Unprocessable Entity') {
        errorMessage.innerHTML = i18next.t('erxWorkflow.errorQrCode');
      } else {
        errorMessage.innerHTML = error
      }
    }
  }
}

