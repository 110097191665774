import React from "react";
import { useHits } from "react-instantsearch";
import { AlgoliaHitExtended, CustomHit } from "./hit";

const updateKairionProductDataset = (products: AlgoliaHitExtended[]) => {
  const kairionProducts = products.map((product) => {
    let categoryIDs: string[] = [];

    if (product.category_ids) {
      categoryIDs = Object.keys(product.category_ids);
    }

    const priceToCents = Math.round(product.price_no_format * 100).toString();

    return {
      gtin: product.pzn,
      categories: categoryIDs,
      position: product.__position,
      price: priceToCents,
    };
  });

  window.kairion_product_datasets = kairionProducts;
};

const fetchKairionPositionFromBackoffice = () => {
  return Number(document.querySelector("[data-kairion-product-position]")?.getAttribute("data-kairion-product-position"));
};

const fetchKairionEnabledFromBackoffice = () => {
  return document.querySelector("[data-kairion-enabled]")?.getAttribute("data-kairion-enabled");
};

export const CustomHits = () => {
  const { hits, results, sendEvent } = useHits<AlgoliaHitExtended>();

  const kairionEnabled = fetchKairionEnabledFromBackoffice();
  const kairionProductPosition = fetchKairionPositionFromBackoffice() - 1;
  updateKairionProductDataset(hits);

  const displayHits = (hit: AlgoliaHitExtended, index: number, arr: AlgoliaHitExtended[]) => {
    const kairionHit = <li id="kasb" className="d-none ais-Hits-item hit-item"></li>;
    const algoliaHit = (
      <li className="ais-Hits-item d-flex hit-item" onClick={() => sendEvent("click", hit, "click on product item | SRP")}>
        <CustomHit hit={hit} />
      </li>
    );

    if (arr.length < kairionProductPosition && index + 1 === arr.length) {
      return (
        <>
          {algoliaHit}
          {kairionHit}
        </>
      );
    }

    return kairionEnabled && index === kairionProductPosition ? (
      <>
        {kairionHit}
        {algoliaHit}
      </>
    ) : (
      algoliaHit
    );
  };

  return (
    <div className="ais-Hits px-lg-spacing-24">
      <ol className="ais-Hits-list search-result-products-list algolia-hits-list px-spacing-0 mt-spacing-6 list-unstyled">
        {hits.map((hit, index, arr) => {
          const hitElement = displayHits(hit, index, arr);
          return <React.Fragment key={hit.objectID}>{hitElement}</React.Fragment>;
        })}
      </ol>
    </div>
  );
};
