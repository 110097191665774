import { ContentfulLivePreview } from '@contentful/live-preview';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Logger = {
  info: (...args) => {
    if (Logger.isLoggingEnabled) {
      console.info('[INFO]', ...args);
    }
  },
  debug: (...args) => {
    if (Logger.isDebugEnabled) {
      console.debug('[DEBUG]', ...args);
    }
  },
  error: (...args) => {
    console.error('[ERROR]', ...args);
  },
  isLoggingEnabled: false,
  isDebugEnabled: false,
};

const subscriptions = [];
const elementCache = new Map();

document.addEventListener('DOMContentLoaded', () => {
  if (!window.contentfulConfig) {
    Logger.info('Skipping Contentful Live Preview setup: no contentfulConfig present.');
    return;
  }

  const { SPACE_ID, ENVIRONMENT, PREVIEW_TOKEN, ENTRY_ID, LOCALE } = window.contentfulConfig;

  Logger.info('Initializing Contentful Live Preview');
  const client = createClient({
    space: SPACE_ID,
    accessToken: PREVIEW_TOKEN,
    host: 'preview.contentful.com',
    environment: ENVIRONMENT,
  });

  ContentfulLivePreview.init({
    locale: LOCALE,
    enableInspectorMode: true,
    enableLiveUpdates: true,
    space: SPACE_ID,
    environment: ENVIRONMENT,
    targetOrigin: 'https://app.contentful.com',
    debugMode: false,
    entryLoader: async (entryId) => {
      Logger.debug('Loading entry:', entryId);
      try {
        const entry = await client.getEntry(entryId, { include: 10, locale: LOCALE });
        Logger.debug('Loaded entry:', entry);
        return entry;
      } catch (err) {
        Logger.error('Failed to load entry:', entryId, err);
        throw err;
      }
    },
  })
    .then(() => {
      Logger.info('Contentful Live Preview initialized successfully');
      return client.getEntry(ENTRY_ID, { include: 10, locale: LOCALE });
    })
    .then((pageEntry) => {
      Logger.debug('Page entry loaded:', pageEntry);
      const allModules = collectAllModules(pageEntry);
      Logger.info(`Total unique modules collected: ${allModules.size}`);
      allModules.forEach((subModule) => {
        if (!subModule?.sys?.id) {
          Logger.debug('Skipping module without sys.id:', subModule);
          return;
        }
        const subModuleId = subModule.sys.id;
        let fieldIds = getDynamicFields(subModuleId);
        fieldIds = fieldIds.filter((fieldId) => fieldId !== 'containerId');
        Logger.debug(`Fields for module ${subModuleId}:`, fieldIds);
        fieldIds.forEach((fieldId) => {
          displayFieldData(subModule, fieldId);
          setupLivePreview(subModule, fieldId);
        });
      });
    })
    .catch((err) => {
      Logger.error('Error setting up Live Preview:', err);
    });
});

function collectAllModules(entry, visited = new Set(), modules = new Set()) {
  if (!entry?.fields || visited.has(entry.sys.id)) return modules;
  visited.add(entry.sys.id);
  Object.values(entry.fields).forEach((value) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item?.sys?.type === 'Entry') {
          Logger.debug('Collecting nested entry:', item.sys.id);
          modules.add(item);
          collectAllModules(item, visited, modules);
        }
      });
    } else if (value?.sys?.type === 'Entry') {
      Logger.debug('Collecting nested entry:', value.sys.id);
      modules.add(value);
      collectAllModules(value, visited, modules);
    }
  });
  return modules;
}

function findElementByDataAttributes(entryId, fieldId) {
  const cacheKey = `${entryId}-${fieldId}`;
  if (elementCache.has(cacheKey)) {
    Logger.debug(`Element found in cache for key: ${cacheKey}`);
    return elementCache.get(cacheKey);
  }
  const element = document.querySelector(
    `[data-contentful-entry-id="${entryId}"][data-contentful-field-id="${fieldId}"]`
  );
  if (element) {
    Logger.debug(`Element found in DOM for entry: ${entryId}, field: ${fieldId}`);
    elementCache.set(cacheKey, element);
  } else {
    Logger.debug(`Element not found in DOM for entry: ${entryId}, field: ${fieldId}`);
  }
  return element;
}

function getDynamicFields(subModuleId) {
  const fields = Array.from(
    new Set(
      [...document.querySelectorAll(`[data-contentful-entry-id="${subModuleId}"]`)].map((el) =>
        el.getAttribute('data-contentful-field-id')
      )
    )
  );
  Logger.debug(`Dynamic fields retrieved for module ${subModuleId}:`, fields);
  return fields;
}

function getImageUrl(asset) {
  if (!asset?.fields?.file?.url) return '';
  const url = asset.fields.file.url;
  return url.startsWith('//') ? `https:${url}` : url;
}

function richTextToHtml(richText) {
  return documentToHtmlString(richText);
}

const iconMap = {
  'Alarm Clock': 'alarm-clock',
  'Box': 'box',
  'Card Prescription': 'card-prescription',
  'Circle Euro': 'circle-euro',
  'Circle Info': 'circle-info',
  'Message Medical': 'message-medical',
  'Pharmaceutical Consultancy': 'pharmaceutical-consultancy',
  'Pills': 'pills',
  'Shield Check': 'shield-check',
  'Shopping Cart': 'shopping-cart',
  'Shopping Cart Pills': 'shopping-cart-pills',
  'Truck Fast': 'truck-fast',
  'Video Consultation': 'video-consultation',
  'Piggy Bank': 'piggy-bank',
  'Question Check': 'question-check',
};

function mapIconValue(iconText) {
  return iconMap[iconText] || null;
}

function displayFieldData(subModule, fieldId) {
  const domElement = findElementByDataAttributes(subModule.sys.id, fieldId);
  if (!domElement) {
    Logger.debug(`No DOM element to display data for entry: ${subModule.sys.id}, field: ${fieldId}`);
    return;
  }
  const fieldValue = subModule.fields[fieldId];

  if (fieldValue?.sys?.type === 'Asset') {
    domElement.src = getImageUrl(fieldValue);
    Logger.debug(`Displayed image content for field: ${fieldId}`);
    return;
  }

  if (typeof fieldValue === 'string' && fieldId !== 'iconSelect') {
    domElement.textContent = fieldValue;
    Logger.debug(`Displayed string content for field: ${fieldId}`);
    return;
  }

  if (fieldValue?.nodeType === 'document') {
    domElement.innerHTML = richTextToHtml(fieldValue);
    Logger.debug(`Displayed rich text content for field: ${fieldId}`);
    return;
  }

  Logger.debug(`Unsupported field type for field: ${fieldId}`);
}

function setupLivePreview(subModule, fieldId) {
  const subModuleId = subModule.sys.id;
  const subscriptionKey = `${subModuleId}-${fieldId}`;
  if (subscriptions.find((sub) => sub.key === subscriptionKey)) {
    Logger.debug(`Already subscribed to live updates for key: ${subscriptionKey}`);
    return;
  }
  Logger.info(`Setting up live preview for entry: ${subModuleId}, field: ${fieldId}`);
  const callback = debounce((updatedData) => {
    Logger.debug('Live preview update received:', updatedData);
    const updatedFieldValue = updatedData.fields[fieldId];
    const domElement = findElementByDataAttributes(subModuleId, fieldId);
    if (!domElement) {
      Logger.debug(`DOM element not found during update for entry: ${subModuleId}, field: ${fieldId}`);
      return;
    }

    if (fieldId === 'iconSelect' && typeof updatedFieldValue === 'string') {
      Logger.debug(`Updating iconSelect to: ${updatedFieldValue}`);
      const iconClass = mapIconValue(updatedFieldValue);
      if (!iconClass) {
        domElement.textContent = updatedFieldValue;
        return;
      }
      const svgElement = domElement.querySelector('svg');
      if (!svgElement) {
        Logger.debug('No existing <svg> found; skipping update');
        return;
      }
      const useElement = svgElement.querySelector('use');
      if (!useElement) {
        Logger.debug('No <use> element found in <svg>; skipping update');
        return;
      }
      useElement.setAttribute('xlink:href', `#icon_${iconClass}`);
      Logger.debug(`Set xlink:href to #icon_${iconClass}`);
      return;
    }

    if (updatedFieldValue?.sys?.type === 'Asset') {
      domElement.src = getImageUrl(updatedFieldValue);
      Logger.debug(`Updated image content for field: ${fieldId}`);
      return;
    }

    if (typeof updatedFieldValue === 'string' && fieldId !== 'iconSelect') {
      domElement.textContent = updatedFieldValue;
      Logger.debug(`Updated string content for field: ${fieldId}`);
      return;
    }

    if (updatedFieldValue?.nodeType === 'document') {
      domElement.innerHTML = richTextToHtml(updatedFieldValue);
      Logger.debug(`Updated rich text content for field: ${fieldId}`);
      return;
    }

    Logger.debug(`Unsupported updated field type for field: ${fieldId}`);
  }, 100);

  try {
    const unsubscribe = ContentfulLivePreview.subscribe({
      event: 'edit',
      data: subModule,
      locale: window.contentfulConfig?.LOCALE,
      callback,
    });
    subscriptions.push({
      key: subscriptionKey,
      unsubscribe,
    });
    Logger.debug(`Subscribed to live updates for key: ${subscriptionKey}`);
  } catch (error) {
    Logger.error(`Failed to subscribe to key: ${subscriptionKey}`, error);
  }
}

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

window.addEventListener('beforeunload', () => {
  Logger.info('Cleaning up before unload: clearing cache and unsubscribing from live updates');
  elementCache.clear();
  subscriptions.forEach((sub) => sub.unsubscribe());
  subscriptions.length = 0;
});