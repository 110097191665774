import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { ClearRefinements, Configure, InstantSearch, SearchBox, useInstantSearch } from "react-instantsearch";

import { history } from "instantsearch.js/es/lib/routers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenDetector } from "../../useScreenDetector";
import { OffcanvasFilter } from "../Search/FilterComponents/Offcanvas/offcanvas";
import { CustomCurrentRefinements } from "../Search/FilterComponents/customcurrentrefinement";
import { translateToDisplayName } from "../Search/FilterComponents/refinementtranslations";
import { SidebarFilter } from "../Search/FilterComponents/sidebar";
import { currentRefinementLabelMap } from "../Search/FilterComponents/translationmaps";
import { CustomHits } from "../Search/ResultComponents/CustomHits";
import { NoResults } from "../Search/ResultComponents/NoResultComponents/NoResults";
import { CustomPagination } from "../Search/ResultComponents/pagination";
import { CONSTANTS } from "../Search/constants";
import { CategoryDescription } from "./categoryDescription";
import { CategoryHighlight } from "./categoryHighlight";
import { Subcategories } from "./subcategories";

interface NoBoundaryProps {
  children?: JSX.Element[] | JSX.Element;
  isTablet?: boolean;
  showNoResultPage?: boolean;
}

/**
 * Algolia Configuration
 */
const algoliaClient = algoliasearch(CONSTANTS.aid, CONSTANTS.sak);
const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests) {
    const initialData = window.__INITIAL_DATA__;
    const shouldSkipRequest = requests.every(
      (request) =>
        (request?.params?.page === 0 || !request?.params?.page) &&
        (!request?.params?.facetFilters || request?.params?.facetFilters.length === 0) &&
        request?.indexName === CONSTANTS.productIndexStorage
    );

    // If all the requests should be skipped, return the php initial response
    if (shouldSkipRequest) {
      return Promise.resolve({
        results: requests.map(() => ({
          ...initialData,
          index: CONSTANTS.productIndexStorage,
        })),
      });
    }

    const requestsWithFacetFilters = requests.map((request) => {
      return {
        ...request,
        params: {
          ...request.params,
          // facetFilters: [[all_category_ids:${window.__CATEGORY_ID__}], ...(request.params?.facetFilters || [])],
          facetFilters: [window.__SUBCATEGORIES_IDS__ || [], ...(request.params?.facetFilters || [])],
        },
      };
    }) as any[];

    // Otherwise, proceed with the actual search
    return algoliaClient.search(requestsWithFacetFilters);
  },
};
export const isModifierClick = (event: React.MouseEvent) => {
  const isMiddleClick = event.button === 1;

  return Boolean(isMiddleClick || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey);
};

/**
 * Searchpage - displays products related to a searchterm + options for filtering the result
 *
 * @constructor
 */
export const CategoryPage = () => {
  /**
   * Use search term as headline
   */
  const { isTablet } = useScreenDetector();
  const queryParams = new URLSearchParams(window.location.search);
  const [searchTerm, setSearchTerm] = useState(queryParams.get("query"));

  useEffect(() => {
    setSearchTerm(searchTerm);
  }, []);

  /**
   * Scroll to top functionality
   */
  const scrollAnchor = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    if (scrollAnchor.current != null) {
      scrollAnchor.current.scrollIntoView();
    }
  };

  const stateMapping = {
    stateToRoute(uiState: any) {
      // result of this goes to createURL
      // Return only the index for easier management
      return uiState[CONSTANTS.productIndexStorage];
    },
    routeToState(routeState: any) {
      // gets the result of parseUrl
      // gets returned only the index state
      return {
        [CONSTANTS.productIndexStorage]: routeState,
      };
    },
  };

  const routing = {
    router: history({
      // Here we take the state of the location (the argument is the default one from uistate)
      // And we return it readable, for example, arrays are now "field_a=value1,value2"
      // _a is appended to refinement list properties so we know it's an array in the parse funtion and can split it.
      createURL({ qsModule, location, routeState }) {
        // gets the result of stateToRoute in routeState

        // convert state into simpler object for query
        // do not add refinementList, this will be transformed and added later
        const simpleRouteState: Record<string, any> = {
          query: routeState.query,
          p: routeState.page,
          sort: routeState.sortBy,
          rm: routeState.ratingMenu,
          r: routeState.range,
        };

        // Take both states and merge them so if there are any params that are not from algolia they are still kept
        // This also means that using custom params for algolia it will not detect them or delete them.
        // This has to be done manually
        const { origin, pathname, hash, search } = location;
        // const queryParameters = qsModule.parse(search.slice(1)) || {};
        // const { query, p, sort, rm, r, ...refinementListParametersPlusRandom } = queryParameters;

        // Object.assign(simpleRouteState, refinementListParametersPlusRandom);

        // transform refinementList to remove parent object and customize keys
        if (routeState.refinementList) {
          // append _a to all array type fields
          // transform the value from array to string with separator
          Object.entries(routeState.refinementList as Record<string, any>).forEach((pair) => {
            const key = pair[0];
            const value = pair[1];
            // resassign keys to the parent object to avoid the rl object level
            if (Array.isArray(value)) {
              simpleRouteState[key + "_a"] = value.join(",");
            } else {
              simpleRouteState[key] = value;
            }
          });
        }

        let queryString = qsModule.stringify(simpleRouteState);
        if (queryString.length) {
          queryString = `?${queryString}`;
        }
        return `${origin}${pathname}${queryString}${hash}`;
      },

      parseURL({ qsModule, location }) {
        // Runs when page is reloaded
        // result of this goes to routeToState
        const { search } = location;
        // grab current query string and convert to object
        const queryParameters = qsModule.parse(search.slice(1)) || {};

        // remove non-refinementList params
        const { query, p, sort, rm, r, ...refinementListParameters } = queryParameters;
        // take custom refinemend list and parse into uistate compatible
        const refinementList: Record<string, any> = {};
        Object.entries(refinementListParameters).forEach((pair) => {
          const customKey = pair[0] as string;
          const stringValue = pair[1] as string;
          // detect which params have _a which indicates array
          if (customKey.endsWith("_a")) {
            const keyClean = customKey.substring(0, customKey.length - 2);
            // transform "," joined string into array for state
            refinementList[keyClean] = stringValue.split(",");
          }
        });
        // We only return the index state
        const state = {
          query: queryParameters.query,
          page: queryParameters.p,
          sortBy: queryParameters.sort,
          refinementList: refinementList,
          ratingMenu: queryParameters.rm,
          range: queryParameters.r,
        } as any;
        return state;
      },
    }),
    stateMapping: stateMapping,
  };

  /*
   * Retrieves the value of a specified URL parameter from the query string.
   *
   * @param {string} name - The name of the URL parameter to retrieve.
   * @returns {string} The value of the specified URL parameter. If the parameter is not found,
   *                   an empty string is returned.
   */
  const getUrlParameter = (filterParameter: string): string => {
    const clearedFilterParameter = filterParameter.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + clearedFilterParameter + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // Check if blacklist parameter is added to URL
  const hideProducts = getUrlParameter("fblc");
  const { t } = useTranslation();
  const shouldDisplaySubcategories = window.__SUB_CATEGORIES__ && window.__SUB_CATEGORIES__.length > 0;
  return (
    <div className="d-flex flex-lg-row flex-column w-100 ">
      <InstantSearch searchClient={searchClient} indexName={CONSTANTS.productIndexStorage} routing={routing} insights={true}>
        <Configure hitsPerPage={24} removeWordsIfNoResults="none" {...(hideProducts && { filters: "advertisement_blacklisted:0" })} />

        <SearchBox className={"d-none"} />

        <HideWhenNoResult>
          <div className="col-12 col-lg-3 d-flex flex-column px-spacing-12 pb-spacing-24">
            <div className="d-flex flex-column">
              {isTablet && window.__CATEGORY_MIDDLE_CONTENT__ && (
                <div id="zusatz_banner_center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: window.__CATEGORY_MIDDLE_CONTENT__,
                    }}
                  />
                </div>
              )}
              <div>{isTablet && <CategoryDescription />}</div>
              <div>{isTablet && <CategoryHighlight />}</div>
              <div>{!isTablet && shouldDisplaySubcategories && <Subcategories />}</div>
              <div className="filter-wrapper d-none d-lg-block">
                <SidebarFilter scrollFunction={executeScroll} />
              </div>
            </div>
          </div>
        </HideWhenNoResult>

        <HasResultsWrapper>
          <div className="container px-spacing-24">{!isTablet && <CategoryDescription />}</div>
          <HideWhenNoResult>
            <div className="filter-wrapper w-100 mt-spacing-32 mb-spacing-32 d-lg-none" data-module={"categoryfilter"}>
              <OffcanvasFilter />
            </div>

            <div className="pt-spacing-16 px-lg-spacing-24">
              <ClearRefinements
                classNames={{
                  button: "btn btn-secondary px-spacing-16 py-spacing-6 mb-spacing-32 clear-all-refinements",
                }}
                translations={{
                  resetButtonText: t("search.filter.button.reset") as string,
                }}
              />
            </div>

            <div className={"current-refinements px-lg-spacing-24"}>
              <CustomCurrentRefinements
                transformItems={(items, metadata) => {
                  return translateToDisplayName(items, metadata, currentRefinementLabelMap, false);
                }}
              />
            </div>
          </HideWhenNoResult>

          <HideWhenNoResult showNoResultPage>
            <div id="search-result-products-list container">
              <CustomHits />
            </div>

            <CustomPagination />
          </HideWhenNoResult>
        </HasResultsWrapper>
      </InstantSearch>
    </div>
  );
};

const HideWhenNoResult = (props: NoBoundaryProps) => {
  const { results, indexUiState } = useInstantSearch();
  const [loading, setLoading] = useState(true);

  const noResults = !results.__isArtificial && results.nbHits === 0;
  const isRefined = indexUiState.refinementList;
  const searchTerm = indexUiState.query!;
  const searchParams = new URLSearchParams(window.location.search);
  const hasQueryOnly = searchParams.has("query") && Array.from(searchParams.keys()).length === 1;

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  if (!props.showNoResultPage && noResults && hasQueryOnly) {
    return null;
  }

  if (props.showNoResultPage && noResults) {
    return <NoResults searchTerm={searchTerm} isRefined={isRefined} />;
  }

  return <>{props.children}</>;
};

const HasResultsWrapper = ({ children }: any) => {
  const { results, indexUiState } = useInstantSearch();
  const hasResults = results.nbHits > 0;
  const isRefined = indexUiState.refinementList;

  return <div className={`col-12 d-flex flex-column px-lg-spacing-12 pb-spacing-24 ${hasResults || isRefined ? "col-lg-9" : ""}`}>{children}</div>;
};
