import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {Cart, CartItemType} from "@apo-app/app/entity/Cart";
import {CartOverlayProvider, CartOverlayContext} from "@apo-app/app/state/CartOverlay"
import {Modal, Button, Row, Col, Image} from "react-bootstrap";
import classNames from "classnames";
import {Heading} from "@apo-app/app/components/Headings";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from '@fortawesome/pro-light-svg-icons';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';
import {Rating} from "@apo-app/app/components/Rating";
import {EqualHeight, EqualHeightElement} from "@apo-app/app/components/EqualHeight";
import {useTranslation} from "react-i18next";
import {LoadingSpinner} from "../LoadingSpinner/loadingSpinner";


export type CartOverlayContentProps = {
  cartItem: CartItemType,
}

export const CartOverlay = () => {

  return (
    <CartOverlayProvider context={CartOverlayContext}>
      <CartOverlayComposition/>
    </CartOverlayProvider>
  )
}

const CartOverlayComposition = () => {
  const context = useContext(CartOverlayContext)

  const handleClose = () => {
    context.closeCartOverlay()
  }

  function getProduct(): CartItemType | undefined {
    if (context.cartData instanceof Cart) {
      return context.cartData.cartItems.find(cartItems => cartItems.PRODUCTS_ID === context.product)
    }
    return undefined
  }

  function provideContent() {
    const product = getProduct()
    if (product === undefined) {
      return (
        <></>
      )
    }
    if (product.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '1') {
      return (
        <CartOverlayContentPrescription cartItem={product}/>
      )
    }
    return (
      <CartOverlayContentOtc cartItem={product}/>
    )
  }

  return (
    <>
      {context.cartData instanceof Cart &&
        <Modal show={context.show} onHide={handleClose} size={'xl'} className={'cart-overlay'}>
          <Modal.Header>
            <button onClick={handleClose} className="btn-close btn-close-white" aria-label="Close"></button>
          </Modal.Header>
          <Modal.Body>
            <Row className={classNames('justify-content-center')}>
              <Col lg={6}>
                <div className={classNames('d-flex', 'justify-content-center', 'mb-spacing-16')}>
                  {context.loading
                    ? <LoadingSpinner />
                    : <div className={'check-icon-blue'}></div>
                  }
                </div>
                <Heading element={'h1'} className={classNames('mt-spacing-16', 'mb-spacing-32', 'mb-sm-spacing-56', 'text-center')}>
                  {context.loading ? 'das produkt wird dem warenkorb hinzugefügt' : context.cartData.cartMessage}
                </Heading>
                {!context.cartData.freeShipping && !context.loading &&
                  <p className={classNames('text-center', 'h5', 'mb-spacing-32')}>
                    Nur noch {context.cartData.untilFreeShipping} bis zum Gratis&#8209;Versand&#8288;<sup>**</sup>
                  </p>
                }
                {!context.loading && provideContent()}
                {!context.loading && <Row className={classNames('mt-spacing-16', 'mb-spacing-40')}>
                  <Col xs={12} md={6} className={classNames('mb-spacing-16', 'd-flex', 'justify-content-center')}>
                    <Button variant="secondary" href={context.cartData.links.CART_LINK} size={'lg'} className={'w-100'}>
                      Zum Warenkorb
                    </Button>
                  </Col>
                  <Col xs={12} md={6} className={classNames('mb-spacing-16', 'd-flex', 'justify-content-center')}>
                    <Button variant="primary" onClick={handleClose} size={'lg'} className={'w-100'}>
                      Weiter einkaufen
                    </Button>
                  </Col>
                </Row>}
              </Col>
            </Row>
          </Modal.Body>
          {!context.loading &&
            <div className={classNames('products-slider-dark', 'modal-footer', 'p-0', 'd-block')}>
              <Row className={classNames('justify-content-center')}>
                <Col lg={8} className="p-spacing-0">
                  <p className={classNames('h1', 'text-center', 'my-spacing-32')}>Wird häufig zusammen gekauft mit:</p>
                  <CartOverlayRecommendationSlider />
                </Col>
              </Row>
            </div>
          }
        </Modal>
      }
    </>
  )
}

const CartOverlayContentOtc: FC<CartOverlayContentProps> = ({cartItem}) => {
  return (
    <Row className={classNames('justify-content-lg-center')}>
      <Col className={classNames('bg-aco-white', 'border-rounded-8', 'p-spacing-16', 'mb-spacing-24', 'mb-sm-spacing-40')} lg={8} xs={12}>
        <Row>
          <Col>
            <Image src={cartItem.PRODUCTS_IMAGE.image_size_120_120}
                   srcSet={`${cartItem.PRODUCTS_IMAGE.image_size_120_120} 1x, ${cartItem.PRODUCTS_IMAGE.image_size_120_120_x2} 2x`}
                   alt={cartItem.PRODUCTS_IMAGE.alt_text}
                   width={120}
                   height={120}
            />
          </Col>
          <Col>
            <p className={classNames('micro-bold', 'text-aco-black')}>{cartItem.PRODUCTS_NAME}</p>
            <ul className={classNames('list-unstyled', 'micro', 'text-aco-gray-01')}>
              <li>PZN: {cartItem.PRODUCTS_MODEL}</li>
              <li>Menge: {cartItem.PRODUCT_QUANTITY}</li>
            </ul>
            <p className={classNames('text-aco-orange', 'fs-5')}><strong>{cartItem.PRODUCT_AMOUNT_PRICE}</strong></p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const CartOverlayContentPrescription: FC<CartOverlayContentProps> = ({cartItem}) => {
  return (
    <>
      <p className={classNames('text-center', 'text-aco-yellow', 'fs-5')}><FontAwesomeIcon icon={faCircleInfo}/> Rezeptpflichtiges Produkt:</p>
      <p className={classNames('text-center', 'mb-spacing-64')}>Um dieses Produkt zu bestellen, benötigen Sie ein gültiges Rezept.</p>
      <Row className={classNames('justify-content-lg-center')}>
        <Col className={classNames('bg-aco-white', 'border-rounded-8', 'p-spacing-16', 'text-aco-black')} lg={8} xs={12}>
          <Row>
            <Col>
              <Image src={cartItem.PRODUCTS_IMAGE.image_size_120_120}
                     srcSet={`${cartItem.PRODUCTS_IMAGE.image_size_120_120} 1x, ${cartItem.PRODUCTS_IMAGE.image_size_120_120_x2} 2x`}
                     alt={cartItem.PRODUCTS_IMAGE.alt_text}
                     width={120}
                     height={120}
              />
            </Col>
            <Col>
              <p className={classNames('micro-bold', 'text-aco-black')}>{cartItem.PRODUCTS_NAME}</p>
              <ul className={classNames('list-unstyled', 'micro', 'text-aco-gray-01')}>
                <li>Menge: {cartItem.PRODUCT_QUANTITY}</li>
                <li><strong>rezeptpflichtig</strong></li>
              </ul>
              <p className={classNames('fs-5')}><strong>{cartItem.PRODUCT_AMOUNT_PRICE}</strong></p>
            </Col>
          </Row>
        </Col>
      </Row>
      <p className={classNames('text-center', 'mt-spacing-40', 'mb-spacing-20')}>Jetzt weitere Produkte <strong>versandkostenfrei</strong> mitbestellen.</p>
    </>
  )
}

const CartOverlayRecommendationSlider = () => {
  const context = useContext(CartOverlayContext)
  const prevRef = useRef<HTMLDivElement>(null)
  const nextRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [loadSlide, setLoadSlide] = useState<boolean>(false)
  const {t} = useTranslation()

  return (
    <EqualHeight updateOnChange={loadSlide}>
      <Swiper
        data-slider="products"
        className="p-spacing-0"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        navigation={{
          prevEl: prevRef.current!,
          nextEl: nextRef.current!,
        }}
        pagination={{
          clickable: true,
          el: paginationRef.current!
        }}
        keyboard={{enabled: true}}
        spaceBetween={24}
        slidesPerView={2}
        breakpoints={{
          570: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4,
          }
        }}
      >
        {context.slider.map((slide, index) => (
          <SwiperSlide key={index} onLoad={(): void => setLoadSlide(true)}>
            <div className="d-flex flex-column p-spacing-0 h-100">
              <a href={slide.PRODUCTS_LINK}>
                <div className="position-relative card mb-spacing-6">
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Image fluid={true}
                             src={slide.PRODUCTS_IMAGE.image_size_180_180}
                             srcSet={`${slide.PRODUCTS_IMAGE.image_size_180_180} 1x, ${slide.PRODUCTS_IMAGE.image_size_180_180_x2} 2x`}
                             alt={slide.PRODUCTS_IMAGE.alt_text}
                             width={180}
                             height={180}
                      />
                    </div>
                  </div>
                </div>
              </a>
              <div className="d-flex flex-column flex-grow-1">
                <EqualHeightElement name={'title'}>
                  <p className="text-aco-white footnote-bold card-title-truncate-overflow">
                    {slide.PRODUCTS_NAME}
                  </p>
                </EqualHeightElement>
                <EqualHeightElement name={'rating'}>
                  {slide.rezeptpflichtig !== '1' &&
                    <div className={'mb-spacing-8'}>
                      <Rating average={slide.PRODUCTS_RATING_AVERAGE} total={slide.PRODUCTS_RATING_COUNT} size={'md'}/>
                    </div>
                  }
                </EqualHeightElement>
                <div>
                  <div className="d-flex flex-column flex-grow-1 mb-spacing-24">
                    <EqualHeightElement name={'attributes'}>
                      {slide.rezeptpflichtig === '1' &&
                        <div className="mb-spacing-16">
                          <span className="badge badge-tag">Rezeptpflichtig</span>
                        </div>
                      }
                    </EqualHeightElement>
                    <EqualHeightElement name={'dosage'}>
                      <p className="text-aco-white mb-spacing-0 footnote text-truncate">
                        {slide.PRODUCTS_INHALT}
                        {slide.PRODUCTS_PRESENTATION &&
                          <span className={classNames('border-start', 'border-aco-white', 'border-medium', 'ps-spacing-6', 'ms-spacing-6')}>
                            {slide.PRODUCTS_PRESENTATION}
                          </span>
                        }
                      </p>
                    </EqualHeightElement>
                    <EqualHeightElement name={'base-price'}>
                      <p className="text-aco-white mb-spacing-8 footnote">
                        <small>{slide.PRODUCTS_GP_PRICE}</small>
                      </p>
                    </EqualHeightElement>
                    <EqualHeightElement name={'mandatory-information'}>
                      <a className="search-result-card-mandatory text-white" href={slide.PRODUCTS_LINK}>
                        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.75 8.5H4.875a.76.76 0 0 0-.75.75c0 .438.344.75.75.75h3.063l-4.22 4.22c-.312.312-.312.78 0 1.062.126.156.313.219.532.219a.622.622 0 0 0 .5-.22L9 11.064v3.063c0 .406.313.75.75.75a.76.76 0 0 0 .75-.75V9.25a.76.76 0 0 0-.75-.75zM12 5H2C.875 5 0 5.907 0 7v10c0 1.125.875 2 2 2h10c1.094 0 2-.875 2-2V7c0-1.093-.906-2-2-2zm.5 12c0 .282-.25.5-.5.5H2a.494.494 0 0 1-.5-.5V7c0-.25.219-.5.5-.5h10c.25 0 .5.25.5.5v10z"
                            fill="#fff"/>
                        </svg>
                        {t('addToCartOverLay.recommendationProductCard.mandatoryText')}
                      </a>

                    </EqualHeightElement>
                  </div>
                </div>
                <div className="flex-grow-1 d-flex flex-column justify-content-end">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-spacing-0 text-decoration-line-through text-aco-gray-03">
                      {slide.PRODUCTS_UVP}
                    </p>
                    <h5 className="text-aco-orange fs-lg mb-spacing-0">
                      {slide.PRODUCTS_PRICE}
                      <sup>*</sup>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="mt-spacing-8 w-100">
                <cart-button data-selector="cart-button-recommendation"
                             product={slide.products_id}
                             className="search-result-card-button shadow-root"
                             quantity={1}
                             prescription={Number(slide.rezeptpflichtig)}>
                  <span>
                   <i className="fas fa-cart-shopping fa-sharp me-spacing-8"></i>
                   {t('addToCartOverLay.recommendationProductCard.addToCart')}
                  </span>

                </cart-button>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="d-flex justify-content-center align-items-center my-spacing-40 swiper-controls-wrapper">
          <div className="d-flex justify-content-between align-items-center swiper-controls">
            <div ref={prevRef} className="swiper-button-prev btn-sm btn-primary"></div>
            <div ref={paginationRef} className="swiper-pagination"></div>
            <div ref={nextRef} className="swiper-button-next btn-sm btn-primary"></div>
          </div>
        </div>
      </Swiper>
    </EqualHeight>
  )
}
