import {CategoryFilter} from "./CategoryFilter"
import {KlarnaCheckout} from './KlarnaCheckout'
import {ShowOrderDetails} from "./ShowOrderDetails/show_order_details"
import {ErxWorkflow} from './ErxWorkflow';
import {OmqContact} from "./OmqContact";


const formCancelOrder = document.querySelector('[data-form="cancelOrder"]')
const refreshOrderTracking= document.querySelector('[data-detail-tracking-info]')
const openModal = document.querySelector('[data-action="openModal"]')
const erxReader = document.getElementById('reader')
const omqContact = document.querySelector('[data-selector="omqContact"]')
/**
 * Import modules
 */

if (formCancelOrder instanceof HTMLFormElement) {
  const showOrderDetails = new ShowOrderDetails(formCancelOrder, refreshOrderTracking, openModal)
  showOrderDetails.cancelOrder()
  refreshOrderTracking && refreshOrderTracking.orderTracking()
  openModal && openModal.handleVisibilityModal()
}


const categoryFilter = document.querySelector('[data-module="categoryfilter"]')
if(categoryFilter) {
  new CategoryFilter(categoryFilter)
}

const klarnaCheckoutElement = document.querySelector('[data-klarna-checkout]')
new KlarnaCheckout(klarnaCheckoutElement)

if(erxReader) {
  new ErxWorkflow(erxReader)
}

if(omqContact) {
  new OmqContact()
}
