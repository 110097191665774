import {ApoEvents} from "./apoEvents";

const CONSOLESTYLE_ALERT = ['background: #fff; color: #fc0000; font-size:13px', 'background: #fc0000; color: #fff; font-size:13px'];
const CONSOLESTYLE_INFO = ['background: #000; color: #00ff00; font-size:11px', 'background: #00ff00; color: #000; font-size:11px'];

export class ApoLibraryLoader {
  static instance: ApoLibraryLoader;
  private _deferredScriptTags: NodeListOf<HTMLScriptElement> = document.querySelectorAll('[data-deferscript]')!;
  private _loggingDisabled: boolean = true;

  constructor() {
    if (ApoLibraryLoader.instance) {
      return ApoLibraryLoader.instance;
    }
    ApoLibraryLoader.instance = this;

    document.addEventListener(ApoEvents.USERCENTRICS_CONSENT_STATUS_CHANGED, this.updateScriptTags)
  }

  private updateScriptTags = (e: CustomEvent) => {
    // console.log('checkScriptTags = e.type, ', e.type);
    switch (e.type) {
      case ApoEvents.USERCENTRICS_CONSENT_STATUS_CHANGED:
        const services = e.detail.services as any[];
        const forceloadScriptTags = Array.from(this.deferredScriptTags).filter(
          script => {
            return script.dataset.defercondition === 'usercentrics' && script.dataset.forceload !== undefined;
          });
        const filteredScriptTags = Array.from(this.deferredScriptTags).filter(
          script => (script.dataset.defercondition === 'usercentrics' && !script.dataset.loaded && script.dataset.forceload === undefined));

        if (!this._loggingDisabled) {
          console.log('%c------------------------', CONSOLESTYLE_INFO[0]);
          console.log(`%cupdateScriptTags : Consented Services = `, CONSOLESTYLE_INFO[0], services);
          console.log(`%cupdateScriptTags : forceloadScriptTags = `, CONSOLESTYLE_INFO[0], forceloadScriptTags);
          console.log(`%cupdateScriptTags : filteredScriptTags = `, CONSOLESTYLE_INFO[0], filteredScriptTags);
          console.log('%c------------------------', CONSOLESTYLE_INFO[0]);
        }

        filteredScriptTags.forEach(scriptTag => {
          if (!this._loggingDisabled) {
            console.log('------------------------');
            console.info(`%cSearching for matching scriptTag.dataset.usercentricsId = %c ${scriptTag.dataset.usercentricsId}`, ...CONSOLESTYLE_ALERT);
          }
          for (let i = 0; i < services.length; i++) {
            let service = services[i];
            if (!this._loggingDisabled) console.log(`%cChecking consented service.id: %c ${service.id}`, ...CONSOLESTYLE_INFO);
            if (service.id === scriptTag.dataset.usercentricsId) {
              if (!this._loggingDisabled) {
                console.log(`%cSCRIPT ID MATCHED! Loading scriptTag => %c ${scriptTag.dataset.deferscript}`, ...CONSOLESTYLE_ALERT);
                console.log('%c------------------------', CONSOLESTYLE_INFO[0]);
              }
              this.activateDeferredScriptTag(scriptTag, service.consent.status);
              break;
            }
          }
        })

        forceloadScriptTags.forEach(scriptTag => {
          if (!this._loggingDisabled) console.info(`%cForce loading scriptTag => %c ${scriptTag.dataset.deferscript}`, ...CONSOLESTYLE_ALERT);
          this.activateDeferredScriptTag(scriptTag, false);
        })
        if (!this._loggingDisabled) console.log('%c------------------------', CONSOLESTYLE_INFO[0]);
        break;
    }
  }


  private activateDeferredScriptTags = ($filteredScriptTags: Array<HTMLScriptElement>) => {
    $filteredScriptTags.forEach((script: HTMLScriptElement) => {
      this.activateDeferredScriptTag(script);
    })
  }

  private activateDeferredScriptTag = ($scriptTag: HTMLScriptElement, $matched: boolean = true) => {
    $scriptTag.onload = (e) => {
      (e.currentTarget as HTMLScriptElement).dataset.loaded = 'true';

      document.dispatchEvent(new CustomEvent(ApoEvents.DEFERRED_LIBRARY_LOADED, {
        'detail': {
          id: $scriptTag.dataset.deferscript,
          isConsented: $matched
        }
      }));
    }

    $scriptTag.src = $scriptTag.dataset.src!;
  }

  public get deferredScriptTags() {
    return this._deferredScriptTags;
  }

  public disableLogging() {
    this._loggingDisabled = true;
  }
}