import {ApoEvents} from "./apoEvents";

const SCRIPT_ID: string = 'kairion';

declare global {
  interface Window {
    kias: any,
    kairion_page_type: any,
    kairion_product_datasets: any,
    kairion_tags: any,
    pageSlots:{}
  }
}

export class ApoKairionController {
  static instance: ApoKairionController;
  private _consented : boolean = false;

  constructor() {
    if (ApoKairionController.instance) {
      return ApoKairionController.instance;
    }
    ApoKairionController.instance = this;

    window.kias=window.kias || [];

    document.addEventListener(ApoEvents.DEFERRED_LIBRARY_LOADED, this.init);
  }


  private init = (e : CustomEvent) => {
    if (e.detail.id === SCRIPT_ID) {
      this._consented = e.detail.isConsented;
      document.removeEventListener(ApoEvents.DEFERRED_LIBRARY_LOADED, this.init)

      this.onScriptLoad();
    }
  }


  private onScriptLoad = () => {
    if(window.kairion_tags?.length <= 0) {
      window.kias.push(
        {cmd:"setPageSettings", pageType:window.kairion_page_type, products:window.kairion_product_datasets, consent: this._consented},
        window.pageSlots);
    }
    else {
      window.kias.push(
        {cmd:"setPageSettings", pageType:window.kairion_page_type, products:window.kairion_product_datasets, tags:window.kairion_tags, consent: this._consented},
        window.pageSlots);
    }
  }
}