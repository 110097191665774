import classNames from "classnames";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faCirclePlus} from "@fortawesome/pro-solid-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import React from "react";
import {useTranslation} from "react-i18next";

type Props = {
  isVisible: boolean
  hideModal?: () => void
  useStorage?: () => void
  deleteStorage?: () => void
}

export const ProductReminderContinueModal = (props: Props) => {

  const {t} = useTranslation();


  return (
    <Modal show={props.isVisible} centered onHide={props.hideModal}>
      <Modal.Header className={classNames('text-aco-white btn-close-aco-blue')}>
        <button className={classNames('btn btn-close btn-close-aco-blue close')}
                type='button'
                data-dismiss='modal'
                aria-label='Close'
                onClick={props.hideModal}
        >
        </button>
      </Modal.Header>
        <Modal.Title className="pb-spacing-16 w-100 h2">{t('productReminder.continueModal.headline')}</Modal.Title>
      <Modal.Body>
        <p className={classNames('text-aco-white')}>
          {t('productReminder.continueModal.content')}
        </p>

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'secondary'}
          onClick={props.deleteStorage}
        >
          {t('productReminder.continueModal.newReminder')}
        </Button>
        <Button
          variant={'primary'}
          onClick={props.useStorage}
        >
          {t('productReminder.continueModal.continueReminder')}


        </Button>
      </Modal.Footer>
    </Modal>
  )
}