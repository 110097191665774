import { useCurrentRefinements, UseCurrentRefinementsProps } from "react-instantsearch";

/**
 * Active Filter Counter - displays the number of active filters in the offcanvas menu inside the offcanvas-toggle-button
 *
 * @param props
 * @constructor
 */
export const ActiveFilterCounter = (props: UseCurrentRefinementsProps) => {
  const { items } = useCurrentRefinements(props);

  return (
    <span>{items.length}</span>
  );
}