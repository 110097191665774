import React from "react";
import {ProductReminderOverviewHeader} from "./product-reminder-overview-header";
import {ProductReminderOverviewAdd} from "./product-reminder-overview-add-button";
import {ProductReminderOverviewDetails} from "./product-reminder-overview-details";
import {ProductReminderList} from "@apo-app/app/entity/ProductReminder";


interface Props {
    reminderData: ProductReminderList,
    deleteReminder: Function,
    addNewReminder: () => void
}

export const ProductReminderOverview = (props: Props) => {

    const addNewReminder = () => {
        props.addNewReminder()
    }

    return (
        <>
            <ProductReminderOverviewHeader products={props.reminderData} addNewReminder={addNewReminder}/>
            <div className="row row-cols-12 align-items-center justify-content-center container">
                {props.reminderData.REMINDER_LIST.length > 0 && ( <ProductReminderOverviewAdd getClick={addNewReminder}/> )}
                <div className="bd-example dark w-100">
                    <ProductReminderOverviewDetails data={props.reminderData} deleteReminder={props.deleteReminder}/>
                </div>
            </div>
        </>

    )
}