import React, {FC} from 'react';
import { Product } from '../../entity/product';
import { ProductList } from '../../components/ProductList';
/** temporary usage of the api mock */
import { mockProductList as productList } from "../../entity/product";

export type CategoryProps = {
  /**
   * the category id if set, otherwise null
   */
  id: string | null
  products: Product[]
}

export const Category:FC<CategoryProps> = ({id, products}) => {
  /**
   * ToDo: get the category from api by id
   */
  return (
    <ProductList list={ products } />
  );
}
