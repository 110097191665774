/**
 * Font Awesome Javascript API
 * @see https://fontawesome.com/v6/docs/apis/javascript/get-started
 */

import {library, dom} from '@fortawesome/fontawesome-svg-core';

/**
 * Pro Regular Icons
 * @see https://fontawesome.com/v6/search?s=regular
 */

import {
  faAlarmClock as faAlarmClockRegular,
  faArrowLeft as faArrowLeftRegular,
  faArrowRight as faArrowRightRegular,
  faAngleRight as faAngleRightRegular,
  faArrowUpArrowDown as faArrowUpArrowDownRegular,
  faAt as faAtRegular,
  faBarcodeRead as faBarcodeReadRegular,
  faBars as faBarsRegular,
  faBell as faBellRegular,
  faBookMedical as faBookMedicalRegular,
  faBox as faBoxRegular,
  faCalendarDays as faCalendarDaysRegular,
  faCamera as faCameraRegular,
  faCartShopping as faCartShoppingRegular,
  faCheck as faCheckRegular,
  faChevronDown as faChevronDownRegular,
  faChevronLeft as faChevronLeftRegular,
  faChevronRight as faChevronRightRegular,
  faChevronUp as faChevronUpRegular,
  faCircle as faCircleRegular,
  faCircleCheck as faCircleCheckRegular,
  faCircleEllipsis as faCircleEllipsisRegular,
  faCircleExclamation as faCircleExclamationRegular,
  faCircleInfo as faCircleInfoRegular,
  faCirclePhone as faCirclePhoneRegular,
  faCircleQuestion as faCircleQuestionRegular,
  faCirclePause as faCirclePauseRegular,
  faCirclePlay as faCirclePlayRegular,
  faCirclePlus as faCirclePlusRegular,
  faCircleXmark as faCircleXmarkRegular,
  faClose as faCloseRegular,
  faEnvelope as faEnvelopeRegular,
  faEnvelopeOpenText as faEnvelopeOpenTextRegular,
  faEuroSign as faEuroSignRegular,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
  faPhone as faPhoneRegular,
  faFilePdf as faFilePdfRegular,
  faFiles as faFilesRegular,
  faFlashlight as faFlashlightRegular,
  faFolder as faFolderRegular,
  faGaugeMax as faGaugeMaxRegular,
  faGear as faGearRegular,
  faHeart as faHeartRegular,
  faHeadphonesSimple as faHeadphonesSimpleRegular,
  faHouse as faHouseRegular,
  faInfo as faInfoRegular,
  faLeaf as faLeafRegular,
  faLock as faLockRegular,
  faMessages as faMessagesRegular,
  faMessageMedical as faMessageMedicalRegular,
  faMinus as faMinusRegular,
  faMosquito as faMosquitoRegular,
  faPaperPlaneTop as faPaperPlaneTopRegular,
  faPause as faPauseRegular,
  faPaw as faPawRegular,
  faPencil as faPencilRegular,
  faPenCircle as faPenCircleRegular,
  faPeopleArrowsLeftRight as faPeopleArrowsLeftRightRegular,
  faPills as faPillsRegular,
  faPipe as faPipeRegular,
  faPlay as faPlayRegular,
  faPlus as faPlusRegular,
  faPlusLarge as faPlusLargeRegular,
  faQuestion as faQuestionRegular,
  faRegistered as faRegisteredRegular,
  faRotate as faRotateRegular,
  faRotateLeft as faRotateLeftRegular,
  faRotateRight as faRotateRightRegular,
  faSearch as faSearchRegular,
  faShareNodes as faShareNodesRegular,
  faShield as faShieldRegular,
  faShieldCheck as faShieldCheckRegular,
  faSliders as faSlidersRegular,
  faSquare as faSquareRegular,
  faSquareArrowRight as faSquareArrowRightRegular,
  faSquareArrowUpRight as faSquareArrowUpRightRegular,
  faSquareArrowUp as faSquareArrowUpRegular,
  faSquareCheck as faSquareCheckRegular,
  faStar as faStarRegular,
  faStarHalfStroke as faStarHalfStrokeRegular,
  faStethoscope as faStethoscopeRegular,
  faTemperatureSnow as faTemperatureSnowRegular,
  faTrashCan as faTrashCanRegular,
  faTriangleExclamation as faTriangleExclamationRegular,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterRegular,
  faUpload as faUploadRegular,
  faUserDoctorMessage as faUserDoctorMessageRegular,
  faUser as faUserRegular,
  faVolume as faVolumeRegular,
  faFax as faFaxRegular,
  faArrowDownLeftAndArrowUpRightToCenter as faArrowDownLeftAndArrowUpRightToCenterRegular,
  faArrowDown as faArrowDownRegular,
  faArrowUpRightAndArrowDownLeftFromCenter as faArrowUpRightAndArrowDownLeftFromCenterRegular,
  faDownload as faDownloadRegular,
  faEllipsis as faEllipsisRegular,
  faExclamation as faExclamationRegular,
  faFilePlus as faFilePlusRegular,
  faGift as faGiftRegular,
  faGlobe as faGlobeRegular,
  faHandDots as faHandDotsRegular,
  faHandHoldingMedical as faHandHoldingMedicalRegular,
  faHeadSideCough as faHeadSideCoughRegular,
  faHeartPulse as faHeartPulseRegular,
  faHourglassClock as faHourglassClockRegular,
  faIslandTropical as faIslandTropicalRegular,
  faPersonDotsFromLine as faPersonDotsFromLineRegular,
  faPersonRays as faPersonRaysRegular,
  faPiggyBank as faPiggyBankRegular,
  faStomach as faStomachRegular,
  faUserHeadset as faUserHeadsetRegular,
  faVirusSlash as faVirusSlashRegular,
  faTruckFast as faTruckFastRegular,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * Pro Solid Icons
 * @see https://fontawesome.com/v6/search?s=solid
 */

import {
  faAlarmClock as faAlarmClockSolid,
  faAngleRight as faAngleRightSolid,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faArrowUpArrowDown as faArrowUpArrowDownSolid,
  faAt as faAtSolid,
  faBarcodeRead as faBarcodeReadSolid,
  faBars as faBarsSolid,
  faBell as faBellSolid,
  faBookMedical as faBookMedicalSolid,
  faBox as faBoxSolid,
  faCalendarDays as faCalendarDaysSolid,
  faCamera as faCameraSolid,
  faCartShopping as faCartShoppingSolid,
  faCheck as faCheckSolid,
  faChevronDown as faChevronDownSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronUp as faChevronUpSolid,
  faCircle as faCircleSolid,
  faCircleCheck as faCircleCheckSolid,
  faCircleEllipsis as faCircleEllipsisSolid,
  faCircleExclamation as faCircleExclamationSolid,
  faCircleInfo as faCircleInfoSolid,
  faCirclePhone as faCirclePhoneSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faCirclePause as faCirclePauseSolid,
  faCirclePlay as faCirclePlaySolid,
  faCirclePlus as faCirclePlusSolid,
  faCircleXmark as faCircleXmarkSolid,
  faClose as faCloseSolid,
  faEnvelope as faEnvelopeSolid,
  faEnvelopeOpenText as faEnvelopeOpenTextSolid,
  faEuroSign as faEuroSignSolid,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faPhone as faPhoneSolid,
  faFilePdf as faFilePdfSolid,
  faFiles as faFilesSolid,
  faFlashlight as faFlashlightSolid,
  faFolder as faFolderSolid,
  faGaugeMax as faGaugeMaxSolid,
  faGear as faGearSolid,
  faHeart as faHeartSolid,
  faHeadphonesSimple as faHeadphonesSimpleSolid,
  faHouse as faHouseSolid,
  faInfo as faInfoSolid,
  faLeaf as faLeafSolid,
  faLock as faLockSolid,
  faMessages as faMessagesSolid,
  faMessageMedical as faMessageMedicalSolid,
  faMinus as faMinusSolid,
  faMosquito as faMosquitoSolid,
  faPaperPlaneTop as faPaperPlaneTopSolid,
  faPause as faPauseSolid,
  faPaw as faPawSolid,
  faPencil as faPencilSolid,
  faPenCircle as faPenCircleSolid,
  faPeopleArrowsLeftRight as faPeopleArrowsLeftRightSolid,
  faPills as faPillsSolid,
  faPipe as faPipeSolid,
  faPlay as faPlaySolid,
  faPlus as faPlusSolid,
  faQuestion as faQuestionSolid,
  faPlusLarge as faPlusLargeSolid,
  faRegistered as faRegisteredSolid,
  faRotate as faRotateSolid,
  faRotateLeft as faRotateLeftSolid,
  faRotateRight as faRotateRightSolid,
  faSearch as faSearchSolid,
  faShareNodes as faShareNodesSolid,
  faShield as faShieldSolid,
  faShieldCheck as faShieldCheckSolid,
  faSliders as faSlidersSolid,
  faSquare as faSquareSolid,
  faSquareArrowRight as faSquareArrowRightSolid,
  faSquareArrowUp as faSquareArrowUpSolid,
  faSquareArrowUpRight as faSquareArrowUpRightSolid,
  faSquareCheck as faSquareCheckSolid,
  faStar as faStarSolid,
  faStarHalfStroke as faStarHalfStrokeSolid,
  faStethoscope as faStethoscopeSolid,
  faTemperatureSnow as faTemperatureSnowSolid,
  faTrashCan as faTrashCanSolid,
  faTriangleExclamation as faTriangleExclamationSolid,
  faUpRightAndDownLeftFromCenter as faUpRightAndDownLeftFromCenterSolid,
  faUpload as faUploadSolid,
  faUserDoctorMessage as faUserDoctorMessageSolid,
  faUser as faUserSolid,
  faFax as faFaxSolid,
  faVolume as faVolumeSolid,
  faArrowDownLeftAndArrowUpRightToCenter as faArrowDownLeftAndArrowUpRightToCenterSolid,
  faArrowDown as faArrowDownSolid,
  faArrowUpRightAndArrowDownLeftFromCenter as faArrowUpRightAndArrowDownLeftFromCenterSolid,
  faDownload as faDownloadSolid,
  faEllipsis as faEllipsisSolid,
  faExclamation as faExclamationSolid,
  faFilePlus as faFilePlusSolid,
  faGift as faGiftSolid,
  faGlobe as faGlobeSolid,
  faHandDots as faHandDotsSolid,
  faHandHoldingMedical as faHandHoldingMedicalSolid,
  faHeadSideCough as faHeadSideCoughSolid,
  faHeartPulse as faHeartPulseSolid,
  faHourglassClock as faHourglassClockSolid,
  faIslandTropical as faIslandTropicalSolid,
  faPersonDotsFromLine as faPersonDotsFromLineSolid,
  faPersonRays as faPersonRaysSolid,
  faPiggyBank as faPiggyBankSolid,
  faStomach as faStomachSolid,
  faUserHeadset as faUserHeadsetSolid,
  faVirusSlash as faVirusSlashSolid,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faAlarmClockRegular,
  faAngleRightRegular,
  faArrowDownLeftAndArrowUpRightToCenterRegular,
  faArrowDownRegular,
  faArrowLeftRegular,
  faArrowRightRegular,
  faArrowUpArrowDownRegular,
  faArrowUpRightAndArrowDownLeftFromCenterRegular,
  faAtRegular,
  faBarcodeReadRegular,
  faBarsRegular,
  faBellRegular,
  faBookMedicalRegular,
  faBoxRegular,
  faCalendarDaysRegular,
  faCameraRegular,
  faCartShoppingRegular,
  faCheckRegular,
  faChevronDownRegular,
  faChevronLeftRegular,
  faChevronRightRegular,
  faChevronUpRegular,
  faCircleCheckRegular,
  faCircleEllipsisRegular,
  faCircleExclamationRegular,
  faCircleInfoRegular,
  faCirclePauseRegular,
  faCirclePhoneRegular,
  faCirclePlayRegular,
  faCirclePlusRegular,
  faCircleQuestionRegular,
  faCircleRegular,
  faCircleXmarkRegular,
  faCloseRegular,
  faDownloadRegular,
  faEllipsisRegular,
  faEnvelopeOpenTextRegular,
  faEnvelopeRegular,
  faEuroSignRegular,
  faExclamationRegular,
  faEyeRegular,
  faEyeSlashRegular,
  faFaxRegular,
  faFilePdfRegular,
  faFilePlusRegular,
  faFilesRegular,
  faFlashlightRegular,
  faFolderRegular,
  faGaugeMaxRegular,
  faGearRegular,
  faGiftRegular,
  faGlobeRegular,
  faHandDotsRegular,
  faHandHoldingMedicalRegular,
  faHeadphonesSimpleRegular,
  faHeadSideCoughRegular,
  faHeartPulseRegular,
  faHeartRegular,
  faHourglassClockRegular,
  faHouseRegular,
  faInfoRegular,
  faIslandTropicalRegular,
  faLeafRegular,
  faLockRegular,
  faMessageMedicalRegular,
  faMessagesRegular,
  faMinusRegular,
  faMosquitoRegular,
  faPaperPlaneTopRegular,
  faPauseRegular,
  faPawRegular,
  faPencilRegular,
  faPenCircleRegular,
  faPeopleArrowsLeftRightRegular,
  faPersonDotsFromLineRegular,
  faPersonRaysRegular,
  faPhoneRegular,
  faPiggyBankRegular,
  faPillsRegular,
  faPipeRegular,
  faPlayRegular,
  faPlusLargeRegular,
  faPlusRegular,
  faQuestionRegular,
  faRegisteredRegular,
  faRotateLeftRegular,
  faRotateRegular,
  faRotateRightRegular,
  faSearchRegular,
  faShareNodesRegular,
  faShieldCheckRegular,
  faShieldRegular,
  faSlidersRegular,
  faSquareArrowRightRegular,
  faSquareArrowUpRightRegular,
  faSquareArrowUpRegular,
  faSquareCheckRegular,
  faSquareRegular,
  faStarHalfStrokeRegular,
  faStarRegular,
  faStethoscopeRegular,
  faStomachRegular,
  faTemperatureSnowRegular,
  faTrashCanRegular,
  faTriangleExclamationRegular,
  faTruckFastRegular,
  faUploadRegular,
  faUpRightAndDownLeftFromCenterRegular,
  faUserDoctorMessageRegular,
  faUserHeadsetRegular,
  faUserRegular,
  faVirusSlashRegular,
  faVolumeRegular,
  faAlarmClockSolid,
  faAngleRightSolid,
  faArrowDownLeftAndArrowUpRightToCenterSolid,
  faArrowDownSolid,
  faArrowLeftSolid,
  faArrowRightSolid,
  faArrowUpArrowDownSolid,
  faArrowUpRightAndArrowDownLeftFromCenterSolid,
  faAtSolid,
  faBarcodeReadSolid,
  faBarsSolid,
  faBellSolid,
  faBookMedicalSolid,
  faBoxSolid,
  faCalendarDaysSolid,
  faCameraSolid,
  faCartShoppingSolid,
  faCheckSolid,
  faChevronDownSolid,
  faChevronLeftSolid,
  faChevronRightSolid,
  faChevronUpSolid,
  faCircleCheckSolid,
  faCircleEllipsisSolid,
  faCircleExclamationSolid,
  faCircleInfoSolid,
  faCirclePauseSolid,
  faCirclePhoneSolid,
  faCirclePlaySolid,
  faCirclePlusSolid,
  faCircleQuestionSolid,
  faCircleSolid,
  faCircleXmarkSolid,
  faCloseSolid,
  faDownloadSolid,
  faEllipsisSolid,
  faEnvelopeOpenTextSolid,
  faEnvelopeSolid,
  faEuroSignSolid,
  faExclamationSolid,
  faEyeSlashSolid,
  faEyeSolid,
  faFaxSolid,
  faFilePdfSolid,
  faFilePlusSolid,
  faFilesSolid,
  faFlashlightSolid,
  faFolderSolid,
  faGaugeMaxSolid,
  faGearSolid,
  faGiftSolid,
  faGlobeSolid,
  faHandDotsSolid,
  faHandHoldingMedicalSolid,
  faHeadphonesSimpleSolid,
  faHeadSideCoughSolid,
  faHeartPulseSolid,
  faHeartSolid,
  faHourglassClockSolid,
  faHouseSolid,
  faInfoSolid,
  faIslandTropicalSolid,
  faLeafSolid,
  faLockSolid,
  faMessageMedicalSolid,
  faMessagesSolid,
  faMinusSolid,
  faMosquitoSolid,
  faPaperPlaneTopSolid,
  faPauseSolid,
  faPawSolid,
  faPencilSolid,
  faPenCircleSolid,
  faPeopleArrowsLeftRightSolid,
  faPersonDotsFromLineSolid,
  faPersonRaysSolid,
  faPhoneSolid,
  faPiggyBankSolid,
  faPillsSolid,
  faPipeSolid,
  faPlaySolid,
  faPlusLargeSolid,
  faPlusSolid,
  faQuestionSolid,
  faRegisteredSolid,
  faRotateLeftSolid,
  faRotateRightSolid,
  faRotateSolid,
  faSearchSolid,
  faShareNodesSolid,
  faShieldCheckSolid,
  faShieldSolid,
  faSlidersSolid,
  faSquareArrowRightSolid,
  faSquareArrowUpSolid,
  faSquareArrowUpRightSolid,
  faSquareCheckSolid,
  faSquareSolid,
  faStarHalfStrokeSolid,
  faStarSolid,
  faStethoscopeSolid,
  faStomachSolid,
  faTemperatureSnowSolid,
  faTrashCanSolid,
  faTriangleExclamationSolid,
  faUploadSolid,
  faUpRightAndDownLeftFromCenterSolid,
  faUserDoctorMessageSolid,
  faUserHeadsetSolid,
  faUserSolid,
  faUserSolid,
  faVirusSlashSolid,
  faVolumeSolid
);

window.addEventListener('DOMContentLoaded', () => {
  dom.i2svg();
  dom.watch();
});
