import type { Hit as AlgoliaHit } from "instantsearch.js/es/types/results";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Highlight, useHits, UseHitsProps } from "react-instantsearch";

interface HitProps {
  hit: AlgoliaHit<{
    categories_name: string;
    objectID: number;
    categories_url: string;
    use_in_search: number;
  }>;
}

interface UseHitsPropsCustom extends UseHitsProps {
  hits?: HitProps[];
}

export const CategoryHits = (props: UseHitsPropsCustom) => {
  const { t } = useTranslation();
  const { hits, results } = useHits(props);

  // Using != 0 instead of !== 0 to handle both strings and numbers
  const filteredHits = useMemo(
    () => hits.filter((hit) => hit.use_in_search != 0),
    [hits]
  );

  if (filteredHits.length === 0) {
    return null;
  }

  return (
    <div
      className={`${
        results!.query === "" || results!.nbHits === 0 ? "d-none" : ""
      }`}
      role="status"
      aria-live="assertive"
    >
      <h3 className={"mb-spacing-16"}>
        {t("search.autosuggest.categories.header")}
      </h3>
      <ul className={"auto-suggest-categories z-index-100 list-style-type-none"}>
        {filteredHits.map((hit) => (
          <li key={hit.objectID} tabIndex={0}>
            <a
              href={hit.categories_url as string}
              target={"_self"}
              className="d-flex justify-content-between align-items-center gap-spacing-8"
              role="button"
            >
              <div className="d-flex align-items-center justify-content-start w-100">
                <Highlight
                  attribute="categories_name"
                  hit={hit}
                  highlightedTagName="span"
                  nonHighlightedTagName="span"
                  classNames={{
                    root: "autosuggest-highlight",
                    highlighted: "highlighted",
                    nonHighlighted: "non-highlighted",
                  }}
                />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};