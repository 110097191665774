import { ActiveFilterCounter } from "../ShellComponents/activefiltercounter";

interface OffcanvasTriggerProps {
  buttonText: string
}

/**
 * Offcanvas Trigger - the button that is used to open the offcanvas filter menu
 *
 * @constructor
 */
export const OffcanvasTrigger = ({ buttonText }: OffcanvasTriggerProps) => {
  return (
    <div className={"datalist-container"}>
      <button className="btn btn-secondary btn-lg w-100 d-flex justify-content-between"
              type="button"
              data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilter"
              aria-controls="offcanvasFilter">
                    <span className="d-flex align-items-center">
                        <i className="far fa-sliders fa-lg me-spacing-12"></i>
                        <span>
                          <span className="filter-label-desktop ms-spacing-12">{buttonText}</span>
                          <span data-selector="offcanvas-filter-title"
                                className={"ms-spacing-12 active-filters"}><ActiveFilterCounter/> Filter aktiv</span>
                        </span>
                    </span>
        <svg className="svg-inline--fa fa-chevron-right fa-xl">
          <use xlinkHref="#icon_chevron-right"></use>
        </svg>
      </button>
    </div>
  )
}