'use strict';

/**
 * @function setInputValue
 * @description Set the value of an input triggering the change event on it.
 * @param {string} value - Input value.
 * @param {HTMLInputElement} input - HTML input element.
 * @param {boolean} [dispatch=true] - Boolean to dispatch the change event. Default=true.
 */
const setInputValue = (value, input, dispatch = true) => {
  input.value = value;
  input.setAttribute('value', value);
  dispatch && input.dispatchEvent(new Event('change'));
};

document.addEventListener('DOMContentLoaded', () => {
  const url_params = new URLSearchParams(window.location.search);

  if (url_params.has('action') && url_params.get('action') === 'confirm') {
    document.querySelector('.plus').addEventListener('click', () => {
      const target = document.getElementById('days_remind');
      const new_value = parseInt(target.value) + 1;

      if (target.value === '' || isNaN(target.value)) {
        setInputValue(1, target);
      }

      if (new_value < 10) {
        setInputValue(new_value, target);
      }
    });

    document.querySelector('.min').addEventListener('click', () => {
      const target = document.getElementById('days_remind');
      const new_value = parseInt(target.value) - 1;

      if (target.value === '' || isNaN(target.value)) {
        setInputValue(1, target);
      }

      if (new_value > 0) {
        setInputValue(new_value, target);
      }

      calculateMaxPossibleQuantityPerDosage();
      calculateDays();
    });
  } else if (url_params.has('action') && url_params.get('action') === 'new') {
    setInputValue(
      document.getElementById('unit').value === 'stk' ? 1 : 0.1,
      document.getElementById('qty_per_dosage')
    );

    calculateMaxPossibleQuantityPerDosage();
    calculateDays();

    document.querySelector('.plus').addEventListener('click', () => {
      const target = document.getElementById('qty_per_day');
      const max_value = parseInt(document.getElementById('quantity').value);
      const new_value = parseInt(target.value) + 1;

      if (new_value <= max_value) {
        setInputValue(new_value, target);
      }

      calculateMaxPossibleQuantityPerDosage();
      calculateDays();
    });

    document.querySelector('.min').addEventListener('click', () => {
      const target = document.getElementById('qty_per_day');
      const new_value = parseInt(target.value) - 1;

      if (new_value > 0) {
        setInputValue(new_value, target);
      }

      calculateMaxPossibleQuantityPerDosage();
      calculateDays();
    });

    document.querySelector('.plus2').addEventListener('click', () => {
      const target = document.getElementById('days');
      const new_value = parseInt(target.value) + 1;

      if (target.value === '' || isNaN(target.value)) {
        setInputValue(1, target);
      } else {
        setInputValue(new_value, target);
      }
    });

    document.querySelector('.min2').addEventListener('click', () => {
      const target = document.getElementById('days');
      const new_value = parseInt(target.value) - 1;

      if (target.value === '' || isNaN(target.value)) {
        setInputValue(1, target);
      }

      if (new_value > 0) {
        setInputValue(new_value, target);
      }
    });

    document
      .getElementById('qty_per_day')
      .addEventListener('change', function () {
        const max_value = parseInt(document.getElementById('quantity').value);
        const new_value = parseInt(this.value);

        if (new_value > max_value) {
          setInputValue(max_value, this, false);
        }

        calculateMaxPossibleQuantityPerDosage();
        calculateDays();
      });

    document
      .getElementById('qty_per_dosage')
      .addEventListener('change', function () {
        calculateMaxPossibleQuantityPerDosage();
        calculateDays();
      });
  } else if (
    url_params.has('action') &&
    url_params.get('action') === 'new_search'
  ) {
    const search_field = document.getElementById('product_search_field');

    if (search_field) {
      let auto_suggest_timestamp = 0;

      // Auto-Suggest Search
      search_field.addEventListener('keyup', function (e) {
        let push_timestamp = new Date().getTime();

        const input = this.value;

        if (
          (e.keyCode === 40 || e.keyCode === 38) &&
          document.getElementById('account_reminder_autosuggest_wrapper')
        ) {
          const index = Array.from(
            document.querySelectorAll('#account_reminder_autosuggest a')
          ).findIndex((element) => element.className === 'hover');

          document
            .querySelectorAll('#account_reminder_autosuggest a')
            .forEach((el) => {
              el.classList.remove('hover');
            });

          if (
            e.keyCode === 40 &&
            document
              .getElementById('account_reminder_autosuggest')
              .getElementsByTagName('a')[index + 1]
          ) {
            // up pressed
            document
              .getElementById('account_reminder_autosuggest')
              .getElementsByTagName('a')[index + 1].classList.add('hover');
          } else if (e.keyCode === 38 && index - 1 >= 0) {
            // down pressed
            document
              .getElementById('account_reminder_autosuggest')
              .getElementsByTagName('a')[index - 1].classList.add('hover');
          }
        } else if (
          e.keyCode === 13 &&
          document.getElementById('account_reminder_autosuggest_wrapper')
        ) {
          // enter key pressed
          const element = document.querySelector(
            '#account_reminder_autosuggest .hover'
          );

          if (element) {
            document.location = document
              .querySelector('#account_reminder_autosuggest .hover')
              .getAttribute('href');
          }
        } else {
          if (input !== '') {
            fetch('ajax/account_reminder_auto_suggest.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                input: input,
              }),
            })
              .then((response) => response.text())
              .then((result) => {
                if (
                  result.trim() !== '' &&
                  push_timestamp > auto_suggest_timestamp
                ) {
                  auto_suggest_timestamp = push_timestamp;

                  if (
                    document.getElementById(
                      'account_reminder_autosuggest_wrapper'
                    )
                  ) {
                    document
                      .getElementById('account_reminder_autosuggest_wrapper')
                      .remove();
                  }

                  document
                    .getElementById('account_reminder_search_field')
                    .insertAdjacentHTML('afterend', result);

                  addMouseOverEventByAutoSuggest();
                }
              });
          } else {
            if (document.getElementById('account_reminder_autosuggest_wrapper')) {
              document
                .getElementById('account_reminder_autosuggest_wrapper')
                .remove();
            }
            auto_suggest_timestamp = push_timestamp;
          }
        }

        e.preventDefault();
      });
    }
  }

  if (document.getElementsByClassName('button_delete_modal').length > 0) {
    document
      .querySelectorAll('.button_delete_modal')
      .forEach((delete_button) => {
        delete_button.addEventListener('click', function () {
          const productimage = this.getAttribute('data-productimage');
          const productname = this.getAttribute('data-productname');
          const productinhalt = this.getAttribute('data-productinhalt');
          const productmodel = this.getAttribute('data-productmodel');
          const nextscheduleddatediffindays = this.getAttribute(
            'data-nextscheduleddatediffindays'
          );
          const nextscheduleddate = this.getAttribute('data-nextscheduleddate');
          const reminderid = this.getAttribute('data-reminderid');
          const reminderlink = this.getAttribute('data-reminderlink');

          document.getElementById('reminder_modal_product_image').src =
            productimage;
          document.getElementById('reminder_modal_product_name').textContent =
            productname;
          document.getElementById('reminder_modal_presentation').textContent =
            productinhalt;
          document.getElementById('reminder_modal_pzn').textContent =
            'PZN: ' + productmodel;
          document.getElementById('reminder_modal_infos').innerHTML =
            '<span class="icon_watch"></span> nächste Erinnerung in ' +
            nextscheduleddatediffindays +
            ' Tagen | ' +
            nextscheduleddate;
          document.getElementsByClassName(
            'reminder_modal_button_delete'
          )[0].href = reminderlink + reminderid;
        });
      });
  }

  // detect button click, change class of "page-item active" to --> "completed"
  const nextBtn = document.querySelector('.account-reminder-wrapper [data-stepper-goto="1"]');
  const prevBtn = document.querySelector('.account-reminder-wrapper [data-stepper-goto="0"]');

  if (nextBtn) {
    nextBtn.addEventListener('click', function () {
      document.querySelector('.page-item.active').classList.add('completed');
      document
        .querySelector('.page-item.active.completed')
        .classList.remove('active');
    });
  }

  if (prevBtn) {
    prevBtn.addEventListener('click', function () {
      document.querySelector('.page-item.completed').classList.add('active');
      document
        .querySelector('.page-item.completed.active')
        .classList.remove('completed');
    });
  }

  // prevent 0
  const days = document.getElementById('days');

  if (days) {
    days.addEventListener('change', function () {
      let daysVal = parseInt(days.value);
      if (isNaN(daysVal) || daysVal === 0) {
        setInputValue(1, document.getElementById('days'), false);
      }
    });
  }
});

function addMouseOverEventByAutoSuggest() {
  document.querySelectorAll('#account_reminder_autosuggest a').forEach((el) => {
    el.addEventListener('mouseover', function () {
      document
        .querySelectorAll('#account_reminder_autosuggest a')
        .forEach((el) => {
          el.classList.remove('hover');
        });

      this.classList.add('hover');
    });
  });
}

function calculateMaxPossibleQuantityPerDosage() {
  const max_qty = parseFloat(document.getElementById('quantity')?.value);
  const unit = document.getElementById('unit').value;

  let qty_per_day = parseInt(document.getElementById('qty_per_day')?.value);
  let qty_per_dosage = document
    .getElementById('qty_per_dosage')
    .value.replace(/[^\d.]/g, '')
    .replace(/(\..*)\./g, '$1');
  let wasEmpty = false;

  if (isNaN(qty_per_day) || qty_per_day === 0) {
    setInputValue(1, document.getElementById('qty_per_day'));
  }

  if (unit === 'stk') {
    if (!parseFloat(qty_per_dosage)) {
      qty_per_dosage = 0.5;
      wasEmpty = true;
    }

    if (Math.round(max_qty / qty_per_day) < qty_per_dosage) {
      const value = Math.round(max_qty / qty_per_day);
      setInputValue(value, document.getElementById('qty_per_dosage'));
      return document.getElementById('qty_per_dosage');
    }
  } else {
    if (!parseFloat(qty_per_dosage)) {
      qty_per_dosage = 0.1;
      wasEmpty = true;
    }

    if (Math.fround(max_qty / qty_per_day) < parseFloat(qty_per_dosage)) {
      const value = Math.fround(max_qty / qty_per_day).toFixed(2);
      setInputValue(value, document.getElementById('qty_per_dosage'));
      return document.getElementById('qty_per_dosage');
    }
  }

  wasEmpty &&
    setInputValue(qty_per_dosage, document.getElementById('qty_per_dosage'));
}

function calculateDays() {
  const qty_per_day = parseInt(document.getElementById('qty_per_day').value);
  const max_qty = parseFloat(document.getElementById('quantity').value);
  const qty_per_dosage = parseFloat(
    document.getElementById('qty_per_dosage').value
  );

  if (isNaN(qty_per_dosage) || isNaN(qty_per_dosage) || isNaN(max_qty)) {
    return;
  }

  const calculated_days = Math.round(max_qty / (qty_per_day * qty_per_dosage));

  setInputValue(calculated_days, document.getElementById('days'), false);

  const reminder_info_text_days = document.querySelector(
    '#reminder-info-text-days'
  );

  if (reminder_info_text_days) {
    reminder_info_text_days.innerHTML = calculated_days;
  }
}
