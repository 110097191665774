export const CONSTANTS = {
  productIndexStorage:  localStorage.getItem('product_index') || '',
  categoryIndexStorage: localStorage.getItem('category_index') || '',
  contentIndexStorage:  localStorage.getItem('content_index') || '',
  aid:                  localStorage.getItem('aid') || '',
  sak:                  localStorage.getItem('sak') || '',
  searchpage_enabled:   localStorage.getItem('searchpage_enabled') || '',
  nameIndexAsc:         localStorage.getItem('product_index') + '_name_asc' || '',
  nameIndexDesc:        localStorage.getItem('product_index') + '_name_desc' || '',
  priceIndexAsc:        localStorage.getItem('product_index') + '_price_asc' || '',
  priceIndexDesc:       localStorage.getItem('product_index') + '_price_desc' || '',
  savingsIndexAsc:      localStorage.getItem('product_index') + '_savings_asc' || '',
  savingsIndexDesc:     localStorage.getItem('product_index') + '_savings_desc' || ''
}
