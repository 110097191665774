// import type {InsightsEvent} from "search-insights/lib/types";
import { CONSTANTS } from "./constants";
import aa from "search-insights";

export const trackCartButtonClickedEvent = (algolia_query_id: string, pzn: string): void => {
  const index = CONSTANTS.productIndexStorage
  let currentUserToken: string = ''

  aa('getUserToken', {}, (err, userToken) => {
    if (err) {
      console.error(err);
      return;
    }
    currentUserToken = userToken?.toString() || ''
  });

  const queryID = algolia_query_id || ''
  const objectIDs = [pzn || '']

  aa('addedToCartObjectIDsAfterSearch', {
    userToken: currentUserToken,
    eventName: 'Product Added To Cart',
    index: index,
    queryID: queryID,
    objectIDs: objectIDs
  });
}


/**
 * Sends the data to Algolia to trigger 'purchasedObjectIDsAfterSearch' event.
 * This represents our conversion event.
 *
 * @param products Products array as json encoded object string
 * @param order_total Order total value as string
 */
export const sendPurchasedProducts = (products : string, order_total : string): void => {
  const index = CONSTANTS.productIndexStorage
  let currentUserToken: string = ''

  initAlgoliaTracking()

  aa('getUserToken', {}, (err, userToken) => {
    if (err) {
      console.error(err);
      return;
    }
    currentUserToken = userToken?.toString() || ''
  });

  let products_object = JSON.parse(products)

  const objectIDs : Array<any> = []
  let objectData : Array<any> = []

  Object.keys(products_object).forEach(key => {
    const value = products_object[key]

    //we need the pzns as an array
    objectIDs.push(value.model)

    //furthermore we need an array of products with additional attributes
    let objectDataItem = {}
    if (value.additional_properties.algolia_query_id !== undefined && typeof value.additional_properties.algolia_query_id === 'string'
    && value.additional_properties.algolia_query_id.length !== 0) {
      objectDataItem = {
        queryID: value.additional_properties.algolia_query_id,
        price: value.price,
        quantity: Number(value.quantity)
      }
    } else {
      objectDataItem = {
        price: value.price,
        quantity: Number(value.quantity)
      }
    }
    objectData.push(
      objectDataItem
    )
  });

  aa('purchasedObjectIDsAfterSearch', {
    userToken: currentUserToken,
    eventName: 'Products Purchased',
    index: index,
    objectIDs: objectIDs,
    objectData: objectData,
    value: order_total,
    currency: 'EUR'
  })
}

const initAlgoliaTracking = (): void => {
  aa('init', {
    appId: CONSTANTS.aid,
    apiKey: CONSTANTS.sak,
    useCookie: true,
  })
}

export const initTracking = () => {
  if ((CONSTANTS.aid !== '' && CONSTANTS.sak  !== '')
    && (document.body.getAttribute('data-pagetype') === 'pdp' || document.body.getAttribute('data-pagetype') === 'search')) {
    initAlgoliaTracking()
  }
}