import i18next from 'i18next';

const checkEmpty = (input, message, eyeButton) => {
  if (!input.value || input.value === "") {
    message.innerHTML = i18next.t('accountPassword.errorMessage.required');
    input.classList.add("is-invalid");
    eyeButton.style.right = "24px";
    return false;
  } else {
    message.innerHTML = "";
    input.classList.remove("is-invalid");
    eyeButton.style.right = "0";
    return true;
  }
};

const checkLength = (input, message, eyeButton) => {
  const minLength = input.getAttribute('minlength') && Number(input.getAttribute('minlength')) > 0
    ? Number(input.getAttribute('minlength'))
    : 8
  const maxLength = input.getAttribute('maxlength') && Number(input.getAttribute('maxlength')) > 0
    ? Number(input.getAttribute('maxlength'))
    : 16

  if (!input.value) return;

  if (input.value.length < minLength) {
    message.innerHTML = i18next.t('accountPassword.errorMessage.minLength');
    input.classList.add("is-invalid");
    eyeButton.style.right = "24px";
    return false;
  } else if (input.value.length > maxLength) {
    message.innerHTML = i18next.t('accountPassword.errorMessage.maxLength');
    input.classList.add("is-invalid");
    eyeButton.style.right = "24px";
    return false;
  } else {
    message.innerHTML = "";
    input.classList.remove("is-invalid");
    eyeButton.style.right = "0";
    return true;
  }
};

const checkValidPassword = (input, message, eyeButton) => {

  //password validation
  const numbers = /[0-9]/g;
  const upperCaseLetters = /[A-Z]/g;
  const lowerCaseLetters = /[a-z]/g;
  const specialChars = /[^A-Za-z0-9]/

  let passwordStrength = 0
  const value = input.value

  value.match(numbers) && passwordStrength++
  value.match(upperCaseLetters) && passwordStrength++
  value.match(lowerCaseLetters) && passwordStrength++
  value.match(specialChars) && passwordStrength++

  if (passwordStrength < 3) {
    message.innerHTML = i18next.t('accountPassword.errorMessage.passwordWeak');
    input.classList.add("is-invalid");
    eyeButton.style.right = "24px";
    return false;
  } else {
    message.innerHTML = "";
    input.classList.remove("is-invalid");
    eyeButton.style.right = "0";
    return true;
  }
};

const checkSamePasswords = (inputConf, messageConf, form, eyeButton) => {
  const newPassInput = form.querySelector(
    '[data-selector="password_new_input"]'
  );

  if (inputConf.value !== newPassInput.value) {
    messageConf.innerHTML = i18next.t('accountPassword.errorMessage.notSame');
    inputConf.classList.add("is-invalid");
    eyeButton.style.right = "24px";
    return false;
  } else {
    messageConf.innerHTML = "";
    inputConf.classList.remove("is-invalid");
    eyeButton.style.right = "0";
    return true;
  }
};

const validation = (currentInput, currentMessage, form, eyeButton) => {
  if (!checkEmpty(currentInput, currentMessage, eyeButton)) return false;
  if (currentInput.name !== "password_current") {
    if (!checkLength(currentInput, currentMessage, eyeButton)) return false;
  }
  if (currentInput.name === "password_new") {
    if (!checkValidPassword(currentInput, currentMessage, eyeButton))
      return false;
  }
  if (currentInput.name === "password_confirmation") {
    if (!checkSamePasswords(currentInput, currentMessage, form, eyeButton))
      return false;
  }
  return true;
};

const changeInputType = (btn) => {
  const input = btn
    .closest('[data-wrapper="form-input"]')
    .querySelector("input");

  input.type = input.type === "text" ? "password" : "text";
};

const changeEyeIconSVG = (eyeButton) => {
  const icon = eyeButton.querySelector("i");
  icon.classList.toggle("fa-eye");
  icon.classList.toggle("fa-eye-slash");
};

const eyeIconsListener = (form) => {
  const eyeButtons = form.querySelectorAll('[data-icon="fa-eye"]');

  eyeButtons.forEach((button) => {
    button.addEventListener("click", () => {
      changeEyeIconSVG(button);
      changeInputType(button);
    });
  });
};

const isIconShow = ({value}) => {
  return value.trim();
};

const handleInputEvent = (input, wrapper, form) => {
  const eyeButton = wrapper.querySelector('[data-icon="fa-eye"]');
  isIconShow(input)
    ? eyeButton.classList.remove("d-none")
    : eyeButton.classList.add("d-none");

  const message = wrapper.querySelector(".form-message");
  validation(input, message, form, eyeButton);
};

const init = () => {
  const form = document.querySelector('[data-form="account-password-reset"]');

  if (form) {
    const inputs = form.querySelectorAll("input");
    if (inputs.length) {
      inputs.forEach((input) => {
        const wrapper = input.closest('[data-wrapper="form-input"]');
        if (!wrapper) return;
        input.addEventListener("input", () => {
          handleInputEvent(input, wrapper, form);
        });
      });
    }

    form.addEventListener("submit", (e) => {
      let valid = true;
      inputs.forEach((input) => {
        const wrapper = input.closest('[data-wrapper="form-input"]');
        if (!wrapper) return;
        const eyeButton = wrapper.querySelector('[data-icon="fa-eye"]');
        const message = wrapper.querySelector(".form-message");
        valid = validation(input, message, form, eyeButton);
      });

      if (!valid) {
        e.preventDefault();
        return;
      }
    });

    eyeIconsListener(form);

    const password = document.getElementById('password_new')
    password && password.addEventListener('blur', (event) => {
      const value = event.target.value;
      const label = password.parentNode.querySelector('.form-message')
      const labelContent = label ? label.innerHTML : ''

      if (!password.classList.contains('is-invalid')) {
        fetch('/ajax/checkPasswordLeaked.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(value)
        })
          .then(res => res.json())
          .then(res => {
            if (res[0].result === "error") {
              password.classList.add("is-invalid");
              label.innerHTML = i18next.t('accountPassword.errorMessage.passwordIsLeaked');
              password.parentNode.querySelector('button').style.right='24px'
            } else {
              password.classList.remove('is-invalid');
              label.innerHTML = labelContent
              password.parentNode.querySelector('button').style.right='0'
            }
          })
      }
    })

  }


};

document.addEventListener("DOMContentLoaded", init);
