import {Modal, Popover, Toast, Tooltip} from 'bootstrap';
import 'slick-carousel';
import '../autocomplete_detect';
import noUiSlider from 'nouislider';
import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay} from 'swiper/modules';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import {ApoEvents} from "../apoEvents";
import {ApoModalController} from "../apo-modal-controller.ts";
import {ApoUserlikeController} from "../apo-userlike-controller.ts";
import {ApoDynamicYieldController} from "../apo-dy-controller.ts";
import {ApoKairionController} from "../apo-kairion-controller.ts";
import {ApoLibraryLoader} from "../apo-library-loader";
import {ApoIntersectionController} from "../apo-intersection-controller";
import {ApoDatepickerController} from "../apo-datepicker-controller";
import {ApoListGroupController} from "../apo-listgroup-controller";
import {ApoStepperController} from "../apo-stepper-controller";
import {CONSTANTS} from "../app/ui/Search/constants";
import {sendPurchasedProducts} from "../app/ui/Search/tracking";
import {initLottieAnimationHomepage} from "../apo-animation-controller";
import {ApoFormValidationController} from "../apo-formvalidation-controller";
import {ApoSelectController} from "../apo-select-controller";
import {ApoModalFormController} from "../apo-modal-form-controller";

/**
 * Set variables
 */

const productImagesSliders = document.querySelectorAll(
  '[data-slider="product-images"] [data-slide="product-images-slide"]'
);
const productImagesPagination = document.querySelector(
  '[data-slider="product-images"] [data-slider-pagination="product-images"]'
);
const quicklinkSliders = Array.from(document.querySelectorAll('[data-slider="quicklinks"]'));

const ratingSliders = Array.from(document.querySelectorAll('[data-slider="rating"]'));

const brandshopSliders = Array.from(document.querySelectorAll('[data-slider="brandshop"]'));

const modalSliders = Array.from(document.querySelectorAll('[data-slider="modal"]'));

const defaultSlider = [].slice.call(
  document.querySelectorAll('[data-slider="default-pagination"]')
);
const productsSliderList = Array.from([].slice.call(
  document.querySelectorAll('[data-slider="products"]')
));
const productsDYSliderList = Array.from([].slice.call(
  document.querySelectorAll('[data-slider="productsDY"]')
));
let productsDYContentfulSliderList = Array.from([].slice.call(
  document.querySelectorAll('[data-slider="productsDYContentful"]')
));

const bannersSliderList = [].slice.call(
  document.querySelectorAll('[data-slider="banner"]')
);
const contentfulBannerSliderList = [].slice.call(
  document.querySelectorAll('[data-slider="contentful-banner"]')
);
const tooltipTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="tooltip"]')
);
const popoverTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'));
const popoverChatTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="popover-chat"]'));

const modalElList = [].slice.call(document.querySelectorAll('.modal'));
const toastElList = [].slice.call(document.querySelectorAll('.toast'));

// Target divs that are going to be turned into range-slider
const rangeSliderSingle = document.querySelectorAll(
  '[data-rangeslider="single"]'
);
const rangeSliderDouble = document.querySelectorAll(
  '[data-rangeslider="double"]'
);
const checkoutConfirmationShippingProvider = [].slice.call(
  document.querySelectorAll('[name="checkout_shipping_provider"]')
);
const $flexIndetEnabled = document.querySelector(
  '[data-selector="flexIndeterminateEnabled"]'
);
const $flexIndetDisabled = document.querySelector(
  '[data-selector="flexCheckIndeterminateDisabled"]'
);
const $autosuggestWrapper = document.querySelector(
  '[data-selector="auto-suggest-wrapper"]'
);
const $medicationRemindersSliderList = [].slice.call(
  document.querySelectorAll('[data-slider="medication-reminders"]')
);
const $checkoutSuccessPage = document.querySelector(
  '[data-checkoutSuccessPage]'
);

const $checkoutPrescriptionInvoiceRecipeChoose = document.querySelector('[data-selector="checkout_prescription_invoice_send_recipe_choose"]')

if ($checkoutPrescriptionInvoiceRecipeChoose) {
  const inputWrappers = $checkoutPrescriptionInvoiceRecipeChoose.querySelectorAll('[data-selector="list-group-item-selection"]')
  const inputStart = $checkoutPrescriptionInvoiceRecipeChoose.querySelectorAll('[name="recipe_sending"]')
  inputStart.checked = true

  const contentDivs = document.querySelectorAll('[data-typ="content_checkout_prescription_invoice"]')

  inputWrappers && inputWrappers.forEach(el => {
    el.addEventListener('click', () => {
      const content = document.querySelector(`[data-selector="${el.id}"]`)
      contentDivs.forEach(el => el.classList.add('d-none'))
      content.classList.remove('d-none')
    })
  })
}

const sliderConfigurationsByType = {
  'default': {
    // configure Swiper to use modules
    modules: [Pagination],

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
  },
  'banner': {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    speed: 2500,
    loop: true,
    watchSlidesProgress: true,

    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 24,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination-banner',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next-banner',
      prevEl: '.swiper-button-prev-banner',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
    on: {
      init: function () {
        this.slides.forEach((slide) => {
          const link = slide.querySelector('a')
          const url = slide.querySelector('a').getAttribute('href')
          const banner_link_use_new_tab = slide.getAttribute('data-banner-link-use-new-tab')

          if (banner_link_use_new_tab) {
            link.addEventListener('click', (event) => {
              event.preventDefault()
              incrementBannerClickCount(slide, () => redirect(url, banner_link_use_new_tab))
            })
          }

        })
      },
      slideChange: function () {
        incrementBannerViewCount(this, this.activeIndex)
      }
    }
  },
  'contentful-banner': {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    autoplay: {
      delay: 20000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    speed: 2000,
    loop: true,
    watchSlidesProgress: true,

    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 24,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination-banner',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next-banner',
      prevEl: '.swiper-button-prev-banner',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
    on: {
      init: function () {
        this.slides.forEach((slide) => {
          const link = slide.querySelector('a')
          const url = slide.querySelector('a').getAttribute('href')
          const banner_link_use_new_tab = slide.getAttribute('data-banner-link-use-new-tab')

          if (banner_link_use_new_tab) {
            link.addEventListener('click', (event) => {
              event.preventDefault()
              incrementBannerClickCount(slide, () => redirect(url, banner_link_use_new_tab))
            })
          }

        })
      },
      slideChange: function () {
        incrementBannerViewCount(this, this.activeIndex)
      }
    }
  },
  'products': {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],

    // Optional parameters
    slidesPerView: 2,
    spaceBetween: 24,
    // Responsive breakpoints
    breakpoints: {
      450: {
        slidesPerView: 2,
      },
      1000: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
    on: {
      init: function (swiper) {
        handleProductSliderClick(swiper)
      }
    }
  },
  'medicationReminder': {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],

    // Optional parameters
    slidesPerView: 3,
    spaceBetween: 16,

    // Responsive breakpoints
    breakpoints: {
      600: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
      1400: {
        slidesPerView: 7,
      },
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
  },
  'modal': {
    modifierClass: 'modalswiper-',
    spaceBetween: 48,
    autoHeight: true,
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: {
      enabled: true,
    },
  },
  'quicklinks': {
    modules: [Navigation, Pagination],
    // Optional parameters
    slidesPerView: 2,
    // Responsive breakpoints
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
    on: {
      breakpoint: (swiper) => {
        toggleControlsDisplay(swiper);
      },
      init: (swiper) => {
        toggleControlsDisplay(swiper);
      }
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
  },
  'rating': {
    modules: [Pagination],
    spaceBetween: 64,
    // Optional parameters
    slidesPerView: 1,
    // Responsive breakpoints
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 64,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 32,
      }
    },
    on: {
      breakpoint: (swiper) => {
        toggleControlsDisplay(swiper);
      },
      init: (swiper) => {
        toggleControlsDisplay(swiper);
      }
    },

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Keyboard control
    keyboard: {
      enabled: true,
    },
  },
  'productImages': {
    slidesPerView: 1,
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (_, className) => {
        return `<span class="${className}"></span>`;
      },
    },
  },
  'brandshop': {
    modules: [Navigation],
    // Optional parameters
    spaceBetween: 12,
    slidesPerView: 3.5,
    breakpoints: {
      576: {
        slidesPerView: 4.5,
      },
      1024: {
        slidesPerView: 6,
      }
    },
    navigation: {
      nextEl: '[data-scroll-direction="right"]',
      prevEl: '[data-scroll-direction="left"]',
    },

    // Keyboard control
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  },
}

/**
 * Initialize
 */

// Tooltip
tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl);
});

// Popover
popoverTriggerList.map(function (popoverTriggerEl, index) {

  let popoverModifier = '';
  let closeButtonModifier = '-blue';
  if (popoverTriggerEl.dataset.bsToggleModifier && popoverTriggerEl.dataset.bsToggleModifier === 'light') {
    popoverModifier = `popover-${popoverTriggerEl.dataset.bsToggleModifier}`;
    closeButtonModifier = `-white`;
  }

  const popover = new Popover(popoverTriggerEl, {
    template: `
      <div class="popover ${popoverModifier}" role="tooltip">
        <button id="tooltip_${index}"
            class="btn btn-close btn-close-aco${closeButtonModifier} close">
        </button>
        <div class="popover-arrow"></div>
        <div class="popover-header h3"></div>
        <div class="popover-body"></div>
      </div>
    `,
    sanitize: false,
    html: true
  });

  const onClickOutside = (e) => {
    if (!popover.tip.contains(e.target)) {
      popover.hide();
    }
  }

  popoverTriggerEl.addEventListener('shown.bs.popover', () => {
    const closeButton = document.querySelector(`#tooltip_${index}`);
    closeButton.addEventListener('click', () => {
      popover.hide();
    });
    window.addEventListener('click', onClickOutside);
  });

  popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
    window.removeEventListener('click', onClickOutside);
  });

  return popover;
});

// Popover Chat
popoverChatTriggerList.map(function (popoverTriggerEl) {
  return new Popover(popoverTriggerEl, {
    customClass: 'chat',
    html: true,
  });
});

// Modal
modalElList.map(function (modalEl) {
  return new Modal(modalEl);
});

// Toasts
toastElList.map(function (toastEl) {
  const toast = new Toast(toastEl);
  const $toastTrigger = document.querySelector(`#${toastEl.id}-trigger`);
  if ($toastTrigger) {
    $toastTrigger.addEventListener('click', () => {
      toast.show();
    });
  }
  return toast;
});

// Turn div into range-slider (single)
if (rangeSliderSingle) {
  rangeSliderSingle.forEach((slider) => {
    noUiSlider.create(slider, {
      connect: 'lower',
      behaviour: 'tap',
      start: [50],
      step: 1,
      range: {
        min: [0],
        max: [100],
      },
    });

    /**
     * Listen for changes on slider and send value to display element
     */
    slider.noUiSlider.on('update', function (values, handle) {
      slider.parentNode.lastElementChild.value = parseInt(values[handle]);
      slider.parentNode.lastElementChild.dispatchEvent(new Event('change'));
    });
  });
}
// Turn div into range-slider (double)
if (rangeSliderDouble) {
  rangeSliderDouble.forEach((slider) => {
    const initMin = Number(slider.dataset.min)
    const initMax = Number(slider.dataset.max)

    noUiSlider.create(slider, {
      start: [initMin || 0, initMax || 100],
      connect: true,
      step: 1,
      range: {
        min: initMin || 0,
        max: initMax || 100
      }
    })

    /**
     * Listen for changes on slider and send value to display element
     */
    slider.noUiSlider.on('update', function (values) {
      const currentMin = parseInt(values[0])
      const currentMax = parseInt(values[1])
      slider.parentNode.lastElementChild.firstElementChild.value = currentMin
      slider.parentNode.lastElementChild.lastElementChild.value = currentMax

      // Update price_filter input field in offcanvas filter (SRP/Category)
      const price_filter = document.querySelector('#fprc #amount')
      const url = new URL(window.location.href)
      const restorePriceFilter = url.searchParams.get('fprc')

      if (price_filter) {
        if (currentMin == initMin && currentMax == initMax && restorePriceFilter == '') {
          // filter is set to initial state
          price_filter.value = ''
          document.querySelector('#fprc-counter').classList.add('d-none')
          document.querySelector('#fprc-counter').innerHTML = 0
        } else {
          // at least one variable has been changed
          price_filter.value = currentMin + "_" + currentMax
          document.querySelector('#fprc-counter').classList.remove('d-none')
          document.querySelector('#fprc-counter').innerHTML = 1
        }

      }
    });
  });
}

const checkProductImageForVideo = () => {
  const productImages = [].slice.call(
    document.querySelectorAll('[data-slider="product-images"]')
  );

  productImages.forEach((image) => {
    const numberOfSlides = image.querySelectorAll('.swiper-slide').length;
    const hasVideo = !!image.querySelector('video');
    image.hasVideo = hasVideo;

    if (image.swiper) image.swiper.destroy();
    /* eslint-disable-next-line */
    // const swiper = new Swiper(image, {loop: numberOfSlides !== 1, ...sliderConfigurationsByType['productImages']});
    const swiper = new Swiper(image, Object.assign(sliderConfigurationsByType['productImages'], {loop: numberOfSlides !== 1}));

    if (hasVideo) {
      swiper.on('activeIndexChange', function () {
        if (this.el.hasVideo) {
          const event = new CustomEvent(ApoEvents.PRODUCTSLIDER_HASVIDEO_INDEX_CHANGE, {
            'detail': {
              swiper: this,
            }
          })
          document.dispatchEvent(event);
        }
      });
    }
  });
}
checkProductImageForVideo();


productImagesSliders.forEach((slider, index) => {
  const video = slider.querySelector('video');

  if (video) {
    const bullet = productImagesPagination.children.item(index);
    if (bullet) {
      bullet.classList.add(
        'd-flex',
        'align-items-center',
        'justify-content-center'
      );
      bullet.innerHTML =
        "<i class='fas fa-play fa-2xs text-aco-dark-blue'></i>";
    }
  }
});


const hasControlsVisible = $swiper => $swiper.slides.length > $swiper.params.slidesPerView;
const toggleControlsDisplay = ($swiper) => {
  if (!$swiper.params.pagination) return;

  hasControlsVisible($swiper)
    ? $swiper.el.querySelector('.swiper-controls-wrapper').style.setProperty('display', 'flex', 'important')
    : $swiper.el.querySelector('.swiper-controls-wrapper').style.setProperty('display', 'none', 'important')
}

const initBannerSliders = () => {
  bannersSliderList.map(function (bannerSlider) {
    if (bannerSlider.swiper) bannerSlider.swiper.destroy();
    new Swiper(bannerSlider, sliderConfigurationsByType['banner']);
  });
}
const initContentfulBannerSliders = () => {
  contentfulBannerSliderList.map(function (bannerSlider) {
    if (bannerSlider.swiper) bannerSlider.swiper.destroy();
    new Swiper(bannerSlider, sliderConfigurationsByType['contentful-banner']);
  });
}
const initProductsSliders = () => {
  productsSliderList.map(function (productsSlider) {
    if (productsSlider.swiper) productsSlider.swiper.destroy();
    initializeSlider(productsSlider);
  });
}
const initProductsDYSliders = () => {
  productsDYSliderList.map(function (productsSlider) {
    if (productsSlider.swiper) productsSlider.swiper.destroy();
    initializeSlider(productsSlider);
  });
}
const initProductsDYContentfulSliders = () => {
  productsDYContentfulSliderList = Array.from([].slice.call(
    document.querySelectorAll('[data-slider="productsDYContentful"]')
  ));
  productsDYContentfulSliderList.map(function (productsSlider) {
    if (productsSlider.swiper) productsSlider.swiper.destroy();
    initializeSlider(productsSlider);
  });
}
const initMediactionReminderSliders = () => {
  $medicationRemindersSliderList.map(function (medicationRemindersSlider) {
    if (medicationRemindersSlider.swiper) medicationRemindersSlider.swiper.destroy();
    new Swiper(medicationRemindersSlider, sliderConfigurationsByType['medicationReminder']);
  });
}
const initDefaultSliders = () => {
  defaultSlider.map(function (slider) {
    if (slider.swiper) slider.swiper.destroy();
    new Swiper(slider, sliderConfigurationsByType['default']);
  });
}
const initModalSliders = () => {
  modalSliders.forEach((modalSlider) => {
    if (modalSlider.swiper) modalSlider.swiper.destroy();
    new Swiper(modalSlider, sliderConfigurationsByType['modal']);
  });
  window.modalSliders = modalSliders;
}
const initQuicklinkSliders = () => {
  quicklinkSliders.forEach((quicklinkSlider) => {
    if (quicklinkSlider.swiper) quicklinkSlider.swiper.destroy();
    new Swiper(quicklinkSlider, sliderConfigurationsByType['quicklinks']);
  })
};

const initRatingSliders = () => {
  ratingSliders.forEach((ratingSlider) => {
    if (ratingSlider.swiper) ratingSlider.swiper.destroy();
    new Swiper(ratingSlider, sliderConfigurationsByType['rating']);
  })
};

const initBrandShopSliders = () => {
  brandshopSliders.forEach((brandshopSlider) => {
    if (brandshopSlider.swiper) brandshopSlider.swiper.destroy();
    new Swiper(brandshopSlider, sliderConfigurationsByType['brandshop']);
  })
};

/**
 * Returns true, if element el is visible in viewport and not hidden behind the header
 * @param el
 * @returns {boolean}
 */
const isElementInViewport = (el) => {
  const position = el.getBoundingClientRect()
  const header_height = document.querySelector('body > header').clientHeight

  return position.top < window.innerHeight && position.bottom >= header_height;
}

const incrementBannerViewCount = (slider, currentSlideIndex) => {

  if (isElementInViewport(slider.slides[currentSlideIndex])) {
    const slide = slider.slides[currentSlideIndex]
    const banner_name = slide.getAttribute('data-banner-name')
    const banner_img = slide.getAttribute('data-banner-img')
    const banner_id = slide.getAttribute('data-banner-id')
    let banner_pos = null

    if (slide.getAttribute('banner-position') !== 'category') {
      banner_pos = 'Startseite-Fade-Banner-' + slide.getAttribute('banner-position')
    } else {
      banner_pos = 'Kategorieseite-Fade-Banner-header'
    }

    if (typeof (window.dataLayer) !== 'undefined') {
      window.dataLayer.push({
        'ecommerce': {
          'promoView': {
            'promotions': [
              {
                'id': banner_id,
                'name': banner_name,
                'creative': banner_img,
                'position': banner_pos
              }
            ]
          }
        }
      });
    }
  }
}

const trackProductSliderClick = (swiper, slide, callback) => {
  const product_pzn = slide.dataset.productModel
  const slider_container = swiper.el.dataset.sliderType
  const slider_title = swiper.el.dataset.sliderTitle

  if (typeof (window.dataLayer) !== 'undefined') {
    window.dataLayer.push({
      'event': 'sliderClick',
      'ecommerce': {
        'sliderClick': {
          'pzn': product_pzn,
          'slider_container': slider_container,
          'slider_title': slider_title
        }
      },
      'eventCallback': callback(),
      'eventTimeout': 2000
    })
  } else {
    return callback()
  }
}

const incrementBannerClickCount = (slide, callback) => {
  const banner_id = slide.getAttribute('data-banner-id')
  const banner_name = slide.getAttribute('data-banner-name')
  const banner_img = slide.getAttribute('data-banner-img')
  let banner_pos = null

  if (slide.getAttribute('banner-position') !== 'category') {
    banner_pos = 'Startseite-Fade-Banner-' + slide.getAttribute('banner-position')
  } else {
    banner_pos = 'Kategorieseite-Fade-Banner-header'
  }

  if (typeof (window.dataLayer) !== 'undefined') {
    window.dataLayer.push({
      'event': 'promotionClick',
      'ecommerce': {
        'promoClick': {
          'promotions': [
            {
              'id': banner_id,
              'name': banner_name,
              'creative': banner_img,
              'position': banner_pos
            }
          ]
        }
      },
      'eventCallback': callback(),
      'eventTimeout': 2000
    })
  } else {
    return callback()
  }
}

// open in new tab if param is 1 else open same window
const redirect = (url, open_in_new_tab) => open_in_new_tab === '1' ? window.open(url) : window.location.href = url


/**
 * PRODUCT SLIDER SECTION
 */

/**
 * Links slider products to corresponding product page
 * @param swiper - an instance of a slider
 */
const handleProductSliderClick = (swiper) => {
  swiper.slides.forEach((slide) => {
    const link = slide.querySelector('a')
    const url = slide.querySelector('a').getAttribute('href')

    link.addEventListener('click', (event) => {
      event.preventDefault()
      trackProductSliderClick(swiper, slide, () => redirect(url, 0))
    })

  })
}

/**
 * Function that invokes the slider build
 * @param sliderNode - an element that should be transformed into a slider
 * @param options - an optional swiper options object
 */
export const initializeSlider = (sliderNode, options) => {
  if (sliderNode.swiper) sliderNode.swiper.destroy();
  new Swiper(sliderNode, options || sliderConfigurationsByType['products']);
}

// takes all sliders on a page and initialize them by using the invoke function


/**
 * Listen for DY-event to re-init the slider building process for a specific DY-slider
 * @property e.detail - contains the node, the init needs to be done for
 * @property e.options - may contain a swiper configuration for overriding default
 */
document.addEventListener("dySliderInit", (e) => {
    let dySliderNode, dySliderOptions = null;
    if (!e.detail) return;
    if (typeof (e.detail) === 'object' && Object.prototype.hasOwnProperty.call(e.detail, "options")) {
      dySliderNode = e.detail.target;
      let modules = {modules: [Navigation, Pagination]};
      dySliderOptions = Object.assign({}, modules, e.detail.options);
    } else {
      dySliderNode = e.detail;
    }
    document.dispatchEvent(new CustomEvent(ApoEvents.DY_SLIDER_INIT));
    initializeSlider(dySliderNode, dySliderOptions);
  }
);


document.addEventListener("dySliderByTypeInit", (e) => {
  if (typeof (e.detail) === 'object' && Object.prototype.hasOwnProperty.call(e.detail, "type")) {
    switch (e.detail.type) {
      case 'default':
        initDefaultSliders();
        break;
      case 'banner':
        initBannerSliders();
        break;
      case 'contentful-banner':
        initContentfulBannerSliders();
        break;
      case 'products':
        initProductsSliders();
        break;
      case 'productsDY':
        initProductsDYSliders();
        break;
      case 'productsDYContentful':
        initProductsDYContentfulSliders();
        break;
      case 'medicationReminder':
        initMediactionReminderSliders();
        break;
      case 'modal':
        initModalSliders();
        break;
      case 'quicklinks':
        initQuicklinkSliders();
        break;
      case 'rating':
        initRatingSliders();
        break;
    }
  }
});

// Checkout Success tracking
if ($checkoutSuccessPage) {
  let orderId = $checkoutSuccessPage.dataset.orderID || ''
  let orderProducts = $checkoutSuccessPage.dataset.orderProducts || ''
  let orderTotals = $checkoutSuccessPage.dataset.orderTotals || ''
  let lastCart = $checkoutSuccessPage.dataset.lastCart || ''

  if (typeof (window.dataLayer) !== 'undefined') {
    window.dataLayer.push({
      'ecommerce': {
        'checkoutSuccessEvent': {
          'orderid': orderId,
          'orderProducts': orderProducts,
          'orderTotals': orderTotals
        }
      }
    });
  }

  //Algolia Tracking
  if (CONSTANTS.aid !== '' && CONSTANTS.sak !== '') {
    sendPurchasedProducts(lastCart, orderTotals)
  }
}

// Set indeterminate checkbox -- id = flexIndeterminateEnabled
if ($flexIndetEnabled) {
  $flexIndetEnabled.indeterminate = true;
}

if ($flexIndetDisabled) {
  $flexIndetDisabled.indeterminate = true;
}

// Autosuggest - Set active class when in focus, hover or clicked
if ($autosuggestWrapper) {
  $autosuggestWrapper.addEventListener('focusin', () => {
    const $autosuggestList = document.querySelector('.auto-suggest-list');
    const callback = (mutList) => {
      mutList.forEach((mut) => {
        if (mut.type === 'childList') {
          if (mut.target.childElementCount === 0) {
            $autosuggestWrapper.classList.remove('active');
            // document.body.classList.remove("stop-scrolling");
          } else {
            $autosuggestWrapper.classList.add('active');
            // document.body.classList.add("stop-scrolling");
          }
        }
      });
    };
    // Observer used to check if the result list has content
    const observer = new MutationObserver(callback);
    const config = {childList: true};
    observer.observe($autosuggestList, config);
    $autosuggestWrapper.addEventListener('focusout', () => {
      observer.disconnect();
    });
  });
}


/**
 * Datepicker
 * take input el and turn it into datepick-calendar (https://mymth.github.io/vanillajs-datepicker)
 */
// todo: replace with nativedatepicker
// const $calendarList = [].slice.call(document.querySelectorAll('input[data-selector="calendar"]'));
//
// if ($calendarList.length !== 0) {
//   $calendarList.forEach(calendar => {
//     // config datepicker
//     new Datepicker(calendar, {
//       format: 'dd.mm.yyyy',
//       language: 'de'
//     });
//   });
// }

// Checkout Confirmation --> change shipping provider on radio click
if (checkoutConfirmationShippingProvider) {
  checkoutConfirmationShippingProvider.forEach((radio) => {
    radio.addEventListener("change", () => {
      radio.closest("form").submit();
    })
  });
}


// js translations
i18next
  .use(Backend)
  .init({
    fallbackLng: 'de',
    backend: {
      loadPath: './dist/resource/locales/{{lng}}/translation.json'
    }
  }).catch(() => {
});


// handles active / inactive cookie consent for DY
new ApoDynamicYieldController();

// handles userlike chat triggers and implementation
new ApoUserlikeController();

// handles kairion script
new ApoKairionController();

// refills modals
new ApoModalController();

// handles deferred libraries and script tags
// remove the disablelogging for better overview which libraries are loaded
new ApoLibraryLoader().disableLogging();

new ApoDatepickerController();

new ApoListGroupController();

new ApoFormValidationController();

new ApoStepperController();

new ApoSelectController();

// WD-9917: Test Re-integration of pharmacore banners
// REMOVE IF TEST SUCCESSFUL!
/**
 * If pharmacore banner do not work, show static ones instead
 * */
const bannerDisplaySwitchIndex = () => {
  const pharmacoreBanners = document.querySelector('[data-selector="pharmacore-banners"]');
  const staticBanners = document.querySelector('[data-selector="static-banners"]');

  if (pharmacoreBanners && staticBanners) {
    pharmacoreBanners.childNodes.length > 1
      ? staticBanners.classList.add("d-none")
      : pharmacoreBanners.classList.add("d-none");
  }
}

if (document.body.getAttribute('data-pagetype') === 'pdp') {
  new ApoIntersectionController();
}

/**
 * WD-10220
 * form submit button is now disabled on the first press to prevent multiple account creations
 * is being enabled everytime a field's validation failed
 */

document.querySelector('form[action*="crt_account.php"]')
  ?.addEventListener('submit', (e) => {
    const submitButton = e.currentTarget.querySelector('*[type=submit]');
    if (submitButton) {
      submitButton.disabled = true;
    }
  });

document.querySelector('form[action*="crt_account.php"]')
  ?.addEventListener('invalid', (e) => {
    const submitButton = e.currentTarget.querySelector('*[type=submit]');
    if (submitButton) {
      submitButton.disabled = false;
    }
  }, true);


// WD-10194
// FIX for different browsers' autofill features that may or may not trigger a change event on the form or field
// autocomplete_detect unifies that behavior and fires a global event to handle this
document.addEventListener('onautocomplete', function (e) {
  const inputElement = e.target.parentNode.querySelector('.form-control');
  const labelElement = e.target.parentNode.querySelector('.form-label');
  if (inputElement && labelElement) {
    labelElement.classList.add('floating-label');
  }
})

// WD-10618
// event handler for apo clicktracking
// console.log('register data-apo-tracking');
document.querySelectorAll('*[data-apo-tracking]').forEach((trackingElement) => {
  trackingElement.addEventListener('click', (e) => {
    // e.preventDefault();
    let event, key, action;
    [key, event, action] = e.currentTarget?.getAttribute('data-apo-tracking')?.split(':');

    action = action.replace('&colon;', ':');

    if (!key || !event || !action) {
      throw new Error('tracking string probably malformed - target : ', e.currentTarget);
    }

    // console.log('logging trackingelement: ', {
    //   event,
    //   [key || 'key']: {
    //     action
    //   }
    // });

    if (typeof (window.dataLayer) !== 'undefined') {
      window.dataLayer.push({
        event,
        [key || 'key']: {
          action
        }
      });
    }
  })
})

function initLoginTab() {
  document.getElementById('spinner-wrap-login').remove();
  document.getElementById('login-login').classList.remove('d-none');
}

bannerDisplaySwitchIndex();
initQuicklinkSliders();
initBrandShopSliders();
initModalSliders();
initBannerSliders();
initContentfulBannerSliders();
initProductsSliders();
initProductsDYSliders();
initMediactionReminderSliders();
initDefaultSliders();
initLottieAnimationHomepage();

if (document.body.getAttribute('data-pagetype') === 'login') initLoginTab();

/*
 * Init ApoModalFormController to all existing selectors [data-selector="apo-modal-form"]
 */
document.querySelectorAll('[data-selector="apo-modal-form"]').forEach((modal) => {
  const modalInstance = new ApoModalFormController(modal);

  modalInstance.resetFormOnHide();
});