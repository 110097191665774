export type WishListItemType = {
  id: number,
  qty: number
}

export type WishListType = {
  items: WishListItemType[] | [],
  counter: number
}

export class WishList {
  constructor(
    /**
     * Items in wishlist
     */
    public items: WishListItemType[] | [],
    /**
     * Product quantity
     */
    public counter: number
  ) {}

  static fromApiObject(object: WishListType) {
    return new WishList(object.items, object.counter)
  }
}