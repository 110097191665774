// Floating label
import {ApoEvents} from "./apoEvents";

const initFloatingIFormFields = () => {
  document.querySelectorAll('.form-input-wrapper:not([data-selector="date_input"])').forEach((wrapper) => {
    const inputElement = wrapper.querySelector('.form-control');
    const labelElement = wrapper.querySelector('.form-label');

    if (inputElement.value && labelElement) {
      labelElement.classList.add('floating-label');
    } else if (labelElement) {
      labelElement.classList.remove('floating-label');
    }

    inputElement.addEventListener('change', (event) => {
      if (event.target.value) {
        labelElement.classList.add('floating-label');
      } else {
        labelElement.classList.remove('floating-label');
      }
    });
  });
}

// Voucher clear icon
document.querySelectorAll('.form-input-wrapper.voucher').forEach((wrapper) => {
  const inputElement = wrapper.querySelector('.form-control');

  if (inputElement.value) {
    inputElement.classList.add('voucher-clear');
  } else {
    inputElement.classList.remove('voucher-clear');
  }

  inputElement.addEventListener('change', (event) => {
    if (event.target.value) {
      inputElement.classList.add('voucher-clear');
    } else {
      inputElement.classList.remove('voucher-clear');
    }
  });
});

const setStepperInputValue = (input, value) => {
  input.value = value;
  input.setAttribute("value", value);
  input.dispatchEvent(new Event('change'))
}

const plusBtnStepper = (input, btn, minusBtn) => {
  let value = Number(input.value.trim()) + 1;
  const inputMax = Number(input.max);

  if (value <= inputMax) {
    setStepperInputValue(input, value);
  } else {
    return;
  }

  stepperValidation(input, minusBtn, btn);
};

const minusBtnStepper = (input, btn, plusBtn) => {
  let value = Number(input.value.trim()) - 1;
  const inputMin = Number(input.min);

  if (value >= inputMin) {
    setStepperInputValue(input, value);
  } else {
    return;
  }

  stepperValidation(input, btn, plusBtn);
};

const stepperValidation = (input, minusBtn, plusBtn) => {
  let value = Number(input.value.trim());
  const inputMin = Number(input.min);
  const inputMax = Number(input.max);

  if (value < inputMin) {
    setStepperInputValue(input, inputMin);
    value = inputMin;
  } else if (value > inputMax) {
    setStepperInputValue(input, inputMax);
    value = inputMax;
  }

  minusBtn.disabled = input.disabled ? true : value === inputMin;
  plusBtn.disabled = input.disabled ? true : value === inputMax;
};

// Input Group - Stepper
const initInputGroupStepper = () => {
  const steppers = document.querySelectorAll('.input-group.stepper');

  steppers.forEach((stepper) => {
    const $input = stepper.querySelector('input');
    const $minusBtn = stepper.querySelector('button.btn-minus');
    const $plusBtn = stepper.querySelector('button.btn-plus');

    $plusBtn?.removeEventListener('click', plusBtnHandler)
    $minusBtn?.removeEventListener('click', minusBtnHandler);
    $input?.removeEventListener('change', inputBtnHandler);

    if ($input && $minusBtn && $plusBtn) {
      $plusBtn.addEventListener('click', plusBtnHandler);
      $minusBtn.addEventListener('click', minusBtnHandler);
      $input.addEventListener('change', inputBtnHandler);

      stepperValidation($input, $minusBtn, $plusBtn);
    }
  })
}

let plusBtnHandler = (e) => {
  const $plusBtn = e.currentTarget;
  const stepper = $plusBtn.closest('.stepper');
  const $minusBtn = stepper.querySelector('button.btn-minus');
  const $input = stepper.querySelector('input');
  plusBtnStepper($input, $plusBtn, $minusBtn);
}

let minusBtnHandler = (e) => {
  const $minusBtn = e.currentTarget;
  const stepper = $minusBtn.closest('.stepper');
  const $plusBtn = stepper.querySelector('button.btn-plus');
  const $input = stepper.querySelector('input');
  minusBtnStepper($input, $minusBtn, $plusBtn);
}

let inputBtnHandler = (e) => {
  const $input = e.currentTarget;
  const stepper = $input.closest('.stepper');
  const $minusBtn = stepper.querySelector('button.btn-minus');
  const $plusBtn = stepper.querySelector('button.btn-plus');
  stepperValidation($input, $minusBtn, $plusBtn);
}


//Placeholder Asterisk

const togglePlaceholderAsterisk = () => {
  const $input = document.querySelector("#product_rating_input_659");
  const $asterisk = document.querySelector("#placeholder-asterisk");

  if ($input && $asterisk) {
    $input.addEventListener("input",
      ({target}) => target.value ? $asterisk.classList.add('gone') : $asterisk.classList.remove('gone'))
  }
}

initFloatingIFormFields();
initInputGroupStepper();
togglePlaceholderAsterisk()
document.addEventListener(ApoEvents.STEPPER_UPDATE, initFloatingIFormFields);