import { ProductReminder } from "@apo-app/apo-responsive/views/ProductReminder";
import { InitCart, InitCartOverlay, InitCategory, InitHeaderIcons, InitProductReminder, InitRatingStars, InitWishList } from "@apo-app/app/main";
import { Category } from "@apo-app/app/ui/Category";
import React from "react";
import ReactDOM from "react-dom/client";
import { BasicCart } from "./ui/Cart";
import { CartOverlay } from "./ui/CartOverlay";
import { CategoryPage } from "./ui/Category/categorypage";
import { Autosuggest } from "./ui/Search/autosuggest";
import { SearchPage } from "./ui/Search/searchpage";

function initSearchPage(Component: React.ComponentType, selector: string) {
  const element = document.querySelector(`[data-selector="${selector}"]`) as HTMLElement | null;
  if (element) {
    // Create a temporary container for the React component
    const tempContainer = document.createElement("div");
    const dataShippingLimit = Number(document.querySelector(`[data-selector="${selector}"]`)?.getAttribute("data-shipping-limit"));
    // add the shippingLimit and data-selector to the new container
    tempContainer.setAttribute("data-shipping-limit", `${dataShippingLimit}`);
    tempContainer.setAttribute("data-selector", selector);
    tempContainer.setAttribute("class", "d-flex justify-content-center p-spacing-0 w-100");

    tempContainer.style.display = "none";
    document.body.appendChild(tempContainer);

    const root = ReactDOM.createRoot(tempContainer);
    root.render(
      <React.StrictMode>
        <Component />
      </React.StrictMode>
    );

    // Use MutationObserver to detect when the component is fully rendered
    const observer = new MutationObserver((_, obs) => {
      const productCard = tempContainer.querySelector("#hit-list-item");
      if (productCard) {
        // Replace the old element with the new one
        element.replaceWith(tempContainer);
        tempContainer.style.display = "";
        obs.disconnect();
      }
    });

    observer.observe(tempContainer, {
      childList: true,
      subtree: true,
    });
  }
}

function initSearch(Component: React.ComponentType, selector: string) {
  const element = document.querySelector(selector) as HTMLElement | null;
  if (element) {
    const root = ReactDOM.createRoot(element);
    root.render(
      <React.StrictMode>
        <Component />
      </React.StrictMode>
    );
  }
}

window.addEventListener("DOMContentLoaded", () => {
  initSearchPage(SearchPage, "searchresults");
  initSearchPage(CategoryPage, "category");
  initSearch(Autosuggest, '[data-selector="search"]');
});

/**
 * Get elements
 */
const cartElement = document.querySelector("[data-cart]") as HTMLElement;
const categoryElement = document.querySelector("[data-category]") as HTMLElement;
const cartOverlayElement = document.querySelector("[data-cart-overlay]") as HTMLElement;
const productReminderElement = document.querySelector("[data-product-reminder]") as HTMLElement;

/**
 * Initialize other components
 */
new InitCategory({ Category }, categoryElement);
new InitCart({ BasicCart }, cartElement);
new InitWishList();
new InitHeaderIcons();
new InitCartOverlay({ CartOverlay }, cartOverlayElement);
new InitRatingStars();
new InitProductReminder({ ProductReminder }, productReminderElement);
