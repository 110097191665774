import { ApoEvents } from "./apoEvents";
// @ts-ignore
import NativeDatepicker from 'native-datepicker';

const dateRegex = /\d{4}-\d{2}-\d{2}/;
NativeDatepicker.prototype.setValue = function setValue(fullString : any) {
  var match = fullString.match(dateRegex);
  if (match) {
    this.fullValue = fullString;
    this.dateValue = match[0];
    this.dateInputElement.value = match[0];
  }
  else {
    this.fullValue = '';
    this.dateValue = '';
    this.dateInputElement.value = '';
  }
};

export class ApoDatepickerController {
  static instance: ApoDatepickerController;
  private controller: AbortController;

  constructor() {
    if (ApoDatepickerController.instance) {
      return ApoDatepickerController.instance;
    }
    ApoDatepickerController.instance = this;

    this.controller = new AbortController();
    this.initDatepickers();
    this.registerGlobalEvents();
  }

  private formatDateString(dateString: string): string {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  private initDatepickers(): void {
    // Abort previous event listeners
    this.controller.abort();
    this.controller = new AbortController();

    const datepickerWrapList = document.querySelectorAll('[data-selector="datepicker-wrap"]');
    datepickerWrapList.forEach(datepickerWrap => {
      const textInput = datepickerWrap.querySelector('input[type="text"]') as HTMLInputElement;
      textInput.value = textInput.value ||'';

      const datepicker = new NativeDatepicker({
                                                initialValue: '',
                                                onChange: (newValue: string) => {
                                                  textInput.parentElement?.classList.add('has-value');
                                                  textInput.value = this.formatDateString(newValue);
                                                  document.dispatchEvent(new CustomEvent(ApoEvents.DATEPICKER_CHANGED, {
                                                    detail: { datepickerWrap }
                                                  }));
                                                }
                                              });

      textInput.addEventListener('change', event => {
        const target = event.target as HTMLInputElement;
        datepicker.setValue(target.value);
        if (target.value === '') {
          textInput.parentElement?.classList.remove('has-value');
        } else {
          textInput.parentElement?.classList.add('has-value');
        }
      }, { signal: this.controller.signal });

      datepickerWrap.appendChild(datepicker.element);
    });
  }

  private registerGlobalEvents(): void {
    document.addEventListener(ApoEvents.STEPPER_UPDATE, () => this.initDatepickers());
  }
}