const typeUrls: Record<string, any> = {
  home: "https://www.apo.com/magazin/wp-json/wp/v2/ratgeber_cpt",
};

interface ArticleCardData {
  title: string;
  imageUrl?: string;
  link: string;
  category: string;
}

interface ApiResponse {
  id: number,
  title: string,
  link: string,
  categories: string[],
  customImage: string,
  thumb_full: string,
  thumb: string
}


const articleCard = (data: ArticleCardData) => {
  const cardHTML = `
  <div class="article-card-image">
    <div class="w-100 h-100 bg-aco-gray-05 d-flex justify-content-center align-items-center article-card-image-wrapper">
      ${data.imageUrl ? `<img src="${data.imageUrl}" alt="article-image" loading="lazy" />` : `<div></div>`}
    </div>
  </div>
  <div class="article-card-body">
    <div class="article-card-title mb-spacing-2">${data.category}</div>
    <div class="article-card-subtitle">${data.title}</div>
    <div class="slide-pagination aco-blue d-none">
      <div class="slide-pagination-icon-start">
        <i class="far fa-files"></i>
      </div>
      <div class="slide-pagination-content">1/3</div>
    </div>
  </div>
`;

  const cardWrapper = document.createElement("a");
  cardWrapper.classList.add("article-card");
  cardWrapper.setAttribute("tabindex", "0");
  cardWrapper.setAttribute("href", data.link);
  cardWrapper.setAttribute("target", "_blank");
  cardWrapper.innerHTML = cardHTML;

  const cardColumn = document.createElement("div");
  cardColumn.classList.add("col-12", "col-md-4");
  cardColumn.append(cardWrapper);

  return cardColumn;
};

export const generateArticles = async () => {
  const articleWrappers: NodeListOf<HTMLDivElement> = document.querySelectorAll(
    '[data-selector="article-wrapper"]'
  );

  const ratgeberPosts = sessionStorage.getItem('ratgeberPosts') !== null && JSON.parse(sessionStorage.getItem('ratgeberPosts') || '')

  if (articleWrappers) {

    articleWrappers.forEach(async (wrap) => {
      if (wrap.dataset.articlesType) {

        // get all posts
        const res = !ratgeberPosts && await fetch(typeUrls[wrap.dataset.articlesType]);
        const parsed = res ? <ApiResponse[]>await res.json() : ratgeberPosts;

        if (parsed && parsed.length) {
          sessionStorage.setItem('ratgeberPosts', JSON.stringify(parsed))

          parsed.forEach((article: ApiResponse) => {
            const teaserImage = article.customImage

            wrap.appendChild(
              articleCard({
                title: article.title,
                imageUrl: teaserImage !== '' ? teaserImage : article.thumb,
                link: article.link,
                category: article.categories[0]
              })
            );
          })
        } else {
          wrap.parentElement?.classList.add('d-none');
        }
      }
    });
  }
};

generateArticles();
