export const initHeaderIconComponents = () => {
  const url = 'ajax/header_icons.php';
  const method = 'GET';

  let loggedIn = false;
  let cartItemCount = 0;
  let headerIconWishlist = document.querySelector("wishlist-counter");

  async function fetchHeaderIconStatus() {
    return await fetch(url, {method: method})
      .then(res => res.json())
      .catch(err => console.error(err));
  }

  fetchHeaderIconStatus()
    .then(res => {
      loggedIn = res['logged_in'];
      cartItemCount = res['cart_count'];

      if(!loggedIn) {
        headerIconWishlist?.classList.add('d-none');
      }

      document.querySelector('cart-counter')?.setAttribute('quantity', `${cartItemCount}`);
    })
    .catch(error => console.error(error));
}