import {Simulate} from "react-dom/test-utils";
import {ApoMutationObserver} from "../../../../../apo_com_responsive/assets/javascript/apo-mutation-observer";

export interface Category {
  name: string
  id: number
}
export class OmqContact {

  private contactFormSelectWrapper: HTMLFormElement
  private categories: Array<Category>
  constructor() {
    this.contactFormSelectWrapper = <HTMLFormElement>document.querySelector('[ data-selector="contactFormSelectWrapper"]')
    this.categories = []
    this.initForm()
    ApoMutationObserver.observe('.omq-contact .omq-question-list').then((elm: any) => {
      this.initOmqContact()
    });
  }

  private initForm(){
    this.contactFormSelectWrapper.style.display = 'none'
  }

  private initOmqContact() {
    // show form select for categories
    this.contactFormSelectWrapper.style.display = 'block'
    const omqHelpcontainer = document.getElementById('omq-help-container')
    // detect click on omq category
    omqHelpcontainer?.addEventListener('onOmqCategoryClick', function(event) {
      let catID = (<any>event).detail.category.id
      let cat = <HTMLFormElement>document.querySelector('[data-selector="contactFormSelect"]')
        if(cat) {
          cat.value = catID
        }

    })

    this.getCategories()
  }

  private getCategories() {
    document.addEventListener('onOmqCategoriesLoaded', (event: any) => {
      this.setCategories(event.detail.categories)
    })
  }

  private setCategories(categories: Array<Category>) {
    if(categories.length != 0) {
      let selectList = document.createElement('select')
      selectList.dataset.selector = 'contactFormSelect'
      selectList.id = 'contactFormCategory'

      categories?.forEach((category: any) => {
        let catID = category.id
        let catLabel = category.name
        if(catID) {
          let option = document.createElement('option')
          option.value = catID
          option.innerHTML = catLabel

          this.setSelectOptions(selectList, option)
        }
      })
      let categoryWrapper = document?.querySelector('[data-selector="contactFormSelectInnerWrapper"]')
      if(categoryWrapper) {
        categoryWrapper.appendChild(selectList)
      }
    }
  }

  private setSelectOptions(selectElement: any, option: any) {
    selectElement.appendChild(option)
  }
}