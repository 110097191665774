/**
 * Load more orders in account my orders view
 */

const loadMoreButton = document.querySelector('#load_more_orders');
const myOrders = document.querySelectorAll('[id^="order-data-"]');
const incrementValue = 5;

const toggleVisibleOrders = (visible: number | void) => {
  if (visible) {
    myOrders.forEach((order, index) =>
      index <= visible - 1
        ? order.classList.remove('d-none')
        : order.classList.add('d-none')
    );

    if (visible >= myOrders.length) {
      loadMoreButton?.classList.add('d-none');
      loadMoreButton?.setAttribute('disabled', 'true');
    } else {
      loadMoreButton?.classList.remove('d-none');
      loadMoreButton?.removeAttribute('disabled');
    }
  }
};

function* visibleOrdersGenerator(increment: number) {
  let visible = increment;
  while (visible <= myOrders.length) {
    yield visible;
    visible += increment;
  }
  yield myOrders.length;
}

const setupOrders = () => {
  const visibleOrdersIterator = visibleOrdersGenerator(loadMoreButton ? incrementValue : myOrders.length);
  loadMoreButton &&
    loadMoreButton.addEventListener('click', () => {
      toggleVisibleOrders(visibleOrdersIterator.next().value);
    });
  toggleVisibleOrders(visibleOrdersIterator.next().value);
};

setupOrders();

export {};
