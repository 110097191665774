import {LitElement, html, nothing} from 'lit';
import {property, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {WishListApi, WishListType, WishList as WishListEntity} from "../../entity/WishList";
import {faHeart as faHeartSolid} from '@fortawesome/free-solid-svg-icons'
import {iconNode} from "../Utils"
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

type Constructor<T> = new (...args: any[]) => T;

interface WishListInterface {
  wishList: WishListType | undefined,
  wishListOptions: WishListOptionsType
}

const WishList = <T extends Constructor<LitElement>>(superClass: T) => {
  class WishListElement extends superClass {
    public wishList: WishListType | undefined
    public wishListOptions: WishListOptionsType | undefined

    public connectedCallback() {
      super.connectedCallback();
      this.wishList = undefined
      this.wishListOptions = wishListOptions
      if (initialWishlistData !== undefined) {
        this.wishList = WishListEntity.fromApiObject(initialWishlistData)
      }
    }

    protected createRenderRoot() {
      return this;
    }
  }

  return WishListElement as Constructor<WishListInterface> & T;
}

class WishlistButton extends WishList(LitElement) {
  private wishListApi: WishListApi
  private readonly wishListCounter: WishListCounter | null
  private readonly isLoggedIn: boolean;

  constructor() {
    super();
    this.wishListApi = new WishListApi()
    this.wishListCounter = document.querySelector('wishlist-counter')
    this.isLoggedIn = document.body.classList.contains('logged_in')
  }

  @property({type: Number})
  product = 0

  @property({type: Number})
  quantity = 1

  @state() active = false

  public connectedCallback() {
    super.connectedCallback()
    this.addEventListener('click', this.handleClick)
    this.setActiveState()
  }

  public disconnectedCallback() {
    this.removeEventListener('click', this.handleClick)
    super.disconnectedCallback()
  }

  protected render() {
    return html`
      <span
        class=${classMap({active: this.active})}
      >
        ${iconNode(this.wishListOptions.buttonIcon)}
      </span>
    `
  }

  private setActiveState() {
    if (!this.validateProperties()) {
      return
    }
    if (this.wishList !== undefined) {
      this.active = (this.wishList.items.filter(item => item.id === this.product).length > 0)
      this.wishListCounter?.updateCounter(this.wishList.counter)
      return
    }
    this.active = false
  }

  private addItem(id: number, qty: number) {
    this.wishListApi.addItem(id, qty)
      .then(data => {
        this.wishList = data
        this.setActiveState()
      })
  }

  private removeItem(id: number) {
    this.wishListApi.deleteItem(id)
      .then(data => {
        this.wishList = data
        this.setActiveState()
      })
  }

  private handleClick() {
    if (!this.validateProperties()) {
      return
    }
    if (!this.isLoggedIn) {
      this.emulateMessageStack()
    } else {
      if (!this.active) {
        this.addItem(this.product, this.quantity)
        return
      }
      this.removeItem(this.product)
    }
  }

  private emulateMessageStack() {
    const location = document.location
    const origin = location.origin
    const href = location.href
    const alarmWrapper = document.querySelector('.alerts-wrapper')

    // create InnerHtml
    const alarmContent = `
      <div class="alert container container-extra is-info aco-blue border-rounded-top-0" role="alert">
          <div class="content justify-content-between">
              <div class="d-flex align-items-center gap-spacing-16">
                  <div class="alert-icon"></div>
                  <div>
                      <b>Zur Nutzung der Merkzettelfunktion müssen Sie im Kundenkonto angemeldet sein.</b>
                      <p>Sie können sich hier <a href="${origin}/login.php?return_page=${href}" class="link-aco-white underline">anmelden</a> oder <a href="${origin}/crt_account.php?return_page=${href}" class="link-aco-white underline">neu registrieren</a>.</p>
                  </div>
              </div>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
      </div>`

    if (alarmWrapper) {
      // append it
      alarmWrapper.innerHTML = alarmContent

      //scrollTo info alarm
      alarmWrapper.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }
  }

  private validateProperties(): boolean {
    return this.product >= 0 || (this.quantity !== undefined && this.quantity > 0);
  }
}

class WishListCounter extends WishList(LitElement) {
  @property({type: Number})
  quantity = 0

  protected render() {
    return html`${this.quantity > 0
      ? html`<span>${this.quantity}</span>`
      : nothing
    }`
  }

  public connectedCallback() {
    super.connectedCallback()
    if (this.wishList !== undefined) {
      this.quantity = this.wishList.counter
    }
  }

  public updateCounter(qty: number = 0) {
    this.quantity = qty
  }
}

export type WishListOptionsType = {
  buttonIcon: IconDefinition
}

let initialWishlistData: WishListType | undefined = undefined
let wishListOptions: WishListOptionsType | undefined = undefined

export const initWishListComponent = (options: WishListOptionsType = {buttonIcon: faHeartSolid}) => {
  const wishListApi = new WishListApi()
  wishListApi.read()
    .then(data => {
      initialWishlistData = data
      wishListOptions = options
      window.customElements.define('wishlist-counter', WishListCounter)
      window.customElements.define('wishlist-button', WishlistButton)
    })
}
