import { useTranslation } from "react-i18next";

interface CategoryHighlightImageProps {
  imageWidth: number;
  imageHeight: number;
  imagePathArray: {
    [key: string]: string | number | undefined;
  };
  imageOverlay: boolean;
}

export const CategoryHighlightImage: React.FC<CategoryHighlightImageProps> = ({ imageWidth, imageHeight, imagePathArray, imageOverlay }) => {
  const { t } = useTranslation();
  const imageSizeKey = `image_size_${imageWidth}_${imageHeight}`;
  const imageSrc = imagePathArray[imageSizeKey] as string;
  const imageSrcSet = `${imageSrc} 1x, ${imagePathArray[`${imageSizeKey}_x2`]} 2x`;
  const altText = imagePathArray.alt_text as string;

  return (
    <div className={`product_image product_image_${imageWidth}_${imageHeight}`}>
      <img src={imageSrc} loading="lazy" srcSet={imageSrcSet} width={imageWidth} height={imageHeight} alt={altText} />
      {imageOverlay && (
        <div className="product_image_overlay">
          {imagePathArray.image_saving_percent === 0 ? null : (
            <>
              {imagePathArray.image_prescription_bonus ? (
                <>
                  <div className="product_image_text_bonus_amount">
                    {imagePathArray.image_prescription_bonus_currency_symbol_left} {imagePathArray.image_prescription_bonus_integer_part},
                    <span>{imagePathArray.image_prescription_bonus_decimal_part}</span>
                  </div>
                  <div className="product_image_text_bonus">
                    {t("category.image.overlayRxBonus")}
                    <sup>10</sup>
                  </div>
                </>
              ) : (
                <>
                  {Number(imagePathArray.image_saving_percent) < 10 ? (
                    <>
                      <div className="product_image_text_offer">{t("category.image.overlayOffer")}</div>
                      <div className="product_image_text_price">{t("category.image.overlayPrice")}</div>
                    </>
                  ) : (
                    <>
                      <div className="product_image_text_percentage">{imagePathArray.image_saving_percent}%</div>
                      <div className="product_image_text_saving"> {t("category.image.overlaySaving")}</div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
