import { useTranslation } from "react-i18next";
import { useScreenDetector } from "../../useScreenDetector";

export const CategoryDescription = () => {
  const categoryName = window.__CATEGORY_NAME__;
  const categoryDescription = window.__CATEGORY_DESCRIPTION__;
  const hideCatFooterDescription = window.__HIDE_CATEGORY_FOOTER_DESCRIPTION__;

  const { t } = useTranslation();
  const { isTablet } = useScreenDetector();
  if (!categoryName) return <></>;
  return (
    <>
      <h1 id="category-heading-title" className="pb-spacing-24">
        {categoryName}
      </h1>
      <div id="category-collapse-text">
        {categoryDescription && (
          <p
            dangerouslySetInnerHTML={{
              __html: categoryDescription,
            }}
          />
        )}
        {hideCatFooterDescription !== 1 && (
          <a
            id="category-anchor-text"
            // className={isTablet ? "d-flex gap-spacing-8 align-items-center mb-spacing-48 mt-spacing-20" : "d-flex gap-spacing-8 align-items-center"}
            onClick={(e) => {
              e.preventDefault();
              const targetId = "mehr_lesen";
              const targetElement = document.getElementById(targetId);
              const headerOffset = isTablet ? 177 + 80 + 20 : 300; //On mobile, add fixed header height + element padding + some space. On desktop, add some space.
              const elementPosition = targetElement?.getBoundingClientRect().top;
              if (!elementPosition) return;
              const offsetPosition = elementPosition + window.scrollY - headerOffset;

              window.scrollTo({
                top: offsetPosition,
              });
            }}
          >
            {t("category.readMore")}
            {categoryName}
            {t("category.readMoreAfter")}
            <i className="far fa-chevron-right ms-spacing-8"></i>
          </a>
        )}
      </div>
    </>
  );
};
