import React, {useState} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ProductReminderNewProduct} from "@apo-app/app/entity/ProductReminder/product-reminder-types";
import {ProductReminderInputGroup, ProductReminderPopoverInfo} from "@apo-app/apo-responsive/ui/ProductReminder";

interface Props {
  data: ProductReminderNewProduct
  setActiveStep: Function,
  saveReminder: Function,
  maxTimeSpan: number,
}

export const ProductReminderCreateConfirm = (props: Props) => {
  const [reminderName, setReminderName] = useState<string>('')
  const [daysBeforeEnd, setDaysBeforeEnd] = useState<number>(1)
  const [complete, setComplete] = useState(false)
  const {t} = useTranslation()


  const handleReminderName = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setReminderName(value)
    value.length !== 0 ? setComplete(true) : setComplete(false)
  }

  const deCreaseUnit = () => {
    daysBeforeEnd <= Number(props.maxTimeSpan) && daysBeforeEnd > 1 && setDaysBeforeEnd(daysBeforeEnd - 1)
  }

  const inCreaseUnit = () => {
    Number(props.maxTimeSpan) <= daysBeforeEnd
      ? setDaysBeforeEnd(daysBeforeEnd)
      : setDaysBeforeEnd(daysBeforeEnd + 1)
  }

  const setActiveStep = () => {
    props.setActiveStep(1)
  }

  const saveReminder = () => {
    props.saveReminder(reminderName, daysBeforeEnd)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    e.code === "Enter" && reminderName && daysBeforeEnd && props.saveReminder(reminderName, daysBeforeEnd)
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    Number(e.currentTarget.value) > Number(props.maxTimeSpan)
      ? setDaysBeforeEnd((prevState => prevState))
      : setDaysBeforeEnd(Number(e.currentTarget.value))
  }


  const popover = (
    <Popover id="popover-positioned-scrolling-top" title="Bezeichnung Erinnerung">
      <div className="popover fade show bs-popover-top" role="tooltip">
        <button className="btn btn-close btn-close-aco-blue close"/>
        <div className="popover-arrow"></div>
        <div className="popover-body">{t('productReminder.create.confirm.popoverInfo')}
        </div>
      </div>
    </Popover>
  );


  return (
    <div id="account_reminder_new_confirm" className="d-flex flex-column justify-content-center align-items-center text-aco-dark-blue">
      <div className="scheduled_date fw-bold container">{t('productReminder.create.confirm.reminderStarted', {date: props.data.DATE_START})}</div>
      <div className="d-flex flex-column justify-content-center align-items-center container">
        <section className="d-flex flex-column justify-content-center align-items-center mb-spacing-40">
          <fieldset className="fw-bold mb-spacing-20">{t('productReminder.create.confirm.whenReminded')}</fieldset>
          <div className="d-flex align-items-center flex-column flex-md-row">
            <div className="input-group stepper">
              <ProductReminderInputGroup
                deCrease={deCreaseUnit}
                inCrease={inCreaseUnit}
                value={daysBeforeEnd}
                handleChange={handleChange}
                handleKeyPress={handleKeyPress}
              />
            </div>
            <span className="ms-md-spacing-24 pt-spacing-24 pt-md-spacing-0">{t('productReminder.create.confirm.daysBefore')}</span>
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center align-items-center">
          <fieldset className="fw-bold mb-spacing-20">{t('productReminder.create.confirm.nameReminderQuestion')}</fieldset>
          <div className="d-flex align-items-end">
            <div>
              <div className="form-input-wrapper d-flex justify-content-center">
                <input className="form-control w-100 reminder-title"
                       type="text" name="title"
                       maxLength={200}
                       onChange={handleReminderName}
                       value={reminderName}
                       autoFocus
                       onKeyPress={handleKeyPress}
                />
                <label className="form-label floating-label">{t('productReminder.create.confirm.nameReminder')}</label>
              </div>
            </div>
            <OverlayTrigger
              container={this}
              trigger="focus"
              placement="top"
              overlay={popover}
            >
              <button className="btn btn-sm p-spacing-0 ms-spacing-8" type="button">
                <i className="far fa-circle-info text-aco-blue"></i>
              </button>
            </OverlayTrigger>
          </div>
        </section>
        <section className="d-flex flex-column justify-content-center align-items-center w-100 gap-spacing-16 mt-spacing-32">
          <button className="btn btn-lg btn-secondary w-100" onClick={setActiveStep}>{t('productReminder.create.confirm.buttonBack')}</button>
          <button className={`btn btn-lg btn-primary w-100 mb-spacing-16 ${!complete ? "disabled" : ""}`}
                  onClick={saveReminder}>{t('productReminder.create.confirm.buttonSave')}</button>
        </section>
      </div>
    </div>
  )
}