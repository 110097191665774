type Props = {
  message: string,
  visibility: boolean,
  hideMessageBox: () => void
}
export const ProductReminderInfoBox = (props: Props) => {
  return (
    <div className={`toast-container ${props.visibility ? 'visible' : 'invisible'}`} onBlur={props.hideMessageBox}>
      <div id="headlineToast" className="toast fade row show">
        <span className="toast-icon">
          <i className="far fa-alarm-clock fa-xl text-aco-light-blue"/>
        </span>
        <div className="toast-header">
          {props.message}
        </div>
        <button className="btn-close toast-close" type="button" aria-label="Close" onClick={props.hideMessageBox}></button>
      </div>

    </div>
  )
}