import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

export class CategoryFilter {
  private readonly categoryFilter

  constructor(categoryFilter: Element|null) {
    if (categoryFilter) {
      this.categoryFilter = <HTMLElement>categoryFilter
      this.handleCategoryFilter()
    }
  }

  private handleCategoryFilter() {
    this.resetFilterItems()
    this.resetAllFilterItems()
    this.initTextSearchManufacturer()
    this.countActiveFilters()
    this.showActiveFilterCategoryInLabel()
  }


  /**
   * return all reset buttons
   * @private
   */
  private getResetButtons() {
    const resetButtons = this.categoryFilter?.querySelectorAll<HTMLLinkElement>('[data-action="reset"]')
    if (resetButtons) {
      return resetButtons
    }
    throw new Error('No reset button found')
  }

  /**
   * reset filter items from current filter category
   * @private
   */
  private resetFilterItems() {
    const resetButtons = this.getResetButtons()
    resetButtons.forEach((resetButton) =>
      resetButton.addEventListener('click', (e) => {
        e.preventDefault()
        let filterType = resetButton.getAttribute('data-type')

        if(filterType === 'fprc') {
          let priceRangeFilter = document.querySelector('input[name="fprc"]') as HTMLInputElement
          priceRangeFilter.setAttribute('value', '')
        }
        // untick the matching boxes to execute the search without this specific filter-type
        document.querySelectorAll('input[name="' + filterType + '"]').forEach((input) => {
          let inputType = input.getAttribute('type')
          if (inputType === 'radio' || inputType === 'checkbox') {
            input.removeAttribute('checked')
          }
        })

        this.categoryFilter?.querySelector('form')?.submit()
      })
    )
  }

  /**
   * reset all filter items
   * @private
   */
  private resetAllFilterItems() {
    const resetButton = this.categoryFilter?.querySelectorAll<HTMLButtonElement>('[data-action="resetAll"]')
    resetButton?.forEach((reset) => {
      reset.addEventListener('click', () => {
        reset.closest('form')?.querySelectorAll<HTMLInputElement>('input').forEach((input : HTMLInputElement) => {
          if (input.type === 'radio' || input.type === 'checkbox') {
            input.checked = false
          }
          if (input.type === 'text') {
            input.value = ''
          }
          if (input.name === 'fprc') {
            input.value = ''
          }
        })
      })
    })
  }

  /**
   * instantly filter manufacturer with text search
   * @private
   */
  private initTextSearchManufacturer() {
    const textInputs = document.querySelectorAll<HTMLInputElement>('input[data-selector="input_search_manu"]')

    // go through the input fields in dropdown & offCanvas
    textInputs?.forEach((input : HTMLInputElement) => {

      // check for current search term inside the checkbox labels
      input?.addEventListener("keyup", () => {
        const filter = input.value.toUpperCase()

        // inside the offCanvas menu
        const listOffCanvas = input.closest('.accordion-body')?.querySelectorAll<HTMLSpanElement>('.filter-name')
        listOffCanvas?.forEach((label : HTMLSpanElement) => {
          const labelContainer = <HTMLDivElement>label.closest('[data-parent="label-container"]')
          if (label.innerHTML.toString().toUpperCase().includes(filter)) {
            labelContainer?.classList.contains('d-none') && labelContainer?.classList.remove('d-none')
            labelContainer?.classList.add('d-block')
          } else {
            labelContainer?.classList.contains('d-block') && labelContainer?.classList.remove('d-block')
            labelContainer?.classList.add('d-none')
          }
        })
      })
    })
  }

  /**
   * Counts all active filters and display them inside a blue bubble next to the filter category
   * @param inputEl - HTML-input element that needs to be checked if its ticked
   * @param filterCategory - Top-level HTML-elem used to find the closest filterBubble
   * @param filterBubble - HTML-elem used to display the amount of active filters
   * @private
   */
  private showFilterBubbles(inputEl: HTMLInputElement, filterCategory: Element, filterBubble: HTMLElement, liveChange: boolean) {
    if (inputEl.type === 'checkbox') {
      liveChange ? inputEl.toggleAttribute('checked') : ''
      let countActiveFilters = filterCategory.querySelectorAll('[checked]').length
      // update blue bubble number
      if (countActiveFilters > 0) {
        filterBubble.classList.contains('d-none') && filterBubble.classList.remove('d-none')
        filterBubble.innerHTML = `${countActiveFilters}`
      } else {
        filterBubble.classList.add('d-none')
      }
    }

    if (inputEl.type === 'radio' && liveChange) {
      filterBubble.classList.contains('d-none') && filterBubble.classList.remove('d-none')
      filterBubble.innerHTML = "1"
    } else {
        let countActiveFilters = filterCategory.querySelectorAll('[checked]').length
        if (countActiveFilters > 0) {
          filterBubble.classList.contains('d-none') && filterBubble.classList.remove('d-none')
          filterBubble.innerHTML = `${countActiveFilters}`
        }
    }

    if(inputEl.name === 'fprc') {
      // check if price filter was set --> show bubble on page load
      const url = new URL(window.location.href)
      const restorePriceFilter = url.searchParams.get('fprc')
      const priceSliderBubble = <HTMLElement>document.getElementById('fprc-counter')
      const priceSliderValue = <HTMLElement>document.getElementById('amount')

      if (restorePriceFilter != '' && typeof restorePriceFilter === "string" && priceSliderBubble) {
        priceSliderValue?.setAttribute('value', restorePriceFilter)
        priceSliderBubble.innerHTML = "1"
        priceSliderBubble.classList.contains('d-none') && priceSliderBubble.classList.remove('d-none')
      }
    }
  }

  /**
   * Show amount of active filters inside blue bubble in offcanvas
   * @private
   */
  private countActiveFilters() {
    // get all filter categories - "Sortierung", "Hersteller"...
    const offCanvasFilter = <HTMLElement>document.getElementById('offcanvasExampleFilter')
    const filterCategory = offCanvasFilter?.querySelectorAll<HTMLUListElement>('[aria-labelledby]')

    filterCategory?.forEach((filter) => {
      // get filter bubble for current filter option
      const blueFilterBubble = <HTMLElement>filter.parentNode?.querySelector('[data-selector="blue-badge"]')
      filter.querySelectorAll('input')?.forEach((input) => {
        // set blue bubble on page load
        this.showFilterBubbles(input, filter, blueFilterBubble, false)

        // set blue bubble on live input
        input.addEventListener('change', () => {
          this.showFilterBubbles(input, filter, blueFilterBubble, true)
        })
      })
    })
  }

  /**
   * Detects the active filter categories and displays names in offCanvas-opening-button
   * @private
   */
  private showActiveFilterCategoryInLabel() {
    const offCanvasButtons = document.querySelectorAll('[data-selector="offcanvas-filter-title"]')
    const activeBubblesCounter = document.querySelectorAll('.badge.badge-calendar-day.active:not(.d-none)').length
    if (activeBubblesCounter > 0) {
      offCanvasButtons.forEach((button) => {
        button.innerHTML = activeBubblesCounter + ' Filter aktiv'
        button.classList.add('active-filters')
        button.previousElementSibling?.classList.contains('d-none') && button.previousElementSibling?.classList.remove('d-none')
      })
    }
  }
}
