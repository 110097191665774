import React from 'react'

export type RatingProps = {
  average: number
  total?: number
  size?: "sm" | "md" | "lg" | "xl"
}

export const Rating = ({average, total, size}: RatingProps) => {
  const starSizes = size || "md"
  const ratingTotals = total || 0

  return (
    <div className={'rating'}>
      <rating-stars size={starSizes} average={average}></rating-stars>
      <span className={'rating-total'}>({ratingTotals})</span>
    </div>
  )
}