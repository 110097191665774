import { useTranslation } from "react-i18next";
import { CategoryHighlightImage } from "./categoryHighlightImage";

export const CategoryHighlight = () => {
  const { t } = useTranslation();
  console.log(window.__CATEGORY_HIGHLIGHT__);

  if (!window.__CATEGORY_HIGHLIGHT__) return <></>;
  return (
    <div id="category_highlight_product_wrapper">
      <div id="category_highlight_product" style={{ borderColor: window.__CATEGORY_COLOR__ }}>
        <div id="category_highlight_product_header" style={{ backgroundColor: window.__CATEGORY_COLOR__ }}>
          {t("category.dealOfTheWeek")}
        </div>
        <a href={window.__CATEGORY_HIGHLIGHT__?.PRODUCTS_LINK}>
          <CategoryHighlightImage
            imageHeight={400}
            imageWidth={400}
            imageOverlay={true}
            imagePathArray={{
              alt_text: window.__CATEGORY_HIGHLIGHT__?.PRODUCTS_NAME,
              ...window.__CATEGORY_HIGHLIGHT__?.PRODUCTS_IMAGE,
            }}
          />
        </a>
      </div>
    </div>
  );
};
