export const ApoEvents : any = {
    PRODUCTSLIDER_HASVIDEO_INDEX_CHANGE : 'PRODUCTSLIDER_HASVIDEO_INDEX_CHANGE',
    SELECTFIELD_OPTIONS_CHANGED : 'SELECTFIELD_OPTIONS_CHANGED',
    SELECTFIELD_CHANGED : 'SELECTFIELD_CHANGED',
    DEFERRED_LIBRARY_LOADED : 'DEFERRED_LIBRARY_LOADED',
    USERCENTRICS_CONSENT_STATUS_CHANGED : 'USERCENTRICS_CONSENT_STATUS_CHANGED',
    USERCENTRICS_CONSENT_CHECKED : 'USERCENTRICS_CONSENT_CHECKED',
    DY_SLIDER_INIT : 'DY_SLIDER_INIT',
    FIXED_PRODUCT_HEADER_VISIBILITY_CHANGE : 'FIXED_PRODUCT_HEADER_VISIBILITY_CHANGE',
    PDP_NODE_CHANGE : 'PDP_NODE_CHANGE',
    PRODUCTSLIDER_NODES_CHANGE : 'PRODUCTSLIDER_NODES_CHANGE',
    DY_API_AVAILABLE : 'DY_API_AVAILABLE',
    DY_ABTEST_INIT : 'DY_ABTEST_INIT',
    DYSLIDER_BY_TYPE_INIT : 'dySliderByTypeInit',
    STEPPER_UPDATE : 'STEPPER_UPDATE',
    STEPPER_VALIDATION_FAILED : 'STEPPER_VALIDATION_FAILED',
    DATEPICKER_CHANGED : 'DATEPICKER_CHANGED',
    LOGIN_MODAL_INIT : 'LOGIN_MODAL_INIT',
};