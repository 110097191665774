import {LitElement, html, nothing} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('cart-counter')
export class CartCounter extends LitElement {

  @property({type: Number})
  quantity = 0

  protected render() {
    return html`${this.quantity > 0
      ? html`<span>${this.quantity}</span>`
      : nothing
    }`
  }

  protected createRenderRoot() {
    return this;
  }

  public updateCounter(qty: number = 0) {
    this.quantity = qty
  }
}