import React, {FC, useContext, useState, FormEvent, useEffect} from 'react';
import {
  Cart,
  CartLinksType,
  CouponCodeType,
  OrderBonusPointsInfoType,
  CashingBonusPointsType,
} from '@apo-app/app/entity/Cart';
import {CartContext} from '@apo-app/app/state/Cart';
import {Form, Button, Row, Col, Modal, FloatingLabel} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faTrashCan} from '@fortawesome/pro-light-svg-icons';
import {faPaperPlaneTop} from '@fortawesome/pro-regular-svg-icons';
import {faCirclePlus, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import {Trans, useTranslation} from "react-i18next";

export type CartCouponProps = {
  cart: Cart;
};

export type CouponCodeInformationProps = {
  info: CouponCodeType;
};

export type BonusPointsInfoProps = {
  links: CartLinksType;
  cart: Cart;
};

export type ApplyBonusPointsToCartProps = {
  info: OrderBonusPointsInfoType;
};

export type RemoveBonusPointsProps = {
  cashing: CashingBonusPointsType;
};

export const CartCouponCode: FC<CartCouponProps> = ({cart}) => {
  return (
    <>
      {cart.couponCode.length > 0 ? (
        <>
          {cart.couponCode.map((info, index) => (
            index < 1 && <CouponCodeInformation key={index} info={info}/>
          ))}
        </>
      ) : (
        <ApplyCouponToCart/>
      )}

      {!cart.cartItems.every(
        (product) => product.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '1'
      ) && (
        <div
          className={classNames(
            'justify-content-md-center',
            'mb-spacing-0',
            'mt-spacing-16',
          )}
        >
          <Col
            className={classNames(
              'bg-aco-blue',
              'border-rounded-8',
              'p-spacing-16'
            )}
          >
            <Row>
              <Col xs={1} className="apo-punkte-icon">
                <FontAwesomeIcon icon={faCirclePlus as IconDefinition}/>
                <FontAwesomeIcon icon={faCirclePlus as IconDefinition}/>
              </Col>
              <Col xs={10}>
                {'possible_bonus_points' in cart.orderBonusPointsInfo ? (
                  <ApplyBonusPointsToCart info={cart.orderBonusPointsInfo}/>
                ) : 'bonus_points_amount_formatted' in
                cart.orderBonusPointsCashing ? (
                  <RemoveBonusPoints cashing={cart.orderBonusPointsCashing}/>
                ) : <BonusPointsInfo links={cart.links} cart={cart}/>
                }
              </Col>
            </Row>
          </Col>
        </div>
      )}
    </>
  );
};

export const ApplyCouponToCart = () => {
  const context = useContext(CartContext);
  const [floating, setFloating] = useState(false);


  function applyCouponCode(event: FormEvent) {
    const form = event.currentTarget as HTMLFormElement;
    context.applyCouponCode(form);
    event.preventDefault();
  }

  const {t} = useTranslation();

  return (
    <Form
      className={'form-apply-coupon-code d-flex justify-content-center'}
      name={'formApplyCouponCode'}
      onSubmit={applyCouponCode}
      noValidate
    >
      <Form.Group
        controlId="couponCode"
        className="form-input-wrapper cart-coupon mb-spacing-16 mt-spacing-0 w-100 mw-100"
      >
        <Form.Control
          name={'couponCode'}
          type="text"
          required
          onChange={(e) => {
            if (!e.target.value) {
              setFloating(false);
              return;
            }
            setFloating(true);
          }}
        />
        <Form.Label className={classNames(floating && 'floating-label')}>
          {t('cartCouponCode.couponCode')}
        </Form.Label>
      </Form.Group>
      <Button type={'submit'} className="apply-coupon-code-button btn-ghost">
        <FontAwesomeIcon icon={faPaperPlaneTop} className="text-aco-blue"/>
      </Button>
    </Form>
  );
};

export const CouponCodeInformation: FC<CouponCodeInformationProps> = ({info}) => {
  const context = useContext(CartContext);

  function removeCouponCode(event: FormEvent) {
    const form = event.currentTarget as HTMLFormElement;
    context.removeCouponCode(form);
    localStorage.removeItem('couponCode')
    event.preventDefault();
  }

  switch (info.type) {
    case 'success':
    case 'error':
      return (
        <>
          {info.stackable ? <ApplyCouponToCart/> : ''}
          <Form
            className={'form-remove-coupon-code mb-spacing-16'}
            name={'formRemoveCouponCode'}
            onSubmit={removeCouponCode}
          >
            <Form.Group
              controlId="couponCode"
              className="mt-spacing-0 form-input-wrapper d-flex justify-content-between"
            >
              <Form.Control
                name={'couponCode'}
                type="text"
                readOnly={true}
                defaultValue={info.code}
                className="d-none"
              />
              <div>{info.code}</div>
              <Button
                type={'submit'}
                className="remove-coupon-code-button btn-ghost"
              >
                <FontAwesomeIcon icon={faTrashCan}/>
              </Button>
            </Form.Group>
              <Form.Text data-type={info.type}>
                {info.message}{' '}
                {info.type === "success" && ( <span className="value-format">{info.value_format}</span>)}
              </Form.Text>

          </Form>
        </>
      );
    case 'fatal_error':
    case 'removed':
      return (
        <>
          <ApplyCouponToCart />
          <p data-type={info.type}>{info.message}</p>
        </>
      );

    default:
      return <ApplyCouponToCart/>;
  }
};

export const BonusPointsInfo: FC<BonusPointsInfoProps> = ({links, cart}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {t} = useTranslation();
  return (
    <>
      <p className={classNames('text-aco-yellow')}>
        {t('cartCouponCode.redeemApoPoints')}
        {!cart.loggedIn &&
          <span className={classNames('micro', 'text-aco-white', 'd-block')}>
            {t('cartCouponCode.loginNeeded')})
          </span>
        }
      </p>
      {!cart.loggedIn ? (
        <>
          <Button
            as={'a'}
            href={links.LOGIN_LINK}
            className={classNames(
              'apo-punkte-button-as-link',
              'link-aco-light-blue',
              'ps-spacing-0'
            )}
            variant={'link'}
          >
            {t('cartCouponCode.login')}
          </Button>
          <Button
            as={'a'}
            href={links.CREATE_ACCOUNT_LINK}
            className={classNames(
              'apo-punkte-button-as-link',
              'link-aco-light-blue',
              'ps-spacing-0'
            )}
            variant={'link'}
          >
            {t('cartCouponCode.register')}
          </Button> </>) : (
        <>
          <p className="text-aco-white">
            {t('cartCouponCode.errorMessage', {
              numberPoints: cart.accountBonusPoints
            })}
          </p>
        </>
      )
      }

      <Button
        onClick={handleShow}
        className={classNames(
          'apo-punkte-button-as-link',
          'link-aco-white',
          'ps-spacing-0',
          'no-underline',
          'fw-medium'
        )}
        variant={'link'}
      >
        {t('cartCouponCode.moreInformation')}
        <FontAwesomeIcon
          icon={faChevronRight as IconDefinition}
          className="ms-spacing-12"
        />
      </Button>

      <Modal show={show} onHide={handleClose} centered className={classNames('cart-modal-apopunkte-info')}>
        <Modal.Header className={classNames('text-aco-white btn-close-aco-blue')}>
          <Modal.Title className={classNames('ps-spacing-8')}>
            <Row>
              <Col xs={2} className={classNames('d-flex align-items-center')}>
                <div className={classNames('blue-gradient small-icon d-flex')}>
                  <div className={classNames('content d-flex justify-content-center align-items-center apo-punkte-icon popup text-aco-light-blue')}>
                    <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                     data-prefix="fas"
                                     className="text-aco-light-blue"/>
                    <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                     data-prefix="fas"
                                     className="text-aco-light-blue"/>
                  </div>
                </div>
              </Col>
              <Col xs={9}>
                <div className="h3 pb-spacing-16 pt-spacing-24"> {t('cartCouponCode.collectApoPointsAndSave')}</div>
              </Col>
              <Col xs={1}>
                <button className={classNames('btn btn-close btn-close-aco-blue close')}
                        type='button'
                        data-dismiss='modal'
                        aria-label='Close'
                        onClick={handleClose}
                >
                </button>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={classNames('text-aco-white')}>
            {t('cartCouponCode.apoPointsInfoText')}
          </p>
          <p className={classNames('text-aco-yellow', 'd-block')}>
            {t('cartCouponCode.apoPointsInfoBuy')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as={'a'}
            href='/apoprimo'
            target='_blank'
            className={classNames('d-block', 'text-aco-white')}
            variant={'link'}
          >
            Mehr Informationen hier

            <FontAwesomeIcon
              icon={faChevronRight as IconDefinition}
              className="ms-spacing-12"
            />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const ApplyBonusPointsToCart: FC<ApplyBonusPointsToCartProps> = ({info}) => {
  const context = useContext(CartContext);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [conversion, setConversion] = useState<string>('');
  const [floating, setFloating] = useState(false);
  const {t} = useTranslation()

  const triggerValidation = (points: Number) => {
    setIsValid(
      points >= parseFloat(info.min_possible_bonus_points) &&
      points <= info.possible_bonus_points
    );
  };

  function applyBonusPoints(event: FormEvent) {
    const form = event.currentTarget as HTMLFormElement;
    event.preventDefault();
    if (isValid) {
       context.applyBonusPoints(form);
    }
  }

  const convertApoPunkte = (value: number, ratio: number): string => {
    return `${(!isNaN(value) && !isNaN(ratio) ? value * ratio : 0).toFixed(
      2
    )} €`;
  };

  const setApoPunkte = (value: number) => {
    setConversion(
      convertApoPunkte(
        value,
        parseFloat(info.conversion_point_to_main_currency_unit)
      )
    );
    triggerValidation(value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)

    if (value < parseFloat(info.min_possible_bonus_points)) {
      setApoPunkte(parseFloat(info.min_possible_bonus_points));
      e.target.value = info.min_possible_bonus_points
      setFloating(true);
      return;
    } else if (value > info.possible_bonus_points) {
      setApoPunkte(info.possible_bonus_points);
      e.target.value = String(info.possible_bonus_points);
      setFloating(true);
      return;
    }
    else if (value > 5000) {
      setApoPunkte(5000);
      e.target.value = String(5000);
      setFloating(true);
      return;
    }

    setFloating(true);
    setApoPunkte(parseFloat(e.target.value));
  };

  useEffect(() => {
    if (info.pre_fill_bonus_points_value) {
      setFloating(true);
      setApoPunkte(info.pre_fill_bonus_points_value);
    }
  }, []);

  return (
    <>
      <p className={classNames('text-aco-yellow')}>
        {t('cartCouponCode.redeemApoPoints')}
        <span className={classNames('text-aco-white', 'd-block')}>
          <Trans>
               {t('cartCouponCode.apoPointsQuantity', {
                 accountBonusPoints: context.cartData.accountBonusPoints
               })}
          </Trans>
        </span>
      </p>

      <div className="apo-punkte-divider"/>
      <Form
        noValidate
        validated={isValid}
        className={classNames(
          'form-apply-bonus-points',
          'dark',
          'text-aco-white',
          'pt-spacing-12'
        )}
        name={'formApplyBonusPoints'}
        onSubmit={applyBonusPoints}
      >
        <Form.Text className="text-aco-white">
          {t('cartCouponCode.desiredAmount')}
        </Form.Text>
        <div className="d-flex flex-wrap align-items-center mt-spacing-24 w-100 gap-spacing-16">
          <div className="d-flex flex-row">
            <div
              className="d-flex align-items-center flex-grow-1"
              style={{
                minWidth: '40%',
              }}
            >
              <Form.Group
                controlId="bonusPoints"
                className="mt-spacing-0 form-input-wrapper w-100"
              >
                <input
                  name={'bonusPoints'}
                  type={'number'}
                  min={info.min_possible_bonus_points}
                  max={info.possible_bonus_points}
                  onBlur={onBlur}
                  required
                  defaultValue={info.pre_fill_bonus_points_value}
                  className={classNames(
                    'apo-punkte-amount-input',
                    'form-control',
                    {'is-invalid': !isValid}
                  )}
                />
                <Form.Label
                  className={classNames(floating && 'floating-label')}
                >
                  {t('cartCouponCode.apoPoints')}
                </Form.Label>
                <div className="form-message position-relative text-aco-white">
                  {t('cartCouponCode.minAndMaxRedeemable', {
                    min: info.min_possible_bonus_points,
                    max: info.possible_bonus_points < 5000 ? info.possible_bonus_points : 5000
                  })}
                </div>
              </Form.Group>
            </div>
            <div
              className="d-flex align-items-start mt-spacing-16"
              style={{
                width: '100%',
                paddingTop: '0.375rem',
              }}
            >
              <span
                className="fw-bold"
                style={{
                  lineBreak: 'anywhere',
                }}
              >
                / {conversion}
              </span>
            </div>
          </div>

          <div
            className="d-flex align-items-center"
            style={{
              width: 'fit-content',
            }}
          >
            <Button type={'submit'}> {t('cartCouponCode.redeem')}</Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export const RemoveBonusPoints: FC<RemoveBonusPointsProps> = ({cashing}) => {
  const context = useContext(CartContext);

  function removeBonusPoints() {
    context.removeBonusPoints();
  }

  const {t} = useTranslation()
  return (
    <div className="d-flex gap-spacing-12">
      <div className="d-flex flex-grow-1">
        <p className={classNames('text-aco-yellow', 'mb-spacing-0', 'w-100')}>
          {t('cartCouponCode.apoPointsWereRedeemed', {
            number: Math.abs(cashing.bonus_points)
          })}

          <strong className={classNames('text-aco-white', 'd-block')}>
            {cashing.bonus_points_amount_formatted}
          </strong>
        </p>
      </div>
      <div>
        {cashing.is_automatically_redeem !== true && (
          <Button
            type={'submit'}
            onClick={removeBonusPoints}
            className="remove-bonus-point-button"
          >
            <FontAwesomeIcon icon={faTrashCan}/>
          </Button>
        )}
      </div>
    </div>
  );
};
