import React, {useState} from "react";
import {
  ProductReminderNewProduct,
  ProductReminderSuggest
} from "@apo-app/app/entity/ProductReminder/product-reminder-types";
import {ProductReminderCreateDosage} from "./product-reminder-create-dosage";
import {ProductReminderCreateDate} from "./product-reminder-create-date";
import {ProductReminderProductCard, ProductReminderStepper} from "@apo-app/apo-responsive/ui/ProductReminder";
import {ProductReminderCreateConfirm} from "./product-reminder-create-confirm";
import {ProductReminderCreateFindProduct} from "./product-reminder-create-find-product";
import {useTranslation} from "react-i18next";

interface Props {
  handleCreateReminder: Function,
  step: number,
  setStep: (step: number) => void,
  product?: ProductReminderNewProduct,
  handleProductReminder: Function
  activeStep: number
  setActiveStep: Function
  confirmReminderData?: ProductReminderNewProduct
  createStep?: Function
  searchProduct: Function
  resultSearch?: ProductReminderSuggest[]
  saveReminder: Function
  completedSteps: boolean[],
  maxTimeSpan:number,
}

export const ProductReminderCreate = (props: Props) => {
  const [inputValue, setInputValue] = useState("")
  const [startDate, setStartDate] = useState('')
  const [reminderData, setReminderData] = useState({})
  const {t} = useTranslation();


  const handleProductSearch = (value: string) => {
    props.searchProduct(value)
  }

  const handleGetProduct = (id: string) => {
    props.handleCreateReminder(id)
  }

  const setActiveStep = (val: number) => {
    props.setActiveStep(val)
  }


  const handleProductReminder = (productReminder: {}) => {
    setReminderData(productReminder)
    props.handleProductReminder(productReminder)
  }

  const handleStartingDate = (date: '') => {
    setStartDate(date)
  }

  const gotoStartingDate = () => {
    const newObj = {date_start: startDate, ...reminderData}
    newObj.date_start !== "" && props.handleProductReminder(newObj)
    setActiveStep(2)
  }


  return (
    <>
      {props.step === 0 && (
        <ProductReminderCreateFindProduct
          handleProductSearch={handleProductSearch}
          handleGetProduct={handleGetProduct}
          resultsSearch={props.resultSearch!}
          inputValue={inputValue}
        />
      )}
      {props.step === 1 && (
        <>
          <>
            <ProductReminderStepper activeStep={props.activeStep}
                                    completedSteps={props.completedSteps}/>
            <ProductReminderProductCard resultProduct={props.product}/>
          </>
          {props.activeStep === 0 &&
            <ProductReminderCreateDosage
              resultProduct={props.product}
              setActiveStep={setActiveStep}
              handleProductReminder={handleProductReminder}
              setStep={props.setStep}
            />
          }
          {props.activeStep === 1 &&
            <>
              <ProductReminderCreateDate handleStartingDate={handleStartingDate}/>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100 gap-spacing-16 mt-spacing-32">
                <button className="btn btn-lg btn-secondary w-100"
                        onClick={() => setActiveStep(0)}>
                  {t('productReminder.create.buttonBack')}
                </button>
                <button className="btn btn-lg btn-primary w-100"
                        onClick={gotoStartingDate}>
                  {t('productReminder.create.nextStep')}
                </button>
              </div>
            </>
          }
          {props.activeStep === 2 &&
            <>
              {props.confirmReminderData && (
                <ProductReminderCreateConfirm data={props.confirmReminderData}
                                              maxTimeSpan={props.maxTimeSpan}
                                              setActiveStep={setActiveStep}
                                              saveReminder={props.saveReminder}></ProductReminderCreateConfirm>)}
            </>
          }
        </>
      )}
    </>
  )
}