import { TransformItemsMetadata } from "instantsearch.js";
import {
  productTypeLabelMap,
  prescriptionTypeLabelMap,
  subscriptionLabelMap,
  homeopaticLabelMap,
  singleIngredientLabelMap,
  classicLabelMap,
  herbalLabelMap,
  genericLabelMap
} from "./translationmaps";


/**
 * Translates attribute names from algolia-index into a readable value from the corresponding value-map and sets it as the title of the current refinement chip
 *
 * @param itemArray - array that stores the refinement items [{amount_unit, ...}, {presentation_form, ...}, ...]
 * @param metadata - object that contains information about the results of a search (used query, hits per page...)
 * @param translationMap - object, which keys must match the name of the refinement and contains a display text
 * @param removeFilterWithValueZero - bool, used to remove options that are 0 (e.g. needed in product_type)
 */
export const translateToDisplayName: (itemArray: any[], metadata: TransformItemsMetadata, translationMap: any, removeFilterWithValueZero: boolean) => any[] = (itemArray: any[], metadata: TransformItemsMetadata, translationMap: any, removeFilterWithValueZero: boolean) => {
  let returnArray = itemArray;

  if (removeFilterWithValueZero) {
    returnArray = returnArray.filter((object) => {
      return object.value != 0;
    })
  }

  return returnArray.map((filterObject) => ({
    ...filterObject,
    refinements: translateItemLabel(filterObject),
    label: translationMap[filterObject.label]
  }))
};

/**
 * Translates values of active filter options into a readable value from the corresponding value-map and sets it as the value-name of the current refinement chip
 *
 * @param filterObject - object that contains the current filters params
 */
const translateItemLabel = (filterObject: any) => {
  let { refinements } = filterObject;

  if (refinements) {
    return refinements.map((refinement: any) => ({
      ...refinement,
      label: getNameFromMap(refinement)
    }))
  }
};

/**
 * Returns the clear string for the refinement label
 *
 * @param refinement - a single filter information
 */
const getNameFromMap = (refinement: any) => {
  const refinementAttribute = refinement.attribute;
  let translation = refinement.label;

  if (refinementAttribute === "products_type") {
    translation = productTypeLabelMap[refinement.label];
  }

  if (refinementAttribute === "homeopatic") {
    translation = homeopaticLabelMap[refinement.label];
  }

  if (refinementAttribute === "single_ingredient") {
    translation = singleIngredientLabelMap[refinement.label];
  }

  if (refinementAttribute === "classic") {
    translation = classicLabelMap[refinement.label];
  }

  if (refinementAttribute === "herbal") {
    translation = herbalLabelMap[refinement.label];
  }

  if (refinementAttribute === "generic") {
    translation = genericLabelMap[refinement.label];
  }

  if (refinementAttribute === "subscription_status") {
    translation = subscriptionLabelMap[refinement.label];
  }

  if (refinementAttribute === "prescription_required") {
    translation = prescriptionTypeLabelMap[refinement.label];
  }

  if (refinementAttribute === "rating") {
    if (refinement.operator && refinement.operator == ">=") {
      translation = "von " + refinement.value + " Sterne";
    }
    if (refinement.operator && refinement.operator == "<=") {
      translation = "bis " + refinement.value + " Sterne";
    }
  }

  if (refinementAttribute === "price_no_format") {
    if (refinement.operator && refinement.operator == ">=") {
      translation = "von " + refinement.value + " €";
    }

    if (refinement.operator && refinement.operator == "<=") {
      translation = "bis " + refinement.value + " €";
    }
  }

  return translation;
}