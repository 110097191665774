import {ApoEvents} from "./apoEvents";
import {FieldValidator} from "./apo-formvalidation-fieldvalidator";
import { Tooltip } from 'bootstrap';

export class ApoFormValidationController {
  static instance: ApoFormValidationController;
  private forms: NodeListOf<HTMLFormElement>;

  // Flags to track field interactions
  private passwordTouched: boolean = false;
  private confirmationTouched: boolean = false;

  constructor() {
    if (ApoFormValidationController.instance) {
      return ApoFormValidationController.instance;
    }
    ApoFormValidationController.instance = this;

    this.forms = document.querySelectorAll('.needs-validation');
    this.registerGlobalEvents();
    this.registerModalResetLogic();
  }

  private registerGlobalEvents(): void {
    document.addEventListener(ApoEvents.STEPPER_VALIDATION_FAILED, () => this.onStepperValidationFailed());
    document.addEventListener(ApoEvents.STEPPER_UPDATE, () => this.initFormValidation());
    document.addEventListener(ApoEvents.LOGIN_MODAL_INIT, () => this.initFormValidation());
    document.addEventListener(ApoEvents.SELECTFIELD_CHANGED, (event: CustomEvent) => {
      const targetElement = event.detail.target as HTMLElement;
      const countryTextElement = document.querySelector('[name="country_text"]');

      if (countryTextElement && countryTextElement.contains(targetElement)) {
        const postcodeElement = document.querySelector('[name="postcode"]') as HTMLInputElement;
        if (postcodeElement) {
          FieldValidator.validateField(postcodeElement, 2); // Mindestlänge als Beispiel
        }
      }
    });
  }

  /**
   * Resets modal forms when hidden, removing validation classes
   * and restoring a clean state.
   */
  private registerModalResetLogic(): void {
    const modals = document.querySelectorAll('[data-selector="apo-modal-form"]');
    modals.forEach(modal => {
      modal.addEventListener('hidden.bs.modal', () => {
        const form = modal.querySelector('form[data-selector="form"]') as HTMLFormElement;
        if (!form) return;
        form.reset();
        form.classList.remove('was-validated');
        setTimeout(() => {
          const inputs = form.querySelectorAll('input.is-invalid, input.is-valid');
          inputs.forEach(input => {
            input.classList.remove('is-invalid', 'is-valid');
          });
        }, 10);
      });
    });
  }

  private onStepperValidationFailed(): void {
    console.log('onStepperValidationFailed :>> ', 'Stepper validation failed');
    const fields = this.getValidationFields(this.getFormElements(this.forms[0]));
    fields.forEach(field => {
      if (field.element) {
        FieldValidator.validateField(field.element, field.minLength);
      }
    });
  }

  private initFormValidation(): void {
    this.forms.forEach((form) => this.setupFormValidation(form as HTMLFormElement));
  }

  private setupFormValidation(form: HTMLFormElement): void {
    const elements = this.getFormElements(form);

    console.log('elements :>> ', elements);

    this.setupEmailValidation(elements.email);
    this.setupTogglePasswordVisibility(elements.togglePasswordVisibility);
    this.setupGenderSelect(form);
    this.setupAddressSwitch(
      elements.deliveryTypeInputs,
      elements.addressWrapClassicAddress,
      elements.addressWrapPacketStation,
      elements.packetStationNumber,
      elements.additionalPacketNumber
    );
    this.setupListGroupItemSelection(elements.listGroupItems, elements.deliveryTypeInputs);
    this.setupDeliveryPaymentToggle(form);

    form.addEventListener('submit', (event: Event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    });

    this.setupAdditionalValidation(elements);

    if (elements.password && elements.confirmation) {
      // Listener for confirmation field
      elements.confirmation.addEventListener('input', () => {
        this.confirmationTouched = true;
        if (this.passwordTouched && this.confirmationTouched) {
          FieldValidator.validateConfirmationField(elements.password, elements.confirmation);
        }
      });

      // Listener for password field
      elements.password.addEventListener('input', () => {
        this.passwordTouched = true;
        if (this.passwordTouched && this.confirmationTouched) {
          FieldValidator.validateConfirmationField(elements.password, elements.confirmation);
        }
      });

      // Reset flags if fields are cleared
      elements.password.addEventListener('blur', () => {
        if (elements.password.value.trim() === '') {
          this.passwordTouched = false;
        }
      });

      elements.confirmation.addEventListener('blur', () => {
        if (elements.confirmation.value.trim() === '') {
          this.confirmationTouched = false;
        }
      });
    }
  }

  private getFormElements(form: HTMLFormElement) {
    return {
      deliveryTypeInputs: form.querySelectorAll(
        '[data-selector="list-group-item-selection"] input[name="delivery_type"], input[name="delivery_type"]'
      ) as NodeListOf<HTMLInputElement>,
      genderSelect: form.querySelector('[data-selector="gender"]') as HTMLSelectElement,
      companyName: form.querySelector('[data-selector="company"]') as HTMLElement,
      addressWrapPacketStation: form.querySelector('[data-selector="address_packing_station_wrapper"]') as HTMLElement,
      addressWrapClassicAddress: form.querySelector('[data-selector="address_street_and_number_wrapper"]') as HTMLElement,
      listGroupItems: form.querySelectorAll('[data-selector="list-group-item-selection"]') as NodeListOf<HTMLElement>,
      password: form.querySelector('[name="password"]') as HTMLInputElement,
      confirmation: form.querySelector('[name="confirmation"]') as HTMLInputElement,
      togglePasswordVisibility: form.querySelectorAll('[data-selector="toggle_visibility_password"]') as NodeListOf<HTMLElement>,
      email: form.querySelector("[name='email_address']") as HTMLInputElement,
      firstname: form.querySelector("[name='firstname']") as HTMLInputElement,
      lastname: form.querySelector("[name='lastname']") as HTMLInputElement,
      telephone: form.querySelector("[name='telephone']") as HTMLInputElement,
      street: form.querySelector('[data-selector="address_street_and_number_wrapper"] [name="street_name"]') as HTMLInputElement,
      streetnumber: form.querySelector('[data-selector="address_street_and_number_wrapper"] [name="street_number"]') as HTMLInputElement,
      packetStationNumber: form.querySelector('[data-selector="address_packing_station_wrapper"] [name="packet_station_number"]') as HTMLInputElement,
      additionalPacketNumber: form.querySelector('[data-selector="address_packing_station_wrapper"] [name="additional_packet_number"]') as HTMLInputElement,
      dob: form.querySelector('[name="dob"]') as HTMLInputElement,
      postcode: form.querySelector("[name='postcode']") as HTMLInputElement,
      city: form.querySelector("[name='city']") as HTMLInputElement,
      countrySelect: form.querySelector("[data-selector='country']") as HTMLSelectElement
    };
  }

  private setupEmailValidation(email: HTMLInputElement): void {
    if (!email) return;

    email.addEventListener("input", () => {
      const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value.toLowerCase());
      email.classList.toggle("is-invalid", !isValid);
      email.classList.toggle("is-valid", isValid);
    });
  }

  private setupTogglePasswordVisibility(toggleButtons: NodeListOf<HTMLElement>): void {
    toggleButtons.forEach((button: HTMLElement) => {
      button.addEventListener('click', () => {
        const inputField = button.parentNode?.querySelector('input') as HTMLInputElement;
        const icon = button.querySelector("i");
        icon?.classList.toggle("fa-eye");
        icon?.classList.toggle("fa-eye-slash");
        inputField.type = inputField.type === "text" ? "password" : "text";
      });
    });
  }

  /**
   * Sets up the gender select functionality to toggle the visibility of the company field.
   * This method works for both modal and non‐modal forms. It uses the passed form
   * if available; otherwise, it falls back to a global document query.
   */
  private setupGenderSelect(form?: HTMLFormElement): void {
    const genderSelect = form
      ? form.querySelector('[data-selector="gender"]') as HTMLSelectElement
      : document.querySelector('[data-selector="gender"]') as HTMLSelectElement;
    const companyField = form
      ? form.querySelector('[data-selector="company"]') as HTMLElement
      : document.querySelector('[data-selector="company"]') as HTMLElement;
    if (!genderSelect || !companyField) return;

    const toggleCompanyField = (value: string) => {
      if (value === 'c') {
        companyField.classList.remove("d-none");
      } else {
        companyField.classList.add("d-none");
      }
    };
    toggleCompanyField(genderSelect.value);
    genderSelect.addEventListener('change', () => {
      toggleCompanyField(genderSelect.value);
    });
    document.addEventListener(ApoEvents.SELECTFIELD_CHANGED, (event: CustomEvent) => {
      const target = event.detail.target as HTMLInputElement;
      const targetDataSelector = target.getAttribute("data-selector");
      if (targetDataSelector === "gender" || targetDataSelector === "gender_text") {
        let effectiveValue: string;
        if (targetDataSelector === "gender_text") {
          effectiveValue = genderSelect.value;
        } else {
          effectiveValue = target.value;
        }
        toggleCompanyField(effectiveValue);
      }
    });
  }

  private setupAddressSwitch(
    deliveryTypeInputs: NodeListOf<HTMLInputElement>,
    streetAddress: HTMLElement,
    packingStation: HTMLElement,
    dhlPackstationNumber?: HTMLInputElement,
    dhlAdditionalNumber?: HTMLInputElement
  ): void {
    const updateAddressVisibility = () => {
      deliveryTypeInputs.forEach(input => {
        if (input.checked) {
          switch (input.value) {
            case 'home':
              this.showElement(streetAddress);
              this.hideElement(packingStation);
              if (dhlPackstationNumber && dhlAdditionalNumber) {
                dhlPackstationNumber.removeAttribute('required');
                dhlPackstationNumber.setCustomValidity('');
                dhlPackstationNumber.classList.remove('is-invalid', 'is-valid');
                dhlAdditionalNumber.removeAttribute('required');
                dhlAdditionalNumber.setCustomValidity('');
                dhlAdditionalNumber.classList.remove('is-invalid', 'is-valid');
              }
              break;
            case 'dhl':
              this.showElement(packingStation);
              this.hideElement(streetAddress);
              if (dhlPackstationNumber && dhlAdditionalNumber) {
                dhlPackstationNumber.setAttribute('required', 'required');
                dhlPackstationNumber.setCustomValidity('');
                dhlPackstationNumber.classList.remove('is-invalid', 'is-valid');
                dhlAdditionalNumber.setAttribute('required', 'required');
                dhlAdditionalNumber.setCustomValidity('');
                dhlAdditionalNumber.classList.remove('is-invalid', 'is-valid');
              }
              break;
            case 'hermes':
              break;
          }
        }
      });
    };

    deliveryTypeInputs.forEach(input => {
      input.addEventListener('change', updateAddressVisibility);
    });

    updateAddressVisibility();
  }

  private setupListGroupItemSelection(listGroupItems: NodeListOf<HTMLElement>, deliveryTypeInputs: NodeListOf<HTMLInputElement>): void {
    listGroupItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        deliveryTypeInputs[index].checked = true;
        deliveryTypeInputs[index].dispatchEvent(new Event('change', {bubbles: true}));
      });
    });
  }



  private getValidationFields(elements: any) {
    return [
      {element: elements.firstname, minLength: 2},
      {element: elements.lastname, minLength: 2},
      {element: elements.email, minLength: 6},
      {element: elements.password, minLength: 8},
      {element: elements.telephone, minLength: 5},
      {element: elements.street, minLength: 3},
      {element: elements.streetnumber, minLength: 1},
      {element: elements.postcode, minLength: 2},
      {element: elements.dob, minLength: 10},
      {element: elements.city, minLength: 2},
      {element: elements.packetStationNumber, minLength: 2},
      {element: elements.additionalPacketNumber, minLength: 2},
    ];
  }

  private setupAdditionalValidation(elements: any): void {
    const fields = this.getValidationFields(elements)

    fields.forEach(field => {
      if (field.element) {
        const validate = () => FieldValidator.validateField(field.element, field.minLength);
        field.element.addEventListener('change', validate);
        field.element.addEventListener('input', validate);
        field.element.addEventListener('blur', validate);
      }
    });
  }

  private showElement(element: HTMLElement): void {
    element?.classList.remove('d-none');
    element?.querySelectorAll('input').forEach(el => el.setAttribute('required', ''));
  }

  private hideElement(element: HTMLElement): void {
    element?.classList.add('d-none');
    element?.querySelectorAll('input').forEach(el => el.removeAttribute('required'));
  }

  /**
   * setupDeliveryPaymentToggle - Toggles between the DHL radio and payment address checkbox.
   * Disables one when the other is selected and adds a Bootstrap tooltip on the disabled container.
   */

  private setupDeliveryPaymentToggle(form: HTMLFormElement): void {
    const dhlRadio = form.querySelector('#chk_dhl') as HTMLInputElement;
    const paymentCheckbox = form.querySelector('#payment_address') as HTMLInputElement;
    if (!dhlRadio || !paymentCheckbox) return;

    const dhlContainer = dhlRadio.parentElement;
    const paymentContainer = paymentCheckbox.parentElement;
    if (!dhlContainer || !paymentContainer) return;

    const tooltipText = "Eine DHL-Packstation kann nicht als Rechnungsadresse verwendet werden.";
    let dhlTooltip: Tooltip | null = null;
    let paymentTooltip: Tooltip | null = null;

    const enableTooltip = (element: HTMLElement): Tooltip => {
      element.setAttribute('data-bs-toggle', 'tooltip');
      element.setAttribute('data-bs-placement', 'bottom');
      element.setAttribute('title', tooltipText);
      return new Tooltip(element);
    };

    const disableTooltip = (element: HTMLElement, tooltipInstance: Tooltip | null): void => {
      if (tooltipInstance) {
        tooltipInstance.dispose();
      }
      element.removeAttribute('data-bs-toggle');
      element.removeAttribute('data-bs-placement');
      element.removeAttribute('title');
    };

    const updateToggle = (): void => {
      if (dhlRadio.checked) {
        paymentCheckbox.setAttribute('disabled', 'disabled');
      } else {
        paymentCheckbox.removeAttribute('disabled');
      }
      if (paymentCheckbox.checked) {
        dhlRadio.setAttribute('disabled', 'disabled');
      } else {
        dhlRadio.removeAttribute('disabled');
      }

      if (dhlRadio.disabled) {
        if (!dhlTooltip) {
          dhlTooltip = enableTooltip(dhlContainer);
        }
      } else {
        disableTooltip(dhlContainer, dhlTooltip);
        dhlTooltip = null;
      }

      if (paymentCheckbox.disabled) {
        if (!paymentTooltip) {
          paymentTooltip = enableTooltip(paymentContainer);
        }
      } else {
        disableTooltip(paymentContainer, paymentTooltip);
        paymentTooltip = null;
      }
    };

    const radioGroup = form.querySelectorAll('input[name="delivery_type"]') as NodeListOf<HTMLInputElement>;
    radioGroup.forEach(radio => {
      radio.addEventListener('change', updateToggle);
    });

    paymentCheckbox.addEventListener('change', updateToggle);
    updateToggle();
  }
}