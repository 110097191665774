import React, {Context, HTMLAttributes, useState, useContext, useEffect, useRef} from "react";
import {CartOverlayContextType, defaultCartOverlayContext} from "./cart-overlay-context"
import {Cart} from "../../entity/Cart";
import {CartOverlaySlider, CartOverlaySliderApi} from "../../entity/ProductSlider"

export type CartOverlayProviderProps = {
  context: Context<CartOverlayContextType>
} & HTMLAttributes<HTMLDivElement>

let triggerCartOverlay: (show: boolean, cartData: Cart | {}, product: number, quantity: number) => void = () => {
}

let isOverlayActive: () => {}
let closeCartOverlay: () => void = () => {}
let reloadATCModal: (loading: boolean) => void = () => {}


export function CartOverlayProvider({context, children}: CartOverlayProviderProps) {
  const cartOverlaySliderApi = new CartOverlaySliderApi()
  const data = useContext(context)
  const [show, setShow] = useState(data.show)
  const [cart, setData] = useState(data.cartData)
  const [product, setProduct] = useState(data.product)
  const [quantity, setQuantity] = useState(data.quantity)
  const [slider, setSlider] = useState(data.slider)
  const [loading, setLoading] = useState(false)

  isOverlayActive = () => {
    return show
  }

  reloadATCModal = (loading: boolean) => {
    setLoading(loading)
  }


  triggerCartOverlay = (show, cartData, product, quantity) => {
    setShow(show)
    setData(cartData)
    setProduct(product)
    setQuantity(quantity)
    cartOverlaySliderApi.getData(product).then(data => {
      const cartOverlaySlider = CartOverlaySlider.fromApiObject(data)
      setSlider(cartOverlaySlider.cartOverlaySliderItems)
    })
  }

  closeCartOverlay = () => {
    setShow(defaultCartOverlayContext.show)
    setData(defaultCartOverlayContext.cartData)
    setProduct(defaultCartOverlayContext.product)
  }

  return (<context.Provider
    value={{show: show, cartData: cart, product: product, quantity: quantity, slider: slider, closeCartOverlay, loading:loading}}>{children}</context.Provider>)
}

export {triggerCartOverlay, isOverlayActive, closeCartOverlay, reloadATCModal}