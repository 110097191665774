import type { Hit as AlgoliaHit } from "instantsearch.js/es/types";
import CustomHitObject from "./CustomHitObject";

export type AlgoliaHitExtended = AlgoliaHit<{
  name: string;
  image_url: string;
  eyecatcher: string;
  pzn: number;
  category: string;
  category_ids: object;
  amount_unit: string;
  price_no_format: number;
  avp_no_format: number;
  avp_formatted: string;
  price_formatted: string;
  product_url: string;
  rating: number;
  rating_count: string;
  status_text: string;
  status_number: number;
  herbal: number;
  homeopathic: number;
  prescription_required: number;
  presentation_form: string;
  status_information: string;
  base_price: string;
  products_id: string;
  alternative_package_sizes: [];
}>;

export type CustomHitProps = {
  hit: AlgoliaHitExtended;
};

export const CustomHit = ({ hit }: CustomHitProps) => {
  return <CustomHitObject hit={hit} />;
};
