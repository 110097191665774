import React, {useEffect, useRef, useState, useContext, useMemo, memo, ReactElement} from "react";
import {EqualHeightContext} from "../../state/EqualHeight";
import styles from "./equal-height.modules.scss";

export type Props = {
  children?: React.ReactNode;
  name: string;
  tag?: string;
  placeholder?: boolean;
  disable?: boolean;
} & React.HTMLAttributes<HTMLElement>

export const EqualHeightElement = memo((props: Props): ReactElement | null => {
  const {
    children = '',
    name,
    tag = 'div',
    placeholder = false,
    disable = false
  } = props;

  const {
    sizes,
    update,
    setTemporarySizes,
    setOriginalChildrenCount,
    setChildrenCount,
    animationSpeed
  } = useContext(EqualHeightContext);

  const [height, setHeight] = useState<number>();
  const innerElement = useRef<HTMLDivElement>(null);

  const getHeight = () => {
    if (!innerElement.current || disable) {
      return;
    }
    const tempHeight: string = innerElement.current.style.getPropertyValue('height');
    innerElement.current.style.removeProperty('height');
    const newHeight: number = innerElement.current.offsetHeight;
    innerElement.current.style.setProperty('height', tempHeight);
    setTemporarySizes(values => {
      return [...values, {
        name,
        height: newHeight
      }]
    });

    if (!disable) {
      setChildrenCount(value => value + 1);
    }
  };

  useEffect(() => {
    if (disable) {
      return;
    }

    setOriginalChildrenCount(value => value + 1);
    return () => {
      setOriginalChildrenCount(value => value - 1);
    };
  }, [disable, placeholder]);

  useEffect((): void => {
    if (disable) {
      return;
    }

    getHeight();
  }, [update, disable, placeholder]);

  useMemo((): void => {
    if (disable) {
      return;
    }

    const elementIndex: number = sizes.findIndex((e) => e.name === name);

    if (sizes && sizes[elementIndex] && sizes[elementIndex].height) {
      setHeight(sizes[elementIndex].height);
    }
  }, [sizes]);

  const inlineStyles: React.CSSProperties = {
    height: `${height}px`,
    transitionDuration: animationSpeed === 0 ? '' : `${animationSpeed}s`
  };

  if (!placeholder && !children) {
    return null;
  }

  return (
    <>
      {disable ? (
        children
      ) : (
        React.createElement(tag, {
          ref: innerElement,
          className: styles.wrapper,
          style: inlineStyles
        }, !placeholder && children)
      )}
    </>
  );
});
