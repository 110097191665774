import React, {createRef, useState, Dispatch, SetStateAction, useEffect} from "react";
import {ProductReminderSuggest} from "@apo-app/app/entity/ProductReminder/product-reminder-types";
import {CartItemImageType} from "@apo-app/app/entity/Cart";
import {useKeyPress} from "@apo-app/app/hooks/useKeyPress";
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "../../../../../../../general/assets/javascript/app/hooks/useLocalStorage";

type Props = {
    handleProductSearch: (value: string) => void
    handleGetProduct: (id: string) => void
    inputValue: string,
    resultsSearch: ProductReminderSuggest[]
};

type ListItemType = {
    item: itemType,
    active: boolean,
    setSelected: Dispatch<SetStateAction<SetStateAction<itemType | undefined>>>,
}

type itemType = {
    product_id: string,
    product_image: CartItemImageType,
    suggest: string,
    pzn?: string
}


export const ProductReminderCreateFindProduct = (props: Props) => {
    const [inputValue, setInputValue] = useState('')
    const searchBox = createRef<HTMLInputElement>()
    const [selected, setSelected] = useState<React.SetStateAction<itemType | undefined>>(undefined);
    const downPress = useKeyPress("ArrowDown", searchBox);
    const upPress = useKeyPress("ArrowUp", searchBox);
    const enterPress = useKeyPress("Enter", searchBox);
    const [cursor, setCursor] = useState<number>(0);
    const [activeProduct, setActiveProduct] = useLocalStorage('activeProduct', '');

    const {t} = useTranslation();

    const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
        const inputValue = event.currentTarget.value
        setInputValue(inputValue)
        props.handleProductSearch(inputValue)
    }

    useEffect(() => {
        if (props.resultsSearch.length && downPress) {
            setCursor(prevState =>
                prevState < props.resultsSearch.length - 1 ? prevState + 1 : prevState
            );
            setActiveProduct('')
        }
    }, [downPress]);


    useEffect(() => {
        if (props.resultsSearch.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
          setActiveProduct('')
        }
    }, [upPress]);


    useEffect(() => {
        if (props.resultsSearch.length && enterPress || props.resultsSearch.length) {
            setSelected(props.resultsSearch[cursor])
            enterPress && getProduct(props.resultsSearch[cursor].product_id)
        }
    }, [cursor, enterPress]);


    const getProduct = (id: string) => {
        props.handleGetProduct(id)
        setActiveProduct(id)
        setSelected(undefined)
    }


    const ListItem = ({item, active}: ListItemType) => (
        <a
            className={`d-flex align-items-start item ${activeProduct === '' && active ? "active" : ""} ${activeProduct===item.product_id ? 'active':''}`}
            onClick={() => getProduct(item.product_id)}
            key={item.product_id}
            data-selector="search-result"
        >
            <img src={item.product_image.image_size_50_50} alt={item.product_image.alt_text}/>
            <div className="d-flex flex-column">
                <span dangerouslySetInnerHTML={{__html: item.suggest}} className="px-spacing-8 pt-spacing-16 "/>
                <p className="px-spacing-8 pt-spacing-16 ">Pzn: {item.pzn}</p>
            </div>
        </a>
    );


    return (
        <>
            <div
                className="container container-extra bg-aco-dark-blue border-rounded-bottom-32 text-aco-white py-spacing-24 py-md-spacing-48">
                <div className="d-flex justify-content-center w-100">
                    <div className="col-12 col-lg-10">
                        <h1 className="h3-xs h1-md mb-spacing-0">{t('productReminder.create.findProduct.headline')}</h1>
                    </div>
                </div>
            </div>
            <div className="container pt-spacing-32">
                <div className="row row-cols-12 align-items-center justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div
                                className="account-reminder-search-field max-width-sm input-group input-product-interaction rounded-pill"
                                id="account_reminder_search_field">
                                <input id="product_search_field"
                                       className="form-control text-aco-blue"
                                       maxLength={50} type="text"
                                       autoComplete="off"
                                       placeholder="Medikament suchen..."
                                       onChange={handleSearch}
                                       value={inputValue}
                                       ref={searchBox}
                                       autoFocus
                                />
                                <span className="input-group-text text-aco-blue me-spacing-8">
                <i className="far fa-search"/>
              </span>
                            </div>
                            {props.resultsSearch.length !== 0 && (
                                <div id="autosuggest-wrapper" className="col-12 col-md-8">
                                    <h2 className="text-aco-dark-blue my-spacing-24">{t('productReminder.create.findProduct.searchResult')}</h2>
                                    {props.resultsSearch.map((result, i) => (
                                        <ListItem
                                            key={result.product_id}
                                            item={result}
                                            active={i === cursor || result.active}
                                            setSelected={setSelected}/>
                                    ))}
                                </div>
                            )}
                            {props.resultsSearch.length === 0 && inputValue.length > 2 && (
                                <div
                                    className="mt-spacing-24">{t('productReminder.create.findProduct.nothingFound')}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}