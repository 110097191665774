declare global {
  interface Window {
    _klarnaCheckout: any
  }
}

export class KlarnaCheckout {
  private readonly klarnaCheckout

  constructor(klarnaCheckout: Element|null) {
    if (klarnaCheckout) {
      this.klarnaCheckout = <HTMLElement>klarnaCheckout
      this.handleKlarnaExpressService()
    }
  }

  private handleKlarnaExpressService() {
    const klarnaExpressServiceElement = this.klarnaCheckout?.querySelector('div[data-klarna-express-service]')
    if (!(klarnaExpressServiceElement instanceof HTMLDivElement)) {
      return
    }
    try {
      const actionButton = this.getExpressServiceActionButton()
      const skipButton = this.getExpressServiceSkipButton()
      const klarnaCheckoutElement = this.getCheckoutElement()
      KlarnaCheckout.disableCheckoutElement(klarnaCheckoutElement)
      actionButton.addEventListener('click', () => {
        // Use express service
        fetch('ajax/checkout_klarna_express_service.php')
          .then(r => r.json())
          .then(() => {
            // Update Klarna Checkout Snippet
            if (typeof(window._klarnaCheckout) == 'function') {
              window._klarnaCheckout(function(api: any) {
                api.resume()
              })
            }
            klarnaExpressServiceElement.style.display = 'none'
            KlarnaCheckout.enableCheckoutElement(klarnaCheckoutElement)
          })
      })
      skipButton.addEventListener('click', () => {
        // Do not use express service
        klarnaExpressServiceElement.style.display = 'none'
        KlarnaCheckout.enableCheckoutElement(klarnaCheckoutElement)
      })
    } catch(e) {
      console.error('Module KlarnaCheckout: ' + e)
    }
  }

  private static disableCheckoutElement(klarnaCheckoutElement: HTMLDivElement) {
    klarnaCheckoutElement.style.setProperty('pointer-events', 'none', 'important')
    klarnaCheckoutElement.style.setProperty('cursor', 'not-allowed')
    klarnaCheckoutElement.style.setProperty('opacity', '0.5')
  }

  private static enableCheckoutElement(klarnaCheckoutElement: HTMLDivElement) {
    klarnaCheckoutElement.style.removeProperty('pointer-events')
    klarnaCheckoutElement.style.removeProperty('cursor')
    klarnaCheckoutElement.style.removeProperty('opacity')
  }

  private getExpressServiceActionButton(): HTMLButtonElement {
    const actionButton = this.klarnaCheckout?.querySelector('button[data-klarna-button-apply]')
    if (actionButton instanceof HTMLButtonElement) {
      return actionButton
    }
    throw new Error('HTMLElement button with data attribute `data-klarna-button-apply` does not found in template')
  }

  private getExpressServiceSkipButton(): HTMLButtonElement {
    const skipButton = this.klarnaCheckout?.querySelector('button[data-klarna-button-skip]')
    if (skipButton instanceof HTMLButtonElement) {
      return skipButton
    }
    throw new Error('HTMLElement button with data attribute `data-klarna-button-skip` does not found in template')
  }

  private getCheckoutElement(): HTMLDivElement {
    const klarnaCheckoutElement = this.klarnaCheckout?.querySelector('div[data-klarna-checkout-form]')
    if (klarnaCheckoutElement instanceof HTMLDivElement) {
      return klarnaCheckoutElement
    }
    throw new Error('HTMLElement div with data attribute `data-klarna-checkout-form` does not found in template')
  }
}