const $producsArray = document.querySelectorAll("[data-selector='product-watch-list']");

const setTotalPrice = (product, input) => {
    const singlePrice = product.dataset.singlePrice;
    const $totalPrice = product.querySelector("[data-selector='total-price']");

    if(singlePrice && $totalPrice) {
        const singlePriceParsed = parseFloat(singlePrice.replace(',', '.')).toFixed(2);
        const quantityParsed = parseInt(input.value);
        const priceFixed = (singlePriceParsed * quantityParsed).toFixed(2).replace('.', ',');
        $totalPrice.innerHTML = `${priceFixed} €<sup>*</sup>`;
    }
}

const setCartButtonQuantity = (product, quantity) => {
    const $cartButton = product.querySelector("[data-selector='cart-button']");
    if($cartButton) {
        $cartButton.setAttribute('quantity', quantity);
    }
}

$producsArray.forEach($product => {
    const $quantityInput = $product.querySelector("[data-stepper-input='product-quantity']");

    if($quantityInput) {
        $quantityInput.addEventListener('change', () => {
            setTotalPrice($product, $quantityInput);
            setCartButtonQuantity($product, $quantityInput.value);
        });

        setTotalPrice($product, $quantityInput);
        setCartButtonQuantity($product, $quantityInput.value);
    }
})
