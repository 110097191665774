import { useTranslation } from "react-i18next";
import { ClearRefinements } from "react-instantsearch";

export const NoResultsByFilter = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex bg-aco-gray-06 border-rounded-12 p-spacing-16">
      <div className="alert pe-spacing-16 pt-spacing-0">
        <div className="alert-icon"></div>
      </div>
      <div className="text-aco-dark-blue">
        <p className="fw-bold mb-spacing-0">{t("search.noResults.byFilter.paragraph1")}</p>
        <p>{t("search.noResults.byFilter.paragraph2")}</p>
        <p className="mb-spacing-24">{t("search.noResults.byFilter.paragraph3")}</p>
        <div className="text-center">
          <ClearRefinements
            classNames={{
              button: "btn btn-primary w-100 btn-lg mb-spacing-24",
            }}
            translations={{
              resetButtonText: t("search.filter.button.reset") as string,
            }}
          />
        </div>
      </div>
    </div>
  );
};
