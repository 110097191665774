import {createContext} from 'react'
import {Cart, Prescriptions} from "../../entity/Cart";

export type CartContextType = {
  /**
   * The Cart
   */
  cartData: Cart
  /**
   * adds products to cart
   */
  addProductToCart: (id: number, qty: number) => void
  /**
   * removes products from cart
   */
  removeProductFromCart: (id: number) => void
  /**
   * removes products with given prescription type from cart
   */
  removePrescriptionProductFromCart: (id: number, filteredPrescription: Prescriptions[]) => void
  /**
   * changes the products quantity in cart
   */
  changeProductCartQty: (id: number, qty: number) => void
  /**
   * Apply a given coupon code
   */
  applyCouponCode: (form: HTMLFormElement) => void
  /**
   * Remove the redeemed coupon code
   */
  removeCouponCode: (form: HTMLFormElement) => void
  /**
   * Apply a given BonusPoints value
   */
  applyBonusPoints: (form: HTMLFormElement) => void
  /**
   * Remove applied bonus points from order
   */
  removeBonusPoints: () => void
  /**
   * Initial loading indicator
   */
  initial: boolean
}

export const defaultContext: CartContextType = {
  cartData: {
    amazon:{
      AMAZON_ADVANCED_PAYMENT_CLIENT_ID: '',
      AMAZON_ADVANCED_PAYMENT_MODE: '',
      AMAZON_ADVANCED_PAYMENT_MERCHANT_ID: 'amzn1.application-oa2-client.f0d808543a1142059c1dbdec8cbafc87',
      AMAZON_ADVANCED_PAYMENT_SESSION: '',
      AMAZON_ADVANCED_OPEN_WINDOW: '',
      AMAZON_ADVANCED_PAYMENT_GOTO: '',
      AMAZON_ADVANCED_ID: '',
      AMAZON_ADVANCED_RETURN: '',
    },
    links: {
      LOGIN_LINK: '/',
      CREATE_ACCOUNT_LINK: '/',
      CHECKOUT_LINK: '/',
      CART_LINK: '/',
      CONTINUE_SHOPPING_LINK: '/',
      AMAZON_ADVANCED_PAYMENT_FEE: '/',
      AMAZON_ADVANCED_PAYMENT_LINK: '/',
      AMAZON_ADVANCED_PAYMENT_GOTO: '/',
      PAYPAL_ADVANCED_PAYMENT_FEE: '/',
      PAYPAL_ADVANCED_PAYMENT_LINK: '/',
      ADD_CART_TO_WATCH_LIST_LINK: '/',
    },
    orderSubTotal: '',
    orderTotalSaving: '',
    orderTotal: '',
    orderVoucher: '',
    couponCode: [],
    initCheckoutMethods: [],
    shippingCostsCalc: '',
    freeShipping: false,
    untilFreeShipping: '',
    freeShippingByAmount: '',
    customsTariffExtraChargeTitle: '',
    customsTariffExtraChargeText: '',
    loggedIn: false,
    orderBonusPoints: [],
    accountBonusPoints: '',
    orderPrescriptionBonusPoints: [],
    orderBonusPointsCashing: [],
    orderBonusPointsInfo: [],
    cartMessage: '',
    cartItemsAmount: 0,
    cartItems: []
  },
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  removePrescriptionProductFromCart: () => {},
  changeProductCartQty: () => {},
  applyCouponCode: () => {},
  removeCouponCode: () => {},
  applyBonusPoints: () => {},
  removeBonusPoints: () => {},
  initial: true,
}

export const CartContext = createContext<CartContextType>(defaultContext)