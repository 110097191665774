import DatePicker, {registerLocale} from "react-datepicker";
import de from 'date-fns/locale/de';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  handleStartingDate:Function
}

export const ProductReminderCreateDate = (props:Props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDateFormat, setStartDateFormat] = useState({});
  const {t} = useTranslation()

  useEffect(() => {
    handleDate()
  }, [startDateFormat]);

  registerLocale('de', de)

  const saveStartingDate = (date:Date) => {
    const dateFormat = date.toLocaleString('de-DE',{year:"numeric",month:"2-digit",day:"2-digit"})
    setStartDate(date)
    props.handleStartingDate(dateFormat)
  }

  const handleDate = () => {
    const dateFormat = startDate.toLocaleString('de-DE',{year:"numeric",month:"2-digit",day:"2-digit"})
    setStartDateFormat(dateFormat)
    props.handleStartingDate(dateFormat)
  }

  return (
    <div className="stepper d-flex justify-content-center align-items-center">
      <div className="w-100">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <fieldset className="text-aco-dark-blue fw-bold mb-spacing-20">
            {t('productReminder.create.date.whenStart')}
          </fieldset>
          <DatePicker
          selected={startDate}
          onChange={(date) => date && saveStartingDate(date)}
          inline
          locale="de"
        />
        </div>
      </div>
    </div>
  );
}