import { ApoEvents } from "./apoEvents";

declare const DYO: {
  ActiveConsent: {
    updateConsentAcceptedStatus: (status: boolean) => void;
  };
};

export class ApoDynamicYieldController {

  constructor() {
    /**
     * Event listener for UserCentrics consent status changes.
     * This function handles the consent status change event and updates the Dynamic Yield consent status accordingly.
     *
     * @param {Event} e - The event object containing the consent status details.
     */
    document.addEventListener(ApoEvents.USERCENTRICS_CONSENT_STATUS_CHANGED, (e) => {
      const scriptTagDy: HTMLScriptElement | null = document.querySelector('script[data-usercentrics="Dynamic Yield"]');

      if (!scriptTagDy) {
        throw new Error('Dynamic Yield script tag not found');
      }

      const dyIdInUc: string | null = scriptTagDy.getAttribute('data-usercentrics-id');

      if (!dyIdInUc) {
        throw new Error('UserCentrics ID for Dynamic Yield not found');
      }

      const dyService: { id: string; consent: { status: boolean } } | undefined = e.detail.services.find((service: { id: string }) => service.id === dyIdInUc);

      if (typeof dyService === 'undefined') {
        throw new Error('Dynamic Yield service not found in UserCentrics services');
      }

      const dyConsentStatus: boolean = dyService?.consent?.status || false;

      window.DY.userActiveConsent = { accepted: dyConsentStatus };
      window.localStorage.setItem("dyUserActiveConsent", JSON.stringify(dyConsentStatus));

      if (DYO?.ActiveConsent?.updateConsentAcceptedStatus) {
        DYO.ActiveConsent.updateConsentAcceptedStatus(dyConsentStatus);
      } else {
        throw new Error('DYO Object not found');
      }

      if (!dyConsentStatus) {
        this.removeCookie('_dyid_server');
      }
    });
  }

  /**
   * Removes a cookie by name.
   *
   * @param {string} name - The name of the cookie to remove.
   */
  private removeCookie(name: string): void {
    document.cookie = `${name}=; Max-Age=0; path=/; domain=${window.location.hostname}`;
  }
}