import React, {useEffect, useState} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ProductReminderNewProduct} from "@apo-app/app/entity/ProductReminder/product-reminder-types";
import {ProductReminderInputGroup} from "@apo-app/apo-responsive/ui/ProductReminder";


interface Props {
  resultProduct?: ProductReminderNewProduct,
  setActiveStep: Function
  handleProductReminder: Function
  setStep: (step: number) => void
}

export const ProductReminderCreateDosage = (props: Props) => {

  const [inputValueNumberUnits, setInputValueNumberUnits] = useState(1)
  const [inputValueUnitCoefficient, setInputValueUnitCoefficient] = useState(1)
  const [inputValueResult, setInputValueResult] = useState(0)
  const {t} = useTranslation()

  const getProductReminder = (productReminder: {}) => {
    props.handleProductReminder(productReminder)
  }


  useEffect(() => {
    props.resultProduct && calculateDuration()
  }, [inputValueNumberUnits, inputValueUnitCoefficient]);


  const deCreaseUnit = () => {
    inputValueNumberUnits > 1 && setInputValueNumberUnits(inputValueNumberUnits - 1)
    props.resultProduct && calculateDuration()
  }

  const inCreaseUnit = () => {
    setInputValueNumberUnits(inputValueNumberUnits + 1)
    props.resultProduct && calculateDuration()
  }

  const handleInputValueNumberUnits = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    const valueNumber = parseFloat(value)

    value.length >= 1 && valueNumber > 0
      ? setInputValueNumberUnits(valueNumber)
      : setInputValueNumberUnits(prevState => prevState)
    props.resultProduct && calculateDuration()
  }

  const handleInputCoefficientChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {name, min, max} = e.currentTarget
    const input = Math.max(parseFloat(min), Math.min(parseFloat(max), parseFloat(e.currentTarget.value)));
    //TODO dont reload data if comp is rendered
    !isNaN(input) ? setInputValueUnitCoefficient(input) : setInputValueUnitCoefficient(prevState => prevState)
  }

  const sendProductReminder = () => {
    //send data to parent
    props.resultProduct && (
      getProductReminder({
        'qty_per_dosage': inputValueNumberUnits,
        'qty_per_day': inputValueUnitCoefficient,
        'amount': Number(props.resultProduct.PRODUCTS_AMOUNT),
        'id': Number(props.resultProduct.products_id),
        'days': Math.round(Number(props.resultProduct.PRODUCTS_AMOUNT) / (inputValueNumberUnits * inputValueUnitCoefficient))
      })
    )
  }

  const calculateDuration = () => {
    const amount = props.resultProduct && Number(props.resultProduct.PRODUCTS_AMOUNT)
    amount && setInputValueResult(Math.round(amount / (inputValueNumberUnits * inputValueUnitCoefficient)))
    sendProductReminder()
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    e.code === "Enter" && props.setActiveStep(1)
  }

  const popover = (
    <Popover id="popover-positioned-scrolling-top" title="Bezeichnung Erinnerung">
      <div className="popover fade show bs-popover-top" role="tooltip">
        <button className="btn btn-close btn-close-aco-blue close"/>
        <div className="popover-arrow"></div>
        <div className="popover-body">{t('productReminder.create.dosage.popoverInfo')}
        </div>
      </div>
    </Popover>
  );

  return (
    <>
      {props.resultProduct && (
        <div className="container d-flex justify-content-center">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <section>
                  <fieldset className="text-aco-dark-blue fw-bold mb-spacing-20">{t('productReminder.create.dosage.howManyUnits')}
                  </fieldset>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-sm-row flex-column gap-spacing-24 w-100">
                      <div className="input-group stepper">
                        <ProductReminderInputGroup
                          deCrease={deCreaseUnit}
                          inCrease={inCreaseUnit}
                          value={inputValueNumberUnits}
                          handleChange={handleInputValueNumberUnits}
                          handleKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="d-flex align-items-center flex-grow-1">
                        <div className="d-flex align-items-end w-100">
                          <div className="form-input-wrapper d-flex justify-content-center w-100 mt-spacing-16 mt-md-spacing-0">
                            <input id="qty_per_dosage"
                                   className="form-control w-100 flex-grow-1 qty-per-dosage"
                                   type="number"
                                   onChange={handleInputCoefficientChange}
                                   value={inputValueUnitCoefficient}
                                   step="0.5"
                                   min='0.5'
                                   max={Number(props.resultProduct.PRODUCTS_AMOUNT)}
                                   autoFocus
                                   onKeyDown={handleKeyPress}
                            />
                            <label className="form-label floating-label">
                              {t('productReminder.create.dosage.unitsPerUse', {units: props.resultProduct.PRODUCTS_UNIT})}
                            </label>
                          </div>
                          <OverlayTrigger
                            container={this}
                            trigger="focus"
                            placement="top"
                            overlay={popover}
                          >
                            <button className="btn btn-sm p-spacing-0 ms-spacing-8"
                                    type="button">
                              <i className="far fa-circle-info text-aco-blue"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                        <button className="btn btn-sm p-spacing-0 ms-spacing-8"></button>
                      </div>

                    </div>
                  </div>
                </section>
                <div className="mt-spacing-16 d-flex gap-spacing-4 align-items-center">
                  <i className="far fa-circle-check me-spacing-6 text-aco-green"></i>
                  <div>{t('productReminder.create.dosage.calculatedResult', {result: inputValueResult})}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <button className="btn btn-lg btn-primary w-100 mt-spacing-32"
                      onClick={() => props.setActiveStep(1)}>{t('productReminder.create.nextStep')}
              </button>
              <button className="btn btn-lg btn-secondary w-100 mt-spacing-32"
                      onClick={() => props.setStep(0)}>{t('productReminder.create.buttonBack')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}