import React from "react";
import {ProductReminderNewProduct} from "@apo-app/app/entity/ProductReminder/product-reminder-types";
import {useTranslation} from "react-i18next";

interface Props {
  resultProduct?: ProductReminderNewProduct
}

export const ProductReminderProductCard = (props: Props) => {
  const {t} = useTranslation()
  return (
    <>
      {props.resultProduct && (
        <div className="container d-flex justify-content-center">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-center align-items-center w-100">
              <img src={props.resultProduct.PRODUCTS_IMAGE.image_size_50_50} alt={props.resultProduct.PRODUCTS_IMAGE.alt_text}/>
              <div className="ms-spacing-12">
                <div className="text-aco-dark-blue fw-bold mb-spacing-8"> {props.resultProduct?.PRODUCTS_NAME}</div>
                <div className="d-flex flex-column text-aco-gray-01 footnote">
                  <span>{t('productReminder.create.product.presentation', { presentation: props.resultProduct.PRODUCTS_PRESENTATION })}</span>
                  <span>{t('productReminder.create.product.packageSize', { packageSize: props.resultProduct.PRODUCTS_INHALT })}</span>
                  <span>{t('productReminder.create.product.pzn', { pzn: props.resultProduct.PRODUCTS_MODEL })}</span>
                </div>
              </div>
            </div>
            <hr className="product-information-divider"></hr>
          </div>
        </div>
      )
      }
    </>
  )
}