/**
 * Map used to translate the label texts in the current refinement badges
 */
export const currentRefinementLabelMap: {[index: string]: string} = {
  sort_by: "Sortierung",
  amount_unit: "Packungsgröße",
  brand: "Marke",
  manufacturer: "Hersteller",
  presentation_form: "Darreichungsform",
  products_type: "Produkttyp",
  rating: "Bewertungen",
  prescription_required: "Verordnung",
  subscription_status: "Sparabo",
  price_no_format: "Preis",
  herbal: "Produkttyp",
  single_ingredient: "Produkttyp",
  generic: "Produkttyp",
  classic: "Produkttyp",
  homeopatic: "Produkttyp"
}

/**
 * Map used to translate the value-labels inside the sidebars "Produkttyp" field
 */
export const productTypeLabelMap: {[index: string]: string} = {
  0: "Monopräparat"
}

/**
 * Map used to translate the value-labels inside the sidebars "Verordnung" field
 */
export const prescriptionTypeLabelMap: {[index: string]: string} = {
  0: "nicht rezeptpflichtig",
  1: "rezeptpflichtig"
}

/**
 * Map used to translate the value-labels inside the sidebars "Sparabo" field
 */
export const subscriptionLabelMap: {[index: string]: string} = {
  1: "nur Spar-Abo Produkte"
}

/**
 * Map used to translate the value-labels inside the sidebars "generic" field
 */
export const genericLabelMap: {[index: string]: string} = {
  1: "Generikum"
}

/**
 * Map used to translate the value-labels inside the sidebars "homeopatic" field
 */
export const homeopaticLabelMap: {[index: string]: string} = {
  1: "Homöopathisch"
}

/**
 * Map used to translate the value-labels inside the sidebars "single_ingredient" field
 */
export const singleIngredientLabelMap: {[index: string]: string} = {
  1: "Monopräparat"
}

/**
 * Map used to translate the value-labels inside the sidebars "herbal" field
 */
export const herbalLabelMap: {[index: string]: string} = {
  1: "Pflanzliches Produkt"
}

/**
 * Map used to translate the value-labels inside the sidebars "classic" field
 */
export const classicLabelMap: {[index: string]: string} = {
  1: "Klassisches Produkt"
}