import {LitElement, html} from 'lit';
import { TemplateResult } from 'lit-html';
import {property, state} from 'lit/decorators.js';
import {IconDefinition} from "@fortawesome/fontawesome-common-types"

export class RatingStars extends LitElement {
  private stars: any[]

  constructor() {
    super();
    this.stars = new Array(this.totalStars).fill(null)
  }

  @state()
  totalStars = 5

  @property({
    converter: (value) => {
      if (value) {
        return parseFloat(
          value.replace(',', '.')
        )
      }
      return undefined
    }
  })
  average: number = 0

  @property({type: String})
  size: "sm" | "md" | "lg" | "xl" = "md"

  protected render() {
    return html`<span class="rating-star-size-${this.size}">
      ${this.getStars().map((icon, index) => (
        html`<span data-value="${Number(index) + 1}">${icon}</span>`
      ))}
    </span>`
  }

  protected getStars(): IconDefinition[] | TemplateResult[] {
    let ratingBar: TemplateResult[] | IconDefinition[] = []
    const fullStars = Math.trunc(this.average)
    const partialStar = this.average - fullStars
    this.stars.forEach((_, index) => {
       if (index < fullStars) {
         ratingBar[index] = ratingStarsOptions.iconStarSolid
       } else if (index <= fullStars && partialStar !== 0) {
         ratingBar[index] = ratingStarsOptions.iconStarHalf
       } else {
         ratingBar[index] = ratingStarsOptions.iconStarRegular
       }
     }
    )
    return ratingBar
  }

  protected createRenderRoot() {
    return this;
  }

  public updateStars(stars: number = 0) {
    this.average = stars
  }
}

export type RatingStarsOptionsType = {
  iconStarSolid: IconDefinition
  iconStarHalf: IconDefinition
  iconStarRegular: IconDefinition
}

export type RatingStarsTemplateResultsType = {
  iconStarSolid: TemplateResult
  iconStarHalf: TemplateResult
  iconStarRegular: TemplateResult
}

let ratingStarsOptions: RatingStarsTemplateResultsType | RatingStarsOptionsType;

export const initRatingStarsComponent = (options: RatingStarsTemplateResultsType | RatingStarsOptionsType = {
  iconStarSolid: html`
    <svg class="svg-inline--fa fa-star">
      <use xlink:href="#icon_rating-star-solid"></use>
    </svg>`,
  iconStarHalf: html`
    <svg class="svg-inline--fa fa-star-half-stroke">
      <use xlink:href="#icon_rating-star-half"></use>
    </svg>`,
  iconStarRegular: html`
    <svg class="svg-inline--fa fa-star">
      <use xlink:href="#icon_rating-star"></use>
    </svg>`
}) => {
  ratingStarsOptions = options
  window.customElements.define('rating-stars', RatingStars)
}