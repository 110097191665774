import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

type Props = {
  getClick: () => void
}
export const ProductReminderOverviewAdd = (props: Props) => {

  const addNewReminderButton = useCallback((inputElement: HTMLDivElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleKey = (e:any) => {
    e.key === "Enter" && props.getClick()
  }

  const {t} = useTranslation();


  return (
    <div className="row row-cols-12 align-items-center justify-content-center mt-spacing-32" >
      <div className="col-12 col-md-8">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="btn btn-primary btn-lg mb-spacing-32 w-100" role="button" tabIndex={0} onClick={props.getClick} ref={addNewReminderButton} onKeyDown={handleKey}>
            <i className="far fa-alarm-clock fa-xl btn-start-icon"></i>
            {t('productReminder.overview.createNewReminder')}
          </div>
        </div>
      </div>
    </div>
  )
}