const getPoints = (radius: any) => {
  const precision = 64
  return [...Array(precision)].map((_, i) => {
    const a = -i / (precision - 1) * Math.PI * 2
    const x = Math.cos(a) * radius + 50
    const y = Math.sin(a) * radius + 50
    return `${x}% ${y}%`
  })
}

const getRadius = (element: any) => {
  if (element.classList.contains('spinner-sm')) {
    return 42
  } else if (element.classList.contains('spinner-lg')) {
    return 39
  }
  return 40
}

export const calculateSpinnerCircles = () => {
  document.querySelectorAll('.spinner-border').forEach((element: any) => {
    const radius = getRadius(element)
    const points = getPoints(radius)
    element.style.clipPath = `polygon(100% 50%, 100% 100%, 0 100%, 0 0, 100% 0, 100% 50%, ${points.join(',')})`
  })
}

export const calculateSpinnerCircleById = (id: string) => {
  const element: HTMLElement | null = document.querySelector(`#${id}.spinner-border`);
  if (element) {
    const radius = getRadius(element);
    const points = getPoints(radius);
    element.style.clipPath = `polygon(100% 50%, 100% 100%, 0 100%, 0 0, 100% 0, 100% 50%, ${points.join(',')})`
  }
}

calculateSpinnerCircles();
