import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {EqualHeight} from "@apo-app/app/components/EqualHeight";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from "swiper/modules";
import {ProductReminderList} from "@apo-app/app/entity/ProductReminder";

interface Props {
  products: ProductReminderList
}


export const ProductReminderSlider = (props: Props) => {
  const nextRef = useRef<HTMLDivElement>(null)
  const prevRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [loadSlide, setLoadSlide] = useState<boolean>(false)
  const {t} = useTranslation()
  const slides = props.products.REMINDER_LIST

  return (
    <>
      <EqualHeight updateOnChange={loadSlide}>
        <Swiper
          data-slider="products"
          className="p-spacing-0"
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: prevRef.current!,
            nextEl: nextRef.current!,
          }}
          pagination={{
            clickable: true,
            el: paginationRef.current!
          }}
          keyboard={{enabled: true}}
          spaceBetween={16}
          slidesPerView={3}
          breakpoints={{
            600: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 5,
            },
            1200: {
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
        >
          {slides.map((el, index) => (
            <SwiperSlide key={index} onLoad={(): void => setLoadSlide(true)} className="reminder-item">
              <div className="reminder-item-days justify-content-center">
                <div className="info-counter text-center">
                  <span className="counter">
                      <span className="content">
                        <h4 className="mb-spacing-0">{el.NEXT_SCHEDULED_DATE_DIFF_IN_DAYS} Tage</h4>
                      </span>
                  </span>
                  <div className="reminder-item-name footnote mt-spacing-16">
                    {el.PRODUCTS.map(el => (<div className="info" key={el.PRODUCTS_MODEL}>{el.PRODUCTS_NAME}</div>))}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="d-flex justify-content-center align-items-center mb-spacing-4 swiper-controls-wrapper">
            <div className="d-flex justify-content-between align-items-center swiper-controls">
              <div ref={paginationRef} className="swiper-pagination"></div>
            </div>
          </div>
        </Swiper>
      </EqualHeight>
    </>
  )
}