export const Subcategories = () => {
  const subcategories = window.__SUB_CATEGORIES__;
  const categoryName = window.__CATEGORY_NAME__;
  const parentCategoryInfo = window.__PARENT_CATEGORY_INFO__;

  if (!subcategories) return <></>;

  return (
    <div id="filter_wrapper">
      <div id="categorie_sub_menu" className="d-flex flex-column mb-spacing-24">
        {parentCategoryInfo?.name && (
          <>
            <a className="bold pb-spacing-0" href={`${parentCategoryInfo.url}`}>
              <i className="far fa-chevron-left me-spacing-2"></i> {parentCategoryInfo.name}
            </a>
            <hr />
          </>
        )}
        <p id="subcategory-name" className="pb-spacing-12">
          {categoryName}
        </p>
        {subcategories.map((moduleData: any, index: number) => (
          <div key={index} className="categorie_sub_menu_cell ms-spacing-8  mt-spacing-8 mb-spacing-8">
            <a className="categorie_sub_menu_cell_inner" href={moduleData.CATEGORIES_LINK} title={moduleData.CATEGORIES_NAME}>
              <div id="subcategory-row" className="d-flex justify-content-between align-items-center">
                {moduleData.CATEGORIES_NAME} <i className="far fa-chevron-right mt-spacing-2"></i>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
