$(document).ready(function() {

  /**
   * Listen to landing page events. Currently only navigation events are supported.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/MessageEvent
   */
  window.addEventListener('message', (message) => {
    /**
     * For navigation events the structure is as follows:
     *
     * message.data = {
     * 'type' => 'navigateTo',
     * 'name' => '<the navigation target URL/page name>'
     * }
     *
     * The navigation target is either the internal name of the tab that is navigated to,
     * or an URL if the navigation's target is an external URL.
     */
    if(message.data.type === 'navigateTo') {
      const isUrlSuccessPage = message.data.name.startsWith('https://email.')
        || message.data.name.startsWith('http://email.')
        || message.data.name.startsWith('//email.')

      if (isUrlSuccessPage) {
        const iframeEl = document.querySelector('[data-selector="maileon-iframe"]')
        iframeEl.classList.add('newsletter-preferences-iframe--success-page');
        return true
      } else {
        window.location.href = message.data.name
      }
    } else {
      return false
    }
  });
});
