import { useTranslation } from "react-i18next";
import { PredictiveIntentSlider } from "../PredictiveIntentSlider";
import { ServiceCard } from "../ServiceCard";

interface Props {
  searchTerm?: string;
}

export const NoResultsBySearch = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div id="hit-list-item" className="d-flex align-items-center flex-column px-spacing-0 px-lg-spacing-24 cascade-wrapper">
        <div className="mb-spacing-24">
          <div className="search-result-no-product-icon-algolia mx-auto">
            <div className="content">
              <i className="far fa-search fa-4x"></i>
            </div>
          </div>
          <p className="h1 search-result-headline px-spacing-12 px-lg-spacing-0 mb-spacing-0 pb-spacing-24">
            {t("search.noResults.bySearch.searchTerm1")} <span>„{props.searchTerm}“</span>
            <br />
            {t("search.noResults.bySearch.searchTerm2")}
          </p>
        </div>
        <div className="bg-aco-gray-06 border-rounded-top-24 cascade container container-extra pb-spacing-64 pt-md-spacing-48 pt-spacing-24 d-flex">
          <svg className="me-spacing-12" xmlns="http://www.w3.org/2000/svg" width="31" height="24" viewBox="0 0 31 24" fill="none">
            <path
              id="icon"
              d="M3.73438 0.1875L5.98438 1.6875C6.5 2.0625 6.64062 2.76562 6.3125 3.28125C5.9375 3.79688 5.23438 3.9375 4.71875 3.5625L2.46875 2.0625C1.95312 1.73438 1.8125 1.03125 2.1875 0.515625C2.51562 0 3.21875 -0.140625 3.73438 0.1875ZM28.4844 2.0625L26.2344 3.5625C25.7188 3.9375 25.0156 3.79688 24.6875 3.28125C24.3125 2.76562 24.4531 2.0625 24.9688 1.6875L27.2188 0.1875C27.7344 -0.140625 28.4375 0 28.8125 0.515625C29.1406 1.03125 29 1.73438 28.4844 2.0625ZM1.625 7.5H4.625C5.23438 7.5 5.75 8.01562 5.75 8.625C5.75 9.28125 5.23438 9.75 4.625 9.75H1.625C0.96875 9.75 0.5 9.28125 0.5 8.625C0.5 8.01562 0.96875 7.5 1.625 7.5ZM26.375 7.5H29.375C29.9844 7.5 30.5 8.01562 30.5 8.625C30.5 9.28125 29.9844 9.75 29.375 9.75H26.375C25.7188 9.75 25.25 9.28125 25.25 8.625C25.25 8.01562 25.7188 7.5 26.375 7.5ZM5.98438 15.5625L3.73438 17.0625C3.21875 17.4375 2.51562 17.2969 2.1875 16.7812C1.8125 16.2656 1.95312 15.5625 2.46875 15.1875L4.71875 13.6875C5.23438 13.3594 5.9375 13.5 6.3125 14.0156C6.64062 14.5312 6.5 15.2344 5.98438 15.5625ZM26.2344 13.7344L28.4844 15.2344C29 15.5625 29.1406 16.2656 28.8125 16.7812C28.4375 17.2969 27.7344 17.4375 27.2188 17.1094L24.9688 15.6094C24.4531 15.2344 24.3125 14.5312 24.6875 14.0156C25.0156 13.5 25.7188 13.3594 26.2344 13.7344ZM21.5469 13.9688C20.6094 15.2344 19.6719 16.5469 19.25 18H11.75C11.2812 16.5469 10.3438 15.2344 9.40625 13.9688C9.17188 13.6406 8.9375 13.3125 8.70312 12.9844C7.76562 11.625 7.25 10.0312 7.25 8.25C7.25 3.70312 10.9062 0 15.5 0C20.0469 0 23.75 3.70312 23.75 8.25C23.75 10.0312 23.1875 11.625 22.25 12.9844C22.0156 13.3125 21.7812 13.6406 21.5469 13.9688ZM19.25 20.25C19.25 22.3594 17.5625 24 15.5 24C13.3906 24 11.75 22.3594 11.75 20.25V19.5H19.25V20.25ZM15.5 4.5C15.875 4.5 16.25 4.17188 16.25 3.75C16.25 3.375 15.875 3 15.5 3C12.5938 3 10.25 5.39062 10.25 8.25C10.25 8.67188 10.5781 9 11 9C11.375 9 11.75 8.67188 11.75 8.25C11.75 6.1875 13.3906 4.5 15.5 4.5Z"
              fill="#003399"
            />
          </svg>

          <div>
            <p>{t("search.noResults.bySearch.inputOptionsVary")}</p>
            <p>{t("search.noResults.bySearch.useArticleNumber")}</p>
            <ul>
              <li>{t("search.noResults.bySearch.pznExample")}</li>
            </ul>
            <p>{t("search.noResults.bySearch.useGenericTerms")}</p>
            <ul>
              <li>{t("search.noResults.bySearch.genericTermExample")}</li>
            </ul>
          </div>
        </div>
        <div className="bg-aco-dark-blue border-rounded-top-24 cascade container container-extra pb-spacing-64 pt-md-spacing-48 pt-spacing-24 text-aco-white d-flex flex-column align-items-center">
          <h2 className="mb-spacing-24">{t("search.noResults.bySearch.productNotFound")}</h2>
          <div className="service-card-section col-lg-10 gap-spacing-16">
            <ServiceCard link="/kontakt" title={t("search.noResults.bySearch.helpCenter")} description={t("search.noResults.bySearch.helpCenterDescription")} />
            <ServiceCard
              link="/pharmazeutische-beratung"
              title={t("search.noResults.bySearch.pharmaceuticalConsultation")}
              description={t("search.noResults.bySearch.consultationDescription")}
            />
            <ServiceCard
              chatTrigger
              title={t("search.noResults.bySearch.liveChatConsultation")}
              description={t("search.noResults.bySearch.liveChatDescription")}
            />
            <ServiceCard
              link="rezept-online-einloesen"
              title={t("search.noResults.bySearch.redeemPrescription")}
              description={t("search.noResults.bySearch.redeemPrescriptionDescription")}
            />
          </div>
        </div>
        <div className="bg-aco-white border-rounded-top-24 cascade container container-extra pb-spacing-64 pt-md-spacing-48 pt-spacing-24">
          <PredictiveIntentSlider />
        </div>
      </div>
    </>
  );
};
