const dropdownFilters = document.querySelectorAll('[data-selector="datalist-dropdown"] [data-bs-toggle="dropdown"]')
let savedWrapper
let savedSpacer

const removeSavedWrapper = () => {
  if (savedWrapper && !savedWrapper.classList.contains('d-none')) {
    savedWrapper.classList.add('d-none')
    savedSpacer.classList.add('d-none')
  }
}

const clickedInButton = (e) => {
  const btn = e.target.closest('[data-selector="datalist-dropdown"]')
  return btn && !btn.length
}

if (dropdownFilters) {
  document.addEventListener("click", (e) => {
    if (!clickedInButton(e)) {
      removeSavedWrapper()
    }
  })

  dropdownFilters.forEach(btn => {
    btn.addEventListener('click', () => {
      const container = btn.closest('[data-selector="datalist-dropdown"]')

      removeSavedWrapper()
      const btnWrapper = container.querySelector('[data-selector="button-wrapper-dropdown"]')
      const spacer = container.querySelector('[data-selector="dropdown-menu-margin"]')
      const isOpen = btn.getAttribute("aria-expanded")

      if (isOpen === "false") {
        btnWrapper.classList.add("d-none")
        spacer.classList.add("d-none")
      } else {
        btnWrapper.classList.remove("d-none")
        spacer.classList.remove("d-none")
      }
      savedWrapper = btnWrapper
      savedSpacer = spacer
    })
  })
}
