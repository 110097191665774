import {CartOverlaySliderItemType} from "./cart-overlay-slider"

export enum CartOverlaySliderApiDefaults {
  url = 'ajax/product_slider/cart.php'
}

export interface CartOverlaySliderApiInterface {
  url: string
}

export class CartOverlaySliderApi {
  public url: string

  constructor(config: CartOverlaySliderApiInterface = CartOverlaySliderApiDefaults) {
    this.url = config.url
  }

  public async getData(product: number): Promise<CartOverlaySliderItemType[]> {
    const params = {products_id: String(product)}
    const method = 'GET'
    const url = this.url + '?' + (new URLSearchParams(params)).toString()

    return fetch(url, {method: method}).then(response => response.json()).catch(error => console.error(error))
  }
}