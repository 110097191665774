import { useTranslation } from "react-i18next";
import { Configure, Index, useInstantSearch } from "react-instantsearch";
import { CONSTANTS } from "../constants";
import { CategoryHits } from "./categoryHits";
import { ContentHits } from "./contentHits";
import { ProductHits } from "./productHits";

export const HitsWrapper = () => {
  const { t } = useTranslation();
  const { results } = useInstantSearch();

  function getSearchpage() {
    let searchpageUrl = "/search.php";
    let searchParameter = "?query=";

    if (CONSTANTS.searchpage_enabled === "false") {
      searchpageUrl = "/advanced_search_result.php";
      searchParameter = "?keywords=";
    }

    return searchpageUrl + searchParameter;
  }

  return (<>
      <div className={`auto-suggest-list ${results!.query === "" ? "invisible" : "visible"}`}>
        <div className={`${results!.query === "" ? "invisible" : "visible"}`}>
          <ul className={"auto-suggest-keywords"}>
            <li tabIndex={0}>
              <a href={getSearchpage() + results!.query} className={"show-all-results-link"}>
                {t("search.autosuggest.showAll")}
              </a>
            </li>
          </ul>
        </div>
        <Configure hitsPerPage={5}/>
        <ProductHits/>
        <Index indexName={CONSTANTS.categoryIndexStorage}>
          <Configure hitsPerPage={3}/>
          <CategoryHits/>
        </Index>
        <Index indexName={CONSTANTS.contentIndexStorage}>
          <Configure hitsPerPage={3}/>
          <ContentHits/>
        </Index>
      </div>
      <div className={`auto-suggest-backdrop ${results!.query === "" ? "invisible" : "visible"}`}/>
    </>
  );
};
