import {ApoEvents} from "./apoEvents";
import {ApoMutationObserver} from "./apo-mutation-observer";

const SCRIPT_ID: string = 'userlike';

export class ApoUserlikeController {
  static instance: ApoUserlikeController;
  // ProductState Modal Elements
  private _userlikeIframe: HTMLElement = document.querySelector('[data-test-id="userlike-container"]')!;
  private _userlikeButton: HTMLElement = document.querySelector('[data-selector="userlikeButton"]')!;
  private _userlikeTriggerList: NodeListOf<HTMLElement> = document.querySelectorAll('[data-selector="userlike-trigger"]')!;

  constructor() {
    if (ApoUserlikeController.instance) {
      return ApoUserlikeController.instance;
    }
    ApoUserlikeController.instance = this;

    document.addEventListener(ApoEvents.DEFERRED_LIBRARY_LOADED, this.init);
  }


  private init = (e: CustomEvent) => {
    if (e.detail.id === SCRIPT_ID) {
      document.removeEventListener(ApoEvents.DEFERRED_LIBRARY_LOADED, this.init)

      ApoMutationObserver.observe('[data-test-id="userlike-container"] div').then((elm: any) => {
        this.onScriptLoad()
      });
    }
  }

  /*
       Handling Userlike-chat time-based button visibility and additional trigger elements
  */
  private onScriptLoad = () => {
    this._userlikeIframe = document.querySelector('[data-test-id="userlike-container"]')!;
    this._userlikeButton = document.querySelector('[data-selector="userlikeButton"]')!;
    this._userlikeTriggerList = document.querySelectorAll('[data-selector="userlike-trigger"]')!;

    this._userlikeButton.classList.remove('inactive');

    // Userlike - Hide Userlike button if service is unavailable
    if (this._userlikeIframe && this._userlikeIframe.childNodes.length === 0) {
      this._userlikeButton.style.display = "none"
    }
    // add eventlisteners to additional, userlike-chat triggering, elements
    else if (this._userlikeTriggerList.length > 0 && this._userlikeIframe && this._userlikeIframe.childNodes.length !== 0) {
      this._userlikeTriggerList.forEach((trigger: HTMLElement | HTMLAnchorElement) => {
        trigger.classList.add('active');
        if(trigger.hasAttribute('href')) trigger.setAttribute('href', '#');
        trigger.title = '';
        trigger.addEventListener('click', (e) => {
          e.preventDefault();
          this._userlikeButton.dispatchEvent(new Event('click'));
        });
      })
    }
  }
}