import { ReactNode } from "react";
import { ActiveFilterBubbleCount } from "./activefilterbubble";

interface AccordionProps {
  title: string,
  indexAttribute?: string,
  children?: ReactNode
}

/**
 * Accordion Item - shell used to display a toggled accordion entry
 *
 * @param title - name of the filter option
 * @param children - the filter component (checkbox, range-slider...)
 * @param indexAttribute - attribute as given in the algolia index
 * @constructor
 */
export const AccordionItem = ({title, children, indexAttribute = ''}: AccordionProps) => {
  return (
    <div className="accordion-item algolia-accordion">
      <h4 className="accordion-header" id={`heading${title}`}>
        <button className="accordion-button collapsed fw-bold px-spacing-16" type="button" data-bs-toggle="collapse"
                data-bs-target={`#collapse${title}`} aria-expanded="false" aria-controls={`collapse${title}`} >
          {title} {indexAttribute !== '' && <ActiveFilterBubbleCount includedAttributes={[`${indexAttribute}`]} />}
        </button>
      </h4>
      <div id={`collapse${title}`} className="accordion-collapse collapse" aria-labelledby={`heading${title}`}
           data-bs-parent="#accordionFilter">
        <div className="accordion-body bg-aco-white text-aco-dark-blue">
          {children}
        </div>
      </div>
    </div>
  )
}