import {ProductReminderSlider} from "@apo-app/apo-responsive/ui/ProductReminder/product-reminder-slider";
import React, {SyntheticEvent, useCallback, useEffect, useRef, useState} from "react";
import {ProductReminderList} from "@apo-app/app/entity/ProductReminder";
import {useTranslation} from "react-i18next";

interface Props {
    products: ProductReminderList
    addNewReminder: () => void
}

export function ProductReminderOverviewHeader(props: Props) {

    const addNewReminderButton = useCallback((inputElement: HTMLDivElement) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    const handleKey = (e:any) => {
       e.key === "Enter" && props.addNewReminder()
    }

  const {t} = useTranslation();


  return (
        <>
            <div
                className="container container-extra bg-aco-dark-blue border-rounded-bottom-32 text-aco-white pb-spacing-32 pb-md-spacing-64">
                <div className="d-flex justify-content-center w-100">
                    <div className="col-12 col-lg-10">
                        <div>
                            <h1 className="h3-xs h1-md mb-spacing-20 mb-md-spacing-24">{t('productReminder.overview.headline')}</h1>
                            <p className="mb-spacing-20 mb-md-spacing-24">{t('productReminder.overview.introText')}</p>
                            <p className="mb-spacing-20 mb-md-spacing-40">
                              {t('productReminder.overview.numberReminder', {count: props.products.REMINDER_LIST.length})}
                            </p>
                            {props.products.REMINDER_LIST.length !== 0 ? (
                                <ProductReminderSlider products={props.products}/>
                            ) : (
                                <>
                                    <div
                                        className="d-flex justify-content-center align-items-center border-aco-gray-01 border border-medium border-rounded-8 gap-spacing-8 p-spacing-32"
                                        role="button"
                                        onClick={props.addNewReminder}
                                        ref={addNewReminderButton}
                                        onKeyDown={handleKey}
                                        tabIndex={0}
                                    >
                                        <span>{t('productReminder.overview.createNewReminder')}</span>
                                        <i className="fas fa-circle-plus fa-xl text-aco-light-blue"></i>
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}