export const manageAddressBook = () => {
  document.addEventListener("DOMContentLoaded", () => {
    // Hide add button and show error text if max. entries are reached
    const additionalAddressEntries = document.querySelectorAll<HTMLElement>(
      '[data-selector="address_entry"]'
    );
    const maximumEntriesError = document.querySelector<HTMLElement>(
      '[data-selector="maximum-addresses-error"]'
    );
    const addEntryButton = document.querySelector<HTMLElement>(
      '[data-selector="btn_new_address"]'
    );

    if (additionalAddressEntries.length > 3 && maximumEntriesError && addEntryButton) {
      maximumEntriesError.classList.remove("d-none");
      addEntryButton.setAttribute("disabled", "");
      return;
    }
  });
};

manageAddressBook();
