import React, {FC, useContext, useState} from "react";
import classNames from "classnames";
import {CartItemType, Prescriptions} from "@apo-app/app/entity/Cart";
import {CartContext} from "@apo-app/app/state/Cart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation} from "@fortawesome/pro-light-svg-icons";
import {
  faCircleInfo,
  faTrashCan,
  faBox,
  faCirclePlus
} from "@fortawesome/pro-regular-svg-icons"
import {
  Col,
  Row,
  InputGroup,
  Button,
  Image,
  OverlayTrigger,
  Popover,
  Modal,
} from "react-bootstrap";
import {Heading} from "@apo-app/app/components/Headings";
import {Badge} from "../../badge/Badge";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {t} from "i18next";

export type CartItemProps = {
  cartItem: CartItemType;
};

export const CartItemPrescription: FC<CartItemProps> = ({cartItem}) => {
  const context = useContext(CartContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const rxBonusPopover = (
    <Popover id="rx-bonus-popover" title="RX Bonus Infopopover">
      <div className="popover fade show" role="tooltip">
        <button className="btn btn-close btn-close-aco-blue close"/>
        <div className="popover-arrow"></div>
        <div className="popover-body">
          <div className="d-flex align-items-center justify-content-center">
            <div className={'blue-gradient small-icon d-flex'}>
              <div className={'content d-flex justify-content-center align-items-center apo-punkte-icon popup text-aco-light-blue'}>
                <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                 data-prefix="fas"
                                 className="text-aco-light-blue"/>
                <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                 data-prefix="fas"
                                 className="text-aco-light-blue"/>
              </div>
            </div>
            <div className="h3 fs-16 pb-spacing-16 pt-spacing-24 ms-spacing-6">{t('cartPopoverRX.headline.preText')}<sup>{t('cartPopoverRX.headline.supNumber')}</sup> {t('cartPopoverRX.headline.postText')}</div>
          </div>
          <p>
            {t('cartPopoverRX.deadline.preText')}<sup>{t('cartPopoverRX.deadline.supNumber')}</sup> {t('cartPopoverRX.deadline.postText')}
          </p>
          <p className={'text-aco-white'}>
            {t('cartPopoverRX.bonusAmount')}
          </p>
          <p className={'text-aco-white'}>
            {t('cartPopoverRX.maxBonusAmount')}
          </p>
          <Button
            as={'a'}
            href='/rezept-bonus'
            target='_blank'
            className={'d-block text-aco-white'}
            variant={'link'}
          >
            {t('cartPopoverRX.buttonText')}

            <FontAwesomeIcon
                icon={faChevronRight as IconDefinition}
                className="ms-spacing-12"
              />
            </Button>
        </div>
      </div>
    </Popover>
  )

  function removeProductFromCart(id: number) {
    context.removeProductFromCart(id);
  }

  function removePrescriptionProductFromCart(id: number, prescription_type: number, prescriptions: Prescriptions[]) {

    const filteredPrescription = prescriptions.filter(el => prescription_type !== el.type)
    context.removePrescriptionProductFromCart(id, filteredPrescription)
  }

  function getPrescriptions(
    PRESCRIPTION_TYPE_1: number | undefined,
    PRESCRIPTION_TYPE_2: number | undefined,
    PRESCRIPTION_TYPE_3: number | undefined
  ): Array<Prescriptions> {
    let prescriptions = [];

    if (PRESCRIPTION_TYPE_1 && PRESCRIPTION_TYPE_1 > 0) {
      prescriptions.push({name: `${PRESCRIPTION_TYPE_1} × Kassenrezept`, type: 1, qty: PRESCRIPTION_TYPE_1});
    }
    if (PRESCRIPTION_TYPE_2 && PRESCRIPTION_TYPE_2 > 0) {
      prescriptions.push({name: `${PRESCRIPTION_TYPE_2} × Privatrezept`, type: 2, qty: PRESCRIPTION_TYPE_2});
    }
    if (PRESCRIPTION_TYPE_3 && PRESCRIPTION_TYPE_3 > 0) {
      prescriptions.push({name: `${PRESCRIPTION_TYPE_3} × Zuzahlungsbefreiung`, type: 3, qty: PRESCRIPTION_TYPE_3});
    }

    return prescriptions;
  }


  return (
    <Row className="shopping-cart-item d-flex flex-row justify-content-between py-spacing-16">
      <Col className="col-auto">
        <div className="shopping-cart-item-image-wrapper p-spacing-6">
          <Image
            src={cartItem.PRODUCTS_IMAGE.image_size_70_70}
            srcSet={`${cartItem.PRODUCTS_IMAGE.image_size_70_70} 1x, ${cartItem.PRODUCTS_IMAGE.image_size_70_70_x2} 2x`}
            alt={cartItem.PRODUCTS_IMAGE.alt_text}
            width={70}
            height={70}
          />
        </div>
      </Col>
      <Col>
        <div className="d-flex flex-row justify-content-between pb-spacing-8 pb-lg-spacing-20">
          <Col xs={11} className="text-aco-dark-blue">{cartItem.PRODUCTS_NAME}</Col>
          <Col xs={1} className="d-flex justify-content-end">
            <a
              className="shopping-cart-item-delete"
              onClick={() => removeProductFromCart(cartItem.PRODUCTS_ID)}
            >
              <FontAwesomeIcon className="text-aco-blue font-size-base" icon={faTrashCan} role="button"/>
            </a>
          </Col>
        </div>
        <div className="d-flex flex-row justify-content-between mb-spacing-16">
          <div>
            <ul className={classNames("list-unstyled", "cart", "mb-spacing-0")}>
              <li>Menge: {cartItem.PRODUCTS_QTY}</li>
              <li>PZN: {cartItem.PRODUCTS_MODEL}</li>
            </ul>
          </div>
          <div>
            <ul
              className={classNames(
                "list-unstyled",
                "fw-bold",
                "text-aco-gray-01",
                "cart",
                "mb-spacing-0"
              )}
            >
              {
                getPrescriptions(
                  cartItem.PRESCRIPTION_TYPE_1,
                  cartItem.PRESCRIPTION_TYPE_2,
                  cartItem.PRESCRIPTION_TYPE_3
                ).map((prescription, key: number, array) => (
                  <li key={key} onClick={() => removePrescriptionProductFromCart(cartItem.PRODUCTS_ID, prescription.type, array)}
                      className={classNames("d-flex", "justify-content-between", "align-items-center", "pb-spacing-4", "pb-md-spacing-0")}>
                    {prescription.name}
                    {array.length !== 1 && <FontAwesomeIcon className="text-aco-blue font-size-base ps-spacing-8" role="button" icon={faTrashCan}/>}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <Row>
          <Col sm={8} md="auto" className="shopping-cart-item-available">
            {cartItem.PRODUCTS_QTY === cartItem.PRODUCTS_MAX_QUANTITY && (
              <div className="d-flex flex-row align-items-start">
                <div className="me-spacing-6 mt-spacing-2">
                  <FontAwesomeIcon
                    className="text-aco-dark-blue"
                    icon={faCircleInfo}
                  />
                </div>
                <span>
                  Maximale Bestellmenge von Produkten mit gleichem Wirkstoff im
                  Warenkorb erreicht.
                </span>
              </div>
            )}
          </Col>
        </Row>
        {cartItem.PRESCRIPTION_TYPE_2 && (
          <div className="d-flex flex-row pb-spacing-8 justify-content-between">
            <OverlayTrigger
              container={this}
              trigger="focus"
              placement="bottom"
              overlay={rxBonusPopover}
            >
              <button className="btn fs-16 p-spacing-0" type="button">
                <div className="me-spacing-6">
                  <FontAwesomeIcon icon={faCircleInfo} />
                </div>
                <span className={"text-aco-blue"}>Rezept-Bonus<sup>10</sup>:</span>
              </button>
            </OverlayTrigger>
            <div className="fw-bold text-aco-dark-blue">
              {cartItem.PRESCRIPTION_BONUS}
            </div>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row align-items-center shopping-cart-item-available">
            <span className={classNames("list-unstyled", "cart", "mb-spacing-0")}>Zuzahlung:</span>
          </div>
          <div className="fw-bold text-aco-dark-blue">
            {cartItem.PRODUCTS_SINGLE_PRICE}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const CartItemOtc: FC<CartItemProps> = ({cartItem}) => {
  const context = useContext(CartContext);
  const [qty, setQty] = useState(cartItem.PRODUCTS_QTY);
  const [showMaxQuantityModal, setShowMaxQuantityModal] =
    useState<boolean>(false);

  const openMaxQuantityModal = () => setShowMaxQuantityModal(true);
  const closeMaxQuantityModal = () => setShowMaxQuantityModal(false);

  function removeProductFromCart(id: number) {
    context.removeProductFromCart(id);
  }


  function increaseCartQty() {
    let value = isNaN(qty) ? 1 : qty;
    value++;
    if (value !== qty && value <= cartItem.PRODUCTS_MAX_QUANTITY) {
      setQty(value);
      context.changeProductCartQty(cartItem.PRODUCTS_ID, value);
    }
  }

  function decreaseCartQty() {
    let value = isNaN(qty) ? 1 : qty;
    value--;
    value < 1 ? (value = 1) : "";
    if (value !== qty) {
      setQty(value);
      context.changeProductCartQty(cartItem.PRODUCTS_ID, value);
    }
  }

  return (
    <Row className="shopping-cart-item d-flex flex-row justify-content-between py-spacing-16">
      <Col className="col-auto">
        <div className="shopping-cart-item-image-wrapper position-relative p-spacing-6">
          <Image
            src={cartItem.PRODUCTS_IMAGE.image_size_70_70}
            srcSet={`${cartItem.PRODUCTS_IMAGE.image_size_70_70} 1x, ${cartItem.PRODUCTS_IMAGE.image_size_70_70_x2} 2x`}
            alt={cartItem.PRODUCTS_IMAGE.alt_text}
            width={70}
            height={70}
          />
          <Badge savingPercentage={cartItem.PRODUCTS_IMAGE.image_saving_percent}/>
        </div>
      </Col>
      <Col>
        <div className="d-flex flex-row justify-content-between pb-spacing-8 pb-lg-spacing-20">
          <Col xs={9}>
            <a
              href={cartItem.PRODUCTS_LINK}
              className={classNames("fw-bold text-aco-dark-blue")}
            >
              {cartItem.PRODUCTS_NAME}
            </a>
          </Col>
          <Col xs={1} className="d-flex justify-content-end">
            <a
              className="shopping-cart-item-delete"
              onClick={() => removeProductFromCart(cartItem.PRODUCTS_ID)}
            >
              <FontAwesomeIcon className="text-aco-blue" icon={faTrashCan}/>
            </a>
          </Col>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-end mb-spacing-16 mb-lg-spacing-20">
          <div>
            <ul className={classNames("list-unstyled", "cart", "mb-spacing-0")}>
              <li>Menge: {cartItem.PRODUCTS_QTY}</li>
              <li>PZN: {cartItem.PRODUCTS_MODEL}</li>
              <li>Einzelpreis: <span className="fw-bold">{cartItem.PRODUCTS_SINGLE_PRICE}</span></li>
            </ul>
          </div>
          <div className="col-auto">
            <div className={classNames("input-group", "stepper", "small-stepper", "cart")}>
              <InputGroup>
                <Button
                  className={classNames("btn-minus")}
                  onClick={() => decreaseCartQty()}
                >
                  {/* <FontAwesomeIcon icon={faMinus}/> */}
                </Button>
                <input
                  className="form-control"
                  type="number"
                  name={`qty${cartItem.PRODUCTS_ID}`}
                  min={1}
                  value={cartItem.PRODUCTS_QTY}
                  readOnly={true}
                />
                <Button
                  className={classNames("btn-plus")}
                  onClick={() => increaseCartQty()}
                >
                  {/* <FontAwesomeIcon icon={faPlus}/> */}
                </Button>
              </InputGroup>
            </div>
          </div>
        </div>


        <div className="d-flex flex-row justify-content-between">
          <Col xs={8} className="shopping-cart-item-available">
            <div className="d-flex flex-row align-items-start mb-spacing-0 text-aco-blue">
              <div role="button">
                <OverlayTrigger
                  placement={"top"}
                  trigger={"click"}
                  rootClose
                  overlay={
                    <Popover className="text-aco-white p-spacing-12">
                      <div className="d-flex align-items-center pb-spacing-16">
                        <div className="blue-gradient small-icon">
                          <div className="content d-flex justify-content-center align-items-center apo-punkte-icon popup text-aco-light-blue">
                            <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                             data-prefix="fas"
                                             className="text-aco-light-blue"/>
                            <FontAwesomeIcon icon={faCirclePlus as IconDefinition}
                                             data-prefix="fas"
                                             className="text-aco-light-blue"/>
                          </div>
                        </div>

                        <div className="ps-spacing-8 fw-bold">
                          <b>apoPunkte sammeln und sparen</b>
                        </div>
                        <button className="btn btn-close btn-close-aco-blue close"
                                onClick={() => document.body.click()}>
                        </button>
                      </div>

                      <div>
                        <p>
                          Für jeden erfolgreichen Kaufabschluss auf apo.com erwerben Sie „apoPunkte“. Ab 250 Punkten können Sie Ihre erste Gutschrift auf Ihre
                          nächste Bestellung einlösen.
                        </p>
                        <p className="text-aco-yellow">
                          Sammeln Sie mit jeder Bestellung apoPunkte. Pro € 1,00 erhalten Sie einen apoPunkt.
                        </p>
                      </div>
                    </Popover>
                  }
                >
                  <div className="me-spacing-6 mt-spacing-2">
                    <FontAwesomeIcon
                      className="text-aco-blue"
                      icon={faCircleInfo}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              <span className={classNames(cartItem.PRODUCTS_STATUS_IMAGE)}>
                +{cartItem.PRODUCT_BONUSPOINTS} apoPunkte
              </span>
            </div>
          </Col>
        </div>


        <div className="d-flex flex-row justify-content-between">
          <Col xs={8} className="shopping-cart-item-available">
            <div className="d-flex flex-row align-items-start mb-spacing-0 text-aco-blue">
              <div role="button">
                <OverlayTrigger
                  placement={"top"}
                  trigger={"click"}
                  rootClose
                  overlay={
                    <Popover className="text-aco-white p-spacing-12">
                      <div className="d-flex align-items-center pb-spacing-16">
                        {cartItem.PRODUCT_STATUS_NUMBER == 0
                          &&
                          <div className="red-gradient small-icon">
                            <div className="content d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon
                                icon={faExclamation}
                                className="text-aco-red w-25 h-25"
                              />
                            </div>
                          </div>
                        }
                        {(cartItem.PRODUCT_STATUS_NUMBER == 1 || cartItem.PRODUCT_STATUS_NUMBER == 2)
                          &&
                          <div className="blue-gradient small-icon">
                            <div className="content d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon
                                icon={faBox}
                                className="text-aco-light-blue"
                              />
                            </div>
                          </div>
                        }
                        <div className="ps-spacing-8 fw-bold">
                          {cartItem.PRODUCTS_STATUS_TEXT}
                        </div>
                        <button className="btn btn-close btn-close-aco-blue close"
                                onClick={() => document.body.click()}>
                        </button>
                      </div>

                      {cartItem.PRODUCTS_STATUS_INFORMATION}
                    </Popover>
                  }
                >
                  <div className="me-spacing-6 mt-spacing-2">
                    <FontAwesomeIcon
                      className="text-aco-blue"
                      icon={faCircleInfo}
                    />
                  </div>
                </OverlayTrigger>
              </div>

              <span className={classNames(cartItem.PRODUCTS_STATUS_IMAGE)}>
                {cartItem.PRODUCTS_STATUS_TEXT}
              </span>
            </div>
          </Col>
          <Col className="shopping-cart-item-price text-aco-orange col-auto">
            {cartItem.PRODUCTS_PRICE}
          </Col>
        </div>


        {qty === cartItem.PRODUCTS_MAX_QUANTITY && (
          <>
            <div className="shopping-cart-item-available d-flex flex-row align-items-start text-aco-blue mb-spacing-0 mt-spacing-16">
              <div
                role="button"
                onClick={openMaxQuantityModal}
                className="me-spacing-6 mt-spacing-2"
              >
                <FontAwesomeIcon className="text-aco-blue" icon={faCircleInfo}/>
              </div>
              <span>
                Maximale Bestellmenge von Produkten mit gleichem Wirkstoff im
                Warenkorb erreicht.
              </span>
            </div>
            <Modal
              show={showMaxQuantityModal}
              onHide={closeMaxQuantityModal}
              centered
            >
              <Modal.Header
                className="pt-spacing-16 text-aco-white"
                closeButton
                closeVariant="white"
              ></Modal.Header>
              <Modal.Title>
                <div className="d-flex justify-content-center align-items-center mb-spacing-24">
                  <div className="red-gradient">
                    <div className="content d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className="w-25 h-25 text-aco-red"
                      />
                    </div>
                  </div>
                </div>
                <Heading element={"h3"} className="text-center mb-spacing-20">
                  Maximalstückzahl wirkstoffgleicher Arzneimittel
                </Heading>
              </Modal.Title>
              <Modal.Body>
                <p className={"text-aco-white text-center mb-spacing-32"}>
                  Lieber Kunde,
                </p>
                <p className="text-center mb-spacing-32">
                  aus pharmazeutischen Gründen müssen wir Ihre Bestellmenge
                  reduzieren.
                  <br/>
                  Wir geben unsere Produkte grundsätzlich nur in
                  haushaltsüblichen Mengen ab. Zudem gibt es Arzneimittel, die
                  aus pharmazeutischer Sicht Höchstmengenbegrenzungen
                  unterliegen. Wir bitten um Ihr Verständnis.
                </p>
                <p className="text-center mb-spacing-0">
                  Bei Rückfragen stehen wir Ihnen gerne zur Verfügung. Nutzen
                  Sie hierfür einfach unser Kontaktformular.
                </p>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center gap-spacing-16 w-100 pb-spacing-24">
                <a
                  className="btn btn-primary btn-lg w-100 max-width-sm"
                  href="/kontakt"
                >
                  Zum Kontaktformular
                </a>
                <Button
                  variant="secondary"
                  size="lg"
                  className="w-100 max-width-sm"
                  onClick={closeMaxQuantityModal}
                >
                  Zurück zum Warenkorb
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Col>
    </Row>
  );
};
