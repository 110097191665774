import '../apo-select-controller'
import {ApoMutationObserver} from "../apo-mutation-observer";

const categorySelectWrapper: HTMLElement | null = document.querySelector(
  '[ data-selector="contactFormSelectInnerWrapper"]'
)

function initSelectStyles() {
  const categorySelect: HTMLFormElement | null = document.querySelector('[data-selector="contactFormSelect"]')

  if(categorySelect && categorySelectWrapper) {
    categorySelect.classList.add('form-select', 'form-control', 'form-select-sm')
    categorySelect.ariaLabel = '.form-select-sm'
    categorySelect.name = 'contact_category'
    categorySelect.dataset.title = categorySelectWrapper.dataset.title
  }
}

function initContactUs() {
  if (categorySelectWrapper) {
    categorySelectWrapper.classList.add('mb-spacing-16', 'select-floating-label', 'select-button', 'select-large', 'text-aco-dark-blue')

    ApoMutationObserver.observe('[data-selector="contactFormSelect"]').then((elm: any) => {
      initSelectStyles()
    })
  }
}

initContactUs()
