import { ApoEvents } from "./apoEvents";

export class ApoListGroupController {
  static instance: ApoListGroupController;
  private controller: AbortController;

  constructor() {
    if (ApoListGroupController.instance) {
      return ApoListGroupController.instance;
    }
    ApoListGroupController.instance = this;

    this.controller = new AbortController();
    this.initListGroupCallbacks();
    this.registerGlobalEvents();
  }

  private initListGroupCallbacks(): void {
    // Abort previous event listeners
    this.controller.abort();
    this.controller = new AbortController();

    const $listGroupSelectionItems = document.querySelectorAll('[data-selector="list-group-item-selection"]');
    $listGroupSelectionItems.forEach(item => {
      const input = item.querySelector("input") as HTMLInputElement;

      if (input && !item.classList.contains("initialized")) {
        input.addEventListener("invalid", () => {
          item.classList.add("invalid-item-selection");
        }, { signal: this.controller.signal });

        item.addEventListener("click", () => this.onClickCallback(input, item), { signal: this.controller.signal });

        item.classList.add("initialized");

        if (input.disabled) {
          item.classList.add("disabled-item-selection");
        } else {
          item.classList.remove("disabled-item-selection");
        }
      }
    });
  }

  private onClickCallback(input: HTMLInputElement, item: Element): void {
    console.log('onClickCallback :>> ', input, item);
    if (input.checkValidity()) {
      item.classList.remove("invalid-item-selection");
    } else {
      item.classList.add("invalid-item-selection");
    }

    if (input) {
      input.checked = true;
      input.parentElement?.classList.add("input-checked");
      const $listGroupSelectionItems = document.querySelectorAll('[data-selector="list-group-item-selection"]');
      $listGroupSelectionItems.forEach(item => {
        const otherInput = item.querySelector("input") as HTMLInputElement;
        if (input.name === otherInput?.name && input.value !== otherInput.value) {
          otherInput.parentElement?.classList.remove("input-checked");
          otherInput.checked = false;
        }
      });
    }
  }

  private registerGlobalEvents(): void {
    document.addEventListener(ApoEvents.STEPPER_UPDATE, () => this.initListGroupCallbacks());
  }
}