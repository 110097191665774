import React, {FC} from "react";
import classNames from "classnames";
import {CartItemOtc, CartItemPrescription} from "../CartItem";
import {CartItemType} from "@apo-app/app/entity/Cart";
import {Heading} from "@apo-app/app/components/Headings";

export type CartItemListProps = {
  cartItems: CartItemType[]
}

export const CartItemList: FC<CartItemListProps> = ({cartItems}) => {
  function filterRxProducts(): CartItemType[] | [] {
    return cartItems.filter(cartItems => cartItems.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '1' || cartItems.IS_ERX_PRODUCT )
  }

  function filterOtcProducts(): CartItemType[] | [] {
    return cartItems.filter(cartItems => cartItems.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '0' && !cartItems.IS_ERX_PRODUCT)
  }

  return (
    <>
      {filterRxProducts().length > 0 &&
        <div className="pb-spacing-32 pb-lg-spacing-24 cart-item-list">
          <Heading element={'h3'} className={classNames('text-aco-dark-blue', "mb-spacing-8 mb-lg-spacing-16")}>
            Produkt(e) mit Rezept
          </Heading>
          {filterRxProducts().map((cartItem, index) => (
            <CartItemPrescription key={index} cartItem={cartItem}/>
          ))}
        </div>
      }
      {filterOtcProducts().length > 0 &&
        <div className={classNames(!!filterRxProducts().length && "pt-spacing-32 pt-lg-spacing-24", "pb-spacing-24", "cart-item-list")}>
          <Heading element={'h3'} className={classNames("text-aco-dark-blue", "mb-spacing-8 mb-lg-spacing-16")}>
            Produkt(e) ohne Rezept
          </Heading>
          {filterOtcProducts().map((cartItem, index) => (
            <CartItemOtc key={index} cartItem={cartItem}/>
          ))}
        </div>
      }
    </>
  )
}
