import React, {useState} from "react";
import Accordion from "react-bootstrap/Accordion"
import Modal from "react-bootstrap/Modal"
import {ProductReminderList, ProductReminder} from "@apo-app/app/entity/ProductReminder";
import iconClock from '../../../../../../assets/img/resource/images/icon_clock.png'

interface Props {
  data: ProductReminderList,
  deleteReminder: Function,
}

export function ProductReminderOverviewDetails(props: Props) {
  const [show, setShow] = useState(false)
  const [modalContent, setModalContent] = useState<ProductReminder>()

  const convertDate = (date: string) => {
    return new Date(date).toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: '2-digit'})
  }

  const showModal = (id: string) => {
    const content = props.data.REMINDER_LIST.find((el) => el.ID === id)
    content && setModalContent(content)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleDelete = (id?: string) => {
    id && props.deleteReminder(id)
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="col-12 col-md-8">
        <Accordion>
          {props.data.REMINDER_LIST.map((el, index) => (
            <Accordion.Item eventKey={String(index)} key={index}>
              <Accordion.Header>
                <div className="d-flex gap-spacing-16">
                  <img src={iconClock} width={24} height={25} className="pt-spacing-4"/>
                  <div>
                    <h6 className="accordion-headline fw-bold mb-spacing-0">
                      {el.TITLE}
                    </h6>
                    <h6 className="footnote fw-normal text-aco-gray-01 mb-spacing-8">
                      {el.NEXT_SCHEDULED_DATE}
                    </h6>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {el.PRODUCTS.map((product, index) => (
                  <div className="d-flex mt-spacing-16" key={index}>
                    <div className="d-flex" data-selector="product_cart">
                      <img src={product.PRODUCTS_IMAGE['image_size_50_50']} width={140} height={140}/>
                      <div className="ms-spacing-8 w-100">
                        <p className="fw-bold my-spacing-8 d-flex justify-content-between align-items-start">
                          {product.PRODUCTS_NAME}
                          <button className="btn btn-ghost not-dark btn-circle" type="button"
                                  onClick={() => showModal(el.ID)}>
                            <i className="far fa-trash-can"/>
                          </button>
                        </p>
                        <p className="mb-spacing-2">
                          PZN: {product.PRODUCTS_MODEL}
                        </p>
                        <p className="mb-spacing-2">
                          Menge: {el.PRODUCTS[index].PRODUCTS_INHALT}
                        </p>
                        <p className="mb-spacing-2">Beginn der Einnahme: {convertDate(el.DATE_START.date)}</p>
                        <p>Einnahmeroutine:
                          Täglich {product.PER_DOSAGE_QUANTITY * product.DAILY_DOSAGE_QUANTITY} {product.DAILY_DOSAGE_UNIT}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Accordion.Body>

            </Accordion.Item>
          ))}
        </Accordion>
        <Modal show={show} onClick={handleClose}>
          <Modal.Header>
            <button className="btn-close btn-close-aco-blue text-primary btn-sm"
                    onClick={handleClose}></button>
          </Modal.Header>

          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="circle-gradient-modal">
              <div className="content">
                <i className="far fa-alarm-clock"></i>
              </div>
            </div>
            <h3 className="mb-spacing-16 mt-spacing-24"> Medikament-Erinnerung entfernen</h3>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
              {modalContent && modalContent.PRODUCTS.map(el => (
                <div className="mb-spacing-6 fw-bold" key={el.PRODUCTS_NAME}>{el.PRODUCTS_NAME}</div>))}
              {modalContent && (
                <div className="mb-spacing-48">Nächste Erinnerung
                  in {modalContent?.NEXT_SCHEDULED_DATE_DIFF_IN_DAYS} Tagen: {modalContent?.NEXT_SCHEDULED_DATE}</div>
              )}
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-spacing-16 w-100">
                <a className="btn btn-primary not-dark col-12"
                   onClick={() => handleDelete(modalContent?.ID)} href="#">Löschen</a>
                <button className="btn btn-secondary not-dark col-12" type="button"
                        onClick={handleClose}>Abrechen
                </button>
              </div>
            </Modal.Body>
          </div>

          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}