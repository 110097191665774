import {useTranslation} from "react-i18next";

interface Props {
  activeStep: number,
  completedSteps: boolean[],
}

export const ProductReminderStepper = (props: Props) => {
  const {t} = useTranslation();

  return (
    <div className="container container-extra bg-aco-dark-blue border-rounded-bottom-32 text-aco-white py-spacing-24 py-md-spacing-48">

      <div className="d-flex justify-content-center w-100">
        <div className="col-12 col-lg-10 pb-spacing-24">
          <h1 className="h3-xs h1-md mb-spacing-24 mb-md-spacing-32">{t('productReminder.stepper.headlineNewReminder')}</h1>
          <div>
            <div className="blue-stepper">
              <ul className="pagination process-bar light px-spacing-32 mb-spacing-32">
                <li className={`page-item ${props.activeStep === 0 ? "active" : ""} ${props.completedSteps[0] ? "completed" : ""}`} data-stepper-item="0">
                  <div className="page-link"></div>
                  <span className="page-step">{t('productReminder.stepper.dosage')}</span>
                </li>
                <li className={`page-separator ${props.completedSteps[0] ? "active" : ""}`}></li>
                <li className={`page-item ${props.activeStep === 1 ? "active" : ""} ${props.completedSteps[1] ? "completed" : ""}`} data-stepper-item="1">
                  <div className="page-link"></div>
                  <span className="page-step">{t('productReminder.stepper.startingDate')}</span>
                </li>
                <li className={`page-separator ${props.completedSteps[1] ? "active" : ""}`}></li>
                <li className={`page-item ${props.activeStep === 2 ? "active" : ""} ${props.completedSteps[2] ? "completed" : ""}`} data-stepper-item="2">
                  <div className="page-link"></div>
                  <span className="page-step">{t('productReminder.stepper.reminder')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}