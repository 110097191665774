export const magazineCategories = () => {
  const maxWidth = 991;
  const scrollWrapper = document.querySelector('[data-selector="magazine-category-scroll"]')
  const scrollButtons = document.querySelectorAll('[data-scroll-direction]')
  const scrollContainer = document.querySelector('[data-selector="magazine-categories-wrapper"]')

  const isScreenSmallerThan = (width: number) => window.innerWidth < width

  const handleScrollCategories = () => {
    isScreenSmallerThan(maxWidth)
      ? scrollWrapper?.classList.remove('d-none')
      : !scrollWrapper?.classList.contains('d-none') && scrollWrapper?.classList.add('d-none')
  }

  handleScrollCategories();
  window.addEventListener('resize', handleScrollCategories);

  scrollButtons.forEach(el => {
    el.addEventListener('click', event => {
      const scrollButtons = event.currentTarget as HTMLElement
      const scrollDirection = scrollButtons.getAttribute('data-scroll-direction')

      if (scrollContainer) {
        const gap = window.getComputedStyle(scrollContainer).getPropertyValue('gap');
        const scrollContainerChild = scrollContainer.querySelector('.category-card__wrapper') as HTMLElement
        const scrollContainerChildWidth = scrollContainerChild.offsetWidth + parseInt(gap)
        if (scrollDirection === "left") {
          scrollContainer.scrollTo({
            left: scrollContainer.scrollLeft - scrollContainerChildWidth,
            behavior: 'smooth'
          });
        } else {
          scrollContainer.scrollTo({
            left: scrollContainer.scrollLeft + scrollContainerChildWidth,
            behavior: 'smooth'
          });
        }
      }
    })
  })
}
magazineCategories();
