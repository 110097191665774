import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import { HitsWrapper } from "./AutosuggestComponents/hitsWrapper";
import { SearchBox } from "./AutosuggestComponents/searchBox";
import { CONSTANTS } from "./constants"
import { MultipleQueriesQuery } from "@algolia/client-search"
import { useTranslation } from "react-i18next";


const algoliaClient = algoliasearch(CONSTANTS.aid, CONSTANTS.sak)

const searchClient = {
  ...algoliaClient,
  search<TObject>(requests: readonly MultipleQueriesQuery[]) {
    if (requests.every(({ params}) => !params?.query || params.query.length < 3)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search<TObject>(requests);
  },
};


export const Autosuggest = () => {
  const { t } = useTranslation();

  return (
    <InstantSearch searchClient={searchClient} indexName={CONSTANTS.productIndexStorage} future={{preserveSharedStateOnUnmount: true}} insights={true}>
      <section className={"auto-suggest"}>
        <h2 className="visually-hidden">{t("search.autoSuggest.heading")}</h2>
        <SearchBox></SearchBox>
        <HitsWrapper/>
      </section>
    </InstantSearch>
  )
}


