import React, {Context, HTMLAttributes, useState, useEffect, useContext} from "react";
import {CartContextType} from "./cart-context";
import {Cart, CartApi, CartFromApi, Prescriptions} from "../../entity/Cart";
import {CartCounter} from "../../web-components/CartCounter"

export type CartProviderProps = {
  context: Context<CartContextType>
} & HTMLAttributes<HTMLDivElement>

export function CartProvider({context, children}: CartProviderProps) {
  const cartCounter: CartCounter | null = document.querySelector('cart-counter')
  const data = useContext(context)
  const cartApi = new CartApi()
  const [cart, setCart] = useState(data.cartData)
  const [init, setInitial] = useState(data.initial)

  useEffect(() => {
    if (data.initial) {
      cartApi.getData().then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
        setInitial(false)
      })
    }
  }, [])

  const addProductToCart = (id: number, qty: number): void => {
    // Add a product to the shopping cart
    cartApi.addProduct(id, qty).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
        updateCartCounter(cart)
      }
    )
  }

  const removeProductFromCart = (id: number) => {
    // Remove the product from the shopping cart
    cartApi.removeProduct(id).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
        updateCartCounter(cart)
        updateCouponCode()

      // BEGIN tracking
      // @ts-ignore
      if (typeof (window.dataLayer) !== 'undefined') {
        // @ts-ignore
        window.dataLayer.push({
          'event': 'removeFromCartEvent',
          'ecommerce': {
            'removeFromCartClick': {
              'pzn': id,
              'cartproducts': cart.cartItems,
              'cartvalue': cart.orderTotal
            }
          }
        })
      }
      // END tracking
      }
    )
  }

  // remove a single prescription type from a product
  const removePrescriptionProductFromCart = (id: number, filteredPrescription: Prescriptions[]) => {

    // rerender cart
    const setCartData = (data: CartFromApi) => {
      const cart = Cart.fromApiObject(data)
      setCart(cart)
      updateCartCounter(cart)
    }

    // after remove the whole product re-add not deleted types
    async function addData(filteredPrescription: Prescriptions[]) {
      for (const [i, el] of filteredPrescription.entries()) {
        if (i !== filteredPrescription.length - 1) {
          await cartApi.addProduct(id, el.qty, el.type)
        } else {
          // last iteration
          await cartApi.addProduct(id, el.qty, el.type).then(data => {
            setCartData(data)
          })

        }
      }
    }


    cartApi.removeProduct(id).then(data => {
      if (filteredPrescription.length !== 0) {
        addData(filteredPrescription)
      } else {
        setCartData(data)
      }

      // BEGIN tracking
      // @ts-ignore
      if (typeof (window.dataLayer) !== 'undefined') {
        // @ts-ignore
        window.dataLayer.push({
          'event': 'removeFromCartEvent',
          'ecommerce': {
            'removeFromCartClick': {
              'pzn': id,
              'cartproducts': data.CART_ITEMS,
              'cartvalue': data.ORDER_TOTAL
            }
          }
        })
      }
      // END tracking
    })
  }

  const changeProductCartQty = (id: number, qty: number) => {
    // Change quantity from existing product in cart
    cartApi.changeProductQty(id, qty).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
        updateCartCounter(cart)
        updateCouponCode()
      }
    )
  }

  const updateCouponCode = () => {
    // change coupon view for cart
    const formRemoveCoupon = document.querySelector<HTMLFormElement>('.form-remove-coupon-code')
    const formData = formRemoveCoupon && new FormData(formRemoveCoupon)

    const couponCode = formData && formData.get('couponCode')

    couponCode && localStorage.setItem('couponCode', JSON.stringify(couponCode))
    const localCouponCode = localStorage.getItem('couponCode')
    const newCouponCode = localCouponCode && JSON.parse(localCouponCode)

    if (couponCode === null && !newCouponCode) {
      return
    }

    cartApi.removeCouponCode(newCouponCode).then(data => {
      cartApi.applyCouponCode(newCouponCode).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
      })
    })
  }

  const applyCouponCode = (form: HTMLFormElement) => {
    const formData = new FormData(form)
    const couponCode = formData.get('couponCode')
    if (couponCode === null) {
      return
    }
    cartApi.applyCouponCode(couponCode).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
      }
    )
  }

  const removeCouponCode = (form: HTMLFormElement) => {
    const formData = new FormData(form)
    const couponCode = formData.get('couponCode')
    if (couponCode === null) {
      return
    }
    cartApi.removeCouponCode(couponCode).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
      }
    )
  }

  const applyBonusPoints = (form: HTMLFormElement) => {
    const formData = new FormData(form)
    const bonusPoints = formData.get('bonusPoints')
    if (bonusPoints === null) {
      return
    }
    cartApi.applyBonusPoints(bonusPoints).then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
      }
    )
  }

  const removeBonusPoints = () => {
    cartApi.removeBonusPoints().then(data => {
        const cart = Cart.fromApiObject(data)
        setCart(cart)
      }
    )
  }

  const updateCartCounter = (cart: Cart) => {
    if (cartCounter instanceof HTMLElement) {
      cartCounter.updateCounter(cart.cartItemsAmount)
    }
  }

  const cartDataUpdate = {
    amazon: cart.amazon,
    links: cart.links,
    orderSubTotal: cart.orderSubTotal,
    orderTotalSaving: cart.orderTotalSaving,
    orderTotal: cart.orderTotal,
    orderVoucher: cart.orderVoucher,
    couponCode: cart.couponCode,
    initCheckoutMethods: cart.initCheckoutMethods,
    shippingCostsCalc: cart.shippingCostsCalc,
    freeShipping: cart.freeShipping,
    untilFreeShipping: cart.untilFreeShipping,
    freeShippingByAmount: cart.freeShippingByAmount,
    customsTariffExtraChargeTitle: cart.customsTariffExtraChargeTitle,
    customsTariffExtraChargeText: cart.customsTariffExtraChargeText,
    loggedIn: cart.loggedIn,
    orderBonusPoints: cart.orderBonusPoints,
    orderPrescriptionBonusPoints: cart.orderPrescriptionBonusPoints,
    orderBonusPointsCashing: cart.orderBonusPointsCashing,
    orderBonusPointsInfo: cart.orderBonusPointsInfo,
    accountBonusPoints: cart.accountBonusPoints,
    cartMessage: cart.cartMessage,
    cartItemsAmount: cart.cartItemsAmount,
    cartItems: cart.cartItems
  }

  return (<context.Provider value={{
    cartData: cartDataUpdate,
    addProductToCart,
    removeProductFromCart,
    removePrescriptionProductFromCart,
    changeProductCartQty,
    applyCouponCode,
    removeCouponCode,
    applyBonusPoints,
    removeBonusPoints,
    initial: init
  }}>{children}</context.Provider>)
}