import React from "react";

// Define the icon constants
const PHARMA_ICON = `<svg><use href="#icon_pharmaceutical-chat"/></svg>`;
const CHAT_ICON = `<i class="far fa-messages fa-xl"></i>`;
const INFO_ICON = `<i class="fa-regular fa-circle-info"></i>`;
const RX_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="58" height="56" viewBox="0 0 58 56" fill="none"><use xlink:href="#service-card-rx-icon"></use></svg>`;

// Define an enum for icon names
enum IconNames {
  HELP_CENTER = 'Hilfe-Center',
  PHARMACEUTICAL_ADVICE = 'Pharmazeutische Beratung',
  LIVE_CHAT_ADVICE = 'Beratung per Live-Chat',
  RX_HELP = 'Rezept einlösen'
}

// Create a map for the icons
const iconMap: { [key: string]: string } = {
  [IconNames.HELP_CENTER]: INFO_ICON,
  [IconNames.PHARMACEUTICAL_ADVICE]: PHARMA_ICON,
  [IconNames.LIVE_CHAT_ADVICE]: CHAT_ICON,
  [IconNames.RX_HELP]: RX_ICON
};

// Function to get the icon based on the requested name
const getCardIcon = (requestedIconName: string): string | undefined => {
  return iconMap[requestedIconName];
}

interface Props {
  link?: string,
  title: string,
  description: string,
  chatTrigger?: boolean
}

export const ServiceCard = (props: Props) => {
  const cardIcon = getCardIcon(props.title);

  return (
    <>
      <a href={props.link} className="service-teaser-card active" {...(props.chatTrigger && { 'data-selector': 'userlike-trigger' })}>
        <div className="icon">
          {cardIcon ? <div dangerouslySetInnerHTML={{ __html: cardIcon }} /> : null}
        </div>
        <div className="content">
          <div className="title">{props.title}</div>
          <div className="description">{props.description}</div>
        </div>
      </a>
    </>
  )
}