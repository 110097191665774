// Alternative package size
import { initializeSlider } from "../modules/init";
import { ApoEvents } from "../apoEvents";
import { initTracking } from "../app/ui/Search/tracking";

const alternativePackageSize = () => {
  const packageSizeInputs = document.querySelectorAll('[data-selector = "input-alternative-package-size"]');
  if (packageSizeInputs.length) {
    packageSizeInputs.forEach(input => input.addEventListener('change', () => location.href = input.value));
  }
}

// Update value of attribute on cart button
const setAttributeOnCartButton = (attribute, value) => {
  const cartButtons = document.querySelectorAll('[data-selector="cart-button"]');

  cartButtons.forEach((btn) => {
    btn.setAttribute(attribute, value);
  })
}

// Update cart button quantity on stepper click
const updateAddToCartQuantity = () => {
  const stepper = document.querySelector('#stepperInputExample');
  setAttributeOnCartButton('quantity', stepper.value);
}

// Max order quantity
const plusBtnValidator = (input, btn, minusBtn) => {
  const value = Number(input.value.trim());
  if (value === input.max) btn.disabled = true;

  if (value > input.max) {
    btn.disabled = true;
    input.value = input.max;
  }

  if (value > input.min && minusBtn && minusBtn.disabled) minusBtn.disabled = false;

  updateAddToCartQuantity();
}

const minusBtnValidator = (input, btn, plusBtn) => {
  const value = Number(input.value.trim());
  if (value === 1) btn.disabled = true;

  if (value < input.max && plusBtn && plusBtn.disabled) plusBtn.disabled = false;

  updateAddToCartQuantity();
}

const maxOrderQuantity = () => {
  const inputs = document.querySelectorAll('[data-stepper-input="max-order-quantity"]');
  const plusButtons = document.querySelectorAll('[data-stepper-button="+"]');
  const minusButtons = document.querySelectorAll('[data-stepper-button="-"]');

  plusButtons.forEach((btn) => {
    const input = btn.parentElement.querySelector('[data-stepper-input="max-order-quantity"]');
    const minusBtn = btn.parentElement.querySelector('[data-stepper-button="-"]');

    if (input.value < 2) minusBtn.disabled = true;

    btn.addEventListener('click', () => plusBtnValidator(input, btn, minusBtn));
  })

  minusButtons.forEach((btn) => {
    const input = btn.parentElement.querySelector('[data-stepper-input="max-order-quantity"]');
    const plusBtn = btn.parentElement.querySelector('[data-stepper-button="+"]');

    btn.addEventListener('click', () => minusBtnValidator(input, btn, plusBtn));
  })


  inputs.forEach(input => {
    const plusBtn = input.parentElement.querySelector('[data-stepper-button="+"]');
    const minusBtn = input.parentElement.querySelector('[data-stepper-button="-"]');

    input.addEventListener('keyup', () => {
      const value = Number(input.value.trim());
      if (value > input.max) input.value = input.max;

      if (value < input.max && plusBtn && plusBtn.disabled) plusBtn.disabled = false;

      if (value > input.min && minusBtn && minusBtn.disabled) minusBtn.disabled = false;
    })
  })
}

const gotoDownloadPDFListener = () => {
  const link = document.querySelector('[data-selector="goto-download-pdf"]');
  if (link) {
    const downloadPDFContainer = document.querySelector('[data-selector="download-pdf"]');
    if (downloadPDFContainer) {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        downloadPDFContainer.scrollIntoView({block: "center", behavior: "smooth"});
      })
    }
  }
}

const gotoExtendedCategoryDescription = () => {
  const link = document.querySelector('[data-selector="goto-category-description"]');
  if (link) {
    link.classList.remove('invisible')
    const categoryDescriptionContainer = document.querySelector('[data-section="seo-text"]');

    if (categoryDescriptionContainer) {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        categoryDescriptionContainer.scrollIntoView({block: "start", behavior: "smooth"});
      })
    }
  }
}

//JS needed for ticket ACRELAUNCH-370 to show/hide the prescription bonus price
const getPrescriptionSelectValue = () => {
  const prescriptionTypeSelect = document.getElementById("prescription_type_text");
  const prescriptionBonus = document.querySelector("[data-id='prescription-bonus-badge']");
  if (prescriptionTypeSelect) {
    prescriptionTypeSelect.addEventListener("change", (e) => {
      if (e.target.value === "Privatrezept") {
        prescriptionBonus.classList.remove("d-none");
      } else {
        prescriptionBonus.classList.add("d-none");
      }
    });
  }
}

// This changes the bonus badge depending on the select value
const changePrescriptionType = () => {
  const select = document.querySelectorAll("[data-selector='pdp-select-prescription-type']")[0];
  const option1 = document.querySelectorAll("[data-selector='pdp-public-additional-payment']")[0];
  const option2 = document.querySelectorAll("[data-selector='pdp-prescription-bonus']")[0];
  const option3 = document.querySelectorAll("[data-selector='pdp-no-additional-payment']")[0];
  const prescriptionBonusValue = option2 && option2.getAttribute('data-rx-bonus-value');

  const selectVariation = document.querySelectorAll("[data-selector='pdp-select-prescription-type']")[1];
  const option1Variation = document.querySelector("[data-selector='pdp-public-additional-payment-package-select']");
  const option2Variation = document.querySelector("[data-selector='pdp-prescription-bonus-package-select']");
  const option3Variation = document.querySelector("[data-selector='pdp-no-additional-payment-package-select']");
  const prescriptionBonusValueVariation = option2Variation && option2Variation.getAttribute('data-rx-bonus-value');

  const packageSizeText1 = document.querySelector("[data-selector='pdp-public-additional-payment-package-select']");
  const packageSizeText2 = document.querySelector("[data-selector='pdp-prescription-bonus-package-select']");
  const packageSizeText3 = document.querySelector("[data-selector='pdp-no-additional-payment-package-select']");

  const desktopPrice1 = document.querySelector("[data-selector='pdp-public-additional-payment-desktop-price']");
  const desktopPrice2 = document.querySelector("[data-selector='pdp-prescription-bonus-desktop-price']");
  const desktopPrice3 = document.querySelector("[data-selector='pdp-no-additional-payment-desktop-price']");


  const changeVisibilityOfOptions = (value) => {
    if (option1 && option2 && option3) {
      option1.classList.add("d-none");
      option3.classList.add("d-none");
      option2.classList.add("d-none");
    }

    if (option1Variation && option2Variation && option3Variation) {
      option1Variation.classList.add("d-none");
      option3Variation.classList.add("d-none");
      option2Variation.classList.add("d-none");
    }

    if (packageSizeText1 && packageSizeText2 && packageSizeText3) {
      packageSizeText1.classList.add("d-none");
      packageSizeText2.classList.add("d-none");
      packageSizeText3.classList.add("d-none");
    }

    if (desktopPrice1 && desktopPrice2 && desktopPrice3) {
      desktopPrice1.classList.add("d-none");
      desktopPrice2.classList.add("d-none");
      desktopPrice3.classList.add("d-none");
    }

    if (value === "public") {
      setAttributeOnCartButton('prescription', 1);
      option1?.classList.remove("d-none");
      option1Variation?.classList.remove("d-none");
      packageSizeText1?.classList.remove("d-none");
      desktopPrice1?.classList.remove("d-none");
    }
    if (value === "private") {
      setAttributeOnCartButton('prescription', 2);
      if (prescriptionBonusValue > 0) option2?.classList.remove("d-none");
      if (prescriptionBonusValueVariation > 0) option2Variation?.classList.remove("d-none");
      if (prescriptionBonusValueVariation > 0) packageSizeText2?.classList.remove("d-none");
      if (prescriptionBonusValueVariation > 0) desktopPrice2?.classList.remove("d-none");
    }
    if (value === "noadditional") {
      setAttributeOnCartButton('prescription', 3);
      option3?.classList.remove("d-none");
      option3Variation?.classList.remove("d-none");
      packageSizeText3?.classList.remove("d-none");
      desktopPrice3?.classList.remove("d-none");
    }
  };

  if (select) {
    changeVisibilityOfOptions(select.value);
    select.addEventListener("change", (e) => {
      changeVisibilityOfOptions(e.target.value);
    });
  }

  if (selectVariation) {
    changeVisibilityOfOptions(selectVariation.value);
    selectVariation.addEventListener("change", (e) => {
      changeVisibilityOfOptions(e.target.value);
    });
  }
}

// Change the text color of the slider content if background is blue
const sliderContrastSwitch = () => {
  const blueBgSliders = document.querySelectorAll('.bg-aco-dark-blue [data-selector="recommendation-wrapper"]');
  blueBgSliders.forEach((slider) => {
    slider.querySelectorAll('.text-aco-dark-blue').forEach((el) => {
      el.classList.remove('text-aco-dark-blue');
      el.classList.add('text-aco-white');
    });

    slider.querySelectorAll('.search-result-card-mandatory').forEach((iconLink) => {
      iconLink.classList.add('light');
      const template = iconLink.querySelector('svg')?.nextElementSibling;
      if (template) iconLink.querySelector('svg')?.replaceWith(template.content.cloneNode(true));
    });
  })
}

//get aws recommendations
let isFetched = false;
const getProductRecommendationAws = () => {
  if(isFetched) return;
  isFetched = true;
  const productRecommendationsWrapper = document.getElementById('product_recommendation_bought_together_wrapper');
  const productId = productRecommendationsWrapper && productRecommendationsWrapper.getAttribute('data-id');

  if (productId) {
    const object = {
      product_recommendation_aws_type: 'bought-together',
      product_recommendation_aws_product_pzn: productId
    }


    fetch('ajax/product_recommendations_aws.php', {
      method: 'POST',
      body: new URLSearchParams(object),
    })
      .then(response => response.text())
      .then(response => {
        productRecommendationsWrapper.innerHTML = response;
        if (!productRecommendationsWrapper.querySelector('[data-slider="also-bought"]')) return;
        initializeSlider(productRecommendationsWrapper.querySelector('[data-slider="also-bought"]'))
      })
  }
}

const init = () => {
  alternativePackageSize();
  maxOrderQuantity();
  gotoDownloadPDFListener();
  gotoExtendedCategoryDescription();
  getPrescriptionSelectValue();
  changePrescriptionType();
  sliderContrastSwitch();
  getProductRecommendationAws();
  initTracking();
}

document.addEventListener('DOMContentLoaded', init);
document.addEventListener(ApoEvents.PDP_NODE_CHANGE, init);