import React from 'react';
import {
  useCurrentRefinements,
  UseCurrentRefinementsProps
} from 'react-instantsearch';

export const CustomCurrentRefinements = (props: UseCurrentRefinementsProps) => {
  const { items, refine } = useCurrentRefinements(props);

  return (
    <div className={"ais-CurrentRefinements refinement-root"}>
      <div className={"ais-CurrentRefinements-list refinement-list gap-spacing-8 p-spacing-0"}>
        {items.map((item) => (
          <div key={[item.indexName, item.label].join('/')} className={"ais-CurrentRefinements-item refinement-item gap-spacing-8"}>
            <span className={"ais-CurrentRefinements-label refinement-label"}>{item.label}:</span>
            {item.refinements.map((refinement) => (
              <span key={refinement.label}>
                <span className={"ais-CurrentRefinements-category refinement-category"}>
                  <span className={"ais-CurrentRefinements-categoryLabel refinement-category-label fw-normal"}>
                    {refinement.label}
                  </span>

                  <button
                    className={"ais-CurrentRefinements-delete refinement-delete btn btn-ghost p-spacing-0 ps-spacing-8"}
                    type="button"
                    onClick={(event) => {
                      if (isModifierClick(event)) {
                        return;
                      }

                      refine(refinement);
                    }}
                  >
                   <i className={"far fa-trash-can fa-sm"}></i>
                </button>
                </span>
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  );
}