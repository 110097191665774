import {Modal} from 'bootstrap'

export class ShowOrderDetails {
  formElement: HTMLFormElement
  openTracking: HTMLHeadingElement

  constructor(formElement: HTMLFormElement, openTracking: HTMLHeadingElement) {
    this.formElement = formElement
    this.openTracking = openTracking
  }

  // cancel Order at my Orders
  cancelOrder() {
    const confirmModal = new Modal(<HTMLElement>document.querySelector('[data-selector="confirm_modal"]'))
    const closeModal = document.querySelectorAll('[data-action="close-modal"]')
    const confirmCancelOrder = <HTMLElement>document.querySelector('[data-action="confirm-cancel-order"]')

    this.formElement.addEventListener('submit', (e: Event) => {
      e.preventDefault()
      confirmModal.show()
    })

    closeModal.forEach((el) => el.addEventListener('click', () => confirmModal.hide()))
    confirmCancelOrder && confirmCancelOrder.addEventListener('click', () => this.formElement.submit())
  }


// currently not used
  orderTracking() {
    // refresh display tracking
    this.openTracking.addEventListener('click', () => {
      const tracking_number = this.openTracking.getAttribute('tracking-number')

      if (tracking_number != "") {
        const trackingResults = document.createElement('div')

        let data = new FormData()
        data.append('json', JSON.stringify({tracking_number: tracking_number}))

        fetch('ajax/order_tracking.php', {method: 'POST', body: data})
          .then((res) => res.json())
          .then((res) => trackingResults.innerHTML = res.json())
          .catch((error) => console.log(error))

        // this.openTracking.after(trackingResults)
        this.openTracking.parentNode && this.openTracking.parentNode.insertBefore(trackingResults, this.openTracking.nextSibling)
      }
    })

  }
}
