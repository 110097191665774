import {LitElement, html, nothing} from 'lit';
import {customElement, property, state, query} from 'lit/decorators.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import {
  AutoSuggestCategoryType,
  AutoSuggestContentType,
  AutoSuggestKeywordType,
  AutoSuggestProductType,
  AutoSuggestApi,
  AutoSuggestType
} from "../../entity/Search";
import i18next from "i18next";


@customElement('auto-suggest')
export class AutoSuggest extends LitElement {
  private readonly autoSuggestApi: AutoSuggestApi
  private suggestBackdrop: HTMLDivElement | null;


  constructor() {
    super();
    this.autoSuggestApi = new AutoSuggestApi()
    this.suggestBackdrop = document.querySelector('[data-selector="auto-suggest-backdrop"]')
    this.suggestBackdrop?.addEventListener('click',()=>this.handleClickBackdrop())
  }

  @property({type: String})
  type = "search"

  @property({type: String})
  name = "keywords"

  @property({type: String})
  autocomplete = "off"

  @property({type: String})
  placeholder = ""

  @property({type: String})
  label = ""

  @property({attribute: false})
  list: AutoSuggestType = {
    keyword: [],
    products: [],
    category: [],
    content: [],
    omq_help_link: ''
  }

  @state()
  currentFocus: number = -1

  @query('.auto-suggest-input')
  input: HTMLInputElement | undefined

  @query('.auto-suggest-list')
  div: HTMLDivElement | undefined

  protected render() {
    return html`
      <input
        class="auto-suggest-input"
        type=${this.type}
        name=${this.name}
        autocomplete=${this.autocomplete}
        placeholder=${this.placeholder}
        aria-label=${this.label}
        value=""
        @keyup=${this.handleKeyUp}
        @input=${this.handleInput}
        @click=${this.handleInput}
      />
      ${this.renderAutoSuggestList(this.list)}
    `
  }

  protected createRenderRoot() {
    return this;
  }

  protected renderBackdrop() {
    this.suggestBackdrop?.classList.remove('invisible')
    return ``
  }

  protected renderAutoSuggestList(list: AutoSuggestType) {

    return html`
      ${list.keyword &&
         list.keyword.length === 0
            && list.products.length=== 0
            && list.category.length=== 0
            && list.content.length === 0
            && this.hasUpdated
            && document.activeElement === this.firstElementChild
      ? html`<div class="auto-suggest-list"><ul><li>${i18next.t('autoSuggest.noEntriesFound')}</li></ul></div>`
      : html`
        <div class="auto-suggest-list">
            ${this.renderAutoSuggestKeyword(list.keyword)}
            ${this.renderAutoSuggestProducts(list.products)}
            ${this.renderAutoSuggestCategories(list.category)}
            ${this.renderAutoSuggestContent(list.content)}
            ${this.renderOmqHelpLink(list.omq_help_link)}
        </div>`
    }`
  }



  protected renderAutoSuggestKeyword(keywords: AutoSuggestKeywordType[] | []) {
    return html`
      ${keywords.length > 0
        ? html`
          <ul class="auto-suggest-keywords">
            ${keywords.map((keyword: AutoSuggestKeywordType) => (
              html`
                <li>
                  <a href="${keyword.link}">
                    ${unsafeHTML(keyword.suggest)}
                  </a>
                </li>`
            ))}
          </ul>`
        : nothing
      }
    `
  }

  protected renderAutoSuggestProducts(products: AutoSuggestProductType[] | []) {
    return html`
      ${products.length > 0
        ? html`<h3>Produkte</h3>
        <ul class="auto-suggest-products">
          ${products.map((product: AutoSuggestProductType) => (
            html`
              <li>
                <a href="${product.link}">
                  <img src="${product.product_image.image_size_50_50}" alt="${product.product_image.alt_text}"/>
                  <span>${unsafeHTML(product.suggest)}</span>
                </a>
              </li>`
          ))}
        </ul>`
        : nothing
      }
    `
  }

  protected renderAutoSuggestCategories(categories: AutoSuggestCategoryType[] | []) {
    return html`
      ${categories.length > 0
        ? html`<h3>Kategorien</h3>
        <ul class="auto-suggest-categories">
          ${categories.map((category: AutoSuggestCategoryType) => (
            html`
                <li>
                    <a href="${category.link}">
                        ${unsafeHTML(category.suggest)}
                        ${category.parent_value !== '' ? html`<span class="w-100 d-block">in ${category.parent_value.replace(/&amp;/g, '&')}</span>` : ''}
                    </a>
                </li>`
          ))}
        </ul>`
        : nothing
      }
    `
  }

  protected renderAutoSuggestContent(content: AutoSuggestContentType[] | []) {
    return html`
      ${content.length > 0
        ? html`<h3>Service</h3>
        <ul class="auto-suggest-content">
          ${content.map((content: AutoSuggestContentType) => (
            html`
              <li>
                <a href="${content.link}">
                  ${unsafeHTML(content.suggest)}
                </a>
              </li>`
          ))}
        </ul>`
        : nothing
      }
    `
  }

  protected renderOmqHelpLink(omq_help_link: string) {
    return html`
      ${omq_help_link !== ""
        ? html`<a href="${omq_help_link}">Hilfe</a>`
        : nothing
      }
    `
  }

  private handleInput() {
    const element = this.input
    if (!element || !this.div || !element.value) return
    this.autoSuggestApi.getData(element.value)
      .then(data => {
        this.list = data
        this.renderBackdrop()
      })
  }

  private handleClickBackdrop() {
    this.list = {
      keyword: [],
      products: [],
      category: [],
      content: [],
      omq_help_link: ''
    }
    this.suggestBackdrop?.classList.add('invisible')
  }

  //not used now
  private handleKeyUp(e: KeyboardEvent): void {
    const listHTMLCollection = this.div?.getElementsByTagName('li')
    if (!listHTMLCollection) return
    switch (e.key) {
      case 'ArrowDown':
        this.currentFocus++
        this.addActive(listHTMLCollection)
        break
      case 'ArrowUp':
        this.currentFocus--
        this.addActive(listHTMLCollection)
        break
      case 'Enter':
        e.preventDefault()
        if (this.currentFocus > -1) {
          if (listHTMLCollection.length) {
            const [first] = listHTMLCollection[this.currentFocus].getElementsByTagName('a')
            first.click()
          }
        }
    }
  }

  private addActive(listHTMLCollection: HTMLCollection): boolean {
    if (!listHTMLCollection) return false
    this.removeActive(listHTMLCollection)
    if (this.currentFocus >= listHTMLCollection.length) this.currentFocus = 0
    if (this.currentFocus < 0) this.currentFocus = (listHTMLCollection.length - 1)
    listHTMLCollection[this.currentFocus].classList.add("active")
    return true
  }

  private removeActive(listHTMLCollection: HTMLCollection): void {
    for (let i = 0; i < listHTMLCollection.length; i++) {
      listHTMLCollection[i].classList.remove("active");
    }
  }
}
