import {ApoEvents} from "./apoEvents";

const volumeIcon = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M333.1 34.8C344.6 40 352 51.4 352 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L163.8 352H96c-35.3 0-64-28.7-64-64V224c0-35.3 
         28.7-64 64-64h67.8L298.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zm172 72.2c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4
         6.8-33.8-3.5s-6.8-25.4 3.5-33.8C507.3 341.3 528 301.1 528 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5
         74.5C466.1 199.1 480 225.9 480 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C425.1 284.4 432 271 432 
         256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z`,
  viewbox: '0 0 576 512',
  className: 'svg-inline--fa',
};

const volumeIconMuted = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M320 64c0-12.6-7.4-24-18.9-29.2s-25-3.1-34.4 5.3L131.8 160H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64h67.8L266.7 471.9c9.4 8.4
         22.9 10.4 34.4 5.3S320 460.6 320 448V64z`,
  viewbox: '0 0 448 512',
  className: 'svg-inline--fa',
};

const volumeIconHigh = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592
         256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3
         8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5
         74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 
         256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 
         3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z`,
  viewbox: '0 0 640 512',
  className: 'svg-inline--fa',
};

const playIcon = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z`,
  viewbox: '0 0 384 512',
  className: 'svg-inline--fa',
};

const pauseIcon = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5
         48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z`,
  viewbox: '0 0 320 512',
  className: 'svg-inline--fa',
};

const fullScreenIcon = {
  fill: 'currentColor',
  stroke: 'currentColor',
  path: `M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 
         0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM184 496H40c-13.3 0-24-10.7-24-24V328c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 
         39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z`,
  viewbox: '0 0 512 512',
  className: 'svg-inline--fa',
};

const playVideo = (video, overlay, controllers) => {
  video.play();
  overlay.style.display = 'none';
  controllers.style.display = 'flex';
};

const pauseVideo = (video, overlay, controllers = null) => {
  video.pause();
  overlay.style.display = 'flex';
  if (controllers) controllers.style.display = 'none';
};

const parseVideoTime = (time) => {
  const totalTimeInSeconds = parseInt(time).toFixed(2);
  const minutes = Math.trunc(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;
  return `${minutes >= 1 ? minutes : '00'}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
};

const hideVolumeRange = (fullscreenButton, soundButton, volumeRange) => {
  fullscreenButton.style.display = 'flex';
  soundButton.style.display = 'flex';
  volumeRange.style.display = 'none';
};

const showVolumeRange = (fullscreenButton, soundButton, volumeRange) => {
  fullscreenButton.style.display = 'none';
  soundButton.style.display = 'none';
  volumeRange.style.display = 'block';
};

const insertSvg = ({
                     node,
                     path,
                     viewbox,
                     fill = 'none',
                     stroke = 'black',
                     className,
                   }) => {
  const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const iconPath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );

  iconSvg.setAttribute('fill', fill);
  iconSvg.setAttribute('viewBox', viewbox);
  iconSvg.setAttribute('stroke', stroke);

  iconPath.setAttribute('d', path);

  iconSvg.classList.add(className);
  iconSvg.appendChild(iconPath);

  return node.replaceChildren(iconSvg);
};

const productsliderVideoIndexChangeHandler = () => {
  document.querySelectorAll('.video-player').forEach((videoPlayer) => {
    const video = videoPlayer?.querySelector('video');
    const overlay = videoPlayer?.querySelector('.overlay');
    pauseVideo(video, overlay);
  })
}

const initVideoPlayers = () => {
  const $videoPlayers = document.querySelectorAll('.video-player');

  $videoPlayers.forEach(($videoPlayer) => {
    const $video = $videoPlayer.querySelector('video');
    const $controls = $videoPlayer.querySelector('.controls');
    let isFullScreen = false;

    if ($video) {
      if ($video.controls && $controls) {
        const $overlay = $controls.querySelector('.overlay');
        const $controllers = $controls.querySelector('.controllers');
        let mouseTimeout = null;

        $controls.style.display = 'flex';

        document.removeEventListener(ApoEvents.PRODUCTSLIDER_HASVIDEO_INDEX_CHANGE, productsliderVideoIndexChangeHandler);
        document.addEventListener(ApoEvents.PRODUCTSLIDER_HASVIDEO_INDEX_CHANGE, productsliderVideoIndexChangeHandler);

        if ($overlay && $controllers) {
          const $playButton = $controls.querySelector('.play-button');
          const $pauseButton = $controls.querySelector('.pause-button');
          const $fullscreenButton = $controls.querySelector('.fullscreen-button');
          const $soundButton = $controls.querySelector('.sound-button');
          const $volumeRange = $controls.querySelector('.volume-range');
          const $progressBar = $controls.querySelector('.progress-bar');
          const $progressBarWrapper =
            $controls.querySelector('.progress-wrapper');

          document.addEventListener('fullscreenchange', (e) => {
            if (e.target === $videoPlayer) {
              isFullScreen = !!document.fullscreenElement;
            }
          });

          $videoPlayer.addEventListener('mousemove', () => {
            if (!$video.paused) {
              $controllers.style.display = 'flex';
              clearTimeout(mouseTimeout);
              mouseTimeout = setTimeout(() => {
                $controllers.style.display = 'none';
              }, 2000);
            }
          });

          $controls.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if ($video.paused) {
              playVideo($video, $overlay, $controllers);
            } else {
              pauseVideo($video, $overlay, $controllers);
            }
          });

          $overlay.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if ($video.paused) {
              playVideo($video, $overlay, $controllers);
            }
          });

          $video.onended = () => {
            $overlay.style.display = 'flex';
            $controllers.style.display = 'none';
            $video.currentTime = 0;
          };

          if ($playButton) {
            insertSvg({
              node: $playButton,
              path: playIcon.path,
              viewbox: playIcon.viewbox,
              fill: playIcon.fill,
              stroke: playIcon.stroke,
              className: playIcon.className,
            });
          }

          if ($pauseButton) {
            insertSvg({
              node: $pauseButton,
              path: pauseIcon.path,
              viewbox: pauseIcon.viewbox,
              fill: pauseIcon.fill,
              stroke: pauseIcon.stroke,
              className: pauseIcon.className,
            });

            $pauseButton.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!$video.paused) {
                pauseVideo($video, $overlay, $controllers);
              }
            });
          }

          if ($fullscreenButton) {
            insertSvg({
              node: $fullscreenButton,
              path: fullScreenIcon.path,
              viewbox: fullScreenIcon.viewbox,
              fill: fullScreenIcon.fill,
              stroke: fullScreenIcon.stroke,
              className: fullScreenIcon.className,
            });

            $fullscreenButton.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();

              if (!isFullScreen) {
                if ($videoPlayer.requestFullscreen) {
                  $videoPlayer.requestFullscreen();
                } else if ($videoPlayer.webkitRequestFullscreen) {
                  /* Safari */
                  $videoPlayer.webkitRequestFullscreen();
                } else if ($videoPlayer.msRequestFullscreen) {
                  /* IE11 */
                  $videoPlayer.msRequestFullscreen();
                }
              } else {
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                  /* Safari */
                  document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                  /* IE11 */
                  document.msExitFullscreen();
                }
              }
            });
          }

          if ($progressBar && $progressBarWrapper) {
            $progressBarWrapper.setAttribute(
              'total-duration',
              parseVideoTime($video.duration)
            );

            $video.ontimeupdate = (e) => {
              $progressBar.style.width = `${
                (e.target.currentTime * 100) / e.target.duration
              }%`;
              $progressBarWrapper.setAttribute(
                'current-time',
                parseVideoTime(e.target.currentTime)
              );
            };

            $progressBarWrapper.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();

              const clientRect = e.target.getBoundingClientRect();
              const coordinateX = e.clientX - clientRect.left;
              const percentageSize = (coordinateX * 100) / clientRect.width;
              const result = (percentageSize * $video.duration) / 100;
              $video.currentTime = result;
            });
          }

          if ($soundButton && $volumeRange) {
            const $volumeInput = $volumeRange.querySelector('input');

            if ($volumeInput) {
              $volumeInput.onchange = (e) => {
                $video.volume = e.target.valueAsNumber / 100;

                if ($video.volume === 0) {
                  insertSvg({
                    node: $soundButton,
                    path: volumeIconMuted.path,
                    viewbox: volumeIconMuted.viewbox,
                    fill: volumeIconMuted.fill,
                    stroke: volumeIconMuted.stroke,
                    className: volumeIconMuted.className,
                  });
                } else if ($video.volume >= 2 / 3) {
                  insertSvg({
                    node: $soundButton,
                    path: volumeIconHigh.path,
                    viewbox: volumeIconHigh.viewbox,
                    fill: volumeIconHigh.fill,
                    stroke: volumeIconHigh.stroke,
                    className: volumeIconHigh.className,
                  });
                } else {
                  insertSvg({
                    node: $soundButton,
                    path: volumeIcon.path,
                    viewbox: volumeIcon.viewbox,
                    fill: volumeIcon.fill,
                    stroke: volumeIcon.stroke,
                    className: volumeIcon.className,
                  });
                }
              };
            }

            $soundButton.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();
              showVolumeRange($fullscreenButton, $soundButton, $volumeRange);
            });

            $volumeRange.addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();
            });

            $volumeRange.addEventListener('mouseleave', () => {
              hideVolumeRange($fullscreenButton, $soundButton, $volumeRange);
            });
          }
        }
      } else if (!$video.controls && $controls) {
        $controls.style.display = 'flex';
      }
      else{
        $video.controls = true;
        $controls.style.display = 'none';
      }
    }
  });
}

initVideoPlayers();
document.addEventListener(ApoEvents.PDP_NODE_CHANGE, initVideoPlayers);