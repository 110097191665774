export const MobileHeaderMenu = () => {
  document.addEventListener('DOMContentLoaded', () => {
    // open/close menu
    const $mobileMenuButton = document.querySelector(
      '[data-selector="header-menu-mobile-button"]'
    ) as HTMLButtonElement;

    const $mobileSearchField = document.querySelector('.header-search') as HTMLDivElement

    const $mobileMenuButtonIconMenu = document.querySelector(
      '[data-selector= "menu-icon-bars"]'
    ) as HTMLSpanElement;

    const $mobileMenuButtonIconClose = document.querySelector(
      '[data-selector= "menu-icon-close"]'
    ) as HTMLSpanElement;

    const $mobileMenu = document.querySelector(
      '[data-selector="header-menu-mobile"]'
    ) as HTMLDivElement;

    const $mobileMenuBackdrop = document.querySelector(
      '[data-selector="mobile-menu-backdrop"]'
    ) as HTMLDivElement;

    $mobileMenuButton?.addEventListener('click', () => {
      // Check if menu is open
      if ($mobileMenu.classList.contains('active')) {
        // Active, so deactivate
        $mobileMenu.classList.remove('active');
        $mobileSearchField.classList.remove('d-none');
        $mobileMenuButtonIconMenu.classList.remove('d-none');
        $mobileMenuButtonIconClose.classList.add('d-none');
        $mobileMenuBackdrop.classList.add('d-none');
        document.body.classList.remove('stop-scrolling');
        document.documentElement.classList.remove('stop-scrolling');
      } else {
        // Inactive, so activate
        $mobileSearchField.classList.add('d-none')
        $mobileMenu.classList.add('active');
        $mobileMenuButtonIconMenu.classList.add('d-none');
        $mobileMenuButtonIconClose.classList.remove('d-none');
        $mobileMenuBackdrop.classList.remove('d-none');
        document.body.classList.add('stop-scrolling');
        document.documentElement.classList.add('stop-scrolling');
      }
    });

    // Close when clicking backdrop
    $mobileMenuBackdrop?.addEventListener('click', () => {
      $mobileMenu.classList.remove('active');
      $mobileMenuButtonIconMenu.classList.remove('d-none');
      $mobileMenuButtonIconClose.classList.add('d-none');
      $mobileMenuBackdrop.classList.add('d-none');
    });

    // Mobile Menu links dropdowns and actions

    const mobileMenuListItem = document.querySelectorAll(
      '[data-selector="mobile-menu-list-item"]'
    );


    // get all links
    mobileMenuListItem?.forEach((mmli: any) => {
      const {level, parentindex, index} = mmli.dataset;

      // all categories with subcategories
      const expandableMenuWrapper = mmli.querySelector(
        '[data-selector="expandable-menu-wrapper"]'
      );

      if (!expandableMenuWrapper) return;

      // get link & children
      const link = expandableMenuWrapper.querySelector(
        '[data-selector="mob-link"]'
      ) as HTMLDivElement;
      const submenu = expandableMenuWrapper.querySelector(
        '[data-selector="mob-submenu"]'
      );

      // get all products link if open next level cat
      const allProductLinks = document.querySelectorAll<HTMLDivElement>('[data-selector="show-cat-products"]')
      const allProductLink = expandableMenuWrapper.querySelector('[data-selector="show-cat-products"]');
      const previousAllProductLink = document.querySelectorAll(`[data-selector="show-cat-products"][data-level="${Number(level) - 1}"]`)


      link?.addEventListener('click', (e: any) => {

        allProductLinks.forEach(el => el.classList.add('d-none'))

        const mobileMenuListItems: HTMLElement[] = Array.from(
          document.querySelectorAll(
            `[data-selector="mobile-menu-list-item"][data-level="${level}"][data-parentindex="${parentindex}"]`
          )
        );
        const parentSubMenu = link?.closest('[data-selector="mob-submenu"]');
        const internalMenuListItems: HTMLElement[] = Array.from(
          submenu?.querySelectorAll('[data-selector="mobile-menu-list-item"]')
        );

        let mobLinksActive: HTMLElement[] = [];

        // close action
        if (link?.classList.contains('active')) {
          if (e.target && e.target.dataset?.categoryLink) {
            window.location.href = e.target.dataset?.categoryLink;
            return;
          }

          link?.classList.remove('active');
          submenu?.classList.remove('active', 'active-border');

          internalMenuListItems?.forEach(
            (internalMenuListItem: HTMLElement) => {
              const internalLink = internalMenuListItem.querySelector(
                '[data-selector="mob-link"]'
              );
              const internalMenu = internalMenuListItem.querySelector(
                '[data-selector="mob-submenu"]'
              );
              internalMenuListItem.classList.remove('active', 'd-none');
              internalLink?.classList.remove('active');
              internalMenu?.classList.remove('active', 'active-border');
            }
          );
          parentSubMenu?.classList?.add('active-border');
          mobileMenuListItems?.forEach((item: HTMLElement) => {
            if (item.dataset.index !== index) {
              item.classList.remove('d-none');
            }
          });
          previousAllProductLink.forEach(el => el.classList.remove('d-none'))
        } else {
          link?.classList.add('active');
          submenu?.classList.add('active', 'active-border');

          parentSubMenu?.classList?.remove('active-border');

          // show all products link
          allProductLink && allProductLink.classList.remove('d-none');

          // hide all other main categories
          mobileMenuListItems?.forEach((item: HTMLElement) => {
            if (item.dataset.index !== index) {
              item.classList.add('d-none');
            }
          });
        }

        mobLinksActive = Array.from(
          $mobileMenu.querySelectorAll('[data-selector="mob-link"].active')
        );

        mobLinksActive?.forEach((mobLinkActive, index) => {
          index !== mobLinksActive.length - 1
            ? mobLinkActive.classList.remove('current')
            : mobLinkActive.classList.add('current');
        });
      });
    });

    // Desktop Menu links dropdowns and actions

    const $desktopSubMenuArray = document.querySelectorAll(
      '[data-selector="desktop-submenu-wrapper"]'
    );

    const $desktopMainLinkArray = document.querySelectorAll(
      '[data-selector="header-navigation-link"]'
    );

    const $desktopMainLinkLevel0Array = document.querySelectorAll(
      '[id^="header-navigation-link-0-"]'
    );


    $desktopMainLinkLevel0Array?.forEach((desktopMainLinkLevel0: Element) => {
      const dropdownTrigger = desktopMainLinkLevel0.closest(
        '[data-bs-toggle="dropdown"]'
      );

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'aria-expanded'
          ) {
            dropdownTrigger?.ariaExpanded &&
            JSON.parse(dropdownTrigger?.ariaExpanded)
              ? desktopMainLinkLevel0.classList.add('active')
              : desktopMainLinkLevel0.classList.remove('active');
          }
        });
      });

      if (dropdownTrigger) {
        observer.observe(dropdownTrigger, {
          attributes: true,
          attributeFilter: ['aria-expanded'],
          childList: false,
          subtree: false,
        });

        dropdownTrigger.addEventListener('click', () => {
          const activeLink = dropdownTrigger.querySelector('.header-navigation-link')
          const removeAllHighlightedLinks = () => $desktopMainLinkLevel0Array?.forEach(el => el.classList.remove('active'))

          activeLink?.classList.contains('active')
            ? (removeAllHighlightedLinks(), activeLink?.classList.remove('active'))
            : (removeAllHighlightedLinks(), activeLink?.classList.add('active'))

          document.body.classList.contains('overflow-hidden')
            ? document.body.classList.remove('overflow-hidden')
            : document.body.classList.add('overflow-hidden')

          // alle submenüs deaktivieren
          // und erstes aktivieren

        })
      }
    });

    $desktopMainLinkArray?.forEach((desktopMainLink) => {
      desktopMainLink.addEventListener('click', () => {

        $desktopSubMenuArray.forEach((currentMainLink) => {
          currentMainLink.classList.remove('active');
          currentMainLink.classList.remove('inactive');
        });

      });
    });

    $desktopSubMenuArray?.forEach((currentMainLink) => {
      const $linkTitle = currentMainLink.querySelector(
        '[data-selector="header-navigation-link"].desktop-submenu-link'
      );

      if ($linkTitle) {

        // Add click function
        $linkTitle.addEventListener('click', ev => {

          const targetId = (ev.currentTarget as HTMLDivElement).getAttribute('data-target')

          // only submenus have a targetId
          if (targetId) {
            const showSubmenu = document.querySelectorAll(`[data-cat-id="${targetId}"]`)
            showSubmenu.forEach(el => el.classList.remove('d-none'))

            currentMainLink.classList.remove('inactive');

            if (currentMainLink.classList.contains('active')) {
              currentMainLink.classList.remove('active');
              $desktopSubMenuArray.forEach((otherMainLinks) => {
                if (otherMainLinks.id !== currentMainLink.id) {
                  otherMainLinks.classList.remove('inactive');
                }
              });

            } else {
              currentMainLink.classList.add('active');
              const closeButton = currentMainLink.querySelector(
                '.inner-submenu-close'
              );

              const resetSubmenuStatus = () => {
                $desktopSubMenuArray.forEach((currentMainLink) => {
                  currentMainLink.classList.remove('active');
                  currentMainLink.classList.remove('inactive');
                });
              }

              closeButton?.addEventListener('click', () => {
                resetSubmenuStatus()
              }, {once: true});

              $desktopSubMenuArray.forEach((otherMainLinks) => {
                if (otherMainLinks.id !== currentMainLink.id) {
                  otherMainLinks.classList.add('inactive');
                }
              });
            }
          }
        });
      }
    });
  });
};

export const DesktopHeaderMenu = () => {
  document.addEventListener('DOMContentLoaded', () => {

    const headerNavigationContainers = document.querySelectorAll('.header-navigation-link-content-container')
    const desktopSubmenuWrappers = Array.from(document.querySelectorAll('[data-selector="desktop-submenu-wrapper"]'))

    const $desktopMenuBackdrop = document.querySelectorAll<HTMLDivElement>(
      '[data-selector="desktop-menu-backdrop"]'
    )

    const $desktopMenuCloseLevel1 = document.querySelectorAll('.btn-close-desktop-menu')

    const toggleHeaderNavigationPaddings = () => {

      const headerNavigationPaddingClasses = [
        'pt-spacing-16',
        'pb-spacing-48',
        'pe-spacing-16',
        'ps-spacing-24',
      ];

      const isSubmenuActive = desktopSubmenuWrappers.some(
        (desktopSubmenuWrapper) =>
          desktopSubmenuWrapper.classList.contains('active')
      );

      isSubmenuActive
        ? (headerNavigationContainers?.forEach(el => el.classList.remove(
          ...headerNavigationPaddingClasses)
        ), $desktopMenuCloseLevel1?.forEach(el => el.classList.add('d-none')))
        : (headerNavigationContainers?.forEach(el => el.classList.add(
          ...headerNavigationPaddingClasses)
        ), $desktopMenuCloseLevel1?.forEach(el => el.classList.remove('d-none')));
    };

    const $desktopMainLinkArray = document.querySelectorAll(
      '[data-selector="header-navigation-link"]'
    );

    $desktopMainLinkArray.forEach(el=>el.addEventListener('click',()=>{
      toggleHeaderNavigationPaddings()
    }))

    const $dropDownMenu = document.querySelectorAll('.header-navigation-link-content.dropdown-menu')

    const closeMenu = () => {
      $dropDownMenu.forEach(el => {
        const dropDownToggle = el.previousElementSibling
        dropDownToggle?.classList.remove('show')
        dropDownToggle?.setAttribute('aria-expanded', 'false');
        el && el?.classList.remove('show');
        // remove scroll-lock from content
        document.body.classList.contains('overflow-hidden') && document.body.classList.remove('overflow-hidden');
      })
    }

    $desktopMenuCloseLevel1?.forEach(el => el.addEventListener('click', () => {
      closeMenu()
    }))

    $desktopMenuBackdrop?.forEach((el) => el.addEventListener('click', () => {
      closeMenu()
    }));

    if (desktopSubmenuWrappers) {
      desktopSubmenuWrappers.forEach(($desktopSubmenuWrapper) => {
        $desktopSubmenuWrapper.addEventListener('click', ev => {
            toggleHeaderNavigationPaddings()
          }
        );
      });
      toggleHeaderNavigationPaddings();
    }

    const subCategoryLinks = document.querySelectorAll('[data-target-id]')

    subCategoryLinks.forEach(el => el.addEventListener('click', (ev) => {
      ev.preventDefault()

      const targetId = (ev.target as HTMLInputElement).getAttribute('data-target-id')
      const currentId = (ev.target as HTMLInputElement).getAttribute('data-current-id')

      const hideCat = document.querySelectorAll(`[data-cat-id="${currentId}"]`)
      const showCat = document.querySelectorAll(`[data-cat-id="${targetId}"]`)

      hideCat.forEach(el => el.classList.add('d-none'))
      showCat.forEach(el => el.classList.remove('d-none'))
    }))
  });
};

MobileHeaderMenu();
DesktopHeaderMenu();
