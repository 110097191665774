import { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClearRefinements } from "react-instantsearch";
import { HitCount } from "../ResultComponents/hitcount";
import { SearchFilter } from "./searchfilter";

interface SidebarFilterProps {
  scrollFunction: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Sidebar Filter - show instant filter for >lg breakpoints by using the searchfilter component
 *
 * @param scrollFunction - connection with onClick button to scroll to the top of the page
 * @constructor
 */
export const SidebarFilter = ({ scrollFunction }: SidebarFilterProps) => {
  const { t } = useTranslation();
  const [sidebarIsToggled, setSidebarIsToggled] = useState(false);

  const toggleSidebar = () => {
    setSidebarIsToggled((prevState) => !prevState);
  };

  return (
    <div className={"sidebar-filter"}>
      <button className="btn btn-secondary btn-lg w-100 d-flex justify-content-between mb-spacing-16" onClick={toggleSidebar} type="button">
        <span className="d-flex align-items-center">
          <i className="far fa-sliders fa-lg me-spacing-12"></i>
          <span className="ms-spacing-12">{t("search.filter.header")}</span>
        </span>
      </button>

      <SearchFilter classNames={"mb-spacing-16 " + (sidebarIsToggled ? "hide" : "show")} isDark={true} groupingSrc={"sidebar"} />
      <ClearRefinements
        classNames={{
          button: "btn btn-secondary w-100 btn-lg mb-spacing-24",
        }}
        translations={{
          resetButtonText: t("search.filter.button.reset") as string,
        }}
      />

      <button className="btn btn-primary btn-lg w-100" onClick={scrollFunction} type="button">
        <HitCount>{t("search.filter.button.count")}</HitCount>
      </button>
    </div>
  );
};
