import {ApoEvents} from "./apoEvents";

export class ApoIntersectionController {
  static instance: ApoIntersectionController;
  private _fixedProductHeader: HTMLElement = document.querySelector('[data-id="fixed-product-header"]')!;
  private _isAbTest: boolean = false;
  private _observer: IntersectionObserver;
  private _target: HTMLElement;
  private _fixedHeaderOffset: string = "-124px 0px 0px";

  constructor() {
    if (ApoIntersectionController.instance) {
      return ApoIntersectionController.instance;
    }
    ApoIntersectionController.instance = this;

    document.addEventListener(ApoEvents.PDP_NODE_CHANGE, () => {
      this.kill();
      this.init();
    })

    if (this._fixedProductHeader) this.init();
  }

  private init = () => {
    if ('IntersectionObserver' in window) {
      const options = {
        root: null,
        rootMargin: this._fixedHeaderOffset,
        threshold: 0
      };

      let previousY = 0
      let previousRatio = 0

      const callback = (entries: IntersectionObserverEntry[]) => {
        this._fixedProductHeader = document.querySelector('[data-id="fixed-product-header"]')!;
        this._isAbTest = (this._fixedProductHeader.parentNode as HTMLElement)?.getAttribute('data-test') === 'variation' || false;

        if (window.scrollY < 200 && !this._isAbTest) return;

        entries.forEach((entry: IntersectionObserverEntry) => {
          let isVisible: boolean = false;

          const currentY = entry.boundingClientRect.y
          const currentRatio = entry.intersectionRatio


          if (entry.intersectionRatio <= 0 && currentY < previousY && currentRatio < previousRatio) {
            this._fixedProductHeader.classList.add('visible');
            isVisible = true;
            if (!this._isAbTest) document.querySelector('header')?.classList.add('offcanvas');
            else {
              document.querySelector('#userlikeCustomTab span.btn-circle')?.classList.add('fixed-header-active');
              document.querySelector('.docyet-preload-button')?.classList.add('fixed-header-active');
            }
          } else {
            this._fixedProductHeader.classList.remove('visible');
            if (!this._isAbTest) document.querySelector('header')?.classList.remove('offcanvas');
            else {
              document.querySelector('#userlikeCustomTab span.btn-circle')?.classList.remove('fixed-header-active');
              document.querySelector('.docyet-preload-button')?.classList.remove('fixed-header-active');
            }
          }

          previousY = currentY
          previousRatio = currentRatio

          document.dispatchEvent(new CustomEvent(ApoEvents.FIXED_PRODUCT_HEADER_VISIBILITY_CHANGE, {
            detail: {
              visible: isVisible
            }
          }));
        });
      };
      this._observer = new IntersectionObserver(callback, options);
      this._target = document.querySelector('#right-product-section [data-selector="cart-button"]')!;

      if (this._target)
        this._observer.observe(this._target);
    }
  }

  private kill = () => {
    this._observer.disconnect();
  }
}