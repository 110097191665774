import { useTranslation } from "react-i18next";
import { OffcanvasMenu } from "./offcanvasmenu";
import { OffcanvasTrigger } from "./offcanvastrigger";

/**
 * Offcanvas Filter - collection of trigger button and menu to display the filter options inside an offcanvas element
 *
 * @constructor
 */
export const OffcanvasFilter = () => {
  const { t } = useTranslation();
  return (
    <>
      <OffcanvasTrigger buttonText={t("search.filter.offcanvas.trigger")} />
      <OffcanvasMenu headline={t("search.filter.offcanvas.triggerShort")} resetFilterText={t("search.filter.button.resetShort")} />
    </>
  );
};
