import Usercentrics, {BaseCategory, Service, TcfUISettingsLabels, UserDecision} from '@usercentrics/cmp-browser-sdk';
import bootstrap from 'bootstrap';
import i18next from "i18next";
import {ApoEvents} from "../apoEvents";

interface ConsentManagerLabels extends TcfUISettingsLabels {
  acceptButtonLabel: string
  buttons: {
    showSecondLayer: string
    acceptAll: string
    denyAll: string
    manageSettings: string
    save: string
    showVendorTab: string
  }
}

const UC_SETTINGS_ID: string = 'MujIcsgGJ';

const userAgent = navigator.userAgent;
const attDeclined = userAgent.includes('ATTDeclined');

export const consentManager = () => {
  if(attDeclined) {
    console.warn('ATT is declined, consent manager will not be shown');
    return;
  }
  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(() => {
      const UC = new Usercentrics(UC_SETTINGS_ID);
      const consentManagerWrapperModal = <HTMLElement>document.querySelector('[data-selector="consent-manager"]');
      const acceptButton = <HTMLElement>document.querySelector('[data-selector="accept_button"]')
      const manageSettingsButton = <HTMLElement>document.querySelector('[data-selector="manage_settings"]')
      const consentManagerStandardContainer = <HTMLElement>document.querySelector('[data-selector="consent_manager_standard"]')
      //  Settings modal
      const acceptSaveButton = <HTMLElement>document.querySelector('[data-selector="accept_save_button"]')
      const consentManagerSettingsContainer = <HTMLElement>document.querySelector('[data-selector="consent_manager_settings"]')
      const consentManagerSettingsAdvancedContainer = <HTMLElement>document.querySelector('[data-selector="consent_manager_settings_advanced"]')
      const consentManagerCategories = <HTMLElement>document.querySelector('[data-selector="consent_categories"]')
      const toggleCategoryButton = <HTMLInputElement>document.querySelector('[data-selector="toggle_categories"]')
      const toggleCategoryButtonLabel = <HTMLElement>document.querySelector('[data-selector="toggle_categories_label"]')
      const saveSettingsButton = <HTMLElement>document.querySelector('[data-selector="button_save_setting"]')
      const textEntrance = <HTMLElement>document.querySelector('[data-selector="text_entrance"]')
      const headlineEntrance = <HTMLElement>document.querySelector('[data-selector="headline_entrance"]')
      const textPrivacyHeadline = <HTMLElement>document.querySelector('[data-selector="privacy_settings_headline"]')
      const textPrivacyDescription = <HTMLElement>document.querySelector('[data-selector="privacy_settings_description"]')
      const linkImprint = <HTMLElement>document.querySelector('[data-selector="link_imprint"]')
      const linkPrivacy = <HTMLElement>document.querySelector('[data-selector="link_privacy"]')
      const changeLanguageValue = <HTMLInputElement>document.querySelector('[data-selector="language_text"]')
      const changeLanguage = <HTMLInputElement>document.querySelector('[data-selector="language"]')
      const changeConsentView = <HTMLInputElement>document.getElementById('change_consent_view')
      const cmAdvancedSideBar = <HTMLElement>document.querySelector('[data-selector="cm_advanced_sidebar"]')
      const cmAdvancedSideBarWrapper = <HTMLElement>document.querySelector('[data-selector="cm_advanced_sidebar_wrapper"]')
      const cmAdvancedMain = <HTMLElement>document.querySelector('[data-selector="cm_advanced_content"]')
      const toggleSidebar = <HTMLElement>document.querySelector('[data-selector="show_sidebar"]')
      const body = document.body
      const cookieOpenLink = <HTMLElement>document.querySelector('[data-selector="open_cookie_consent"]')
      const managerSettingsBackButton = <HTMLElement>document.querySelector('[data-selector="consent_manager_settings_back_button"]')
      const managerSettingsAdvancedBackButton = <HTMLElement>document.querySelector('[data-selector="consent_manager_settings_advanced_back_button"]')
      const searchInput = <HTMLInputElement>document.getElementById('consent-search-input')
      const searchButton = document.querySelector('[data-selector="search_button"]')

      let watchForChanges = false
      let consentHasChanged = false
      let categories: BaseCategory[];
      let consentedServicesList: any = [];
      // init service structure
      let services: UserDecision[] = []
      let updatedServices: UserDecision[] = []
      const isIosApp = window.navigator.userAgent.includes('ApoApp') && ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(
        /iPod/i)) || (navigator.userAgent.match(/iPad/i)))
      const isAndroidApp = window.navigator.userAgent.includes('ApoApp') && navigator.userAgent.includes('wv');

      // all services array
      const generateServices = () => {
        updatedServices = categories.map(
          category => category.services.map(
            service => ({serviceId: service.id, status: false})
          )
        ).flat(1)
      }

      const triggerConsentChangeEvent = () => {
        document.dispatchEvent(new CustomEvent(ApoEvents.USERCENTRICS_CONSENT_STATUS_CHANGED, {
          'detail': {
            services: consentedServicesList,
          }
        }));
      }


      UC.init().then(() => {
        const pathname = window.location.pathname
        // handle modal visibility
        const toggleElement = (element: HTMLElement, open: boolean = true) => {
          if (element) {
            if (open) {
              element.classList.add('show');
              element.classList.contains('d-none') && element.classList.remove('d-none');
              element.style.display = 'flex';

              if (pathname === "/datenschutz" || pathname === "/impressum") {
                consentManagerWrapperModal.style.pointerEvents = "none"
                consentManagerWrapperModal.classList.add('bg-transparent')
              } else {
                body.classList.add('overflow-hidden')
                consentManagerWrapperModal.classList.remove('bg-transparent')
              }

              element.tabIndex = -1;
              element.focus();
            } else {
              element.classList.remove('show');
              element.classList.add('d-none');
              element.style.display = 'none';
              element.blur();
              body.classList.contains('overflow-hidden') && document.body.classList.remove('overflow-hidden');
            }
          }
        }

        const truncate = (str: string, max = 10) => {
          const array = str.trim().split(' ');

          return [
            array.slice(0, max).join(' '),
            array.slice(max).join(' ')
          ]
        };

        const addReadMoreButton = () => {
          const readMoreLink = document.createElement('a')
          readMoreLink.textContent = " Mehr lesen"
          readMoreLink.href = "#"
          readMoreLink.dataset.selector = "read_more"
          readMoreLink.classList.add('hide_expanded')

          const readMoreButton = document.querySelector('[data-selector="read_more"]')
          const moreText = document.querySelector('.more_text')

          readMoreLink.setAttribute('data-bs-toggle', 'collapse')
          readMoreLink.setAttribute('data-bs-target', '.more_text')

          !readMoreButton && textEntrance.append(readMoreLink)
        }

        categories = UC.getCategoriesBaseInfo();
        // open initial CM Screen
        if (UC.getIsConsentRequired()) {
          toggleElement(consentManagerWrapperModal)
        } else {
          UC.getServicesFullInfo().then((el) => {
            consentedServicesList = el
            triggerConsentChangeEvent();
          });
        }

        // all EventListeners

        // accept all Services and close CM
        acceptButton.addEventListener('click',
          () => UC.acceptAllServices().then(() => {
            UC.getServicesFullInfo().then((el) => {
              consentedServicesList = el
              triggerConsentChangeEvent();
            });
            // close Modal
            toggleElement(consentManagerWrapperModal, false)
          }))

        // open settings area
        manageSettingsButton.addEventListener('click',
          () => {
            toggleElement(consentManagerStandardContainer, false)
            toggleElement(consentManagerSettingsContainer)
            consentHasChanged = true
          })

        // settings to advanced
        changeConsentView.addEventListener('change',
          () => {
            toggleElement(consentManagerSettingsContainer, false)
            toggleElement(consentManagerSettingsAdvancedContainer)
            getConsentManagerAdvanced().then(() => {
            })
          })

        // button consent categories
        saveSettingsButton.addEventListener('click', () => {
          if (consentHasChanged) {
            if (services.length !== 0) {
              UC.updateServices(services).then(() => {
                consentManagerWrapperModal && toggleElement(consentManagerWrapperModal, false)
              })
            } else {
              UC.updateServices(updatedServices).then(() => {
                consentManagerWrapperModal && toggleElement(consentManagerWrapperModal, false)
              })
            }
          } else {
            closePreviousOpenedModals()
          }

          UC.getServicesFullInfo().then((el) => {
            consentedServicesList = el
            triggerConsentChangeEvent();
          });
          consentHasChanged = false
        })


        //button advanced Consent settings
        acceptSaveButton?.addEventListener('click', () => {
          // if (watchForChanges) {
          toggleElement(consentManagerWrapperModal, false)
        })

        // manager settings back button
        managerSettingsBackButton?.addEventListener('click', () => {
          toggleElement(consentManagerSettingsContainer, false)
          toggleElement(consentManagerStandardContainer)
        })

        // manager settings advanced back button
        managerSettingsAdvancedBackButton?.addEventListener('click', () => {
          toggleElement(consentManagerSettingsAdvancedContainer, false)
          toggleElement(consentManagerSettingsContainer)
          changeConsentView.checked = false
        })

        // show sidebar in settings advanced screen
        toggleSidebar.addEventListener('click', () => cmAdvancedSideBarWrapper.classList.toggle('d-none'))

        //close all opened Modals
        const closePreviousOpenedModals = () => {
          toggleElement(consentManagerWrapperModal, false)
          toggleElement(consentManagerStandardContainer, false);
          toggleElement(consentManagerSettingsContainer, false);
          toggleElement(consentManagerSettingsAdvancedContainer, false);
        }

        const consentUpdate = () => {
          saveSettingsButton.innerHTML = consentHasChanged ? i18next.t('common.button.save') : i18next.t('common.button.close')
          acceptSaveButton.innerHTML = consentHasChanged ? i18next.t('common.button.saveAndClose') : i18next.t('common.button.close')
        }

        //open Cookie Consent Footer
        cookieOpenLink?.addEventListener('click', (evt) => {
          evt.preventDefault();
          generateServices()
          closePreviousOpenedModals()
          toggleElement(consentManagerWrapperModal);
          toggleElement(consentManagerSettingsContainer);
          watchForChanges = true
          consentUpdate();
        })

        // get language global
        const getLanguage = () => UC.getSettingsCore().language.selected

        // paint Screen
        const startConsentManager = () => {
          const textContent = <ConsentManagerLabels>UC.getSettingsLabels();
          const labels = UC.getAriaLabels();
          const settings = UC.getSettingsCore();
          const languageSelected = settings.language.selected;
          let categories = UC.getCategoriesBaseInfo();

          // text not available at backend
          toggleCategoryButtonLabel.innerHTML = languageSelected === 'de' ? "Alles auswählen" : "All categories"

          // First Layer Text Data
          // First Layer Text Data
          if (window.innerWidth < 576) {

            const text = truncate(textContent.firstLayer.description.default, 16)
            const consentText = textEntrance

            consentText.innerHTML = text[0];
            addReadMoreButton();

            const hiddenText = document.createElement('span');
            hiddenText.classList.add('more_text', 'collapse');
            hiddenText.innerHTML = text[1]
            consentText.append(hiddenText)

            const hideElements = document.querySelectorAll('.hide_expanded')

            hiddenText.addEventListener('show.bs.collapse', function () {
              hideElements.forEach(el => el.classList.add('d-none'))
            })

          } else {
            textEntrance.innerHTML = textContent.firstLayer.description.default
          }


          headlineEntrance.innerHTML = textContent.firstLayer.title
          linkImprint.innerHTML = textContent.links.imprint.label as string
          linkPrivacy.innerHTML = textContent.links.privacyPolicy.label as string
          manageSettingsButton.innerHTML = textContent.buttons.showSecondLayer

          // Second Layer Text Data
          textPrivacyHeadline.innerHTML = textContent.secondLayer.title
          textPrivacyDescription.innerHTML = textContent.secondLayer.description
          saveSettingsButton.innerHTML = labels.saveButton
          acceptSaveButton.innerHTML = textContent.buttons.save

          const googleLink = document.querySelector('[href="https://policies.google.com/technologies/partner-sites"]')

          googleLink?.addEventListener('click', (ev: Event) => {
            toggleElement(consentManagerStandardContainer, false)
            toggleElement(consentManagerSettingsAdvancedContainer)
            getConsentManagerAdvanced().then(() => {
              ev.preventDefault()
              const entry = <HTMLAnchorElement>document.querySelector(`a[href="#u7hYX1u44yMYC5"]`)
              entry && navigateToContent(entry, ev);
            })
          })
        }

        const getConsentManagerCategories = () => UC.getServicesFullInfo().then((el) => {
          consentedServicesList = el

          // setting screen display category select
          let allCategoryLabels: string = ''
          const consentCat = document.createElement('div')
          categories.forEach((el) => allCategoryLabels += `
                      <div class="form-check form-switch dark col ${el.slug === "essential" ? 'order-1' : 'mb-spacing-32'} flex-column no-gap">
                          <div class="d-flex w-100 align-items-center mb-spacing-8">
                            <input class="form-check-input" type="checkbox" data-selector="categories" data-slug="${el.slug}" ${el.slug === "essential" && 'disabled checked'}/>
                            <label class="form-check-label d-flex justify-content-between ms-spacing-20 w-100">
                            <div data-selector="cm_label" class="fw-bold">${el.label}</div> <div data-selector="open_advanced" data-target="${el.slug}"><i class="fas fa-circle-info"></i></div></label>
                          </div>
                         <p class="ms-spacing-12 footnote">${el.description}</p>
                      </div>`)

          // empty for repaint
          consentManagerCategories.innerHTML = ''

          //append content
          consentCat.classList.add('d-flex', 'flex-column', 'consent-categories')
          consentCat.innerHTML = allCategoryLabels
          consentManagerCategories.appendChild(consentCat)


          // add eventListener after create element
          const openAdvanced = document.querySelectorAll<HTMLElement>('[data-selector="open_advanced"]')

          openAdvanced?.forEach((el) => el.addEventListener('click', (ev: Event) => {
            toggleElement(consentManagerSettingsContainer, false)
            toggleElement(consentManagerSettingsAdvancedContainer)
            getConsentManagerAdvanced().then(() => {

              const scrollTo = el.getAttribute('data-target')

              switch (scrollTo) {
                case 'marketing':
                  const marketingEntry = <HTMLAnchorElement>document.querySelector(`a[href="#w-cwb3XM"]`)
                  marketingEntry && navigateToContent(marketingEntry, ev);
                  break;
                case 'essential':
                  const essentialEntry = <HTMLAnchorElement>document.querySelector(`a[href="#H1Vl5NidjWX"]`)
                  essentialEntry && navigateToContent(essentialEntry, ev);
                  break;
                case 'functional' :
                  const functionalEntry = <HTMLAnchorElement>document.querySelector(`a[href="#S1krl5Eo_obm"]`)
                  functionalEntry && navigateToContent(functionalEntry, ev);
                  break;
              }
            })
          }))
        }).then(() => {
          const categoryEntriesElement = document.querySelectorAll<HTMLInputElement>('[data-selector="categories"]:not([data-slug="essential"])')

          // consent categories
          categoryEntriesElement.forEach(el => el.addEventListener('change', () => {
            consentHasChanged = true
            consentUpdate()

            const cats = categories.find((category) => category.slug === el.dataset.slug)
            cats?.services.forEach((service) => {
              services.push({serviceId: service.id, status: el.checked})
            })

            // no double serviceID
            services = [...new Map(services.map((m) => [m.serviceId, m])).values()]
          }))
        })


        // todo: apply service status to UI checkbox (currently its being overwritten)
        const getConsentManagerAdvanced = () => UC.getServicesFullInfo().then((el) => {
          consentedServicesList = el

          // marketing apps
          cmAdvancedSideBar.innerHTML =
            `<div class="h5 text-aco-blue">Marketing</div> <ul class="ps-spacing-16">` +
            el.filter(el => el.categorySlug === 'marketing')
              .map((el) => `<li><a href="#${el.id}">${el.name}</a></li>`)
              .join('') + '</ul>'

          // essential apps
          cmAdvancedSideBar.innerHTML +=
            `<div class="h5 text-aco-blue">Essential</div> <ul class="ps-spacing-16">` +
            el.filter(el => el.categorySlug === 'essential')
              .map((el) => `<li><a href="#${el.id}">${el.name}</a></li>`)
              .join('') + '</ul>'

          // functional apps
          cmAdvancedSideBar.innerHTML +=
            `<div class="h5 text-aco-blue">Functional</div> <ul class="ps-spacing-16">` +
            el.filter(el => el.categorySlug === 'functional')
              .map((el) => `<li><a href="#${el.id}">${el.name}</a></li>`)
              .join('') + '</ul>'

          cmAdvancedMain.innerHTML = el.map((el) =>
            `<div data-hash="#${el.id}" class="mb-spacing-64">
                  <div class="h3 text-capitalize pb-spacing-4">${el.categorySlug}</div>
                  <p>${getLanguage() === 'de' ? `Klicken Sie hier, um auf allen Domains des verarbeitenden Unternehmens zu widerrufen:` : `Click here to revoke on all domains of the processing company:`}</p>
                  <a class="text-aco-light-blue" href="${el.dataProtectionOfficer}">${el.dataProtectionOfficer}</a>
                  <div class="form-check mt-spacing-32">
                    <input type="checkbox" ${el.categorySlug === "essential" && `disabled checked`} value="${el.id}" class="form-check-input" name="${el.id}" data-selector="checkbox_service"  ${el.consent.status ? `checked` : ''}/>
                    <label for="${el.id}" class="mb-spacing-16"><h5 class="ps-spacing-12 mb-spacing-0">${el.name}</h5></label>
                  </div>
                  <p class="mb-spacing-32">${el.description}</p>
                  <p class="mb-spacing-16"><b>${getLanguage() === 'de' ? `Verabeitene Unternehmen` : `Processed Companies`}</b></p>
                  <p>${el.processingCompany && el.processingCompany.name}</p>
                  <p class="mb-spacing-24">${el.processingCompany && el.processingCompany.address}</p>
                  <p class="text-aco-light-blue mb-spacing-16">${getLanguage() === 'de' ? `Gespeicherte Informationen` : `saved information`}</p>
                  <p class="pb-spacing-16">${el.technologiesUsed.map((el) => ` ${el}`)}</p>
                  ${el.consent.history && el.consent.history.map(el =>
              `<div class="d-flex w-100 justify-content-between pb-spacing-16 pe-spacing-56">
                ${el.status
                ? `<div class="d-flex align-items-center"><div class="status-round-green status-round me-spacing-12"></div><p class="small">Ja</p></div>`
                : `<div class="d-flex align-items-center"><div class="status-round-red status-round me-spacing-12"></div><p class="small">Nein</p></div>`}
                  <p class="small">${new Date(el.timestamp).toLocaleDateString('en-US')},${new Date(el.timestamp).toLocaleTimeString('de-DE')}</p></div>`
            ).join('')}
                  </div>`).join('')
        }).then(() => {
          cmAdvancedSideBar.querySelectorAll('a').forEach(el => el.addEventListener('click', evt => navigateToContent(el, evt)))
          cmAdvancedMain.querySelectorAll<HTMLInputElement>('input[data-selector="checkbox_service"]').forEach((el) => el.addEventListener('click', () => updateServices(el)))
        })


        const searchAction = (el: Service[]) => {
          const result = searchInput.value.length > 0 && el.filter((result) => result.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0)
          return result && result.length > 0 && '#' + result[0].id
        }

        const getAllServices = async () => await UC.getServicesFullInfo()
        const searchWrapper = searchInput?.parentElement?.parentElement as HTMLElement

        searchButton?.addEventListener('click', (e) => {
          searchInput.value.length > 0 && getAllServices().then(el => {
            searchConsent(e)
          })
        })

        searchInput.addEventListener('focus', () => {
          searchWrapper.style.color = "rgb(0, 51, 153)"
        })

        searchInput.addEventListener('focusout', () => {
          searchWrapper.style.color = "#FFF"
        })

        const searchConsent = (e: Event) => {

          getAllServices().then(el => {
            const serviceHash = searchAction(el)
            const service = serviceHash && <HTMLElement>document.querySelector(`[data-hash="${serviceHash}"]`)

            const offset = service && service.offsetTop

            if (offset) {
              e.preventDefault()
              cmAdvancedMain.scrollTop = offset - cmAdvancedMain.offsetTop
            } else {
              searchInput.style.border = "1px solid red"
            }
          })
        }

        searchInput && searchInput.addEventListener('keypress', (e) => {

          const element = e.target as HTMLInputElement
          searchInput.style.border = "1px solid transparent"

          if (e.key === "Enter" && element?.value.length > 2) {
            searchConsent(e)
          }
        })


        // sidebar navigate in main Wrapper
        const navigateToContent = (el: HTMLAnchorElement, event: Event) => {
          event.preventDefault();
          const href = el.hash;
          const element = <HTMLElement>cmAdvancedMain.querySelector(`[data-hash="${href}"]`)
          const offset = element.offsetTop
          cmAdvancedMain.scrollTop = offset - cmAdvancedMain.offsetTop
        }


        // update services for saving at advanced Consent Manager Screen
        const updateServices = (serviceToChange: HTMLInputElement) => {

          updatedServices = consentedServicesList.map((el: Service) => ({
            serviceId: el.id,
            status: el.id === serviceToChange.value ? !el.consent.status : el.consent.status
          }))

          UC.updateServices(updatedServices).then(() => {
            UC.getServicesFullInfo().then((el) => {
              consentedServicesList = el;
            });
          })
        }


        // choose all categories
        toggleCategoryButton.addEventListener('change',
          () => {
            const categoryEntriesElement = document.querySelectorAll<HTMLInputElement>('[data-selector="categories"]:not([data-slug="essential"])')

            consentHasChanged = true
            consentUpdate()

            toggleCategoryButton.checked
              ? categoryEntriesElement.forEach(el => el.checked = true)
              : categoryEntriesElement.forEach(el => el.checked = false)

            // empty services
            services.length = 0


            // get user decision and save in service var
            categories.forEach((category) => {
              !category.isEssential && category.services.forEach((el) => {
                services.push({serviceId: el.id, status: toggleCategoryButton.checked})
              })
            })
          }
        )
        startConsentManager()
        getConsentManagerCategories()
        generateServices()
      })
    }, 4000);
  });
}

consentManager()
