import { useSearchBox } from "react-instantsearch";
import React, { useMemo, useRef, useState } from "react";
import { CONSTANTS } from "../constants";

export const SearchBox = (props: any) => {
  const { refine } = useSearchBox(props);
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('query') || '';
  });

  const [timer, setTimer] = useState<number>();

  const searchpageUrl = useMemo(() => {
    let searchpageUrl = "search.php";
    let searchParameter = encodeURI(`?query=${inputValue}`);

    if (CONSTANTS.searchpage_enabled === "false") {
      searchpageUrl = "advanced_search_result.php";
      searchParameter = "?keywords=" + inputValue;
    }

    return searchpageUrl + searchParameter;
  }, [inputValue]);

  function setQuery(newQuery: any) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  function inputChanged(event: { target: { value: React.SetStateAction<string> } }) {
    let text = event.target.value;
    setInputValue(text);

    clearTimeout(timer);

    const newTimer = window.setTimeout(() => {
      if (text.length >= 3) {
        setQuery(text);
      }
    }, 200);

    setTimer(newTimer);
  }

  return (
    <form
      action=""
      role="search"
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (inputValue || inputValue.length >= 3) {
          window.location.assign(searchpageUrl);
          return;
        }
      }}
      onReset={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setQuery("");
      }}
    >
      <div className="d-flex border-rounded-24 bg-aco-gray-06">
        <button type="submit" className="btn btn-circle btn-ghost" aria-label="Button zum Starten der Suche">
          <i className="far fa-search fa-lg icon-start"/>
        </button>

        <input
          className="bg-transparent w-100 border-0"
          ref={inputRef}
          aria-label="Suchbegriff eingeben"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={inputChanged}
        />
        <button
          type="button"
          className="btn search-close"
          style={{display: inputValue.length > 0 ? "inline" : "none"}}
          onClick={() => setQuery("")}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
    </form>
  );
};