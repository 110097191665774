const $ratingStarsBlocks = document.querySelectorAll('.rating-stars');

Array.from($ratingStarsBlocks).forEach(($ratingStarsBlock) => {
  const totalStars = 5;
  const stars = new Array(totalStars).fill(null);
  const ratingAverage = parseFloat(
    $ratingStarsBlock.dataset.ratingAverage.replace(',', '.')
  );
  const starSize = $ratingStarsBlock.dataset.starSize;
  const fullStars = Math.trunc(ratingAverage);
  const partialStar = ratingAverage - fullStars;
  stars.forEach((_, index) => {
    const $star = document.createElement('span');
    $star.style.width = starSize;
    $star.style.height = starSize;
    if (index < fullStars) {
      $star.classList.add('icon-star-full');
    } else if (index <= fullStars && partialStar !== 0) {
      $star.classList.add('icon-star-half');
    } else {
      $star.classList.add('icon-star-empty');
    }
    $ratingStarsBlock.appendChild($star);
  });
});
