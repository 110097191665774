import React from 'react';
import ReactDOM from 'react-dom/client';
import "./web-components/CartButton";
import "./web-components/CartCounter"
import "./web-components/AutoSuggest"
import {initRatingStarsComponent, RatingStarsOptionsType} from "./web-components/RatingStars"
import {initWishListComponent, WishListOptionsType} from "./web-components/WishList"
import reportWebVitals from './reportWebVitals';
import './i18n';
import {initHeaderIconComponents} from "./ui/HeaderIcons";

/**
 * Initialize
 */
export class InitCategory {
  constructor({Category}: any, categoryElement: HTMLElement | null) {
    if (categoryElement instanceof HTMLElement) {
      const rootCategory = ReactDOM.createRoot(categoryElement);
      const categoryID = categoryElement.getAttribute('data-category')
      const products = JSON.parse(categoryElement?.getAttribute('data-products') || '""')

      rootCategory.render(
        <React.StrictMode>
          <Category id={categoryID} products={products} />
        </React.StrictMode>
      )
    }
  }
}

export class InitCart {
  constructor({BasicCart}: any, cartElement: HTMLElement | null) {
    if (cartElement instanceof HTMLElement) {
      const rootCart = ReactDOM.createRoot(cartElement)

      rootCart.render(
        <React.StrictMode>
          <BasicCart />
        </React.StrictMode>
      )
    }
  }
}

export class InitCartOverlay {
  constructor({CartOverlay}: any, cartOverlayElement: HTMLElement | null) {
    if (cartOverlayElement instanceof HTMLElement) {
      const rootCartOverlay = ReactDOM.createRoot(cartOverlayElement)

      rootCartOverlay.render(
        <React.StrictMode>
          <CartOverlay />
        </React.StrictMode>
      )
    }
  }
}

export class InitSearch {
  constructor({Search}: any, searchWrapper: HTMLElement | null) {
    if (searchWrapper instanceof HTMLElement) {
      const rootSearch = ReactDOM.createRoot(searchWrapper)

      rootSearch.render(
        <React.StrictMode>
          <Search />
        </React.StrictMode>
      )
    }
  }
}

export class InitSearchresults {
  constructor({Searchresults}: any, searchresultsWrapper: HTMLElement | null) {
    if (searchresultsWrapper instanceof HTMLElement) {
      const rootSearchresults = ReactDOM.createRoot(searchresultsWrapper)

      rootSearchresults.render(
        <React.StrictMode>
          <Searchresults />
        </React.StrictMode>
      )
    }
  }
}

export class InitWishList {
  constructor(options: null | WishListOptionsType = null) {
    if (options === null) {
      initWishListComponent()
    } else {
      initWishListComponent(options)
    }
  }
}

export class InitHeaderIcons {
  constructor() {
    initHeaderIconComponents()
  }
}

export class InitRatingStars {
  constructor(options: null | RatingStarsOptionsType = null) {
    if (options === null) {
      initRatingStarsComponent()
    } else {
      initRatingStarsComponent(options)
    }
  }
}

export class InitProductReminder {
  constructor({ProductReminder}: any, productReminderElement: HTMLElement | null) {

    if (productReminderElement instanceof HTMLElement) {
      const rootProductReminder = ReactDOM.createRoot(productReminderElement)

      rootProductReminder.render(
        <React.StrictMode>
          <ProductReminder />
        </React.StrictMode>
      )
    }
  }
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
