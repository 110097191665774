import React, { FC } from 'react';
import classNames from 'classnames';
import {
  Cart,
  CartLinksType,
  OrderBonusPointsType,
} from '@apo-app/app/entity/Cart';
import { Heading } from '@apo-app/app/components/Headings';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export type CartTotalsProps = {
  cart: Cart;
};

export type CartTotalsBonusPointsProps = {
  orderBonusPoints: OrderBonusPointsType[];
};

export type CartTotalsItemProps = {
  name: string;
  description?: string;
  value: string;
  valueClassName?: string;
  nameClassName?: string;
  className?: string;
};

export type CartTotalsButtonsProps = {
  links: CartLinksType;
  login: boolean;
};

export const CartTotals: FC<CartTotalsProps> = ({ cart }) => {
  function onlyRxProducts() {
    return (
      cart.cartItems.filter(
        (cartItems) => cartItems.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '1'
      ).length === cart.cartItems.length
    );
  }
  return (
    <>
      <CartTotalsSummary cart={cart} />
      {!onlyRxProducts() && cart.orderBonusPoints.some((item) => item.bonus_points) && (
        <CartTotalsBonusPoints orderBonusPoints={cart.orderBonusPoints} />
      )}
      <CartTotalsButtons links={cart.links} login={cart.loggedIn} />
    </>
  );
};

export const CartTotalsSummary: FC<CartTotalsProps> = ({ cart }) => {
  function hasRxProducts() {
    return (
      cart.cartItems.filter(
        (cartItems) => cartItems.PRODUCTS_ATTRIBUTES.PRESCRIPTION === '1'
      ).length > 0
    );
  }

  return (
    <>
      <div className="px-spacing-12">
        <Heading
          element={'h3'}
          className={classNames(
            'mb-spacing-32 text-aco-white'
          )}
        >
          Bestellübersicht
        </Heading>
        <CartTotalsSummaryItem
          name={'Zwischensumme'}
          value={cart.orderSubTotal}
        />
        {'bonus_points_amount_formatted' in cart.orderBonusPointsCashing && (
          <CartTotalsSummaryItem
            name={'Eingelöste apoPunkte'}
            value={cart.orderBonusPointsCashing.bonus_points_amount_formatted}
          />
        )}
        <CartTotalsSummaryItem
          name={'Versandkosten'}
          value={cart.freeShipping ? 'Gratis' : cart.shippingCostsCalc}
          valueClassName={cart.freeShipping ? 'text-aco-yellow' : ''}
        />
        {cart.orderVoucher !== '' && (
          <CartTotalsSummaryItem name={'Gutschein'} value={cart.orderVoucher} />
        )}
        <div className="cart-totals-total-price">
          <CartTotalsSummaryItem
            name={'Gesamtsumme'}
            description={'Inkl. MwSt.'}
            value={cart.orderTotal}
            valueClassName="order-totals pb-md-spacing-12"
          />
        </div>

        {!hasRxProducts() && (
          <>
            <CartTotalsSummaryItem
              name={'Ihre Ersparnis beträgt bei diesem Kauf'}
              value={cart.orderTotalSaving}
              className="pt-spacing-20"
            />
          </>
        )}
      </div>
    </>
  );
};

export const CartTotalsSummaryItem: FC<CartTotalsItemProps> = ({
  name,
  description,
  value,
  valueClassName,
  nameClassName,
  className,
}) => {
  return (
    <Row
      className={classNames(
        'footnote-medium d-flex justify-content-between',
        className
      )}
    >
      <Col className={classNames('pb-spacing-8', nameClassName)}>
        {!description ? (
          name
        ) : (
          <>
            <div>{name}</div>
            <div className="text-aco-gray-03 micro mt-spacing-2">
              {description}
            </div>
          </>
        )}
      </Col>
      <Col className={classNames('text-end', valueClassName)}>{value}</Col>
    </Row>
  );
};

export const CartTotalsBonusPoints: FC<CartTotalsBonusPointsProps> = ({
  orderBonusPoints,
}) => {
  return (
    <Row
      className={classNames(
        'justify-content-md-start',
        'my-spacing-40',
        'mx-spacing-0'
      )}
    >
      <Col
        xs={12}
        md={8}
        className={classNames(
          'bg-aco-blue',
          'border-rounded-20',
          'p-spacing-16'
        )}
      >
        <Row className="d-flex flex-row align-items-center">
          <Col xs={1} className="apo-punkte-icon mt-spacing-0">
            <FontAwesomeIcon icon={faCirclePlus as IconDefinition} />
            <FontAwesomeIcon icon={faCirclePlus as IconDefinition} />
          </Col>
          <Col xs={10}>
            <ul
              className={classNames(
                'list-unstyled',
                'footnote',
                'text-aco-yellow',
                'mb-spacing-0'
              )}
            >
              {orderBonusPoints.map((item, index) => (
                <li key={index}>
                  {item.name} {item.bonus_points_formatted}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const CartTotalsButtons: FC<CartTotalsButtonsProps> = ({
  links,
  login,
}) => {
  return (
    <Col
      className={classNames(
        'mt-spacing-32',
        'd-flex',
        'flex-column',
        'justify-content-between',
        'align-items-center',
        'flex-md-row',
        'w-100'
      )}
    >
      <Col
        xs={12}
        md={6}
        className="d-flex justify-content-center pe-md-spacing-12 mb-spacing-24 mb-md-spacing-0"
      >
        {login ? (
          <Button
            variant={'secondary'}
            href={links.ADD_CART_TO_WATCH_LIST_LINK}
            size={'lg'}
            className="w-100"
          >
            Warenkorb merken
          </Button>
        ) : (
          ''
        )}
      </Col>
      <Col
        xs={12}
        md={6}
        className="d-flex justify-content-center ps-md-spacing-12"
      >
        <Button className="w-100" href={links.CHECKOUT_LINK} size={'lg'}>
          Zur Kasse
        </Button>
      </Col>
    </Col>
  );
};
