import {
  CartLinksType,
  OrderBonusPointsType,
  CouponCodeType,
  CashingBonusPointsType,
  OrderBonusPointsInfoType,
  CartItemType,
  CartFromApi,
  CartAmazonType
} from "./cart-types"

export class Cart {
  constructor(
    public links: CartLinksType,
    public amazon: CartAmazonType,
    public orderSubTotal: string,
    public orderTotalSaving: string,
    public orderTotal: string,
    public orderVoucher: string,
    public couponCode: CouponCodeType[] | [],
    public initCheckoutMethods: any[],
    public shippingCostsCalc: string,
    public freeShipping: boolean,
    public untilFreeShipping: string,
    public freeShippingByAmount: string,
    public customsTariffExtraChargeTitle: string,
    public customsTariffExtraChargeText: string,
    public loggedIn: boolean,
    public orderBonusPoints: OrderBonusPointsType[] | [],
    public orderPrescriptionBonusPoints: any[],
    public orderBonusPointsCashing: CashingBonusPointsType | [],
    public orderBonusPointsInfo: OrderBonusPointsInfoType | [],
    public accountBonusPoints: '',
    public cartMessage: string,
    public cartItemsAmount: number,
    public cartItems: CartItemType[] | []
  ) {
  }

  static fromApiObject(object: CartFromApi) {
    return new Cart(
      object.LINKS,
      object.AMAZON,
      object.ORDER_SUB_TOTAL,
      object.ORDER_TOTAL_SAVING,
      object.ORDER_TOTAL,
      object.ORDER_VOUCHER,
      object.VOUCHER_ADVANCED_CODES_INFORMATION,
      object.INIT_CHECKOUT_METHODS,
      object.SHIPPING_COSTS_CALC,
      object.FREE_SHIPPING,
      object.UNTIL_FREE_SHIPPING,
      object.FREE_SHIPPING_BY_AMOUNT,
      object.CUSTOMS_TARIFF_EXTRA_CHARGE_TITLE,
      object.CUSTOMS_TARIFF_EXTRA_CHARGE_TEXT,
      object.LOGGED_IN,
      object.ORDER_BONUS_POINTS_CREDIT,
      object.ORDER_PRESCRIPTION_BONUS_POINTS_CREDIT,
      object.ORDER_BONUS_POINTS_CASHING,
      object.ORDER_BONUS_POINTS_INFO,
      object.ACCOUNT_BONUS_POINTS,
      object.CART_MESSAGE,
      object.CART_ITEMS_AMOUNT,
      object.CART_ITEMS,
    );
  }
}
