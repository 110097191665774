import type { Hit as AlgoliaHit } from "instantsearch.js/es/types/results";
import { useTranslation } from "react-i18next";
import { Highlight, useHits, UseHitsProps } from "react-instantsearch";

interface HitProps {
  hit: AlgoliaHit<{
    content_title: string;
    objectID: number;
    content_link: string;
  }>;
}

interface UseHitsPropsCustom extends UseHitsProps {
  hits?: HitProps[];
}
export const ContentHits = (props: UseHitsPropsCustom) => {
  const { t } = useTranslation();
  const { hits, results } = useHits(props);

  return (
    <div className={`${results!.query === "" || results!.nbHits === 0 ? "d-none" : ""}`}>
      <h3 className={"mb-spacing-16"}>{t("search.autosuggest.contents.header")}</h3>
      <ul className={"auto-suggest-content z-index-100 list-style-type-none"}>
        {hits.map((hit) => (
          <li key={hit.objectID} tabIndex={0}>
            <a href={hit.content_link as string} target={"_self"} className="d-flex justify-content-between align-items-center gap-spacing-8" role="button">
              <div className="d-flex align-items-center justify-content-start w-100">
                <Highlight
                  attribute="content_title"
                  hit={hit}
                  highlightedTagName="span"
                  nonHighlightedTagName="span"
                  classNames={{
                    root: "autosuggest-highlight",
                    highlighted: "highlighted",
                    nonHighlighted: "non-highlighted",
                  }}
                />
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
