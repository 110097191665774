const $accountHeaderApoPunkteContent = document.querySelector(
  "[data-id='account_header_apoPunkte_content']"
);

const adjustApoPunkteFontSize = (element, maxFontSize = 28) => {
  if (element && element.parentElement) {
    const elementTextContent = element.textContent;
    const parentRect = element.parentElement.getBoundingClientRect();
    const elementFontSize = parentRect.width / elementTextContent.length;
    element.style.fontSize = `${Math.min(elementFontSize, maxFontSize)}px`;
  }
};

if ($accountHeaderApoPunkteContent) {
  adjustApoPunkteFontSize($accountHeaderApoPunkteContent);
  $accountHeaderApoPunkteContent.addEventListener(
    'DOMCharacterDataModified',
    () => {
      adjustApoPunkteFontSize($accountHeaderApoPunkteContent);
    }
  );
}
