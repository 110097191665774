// Enable any el with data-selector 'docyetTrigger' to open up the overlay
function loadAlternativeTriggerBtns() {
  const docyetEmbeddedContent = document.getElementById('docyet_embedded_content')
  const docyetEmbeddedButton = document.getElementById('docyet_embedded_button')
  const docyetEmbeddedApp = document.getElementById('docyet_embedded_app')
  const docyetTrigger = document.querySelectorAll("[data-selector='docyetTrigger']")
  const docyetPreloadButton = document.querySelector("[data-selector='docyetPreloadButton']")

  if (docyetTrigger) {
    docyetTrigger.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (!docyetEmbeddedButton) {
          initDocyet();
          docyetPreloadButton.style.display = "none";
        }
        docyetEmbeddedButton.classList.add('open')
        // eslint-disable-next-line max-len
        docyetEmbeddedButton.innerHTML = `
          <svg class="svg-inline--fa fa-xmark fa-xl"
          aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512" data-fa-i2svg="">
          <path fill="currentColor" d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57
          9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369
          24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"></path></svg>
        `

        docyetEmbeddedContent.classList.add('desktop')
        docyetEmbeddedContent.style.display = "block"

        docyetEmbeddedApp.classList.add('desktop')
      })
    })
  }
}

function initDocyet() {
  const n = "abaebac53d8ab381bc7ff5b2716fbc95d5a3772b83048f8a5479e26b796e3668", c = `._docyet_embedded_content_1p0ug_1{
    display: none;
    position: fixed;
    z-index: 9999;
  }

  ._docyet_embedded_content_1p0ug_1.desktop {
    bottom: 66px;
    right: calc(6% + 24px);
  }

  ._docyet_embedded_app_1p0ug_9{
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    width: 100vw;
    border: none;
    overflow-y: scroll;
  }

  ._docyet_embedded_app_1p0ug_9.desktop {
    height: 667px;
    width: 375px;
    max-width: 90vw;
    max-height: 86vh;
    border: none;
    border-radius: 30px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.32);
    z-index:100
  }

  ._docyet_embedded_button_1p0ug_19{
    position: fixed !important;
    right: 16px !important;
    bottom: 20px !important;
    z-index: 2 !important;
    appearance: none !important;
    border: none !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.30) !important;
    font-weight: bold !important;
    font-size: 16px !important;
    cursor: pointer !important;
    width: 50px !important;
    height: 50px !important;
    &.open {

    }
  }`;

  (function () {
    if (!document.getElementById(n)) {
      var e = document.createElement("style")
      e.id = n, e.textContent = c, document.head.appendChild(e)
    }
  })()
  const o = {
    docyetEmbeddedContent: "_docyet_embedded_content_1p0ug_1",
    docyetEmbeddedApp: "_docyet_embedded_app_1p0ug_9",
    docyetEmbeddedButton: "_docyet_embedded_button_1p0ug_19"
  }
  const a = e => {
      e.style.display != "block" ? e.style.display = "block" : e.style.display = "none"
    },
    m = () => {
      let e = document.createElement("button")
      e.setAttribute("id", "docyet_embedded_button"),
        e.className = o.docyetEmbeddedButton,
        e.classList.add(...["btn", "btn-primary", "btn-circle"]),
        e.innerHTML = "<i class='far fa-message-medical fa-xl'></i>",
        document.body.append(e)
    },
    p = e => {
      let t = document.createElement("div")
      t.className = o.docyetEmbeddedContent
      t.setAttribute("id", "docyet_embedded_content")
      let d = document.createElement("iframe")
      d.className = o.docyetEmbeddedApp,
        d.setAttribute("scrolling", "yes"),
        d.setAttribute("id", "docyet_embedded_app"),
        d.setAttribute("src", e),
        t.append(d),
        document.body.append(t),
      document.getElementById("docyet_embedded_button") || m()
      let appButton = document.getElementById("docyet_embedded_button")
      appButton.addEventListener("click", () => {
        a(t)
        appButton.classList.toggle("open")
        if (appButton.classList.contains("open")) {
          document.body.classList.add('overflow-hidden')
          appButton.innerHTML = "<i class='far fa-xmark fa-xl'></i>"
          document.getElementById("docyet_embedded_content").classList.add("desktop")
          document.getElementById("docyet_embedded_app").classList.add("desktop")
        } else {
          appButton.innerHTML = "<i class='far fa-message-medical fa-xl'></i>"
          document.body.classList.remove('overflow-hidden')
        }
      })
      window.addEventListener("message", (e) => {
        const message = e.data

        if (Object.prototype.hasOwnProperty.call(message, 'ExecuteDeepLink')) {
          fetch('ajax/docyet/get_redirect_uri.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
              'event': message.ExecuteDeepLink
            })
          })
            .then((response) => response.json())
            .then(result => {
              if (Object.prototype.hasOwnProperty.call(result, 'redirect_uri')) {
                window.location.href = result.redirect_uri
              }
            })

        } else if (Object.prototype.hasOwnProperty.call(message, 'CloseBot')) {
          appButton.click()
          document.getElementById("docyet_embedded_content").style.display = "none"
        } else if (Object.prototype.hasOwnProperty.call(message, 'OpenLiveChat')) {
          const userlikeContainer = document.querySelector("[data-test-id='userlike-container']")
          const userlikeButton = document.querySelector("[data-selector='userlikeButton']")
          if (userlikeContainer && userlikeContainer.childNodes.length > 0 && userlikeButton) {
            userlikeButton.click()
            appButton.click()
            document.getElementById("docyet_embedded_content").style.display = "none"
          } else {
            window.location.href = '/kontakt'
          }
        } else if (Object.prototype.hasOwnProperty.call(message, 'CustomerService')) {
          const messageValue = message.CustomerService
          if (messageValue === 'email' || messageValue === 'call') {
            window.location.href = '/kontakt'
          }
        }
      })
    }

  let e = "https://app.docyet.com/client/index.html?referrer=apo&dashboard_id=5"
  document.getElementById("docyet_embedded_app") || p(`${e}?referrer=apo`)

  document.getElementById('docyet_embedded_button').click()
}

window.onload = function () {
  const docyetPreloadButton = document.querySelector("[data-selector='docyetPreloadButton']")
  if (docyetPreloadButton) {
    docyetPreloadButton.addEventListener('click', () => {
      initDocyet()
      docyetPreloadButton.style.display = "none"
    })
  }
  loadAlternativeTriggerBtns()
}
