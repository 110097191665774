import i18next from "i18next";
import {ApoEvents} from "../apoEvents";

export const shopContent = () => {
  document.addEventListener("DOMContentLoaded", function () {

      const contactFormHeadline = document.querySelectorAll('.contact-form-headline');
      const callOneWrapper : any = document.querySelector('[data-selector="phoneWidget"]');
      const statusLabel : HTMLDivElement = callOneWrapper?.querySelector('[data-selector="status"]')!;
      const callOneForm = <HTMLFormElement>document.querySelector('[name="callone_callback_form"]');
      const ddi = <HTMLFormElement>document.querySelector('[name="ddi"]');
      const time = <HTMLFormElement>document.querySelector('[name="time"]');
      const caller = <HTMLFormElement>document.querySelector('[name="caller"]');
      let callone_callback_interval: number;
      let contentWrapper = document.createElement('div')
      contentWrapper.dataset.dataSelector = "callone_callback_wrapper"
      callOneForm?.append(contentWrapper)
      caller?.classList.add('form-control')
      caller ? caller.required = true : ''

      callOneForm?.reset()


      contactFormHeadline.forEach(el => el.addEventListener('click', () => {
        el && el.querySelector('.contact-form-body')?.classList.toggle('d-none');
        el && el.classList.toggle('open')
      }))

      callOneForm && callOneForm.addEventListener('submit', (evt) => {
        evt.preventDefault();

        if (ddi.value !== '' && time.value !== '0') {
          submitCallbackForm();
        }
      })

      ddi && ddi.addEventListener('change', () => {
        loadAllCalloneTimes(ddi.value);
      })

      // returns the current status of a telephone request from callone
      const checkCallbackStatus = () => {
        let formData = new FormData()
        formData.append('check', 'status')
        fetch('/ajax/callone_callback_api.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (callOneWrapper) {
              callOneWrapper.innerHTML = response.message.status.description
              if (typeof (response.message.done.status) != "undefined" && response.message.done.status === true) {
                clearInterval(callone_callback_interval);
              }
            }
          })
          .catch(() => clearInterval(callone_callback_interval))
      }

      const serializeForm = (form: HTMLFormElement) => {
        const formData = new FormData(form);
        return new URLSearchParams(formData as any)
      }


      const submitCallbackForm = () => {
        fetch('/ajax/callone_callback_api.php', {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: serializeForm(callOneForm),
        })
          .then((response) => response.json())
          .then((response) => {
            callone_callback_interval = window.setInterval(() => checkCallbackStatus(), 4000)
            if (callOneWrapper) {
              statusLabel.classList.remove('text-aco-red');
              statusLabel.innerHTML = response.message;

              const elements = callOneForm.elements;
              for (let i = 0, len = elements.length; i < len; ++i) {
                elements[i].setAttribute('disabled',  'true');
              }
            }
          })
          .catch(error => {
            if (callOneWrapper) {
              statusLabel.classList.add('text-aco-red');
              statusLabel.innerHTML = error.message;
            }
          })
      }

      // request for available time slots
      const loadAllCalloneTimes = ((subject_id: any) => {

        let formData = new FormData();
        formData.append('action', "getAvailableCallbackHoursForFormSubject");
        formData.append('subject_id', subject_id);

        fetch('/ajax/shop_content.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            const timeSelectField = time.parentNode?.querySelector('select')!;
            let htmlStr = `<option value="sofort">${i18next.t('shopContent.loadCallone.now')}</option>`;
            response.forEach((el: string) => {
              htmlStr += '<option value="' + el + '">' + el + '</option>';
            })
            timeSelectField.innerHTML = htmlStr;
            const event = new CustomEvent(ApoEvents.SELECTFIELD_OPTIONS_CHANGED, {
              'detail': {
                changedEl: timeSelectField,
              }
            })
            document.dispatchEvent(event);
          })
          .catch(error => {
            console.warn(error);
            statusLabel.innerHTML = error.message;
          })
      })

    }
  )
}
shopContent()