import { SearchFilter } from "../searchfilter";
import { ClearRefinements } from "react-instantsearch";
import {HitCount} from "../../ResultComponents/hitcount";

interface OffcanvasMenuProps {
  headline: string,
  resetFilterText: string
}

/**
 * Offcanvas Menu - shell element to display the filter options in an offcanvas
 *
 * @constructor
 */
export const OffcanvasMenu = ({ headline, resetFilterText }: OffcanvasMenuProps) => {
  return (
    <div className="offcanvas offcanvas-white filter d-flex flex-column" data-bs-scroll="false" data-bs-backdrop="true" tabIndex={-1} id="offcanvasFilter"
         aria-labelledby="offcanvasScrollingLabel">
      <div className="offcanvas-header align-items-start">
        <div className="btn d-md-none"></div>
        <p className="offcanvas-title d-md-none" id="offcanvasFilterLabel">{headline}</p>
        <div className="offcanvas-title d-none d-md-block ms-spacing-40 h1" id="offcanvasFilterLabel">{headline}</div>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body d-flex flex-column align-content-start">
        <div className="d-flex flex-column overflow-scroll">
          <SearchFilter isDark={true} groupingSrc={"offcanvas"}/>
        </div>
      </div>
      <div className="offcanvas-footer d-flex d-md-none justify-content-center align-items-center">
        <div className="container d-flex flex-column gap-spacing-16">
          <ClearRefinements
            classNames={{
              button: "btn btn-secondary btn-lg w-100"
            }}
            translations={{
              resetButtonText: `${resetFilterText}`,
            }}
          />
          <button className="btn btn-primary btn-lg w-100"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close">
            <HitCount>Ergebnisse anzeigen</HitCount>
          </button>
        </div>
      </div>
    </div>
  )
}