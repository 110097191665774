import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useLocalStorage} from "@apo-app/app/hooks/useLocalStorage";
import {
  ProductReminderApi,
  ProductReminderList,
  ProductReminderNewProduct,
  ProductReminderSuggest,
  ProductReminderConfirm
} from "@apo-app/app/entity/ProductReminder";
import {ProductReminderCreate} from "./ProductReminderCreate/product-reminder-create";
import {ProductReminderOverview} from "./ProductReminderOverview/product-reminder-overview";
import {ProductReminderContinueModal, ProductReminderInfoBox} from "@apo-app/apo-responsive/ui/ProductReminder";


export function ProductReminder() {
  const productReminderApi = new ProductReminderApi()
  const [reminderData, setReminderData] = useState<ProductReminderList>()
  const [initial, setInitial] = useState(false)
  const [step, setStep] = useState(1)
  const [createReminderStep, setCreateReminderStep] = useState(0)
  const [productData, setProductData] = useState<ProductReminderNewProduct>()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [confirmReminderData, setConfirmReminderData] = useState<ProductReminderNewProduct>()
  const [resultSearch, setResultSearch] = useState<ProductReminderSuggest[]>([])
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([false, false, false])
  const [messageInfoBox, setMessageInfoBox] = useState<string>("")
  const [visibilityInfoBox, setVisibilityInfoBox] = useState<boolean>(false)
  const [continueModalVisible, setContinueModalVisible] = useState<boolean>(false)
  const {t} = useTranslation();
  const [reminderStorage, setReminderStorage] = useLocalStorage('reminderStorage', 'null');
  const [reminderStorageSearch, setReminderStorageSearch] = useLocalStorage('reminderStorageSearch', 'null');

// initial load existing reminders
  useEffect(() => {
    if (!initial) {
      productReminderApi.default()
        .then(data => {
          data && setReminderData(data)
          setLoading(false)

          // if (reminderStorage !== "") {
          //   handleContinueModal(true)
          // }
        })
    }
  }, [initial])


  const handleContinueModal = (showModal: boolean) => {
    setContinueModalVisible(showModal)
  }

  const hideContinueModal = () => {
    setContinueModalVisible(false)
  }
  const deleteStorage = () => {
    setReminderStorage('')
    hideContinueModal()
  }

  const useStorage = () => {
    const {DATE_START, MAX_SELECTABLE_TIMESTAMP_IN_DAYS, ...searchResultSaved} = reminderStorage

   setConfirmReminderData(reminderStorage)
    setResultSearch(reminderStorageSearch)
    setProductData(searchResultSaved)
    setStep(2)
    setActiveStep(0)
    setCreateReminderStep(1)
    hideContinueModal()
  }

  const handleProductSearch = (value: string) => {
    if (value.length > 1) {
      fetch('ajax/account_reminder_auto_suggest.php', {
        method: 'POST',
        body: new URLSearchParams({
          input: value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setResultSearch(response)
          setReminderStorageSearch(response)
        })
    }

  }

  const getDefault = async (): Promise<any> => {
    productReminderApi.default()
      .then(res => res && setReminderData(res))
  }

  const createReminder = (id: string) => {
    productReminderApi.new(id)
      .then((res) => {
        if (res.DATA) {
          setProductData(res.DATA)
          setActiveStep(0)
          setCreateReminderStep(1)
          setReminderStorage(res.DATA)
        }
      })

  }
  const deleteReminder = (id: string) => {
    setInitial(false)
    setLoading(true)

    productReminderApi.delete(id).then(res => {
      getDefault()
        .then(() => {
          setMessageInfoBox(`${t('productReminder.messageInfoBox.delete')}`)
          setVisibilityInfoBox(true)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    })
  }

  // collect data and send it to session
  const confirmReminder = (productReminder: ProductReminderConfirm) => {

    const data = {
      id: productReminder.id,
      qty_per_day: productReminder.qty_per_day,
      qty_per_dosage: productReminder.qty_per_dosage,
      days: productReminder.days,
      date_start: productReminder.date_start,
      amount: productReminder.amount
    }

    productReminder.date_start && productReminderApi.confirm(
      data.id,
      data.amount,
      data.date_start,
      data.days,
      data.qty_per_dosage,
      data.qty_per_day
    ).then(res => {
      if (res.DATA) {
        setConfirmReminderData(res.DATA)
        setReminderStorage(res.DATA)
        setActiveStep(2)
      }
    })
  }

  //TODO integrate createReminderStep into step
  const saveReminder = (name: string, daysBefore: number) => {
    productReminderApi.save(name, daysBefore).then(response => {

      getDefault().then(() => {
        // all state back to initial
        setStep(1)
        setCreateReminderStep(0)
        setActiveStep(0)
        setResultSearch([])
        setCompletedSteps([false, false, false])
        setReminderStorage('')
      }).then(() => {
        setMessageInfoBox(`${t('productReminder.messageInfoBox.create', {name: name})}`)
        setVisibilityInfoBox(true)
      })

    })
  }

  const handleActiveStep = (step: number) => {
    setActiveStep(step)
    handleCompletedSteps(step)
  }

  const handleCompletedSteps = (step: number) => {
    let item = [...completedSteps]
    item[step - 1] = true
    setCompletedSteps(item)
  }

  const setLoading = (loading: boolean) => {
    const breadCrumb = document.querySelector('[data-selector="breadcrumb"]')

    if (loading) {
      setInitial(false)
      breadCrumb?.classList.add('d-none')
    } else {
      setInitial(true)
      breadCrumb?.classList.remove('d-none')
    }
  }

  const handleCreateReminderStep = (step: number) => {
    setCreateReminderStep(step)
  }

  const createNewReminder = () => {
    setStep(2)
  }


  return (
    <>
      {initial ? (
        <>
          <ProductReminderContinueModal
            isVisible={continueModalVisible}
            hideModal={hideContinueModal}
            useStorage={useStorage}
            deleteStorage={deleteStorage}/>
          {step === 1 && reminderData && (
            <>
              <ProductReminderOverview
                reminderData={reminderData}
                deleteReminder={deleteReminder}
                addNewReminder={createNewReminder}
              />
              <ProductReminderInfoBox
                message={messageInfoBox}
                visibility={visibilityInfoBox}
                hideMessageBox={() => setVisibilityInfoBox(false)}
              />
            </>

          )}
          {step === 2 && (
            <ProductReminderCreate
              handleCreateReminder={createReminder}
              step={createReminderStep}
              setStep={handleCreateReminderStep}
              product={productData}
              handleProductReminder={confirmReminder}
              activeStep={activeStep}
              setActiveStep={handleActiveStep}
              confirmReminderData={confirmReminderData}
              searchProduct={handleProductSearch}
              resultSearch={resultSearch}
              saveReminder={saveReminder}
              completedSteps={completedSteps}
              maxTimeSpan={reminderData!.MAX_DAYS}
            />
          )}
        </>
      ) : (
        <div className="container">{t('productReminder.loading')}</div>
      )
      }
    </>
  )
}





