import {createContext} from 'react'
import {Cart} from "../../entity/Cart";
import {CartOverlaySliderItemType} from "../../entity/ProductSlider";

export type CartOverlayContextType = {
  quantity: number;
  show: boolean
  cartData: Cart | {}
  product: number
  slider: CartOverlaySliderItemType[] | []
  closeCartOverlay: () => void
  loading: boolean
}

export const defaultCartOverlayContext: CartOverlayContextType = {
  show: false,
  cartData: {},
  product: 0,
  quantity: 0,
  slider: [],
  closeCartOverlay: () => {
  },
  loading: false
}

export const CartOverlayContext = createContext<CartOverlayContextType>(defaultCartOverlayContext)