import React from "react";
import { withTranslation } from "react-i18next";
import { ApoModalController } from "../../../../apo-modal-controller";
import image from "../../../assets/noimage.gif";
import { HitProps, HitState, PackageSizeItem } from "./hit-types";

class CustomHitObject extends React.Component<HitProps, HitState> {
  constructor(props: HitProps | Readonly<HitProps>) {
    super(props);
    this.state = {
      name: this.props.hit.name,
      image_url: this.props.hit.image_url,
      eyecatcher: this.props.hit.eyecatcher,
      pzn: this.props.hit.pzn,
      amount_unit: this.props.hit.amount_unit,
      price_no_format: this.props.hit.price_no_format,
      avp_no_format: this.props.hit.avp_no_format,
      avp_formatted: this.props.hit.avp_formatted,
      price_formatted: this.props.hit.price_formatted,
      product_url: this.props.hit.product_url,
      rating: this.props.hit.rating,
      rating_count: this.props.hit.rating_count,
      status_text: this.props.hit.status_text,
      status_information: this.props.hit.status_information,
      status_number: Number(this.props.hit.status_number),
      herbal: this.props.hit.herbal,
      homeopathic: this.props.hit.homeopathic,
      prescription_required: this.props.hit.prescription_required,
      presentation_form: this.props.hit.presentation_form,
      base_price: this.props.hit.base_price,
      products_id: "",
      currentPackageSizePZN: this.props.hit.pzn,
    };
    this.handleImageError = this.handleImageError.bind(this);
    this.preloadImage = this.preloadImage.bind(this);
  }

  componentDidMount() {
    this.preloadImage(this.state.image_url);
    this.setState({
      name: this.props.hit.name,
      image_url: this.props.hit.image_url,
      eyecatcher: this.props.hit.eyecatcher,
      pzn: this.props.hit.pzn,
      amount_unit: this.props.hit.amount_unit,
      price_no_format: this.props.hit.price_no_format,
      avp_no_format: this.props.hit.avp_no_format,
      avp_formatted: this.props.hit.avp_formatted,
      price_formatted: this.props.hit.price_formatted,
      product_url: this.props.hit.product_url,
      rating: this.props.hit.rating,
      rating_count: this.props.hit.rating_count,
      status_text: this.props.hit.status_text,
      status_information: this.props.hit.status_information,
      status_number: Number(this.props.hit.status_number),
      herbal: this.props.hit.herbal,
      homeopathic: this.props.hit.homeopathic,
      prescription_required: this.props.hit.prescription_required,
      presentation_form: this.props.hit.presentation_form,
      base_price: this.props.hit.base_price,
      products_id: this.props.hit.products_id,
      currentPackageSizePZN: this.props.hit.pzn,
    });
  }

  showEyecatcher = () => {
    return (
      <>
        {this.state.eyecatcher !== "" && (
          <div className={"top-0 w-100 start-0 position-absolute pt-spacing-16"}>
            <div className={"eyecatcher eyecatcher-3d"}>{this.state.eyecatcher}</div>
          </div>
        )}
      </>
    );
  };

  showCancelPrice = () => {
    if (this.state.price_no_format < this.state.avp_no_format && this.state.prescription_required === 0) {
      return <div className={"product-price small strike"}>{this.state.avp_formatted}</div>;
    }
    return;
  };

  showTags = () => {
    const { t } = this.props;
    return (
      <>
        {this.state.prescription_required == 1 ||
          this.state.herbal == 1 ||
          (this.state.homeopathic == 1 && (
            <div className={"tags-section"}>
              {this.state.prescription_required == 1 && <span className="badge badge-tag">{t("search.hits.tags.prescriptionRequired")}</span>}
              {this.state.herbal == 1 && <span className="badge badge-tag">{t("search.hits.tags.herbal")}</span>}
              {this.state.homeopathic == 1 && <span className="badge badge-tag">{t("search.hits.tags.homeopathic")}</span>}
            </div>
          ))}
      </>
    );
  };

  showPackageSizes = () => {
    let packageSizesList = this.generatePackageSizesList(this.props.hit.alternative_package_sizes);

    return (
      <div className={"package-sizes"}>
        {packageSizesList.map((packageSizeItem) => {
          return (
            <button
              key={packageSizeItem.pzn}
              className={`choice-chip choice-chip-tag mb-spacing-8 me-spacing-8 ${this.state.currentPackageSizePZN == packageSizeItem.pzn && "active"}`}
              onClick={() => {
                this.changePackageSize(packageSizeItem);
              }}
            >
              {packageSizeItem.amount_unit.trim() !== "" && packageSizeItem.amount_unit.trim()}
              {packageSizeItem.amount_unit.trim() === "" && <span>X St.</span>}
            </button>
          );
        })}
      </div>
    );
  };

  changePackageSize = (item: PackageSizeItem) => {
    this.setState({
      name: item.name,
      image_url: item.image_url,
      eyecatcher: item.eyecatcher,
      pzn: item.pzn,
      amount_unit: item.amount_unit,
      price_no_format: item.price_no_format,
      avp_no_format: item.avp_no_format,
      avp_formatted: item.avp_formatted,
      price_formatted: item.price_formatted,
      product_url: item.product_url,
      rating: item.rating,
      rating_count: item.rating_count,
      status_text: item.status_text,
      status_information: item.status_information,
      status_number: item.status_number,
      herbal: item.herbal,
      homeopathic: item.homeopathic,
      prescription_required: item.prescription_required,
      presentation_form: item.presentation_form,
      base_price: item.base_price,
      products_id: item.products_id,
      currentPackageSizePZN: item.pzn,
    });
  };

  generatePackageSizesList = (alternativePackageSizes: []) => {
    let packageSizesList: PackageSizeItem[] = [];
    const defaultItem: PackageSizeItem = {
      name: this.props.hit.name,
      image_url: this.props.hit.image_url,
      eyecatcher: this.props.hit.eyecatcher,
      pzn: this.props.hit.pzn,
      amount_unit: this.props.hit.amount_unit,
      price_no_format: this.props.hit.price_no_format,
      avp_no_format: this.props.hit.avp_no_format,
      avp_formatted: this.props.hit.avp_formatted,
      price_formatted: this.props.hit.price_formatted,
      product_url: this.props.hit.product_url,
      rating: this.props.hit.rating,
      rating_count: this.props.hit.rating_count,
      status_text: this.props.hit.status_text,
      status_information: this.props.hit.status_information,
      status_number: this.props.hit.status_number,
      herbal: this.props.hit.herbal,
      homeopathic: this.props.hit.homeopathic,
      prescription_required: this.props.hit.prescription_required,
      presentation_form: this.props.hit.presentation_form,
      base_price: this.props.hit.base_price,
      products_id: this.props.hit.products_id,
    };

    packageSizesList.push(defaultItem);

    alternativePackageSizes.map((alternativePackageSizeItem) => {
      packageSizesList.push({
        name: alternativePackageSizeItem["name"],
        image_url: alternativePackageSizeItem["image_url"],
        eyecatcher: alternativePackageSizeItem["eyecatcher"],
        pzn: alternativePackageSizeItem["pzn"],
        amount_unit: alternativePackageSizeItem["amount_unit"],
        price_no_format: alternativePackageSizeItem["price_no_format"],
        avp_no_format: alternativePackageSizeItem["avp_no_format"],
        avp_formatted: alternativePackageSizeItem["avp_formatted"],
        price_formatted: alternativePackageSizeItem["price_formatted"],
        product_url: alternativePackageSizeItem["product_url"],
        rating: alternativePackageSizeItem["rating"],
        rating_count: alternativePackageSizeItem["rating_count"],
        status_text: alternativePackageSizeItem["status_text"],
        status_information: alternativePackageSizeItem["status_information"],
        status_number: Number(alternativePackageSizeItem["status_number"]),
        herbal: alternativePackageSizeItem["herbal"],
        homeopathic: alternativePackageSizeItem["homeopathic"],
        prescription_required: alternativePackageSizeItem["prescription_required"],
        presentation_form: alternativePackageSizeItem["presentation_form"],
        base_price: alternativePackageSizeItem["base_price"],
        products_id: alternativePackageSizeItem["products_id"],
      });
    });

    return packageSizesList;
  };

  showCTA = () => {
    const isAvailable = this.props.hit.status_number < 10;
    const isRxProduct = this.props.hit.prescription_required === 1;
    const { t } = this.props;
    return (
      <>
        {(isAvailable && !isRxProduct) && (
          <div className="card-button-wrapper">
            <cart-button
              product={Number(this.state.products_id)}
              quantity={1}
              max={1}
              class={"search-result-card-button shadow-root"}
              algoliaQueryID={this.props.hit.__queryID}
              pzn={this.props.hit.objectID}
            >
              <span>
                <i className="fas fa-sharp fa-cart-shopping me-spacing-8"></i>
                {t("search.hits.buttons.addToCart")}
              </span>
            </cart-button>
          </div>
        )}
        {(!isAvailable || isRxProduct) && (
          <div className="search-result-card-button card-button-wrapper">
            <a className="card-button btn btn-primary-big" href={this.getProductLink()}>
              {t("search.hits.buttons.openProduct")}
            </a>
          </div>
        )}
      </>
    );
  };

  showStatusModal = (status: number, status_information: string) => {
    let isAvailable = "0";
    if (status < 10) {
      isAvailable = "1";
    }

    const modalController = new ApoModalController();
    modalController.fill({
      TYPE: "#productStatusModal",
      PRODUCT_STATUS: isAvailable,
      PRODUCT_PRESCRIPTION_STATUS: isAvailable,
      PRODUCT_STATUS_INFORMATION: status_information,
      PRODUCT_STATUS_HEADLINE: "Information zum Status",
    });
  };

  getProductLink = () => {
    const product_url = this.props.hit.product_url;

    const url = new URLSearchParams(window.location.search);
    const queryParam = url.get("query") || "";

    const params = new URLSearchParams();

    if (this.props.hit.objectID) {
      params.append("objectID", this.props.hit.objectID);
    }
    if (this.props.hit.__queryID) {
      params.append("queryID", this.props.hit.__queryID);
    }

    if (queryParam) {
      params.append("query", queryParam);
    }

    return `${product_url}?${params.toString()}`;
  };

  // Since sometime the images take too long to error,
  // we need to handle the error earlier and show a fallback image.
  // Timeout can be adjusted.
  preloadImage(url: string) {
    const img = new Image();
    const timeout = 1000;

    const timer = setTimeout(() => {
      this.handleImageError();
    }, timeout);

    img.onload = () => {
      clearTimeout(timer);
    };

    img.onerror = () => {
      clearTimeout(timer);
      this.handleImageError();
    };

    img.src = url;
  }

  handleImageError() {
    this.setState({
      image_url: image,
    });
  }

  render() {
    return (
      <div className="product-card" id="hit-list-item">
        {/* image */}
        <div className="position-relative card">
          <div className="card-body">
            {this.showEyecatcher()}
            <a href={this.getProductLink()}>
              {<img src={this.state.image_url} alt="product image" width={100} height={162} style={{ minHeight: "162px" }} onError={this.handleImageError} />}
            </a>
          </div>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          {/* name */}
          <div className="d-flex flex-column overflow-hidden">
            <a className="text-aco-dark-blue footnote-bold card-title-truncate-overflow" href={this.getProductLink()}>
              <p>{this.state.name}</p>
            </a>
          </div>
          {/* RATING, TAGS AND UNIT PRICE */}
          <div className="d-flex flex-column flex-grow-1">
            {/* RATING */}
            <div className="rating d-flex align-items-center mb-spacing-12">
              <rating-stars average={this.state.rating} size="sm"></rating-stars>
              <span className="amount">({this.state.rating_count !== null && this.state.rating_count !== "" ? this.state.rating_count : "0"})</span>
            </div>
            {this.showTags()}
            <div className={"presentation-form-section"}>
              <p className={"text-aco-dark-blue mb-spacing-0 footnote-bold"}>{this.state.presentation_form}</p>
            </div>
            <div className={"unit-price-section"}>
              <p className={"text-aco-dark-blue mb-spacing-0 micro"}>{this.state.base_price}</p>
            </div>
          </div>
          {/* Bulletpoints */}
          <div className="bullet-points">
            <a className="search-result-card-mandatory" href={this.getProductLink()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M12 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0ZM12.5 12C12.5 12.2812 12.25 12.5 12 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H12C12.25 1.5 12.5 1.75 12.5 2V12ZM7.25 3.21875C6.9375 2.9375 6.46875 2.9375 6.1875 3.25C5.90625 3.5625 5.90625 4.03125 6.21875 4.3125L8.3125 6.25H3.75C3.3125 6.25 3 6.59375 3 7.03125C3 7.4375 3.3125 7.75 3.75 7.75H8.3125L6.21875 9.71875C5.90625 10 5.90625 10.4688 6.1875 10.7812C6.46875 11.0938 6.9375 11.0938 7.25 10.8125L10.75 7.5625C10.9062 7.40625 11 7.21875 11 7C11 6.75 10.9062 6.59375 10.75 6.4375L7.25 3.21875Z"
                  fill="#003399"
                />
              </svg>
              Pflichthinweise
            </a>
            <section>
              <div
                data-bs-toggle="modal"
                data-bs-target="#productStatusModal"
                className={"d-flex py-spacing-6 product-bullet-point"}
                role="button"
                onClick={() => this.showStatusModal(this.state.status_number, this.state.status_information)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 14.5C4.40625 14.5 1.5 11.5938 1.5 8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8C14.5 11.5938 11.5625 14.5 8 14.5ZM9.25 10.5H8.75V7.75C8.75 7.34375 8.40625 7 8 7H7C6.5625 7 6.25 7.34375 6.25 7.75C6.25 8.1875 6.5625 8.5 7 8.5H7.25V10.5H6.75C6.3125 10.5 6 10.8438 6 11.25C6 11.6875 6.3125 12 6.75 12H9.25C9.65625 12 10 11.6875 10 11.25C10 10.8438 9.65625 10.5 9.25 10.5ZM8 6C8.53125 6 9 5.5625 9 5C9 4.46875 8.53125 4 8 4C7.4375 4 7 4.46875 7 5C7 5.5625 7.4375 6 8 6Z"
                    fill="#003399"
                  />
                </svg>
                <span className={"status-text micro"} dangerouslySetInnerHTML={{ __html: this.state.status_text }}></span>
              </div>
            </section>
          </div>
          {/* PRICES and ADDTOCART-BUTTON */}
          <div className="price-and-button">
            <div className={"price-wrapper justify-content-between"}>
              {this.showCancelPrice()}
              <div className={"product-price fw-bold text-aco-orange"}>
                {this.state.prescription_required === 1 && this.state.avp_formatted}
                {this.state.prescription_required === 0 && this.state.price_formatted}
              </div>
            </div>
            {this.showPackageSizes()}
            {this.showCTA()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomHitObject);
