import loadingSpinner from "../../../../img/resource/images/loading-spinner-large.png";
import React from "react";


export const LoadingSpinner = () => {
  return (
    <div
      className="spinner-border spinner-lg dark mb-spacing-32 loading_spinner"
      role="status"
    ></div>
    )
}