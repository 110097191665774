import {WishListType} from "./wish-list"

export enum WishListApiDefaults {
  url = 'ajax/wishlist.php'
}

export interface WishListApiInterface {
  url: string
}

export class WishListApi implements WishListApiInterface {
  public url: string

  constructor(config: WishListApiInterface = WishListApiDefaults) {
    this.url = config.url
  }

  private async request(params = {}): Promise<WishListType> {
    const method = 'GET'
    const url = this.url + (Object.entries(params).length > 0 ?  '?' + (new URLSearchParams(params)).toString() : '')

    return await fetch(url, {method: method}).then(response => response.json()).catch(error => console.error(error))
  }

  public read(): Promise<WishListType> {
    return this.request({action: 'read'})
  }

  public addItem(id: number, qty: number): Promise<WishListType> {
    return this.request({product_id: id, product_qty: qty, action: 'create'})
  }

  public updateItem(id: number, qty: number): Promise<WishListType> {
    return this.request({product_id: id, product_qty: qty, action: 'update'})
  }

  public deleteItem(id: number): Promise<WishListType> {
    return this.request({product_id: id, action: 'delete'})
  }
}