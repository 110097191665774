import React, {FC} from 'react';
import { ProductCard } from '../ProductCard';
import { Product } from '../../entity/product';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export type ProductListProps = {
  /**
   * a list of products
   */
  list: Product[];
} & React.HTMLAttributes<HTMLDivElement>;

export const ProductList:FC<ProductListProps> = ({ list }) => {
  return (
    <>
      {list.length > 0 ? (
        <Row>
          {list.map((product, index) => (
            <Col xs={12} md={3} key={product.pzn}>
              <ProductCard id={index} src={product.src} text={product.text} link={product.link} price={product.price} title={product.title} />
            </Col>
          ))}
        </Row>
      ) : (
        'No products to display'
      )}
    </>
  );
}
