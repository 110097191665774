import { useConnector } from 'react-instantsearch';
import connectRatingMenu from 'instantsearch.js/es/connectors/rating-menu/connectRatingMenu';

import type {
  RatingMenuConnectorParams,
  RatingMenuWidgetDescription,
} from 'instantsearch.js/es/connectors/rating-menu/connectRatingMenu';
import React, { useState } from "react";

/**
 * Algolia Hook, provides data to create custom rating menu component
 * @param props
 */
export function useRatingMenu(props?: RatingMenuConnectorParams) {
  return useConnector<RatingMenuConnectorParams, RatingMenuWidgetDescription>(
    connectRatingMenu,
    props
  );
}

interface CustomRatingMenuConnectorParams extends RatingMenuConnectorParams {
  groupingKey: string
}

/**
 * Rating Menu - uses the apo input-group component to display a radio selection for filtering products by rating
 *
 * @param props
 * @constructor
 */
export function RatingMenu(props: CustomRatingMenuConnectorParams) {
  const { items, refine } = useRatingMenu(props);
  const [ currentRefinement, setCurrentRefinement ] = useState("");

  /**
   * Returns the apo stars component
   * @param numberAsString - the number of stars to be displayed
   */
  const returnStars = (numberAsString: string) => {
    const numAsNum = parseInt(numberAsString);

    return (
      <>
        <rating-stars average={numAsNum} size="md"></rating-stars>
      </>
    )
  }

  /**
   * Synchronizes the sidebar & offcanvas active radio item
   * @param event - the clicked input element
   */
  const syncRadioSelection = (event: React.MouseEvent) => {
    const clickedRadioInput = event.target as HTMLInputElement;
    // get all rating radio items (with .rating-star-radio)
    const ratingRadioGroupSelector = clickedRadioInput?.classList[1];
    const ratingRadioGroupElements= document.querySelectorAll("." + ratingRadioGroupSelector);

    // clear previous active radios
    ratingRadioGroupElements?.forEach(ratingRadio => {
      ratingRadio?.removeAttribute("checked");
      ratingRadio.parentElement?.classList.remove("input-checked");
    })

    // get radio item that was clicked on + its twin in offcanvas (with .rating-star-radio-x)
    const ratingRadioClickedSelector = clickedRadioInput?.classList[2];
    const ratingRadioClickedElements= document.querySelectorAll("." + ratingRadioClickedSelector);

    // set new active radio
    ratingRadioClickedElements?.forEach(clickedRadio => {
      clickedRadio?.setAttribute("checked", "true");
      clickedRadio.parentElement?.classList.add("input-checked");
    })
  }

  return (
    <>
      {items.map((item) => (
        <div key={item.value}>
          <div className={"list-group"}>
            <div className="dropdown-menu-item mb-spacing-8 p-spacing-0">
              <div className={"d-flex align-items-center list-group-item list-group-item-selection"}
                   data-selector="list-group-item-selection"
                   data-parent="label-container">
                <div className="list-group-item-radio">
                  <input type={"radio"}
                         id={item.value}
                         className={`form-check-input rating-star-radio rating-star-radio-${item.value}`}
                         name={`rating-${props.groupingKey}`}
                         value={item.value}
                         checked={currentRefinement === item.value}
                         readOnly={true}
                         onClick={(event) => {
                           refine(item.value)
                           setCurrentRefinement(item.value)
                           syncRadioSelection(event)
                          }
                         }
                  />
                </div>
                <div className={"list-group-item-content rating-filter"}>
                  <label htmlFor={item.value} className={"form-check-label w-100"}>
                    <span className={"filter-name"}>{returnStars(item.label)} <span className={"rating-count"}>({item.count})</span></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}