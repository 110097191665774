import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IBadge {
  savingPercentage?: number;
  positionTop?: boolean;
}

export const Badge: React.FC<IBadge> = ({
  savingPercentage,
  positionTop = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {savingPercentage && (
        <div
          className={classNames(
            positionTop
              ? 'overlay-badge badge-aco-orange'
              : 'bottom-0 w-100 start-0 position-absolute pb-spacing-8'
          )}
        >
          {savingPercentage < 40 ? (
            <div className="eyecatcher eyecatcher-3d">
              <div className="text-uppercase">{t('badge.discountPrice')}</div>
            </div>
          ) : (
            <div className="badge badge-price-discount badge-small ribbon price-only">
              <div className="price">-{savingPercentage}%</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
