import { Postnummer } from "./apo-formvalidation-postnummer";
import { ValidationError } from "./interfaces/apo-formvalidation-validation-error";

export class FieldValidator {
  private static abortController: AbortController | null = null;

  static async validateField(element: HTMLInputElement, minLength: number): Promise<void> {
    if (element.hasAttribute('formnovalidate')) {
      console.log('validateField :>> ', 'Form validation is disabled for this field');
      return;
    }
    console.log('validateField :>> ', element, minLength);

    let value : string = element.value.trimStart();
    element.value = value; // Update the field value

    if (element.getAttribute('data-type') === 'postcode') {
      value = value.toUpperCase();
      element.value = value; // Set the value to uppercase
    }
    const errorContainer = element.closest('.form-input-wrapper')?.querySelector('.invalid-feedback');
    const errorOptions = errorContainer?.querySelectorAll('.error-option') as NodeListOf<HTMLElement>;

    if (!errorContainer || !errorOptions) return;

    errorOptions.forEach(option => option.classList.add('d-none'));

    if (value.trim() === '') {
      this.showError(errorOptions, 'error_empty');
    } else if (value.length < minLength) {
      this.showError(errorOptions, 'error_length');
    } else {
      const validationResult = await this.isValidInput(value, element);
      if (!validationResult.isValid) {
        this.showError(errorOptions, validationResult.errorKey || 'error_invalid');
      } else {
        element.setCustomValidity('');
        element.classList.remove('is-invalid');
        element.classList.add('is-valid');
        return;
      }
    }

    element.setCustomValidity('error');
    element.classList.add('is-invalid');
    element.classList.remove('is-valid');
  }

  private static showError(errorOptions: NodeListOf<HTMLElement>, errorKey: string): void {
    errorOptions.forEach(option => {
      if (option.getAttribute('data-option') === errorKey) {
        option.classList.remove('d-none');
      } else {
        option.classList.add('d-none');
      }
    });
  }

  static validateConfirmationField(passwordField: HTMLInputElement, confirmationField: HTMLInputElement): void {
    const errorContainer = confirmationField.closest('.form-input-wrapper')?.querySelector('.invalid-feedback');
    const errorOptions = errorContainer?.querySelectorAll('.error-option') as NodeListOf<HTMLElement>;

    if (!errorContainer || !errorOptions) return;

    errorOptions.forEach(option => option.classList.add('d-none'));

    if (confirmationField.value !== passwordField.value) {
      this.showError(errorOptions, 'error_mismatch');
      confirmationField.setCustomValidity('error');
      confirmationField.classList.add('is-invalid');
      confirmationField.classList.remove('is-valid');
    } else {
      confirmationField.setCustomValidity('');
      confirmationField.classList.remove('is-invalid');
      confirmationField.classList.add('is-valid');
    }
  }

  private static async isValidInput(value: string, field: HTMLInputElement): Promise<ValidationError> {
    let fieldType = field.type;
    if (field.hasAttribute('formnovalidate')) return { isValid: true };
    if (field.hasAttribute('data-type')) {
      fieldType = field.getAttribute('data-type')!;
    }

    console.log(`Validating input: ${value} for field type: ${fieldType}`);

    switch (fieldType) {
      case 'email':
        const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(value.toLowerCase());
        console.log(`Email validation result: ${emailValid}`);
        return { isValid: emailValid };
      case 'tel':
        const telValid = /^[0-9]+$/.test(value);
        console.log(`Telephone validation result: ${telValid}`);
        return { isValid: telValid };
      case 'text':
        const textValid = /^[a-zA-ZäöüÄÖÜß\s-]+$/.test(value) && !/[!@#$%^&*(),.?":{}|<>]/.test(value);
        console.log(`Text validation result: ${textValid}`);
        return { isValid: textValid };
      case 'street':
        const streetValid = /^[a-zA-ZäöüÄÖÜß\s0-9-]+$/.test(value) && !/[!@#$%^&*(),.?":{}|<>]/.test(value);
        console.log(`Street validation result: ${streetValid}`);
        return { isValid: streetValid };
      case 'number':
        const numberValid = /^[0-9a-zA-Z]+$/.test(value);
        console.log(`Number validation result: ${numberValid}`);
        return { isValid: numberValid };
      case 'dhl-packstation-user-id':
        const dhlValid = Postnummer.isValid(value);
        console.log(`DHL Packstation User ID validation result: ${dhlValid}`);
        return { isValid: dhlValid };
      case 'postcode':
        const postcodeValid = this.isValidPostcode(value);
        console.log(`Postcode validation result: ${postcodeValid}`);
        return { isValid: postcodeValid };
      case 'alphanumeric':
        const alphanumericValid = /^[a-zA-Z0-9\s]+$/.test(value) && !/[!@#$%^&*(),.?":{}|<>]/.test(value);
        console.log(`Alphanumeric validation result: ${alphanumericValid}`);
        return { isValid: alphanumericValid };
      case 'password':
        const hasNumber = /[0-9]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSpecialChar = /[^A-Za-z0-9]/.test(value);
        const passwordValid = hasNumber && hasUpperCase && hasLowerCase && hasSpecialChar;
        console.log(`Password validation results: hasNumber=${hasNumber}, hasUpperCase=${hasUpperCase}, hasLowerCase=${hasLowerCase}, hasSpecialChar=${hasSpecialChar}`);
        if (!passwordValid) {
          return { isValid: false, errorKey: 'error_invalid' };
        }
        return await this.checkPasswordLeaked(field);
      case 'dob':
        const dobValid = this.isValidDateOfBirth(value);
        console.log(`Date of Birth validation result: ${dobValid.isValid}`);
        return dobValid;
      default:
        console.log(`No validation required for field type: ${fieldType}`);
        return { isValid: true };
    }
  }

  private static isValidDateOfBirth(value: string): ValidationError {
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/; // DD.MM.YYYY format
    if (!datePattern.test(value)) {
      console.error('Invalid date format. Expected format is DD.MM.YYYY.');
      return { isValid: false, errorKey: 'error_invalid' };
    }

    const [day, month, year] = value.split('.').map(Number);
    const date = new Date(year, month - 1, day);

    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
      console.error('Invalid date.');
      return { isValid: false, errorKey: 'error_invalid' };
    }

    const today = new Date();
    if (date > today) {
      console.error('Date of birth cannot be in the future.');
      return { isValid: false, errorKey: 'error_future_date' };
    }

    const age = today.getFullYear() - year;
    if (age < 14) {
      console.error('Minimum age is 14.');
      return { isValid: false, errorKey: 'error_minimum_age' };
    }
    if (age > 150) {
      console.error('Invalid age. Age must be less than 150.');
      return { isValid: false, errorKey: 'error_maximum_age' };
    }

    return { isValid: true };
  }

  private static isValidPostcode(value: string): boolean {
    if (!value) {
      console.error('Postleitzahl darf nicht leer sein.');
      return false;
    }

    const country = document.querySelector('[name="country_text"]')?.getAttribute('value');
    if (!country) {
      console.error('Land konnte nicht ermittelt werden.');
      return false;
    }

    const postcodeRegex: Record<string, { regex: RegExp, minLength: number }> = {
      'Belgien': { regex: /^\d{4}$/, minLength: 4 },
      'Bulgarien': { regex: /^\d{4}$/, minLength: 4 },
      'Dänemark': { regex: /^\d{4}$/, minLength: 4 },
      'Deutschland': { regex: /^\d{5}$/, minLength: 5 },
      'Estland': { regex: /^\d{5}$/, minLength: 5 },
      'Finnland': { regex: /^\d{5}$/, minLength: 5 },
      'Frankreich': { regex: /^\d{5}$/, minLength: 5 },
      'Griechenland': { regex: /^\d{5}$/, minLength: 5 },
      'Irland': { regex: /^[A-Za-z]\d{2}$/, minLength: 3 },
      'Italien': { regex: /^\d{5}$/, minLength: 5 },
      'Kroatien': { regex: /^\d{5}$/, minLength: 5 },
      'Lettland': { regex: /^\d{4}$/, minLength: 4 },
      'Liechtenstein': { regex: /^\d{4}$/, minLength: 4 },
      'Litauen': { regex: /^\d{5}$/, minLength: 5 },
      'Luxemburg': { regex: /^\d{4}$/, minLength: 4 },
      'Malta': { regex: /^[A-Za-z]{3}\d{4}$/, minLength: 7 },
      'Monaco': { regex: /^\d{5}$/, minLength: 5 },
      'Niederlande': { regex: /^\d{4} [A-Za-z]{2}$/, minLength: 6 },
      'Norway': { regex: /^\d{4}$/, minLength: 4 },
      'Portugal': { regex: /^\d{4}-\d{3}$/, minLength: 8 },
      'Rumänien': { regex: /^\d{6}$/, minLength: 6 },
      'Schweden': { regex: /^\d{3} \d{2}$/, minLength: 5 },
      'Schweiz': { regex: /^\d{4}$/, minLength: 4 },
      'Slowakei': { regex: /^\d{3} \d{2}$/, minLength: 5 },
      'Slowenien': { regex: /^\d{4}$/, minLength: 4 },
      'Spanien': { regex: /^\d{5}$/, minLength: 5 },
      'Tschechische Republik': { regex: /^\d{3} \d{2}$/, minLength: 5 },
      'Ungarn': { regex: /^\d{4}$/, minLength: 4 },
      'Vereinigtes Königreich': { regex: /^([A-Z]{1,2}[0-9R][0-9A-Z]? ?[0-9][A-Z]{2})$/, minLength: 5 },
      'Zypern': { regex: /^\d{4}$/, minLength: 4 }
    };

    const countryConfig = postcodeRegex[country];
    if (!countryConfig) {
      console.error(`Keine Postleitzahl-Validierung für das Land ${country} verfügbar.`);
      return false;
    }

    if (value.length < countryConfig.minLength) {
      console.error(`Postleitzahl für das Land ${country} ist zu kurz.`);
      return false;
    }

    const isValid = countryConfig.regex.test(value);
    if (!isValid) {
      console.error(`Ungültige Postleitzahl für das Land ${country}.`);
    }

    return isValid;
  }

  private static async checkPasswordLeaked(password: HTMLInputElement): Promise<ValidationError> {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();
    const signal = this.abortController.signal;

    const value = password.value;
    const errorContainer = password.closest('.form-input-wrapper')?.querySelector('.invalid-feedback');
    const errorOptions = errorContainer?.querySelectorAll('.error-option') as NodeListOf<HTMLElement>;

    if (!errorContainer || !errorOptions) return { isValid: true };

    try {
      const response = await fetch('/ajax/checkPasswordLeaked.php', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(value),
        signal: signal
      });

      const result = await response.json();

      if (result[0].result === "error") {
        this.showError(errorOptions, 'error_leaked');
        return { isValid: false, errorKey: 'error_leaked' };
      } else {
        this.showError(errorOptions, '');
        return { isValid: true };
      }
    } catch (error : any) {
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Fetch request failed', error);
      }
      return { isValid: true };
    }
  }
}