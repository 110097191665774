import {ApoEvents} from "./apoEvents";

interface ModalContent {
  TYPE: "#productStatusModal" | "#reimportInfoModal" | "#voucherInfoModal";
  PRODUCT_STATUS?: string;
  PRODUCT_PRESCRIPTION_STATUS?: string;
  PRODUCT_STATUS_INFORMATION?: string;
  PRODUCT_STATUS_HEADLINE?: string;
  PRODUCT_REIMPORT_TEXT?: string;
  VOUCHER_HINT? : string;
}

export class ApoModalController {
  static instance: ApoModalController;
  // ProductState Modal Elements
  private _productstate_iconColor: HTMLElement = document.querySelector('[data-selector="productstate-modal-iconcolor"]')!;
  private _productstate_iconBox: HTMLElement = document.querySelector('[data-selector="productstate-modal-icon-box"]')!;
  private _productstate_iconCart: HTMLElement = document.querySelector('[data-selector="productstate-modal-icon-shoppingcart"]')!;
  private _productstate_headline: HTMLElement = document.querySelector('[data-selector="productstate-modal-headline"]')!;
  private _productstate_statusInfo: HTMLElement = document.querySelector('[data-selector="productstate-modal-status-info"]')!;
  private _productstate_link: HTMLElement = document.querySelector('[data-selector="productstate-modal-link"]')!;

  // Reimport Modal Elements
  private _reimport_text: HTMLElement = document.querySelector('[data-selector="reimport-modal-reimport-text"]')!;
  private _productStatusModalTriggerList = document.querySelectorAll('[data-bs-target="#productStatusModal"]');
  private _productReimportModalTriggerList = document.querySelectorAll('[data-bs-target="#reimportInfoModal"]')!;

  // VoucherInfo Modal Elements
  private _voucherInfoModalTriggerList = document.querySelectorAll('[data-bs-target="#voucherInfoModal"]');
  private _voucher_hint_text: HTMLElement = document.querySelector('[data-selector="voucherinfo-modal-voucher-hint"]')!;

  constructor() {
    if (ApoModalController.instance) {
      return ApoModalController.instance;
    }
    ApoModalController.instance = this;

    document.addEventListener(ApoEvents.DY_SLIDER_INIT, this.registerListeners);

    this.registerListeners();
  }

  onProductStatusClick = (e: any) => {
    this.fill({
                TYPE: e.currentTarget.dataset.bsTarget,
                PRODUCT_STATUS: e.currentTarget.dataset.productStatus,
                PRODUCT_PRESCRIPTION_STATUS: e.currentTarget.dataset.productPrescriptionStatus,
                PRODUCT_STATUS_INFORMATION: e.currentTarget.dataset.productStatusInformation,
                PRODUCT_STATUS_HEADLINE: e.currentTarget.dataset.productStatusHeadline
              });
  }

  onReimportClick = (e: any) => {
    this.fill({
                TYPE: e.currentTarget.dataset.bsTarget,
                PRODUCT_REIMPORT_TEXT: e.currentTarget.dataset.productReimportText
              });
  }

  voucherInfoClick = (e: any) => {
    this.fill({
                TYPE: e.currentTarget.dataset.bsTarget,
                VOUCHER_HINT: e.currentTarget.dataset.voucherHint
              });
  }

  registerListeners = () => {
    this._productStatusModalTriggerList = document.querySelectorAll('[data-bs-target="#productStatusModal"]');
    this._productReimportModalTriggerList = document.querySelectorAll('[data-bs-target="#reimportInfoModal"]');
    this._voucherInfoModalTriggerList = document.querySelectorAll('[data-bs-target="#voucherInfoModal"]');

    this._productStatusModalTriggerList.forEach((el: Element) => {
      el.removeEventListener('click', this.onProductStatusClick);
      el.addEventListener('click', this.onProductStatusClick);
    })

    this._productReimportModalTriggerList.forEach((el: Element) => {
      el.removeEventListener('click', this.onReimportClick);
      el.addEventListener('click', this.onReimportClick);
    })

    this._voucherInfoModalTriggerList.forEach((el: Element) => {
      el.removeEventListener('click', this.voucherInfoClick);
      el.addEventListener('click', this.voucherInfoClick);
    })
  }

  fill = ($content: ModalContent) => {
    switch ($content.TYPE) {
      case "#productStatusModal":
        // icon color
        if ($content.PRODUCT_STATUS == "0" && $content.PRODUCT_PRESCRIPTION_STATUS == "0") {
          this._productstate_iconColor.classList.add('red-icon');
        } else {
          this._productstate_iconColor.classList.remove('red-icon');
        }
        // icon
        if ($content.PRODUCT_PRESCRIPTION_STATUS == "0" || $content.PRODUCT_PRESCRIPTION_STATUS == "1" && $content.PRODUCT_STATUS == "1") {
          this._productstate_iconBox.classList.remove('d-none');
          this._productstate_iconCart.classList.add('d-none');
        } else {
          this._productstate_iconBox.classList.add('d-none');
          this._productstate_iconCart.classList.remove('d-none');
        }
        // headline
        this._productstate_headline.innerHTML = $content.PRODUCT_STATUS_HEADLINE!;
        // status-info
        this._productstate_statusInfo.innerHTML = $content.PRODUCT_STATUS_INFORMATION!;
        // link
        if ($content.PRODUCT_STATUS == "0" && $content.PRODUCT_PRESCRIPTION_STATUS == "1") {
          this._productstate_link.classList.remove('d-none');
        } else {
          this._productstate_link.classList.add('d-none');
        }
        break;

      case "#reimportInfoModal":
        this._reimport_text.innerHTML = $content.PRODUCT_REIMPORT_TEXT!;
        break;

      case "#voucherInfoModal":
        this._voucher_hint_text.innerHTML = $content.VOUCHER_HINT!;
        break;
    }
  }

}