import { usePagination, UsePaginationProps } from "react-instantsearch";
import { isModifierClick } from "../searchpage";

export const CustomPagination = (props: UsePaginationProps) => {
  const {
    pages,
    currentRefinement,
    isFirstPage,
    isLastPage,
    refine,
    createURL,
  } = usePagination(props)
  const previousPageIndex = currentRefinement - 1
  const nextPageIndex = currentRefinement + 1

  return (
    <div className={"d-flex justify-content-center w-100"}>
      <ul className={"flex-wrap pagination pagination-md"}>
        <PaginationItem
          isDisabled={isFirstPage}
          isCurrentRefinement={false}
          href={createURL(previousPageIndex)}
          onClick={() => refine(previousPageIndex)}
        >
          <svg className="svg-inline--fa fa-chevron-left">
            <use xlinkHref="#icon_chevron-left"></use>
          </svg>
        </PaginationItem>
        {pages.map((page) => {
          const label = page + 1;

          return (
            <PaginationItem
              key={page}
              isDisabled={false}
              isCurrentRefinement={currentRefinement == page}
              aria-label={`Page ${label}`}
              href={createURL(page)}
              onClick={() => refine(page)}
            >
              {label}
            </PaginationItem>
          );
        })}
        <PaginationItem
          isDisabled={isLastPage}
          isCurrentRefinement={false}
          href={createURL(nextPageIndex)}
          onClick={() => refine(nextPageIndex)}
        >
          <svg className="svg-inline--fa fa-chevron-right">
            <use xlinkHref="#icon_chevron-right"></use>
          </svg>
        </PaginationItem>
      </ul>
    </div>
  )
}

type PaginationItemProps = Omit<React.ComponentProps<'a'>, 'onClick'> & {
  onClick: NonNullable<React.ComponentProps<'a'>['onClick']>;
  isDisabled: boolean;
  isCurrentRefinement: boolean;
};

const scrollUp = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

const PaginationItem = ({ isDisabled, isCurrentRefinement, href, onClick, ...props }: PaginationItemProps) => {
  if (isDisabled) {
    return (
      <li className={"page-item disabled"}>
        <span className={"page-link"} {...props} />
      </li>
    );
  }

  return (
    <li className={isCurrentRefinement ? "page-item active" : "page-item "}>
      <a
        href={href}
        className={"page-link"}
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }
          scrollUp()
          event.preventDefault();

          onClick(event);
        }}
        {...props}
      />
    </li>
  );
}