import {AutoSuggestType} from "./auto-suggest"

export enum AutoSuggestApiDefaults {
  url = 'ajax/auto_suggest.php'
}

export interface AutoSuggestApiInterface {
  url: string
}

export class AutoSuggestApi {
  public url: string

  constructor(config: AutoSuggestApiInterface = AutoSuggestApiDefaults) {
    this.url = config.url
  }

  public async getData(keyword: string): Promise<AutoSuggestType> {
    const params = {input: keyword}
    const method = 'GET'
    const url = this.url + '?' + (new URLSearchParams(params)).toString()

    return fetch(url, {method: method}).then(response => response.json()).catch(error => console.error(error))
  }
}