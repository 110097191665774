import React, {useCallback, useEffect, useState, useMemo, memo, ReactNode} from "react";
import {EqualHeightProvider, defaults} from "../../state/EqualHeight";

interface Props {
  children: ReactNode;
  timeout?: number;
  animationSpeed?: number;
  updateOnChange?: unknown;
}

export interface SizesProps {
  name: string;
  height: number;
}

export interface StatesProps {
  sizes: SizesProps[];
  temporarySizes: SizesProps[];
  update: boolean;
  forceUpdate: boolean;
  originalChildrenCount: number;
  childrenCount: number;
  updateOnChange?: unknown;
}

export const EqualHeight = memo((props: Props) => {
  const {
    children,
    timeout = defaults.timeout,
    animationSpeed = defaults.animationSpeed,
    updateOnChange = defaults.updateOnChange
  } = props;

  const [sizes, setSizes] = useState<StatesProps["sizes"]>(defaults.sizes);
  const [temporarySizes, setTemporarySizes] = useState<StatesProps["sizes"]>(defaults.temporarySizes);
  const [update, setUpdate] = useState<StatesProps["update"]>(defaults.update);
  const [forceUpdate, setForceUpdate] = useState<StatesProps["forceUpdate"]>(defaults.forceUpdate);
  const [originalChildrenCount, setOriginalChildrenCount] = useState<StatesProps["originalChildrenCount"]>(defaults.originalChildrenCount);
  const [childrenCount, setChildrenCount] = useState<StatesProps["childrenCount"]>(defaults.childrenCount);
  const handleUpdate = useCallback(() => setUpdate(value => !value), []);

  useEffect(() => {
    let resizeTimer: number;
    let orientationChangeTimer: number;
    const browser: boolean = typeof window === 'object' && typeof window.document === 'object';

    if (browser) {
      window.addEventListener('resize', timeout ? () => {
        clearTimeout(resizeTimer);
        resizeTimer = window.setTimeout(handleUpdate, timeout);
      } : handleUpdate);

      window.addEventListener('orientationchange', timeout ? () => {
        clearTimeout(orientationChangeTimer);
        orientationChangeTimer = window.setTimeout(handleUpdate, timeout);
      } : handleUpdate);

      return () => {
        window.removeEventListener('resize', handleUpdate);
        window.removeEventListener('orientationchange', handleUpdate);
      };
    }
    return
  }, []);

  useMemo(() => {
    handleUpdate();
  }, [forceUpdate, originalChildrenCount, updateOnChange]);

  useMemo(() => {
    if (originalChildrenCount <= childrenCount) {
      let filteredSizes: SizesProps[] = [];
      temporarySizes.map((filteredSize) => {
        const name = filteredSize.name;
        const height = filteredSize.height;
        const elementIndex: number = filteredSizes.findIndex((e) => e.name === name);
        if (elementIndex > -1) {
          const savedHeight: number = filteredSizes[elementIndex].height;
          if (savedHeight < height) {
            filteredSizes[elementIndex].height = height;
          }
        } else {
          filteredSizes = [...filteredSizes, {
            name,
            height
          }]
        }
      });
      setSizes(filteredSizes);
      setTemporarySizes([]);
      setChildrenCount(0);
    }
  }, [childrenCount]);

  return (
    <EqualHeightProvider value={{
      sizes,
      temporarySizes,
      update,
      animationSpeed,
      forceUpdate,
      originalChildrenCount,
      childrenCount,
      setTemporarySizes,
      setOriginalChildrenCount,
      setChildrenCount,
      setForceUpdate,
      updateOnChange
    }}>
      {children}
    </EqualHeightProvider>
  );
});
